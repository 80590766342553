import { SET_ERROR_MESSAGES, SET_SUCCESS_MESSAGES } from './actions';

const defaultState = {
	errorMessages: [],
	successMessages: []
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case SET_ERROR_MESSAGES:
			return { ...state, errorMessages: action.payload };
		case SET_SUCCESS_MESSAGES:
			return { ...state, successMessages: action.payload };
		default:
			return state;
	}
};

export default reducers;
