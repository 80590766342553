import React, { useState } from 'react';
import classNames from 'classnames';
import { useEnvUtils } from 'utils/env/env';

import Pagination from 'components/Dev/Pagination/Pagination';
import FlowSelector from 'components/Dev/FlowSelector/FlowSelector';
import SubpageSelector from 'components/Dev/SubpageSelector/SubpageSelector';

import styles from './Dev.module.scss';

const Dev = () => {
	const { showDebugNavigation } = useEnvUtils();

	const [showTerminal, setShowTerminal] = useState(false);

	const toggleTerminal = () => {
		setShowTerminal(!showTerminal);
	};

	return (
		<>
			{showDebugNavigation() && (
				<div className={styles.devTerminal}>
					<div className={classNames([styles.devTerminalInner, showTerminal ? styles.active : null])}>
						<Pagination />
						<FlowSelector />
						<SubpageSelector />
					</div>
					<div className={styles.mobileTrigger} onClick={toggleTerminal}>
						<span>&lt;/&gt;</span>
					</div>
				</div>
			)}
		</>
	);
};
export default Dev;
