import _ from 'lodash';
import { SET_IS_LOADING_PAGE } from './actions';

const defaultState = {
	isLoadingPage: false
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case SET_IS_LOADING_PAGE:
			return _.merge({}, state, { isLoadingPage: action.payload });
		default:
			return state;
	}
};

export default reducers;
