export const useRefUtils = () => {
	const focusRef = (ref, refToGo) => {
		if (ref && refToGo) {
			const item = refToGo.current;
			if (item && item.focus) {
				item.focus();
			} else if (item && item.element && item.element.focus) {
				item.element.focus();
			}
		}
	};

	return {
		focusRef
	};
};
