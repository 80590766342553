import _ from 'lodash';
import { UPDATE_TERMS_AND_CONDITIONS } from './actions';

const defaultState = {
	acceptTermsAndConditions: false,
	ownResponsibility: false,
	stockBasicInfo: false,
	acceptSchufa: false,
	acceptPpi: false,
	acceptTermsAndConditions2: false,
	acceptSchufa2: false
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case UPDATE_TERMS_AND_CONDITIONS:
			return _.merge({}, state, {
				acceptTermsAndConditions: action.payload.acceptTermsAndConditions,
				ownResponsibility: action.payload.ownResponsibility,
				stockBasicInfo: action.payload.stockBasicInfo,
				acceptSchufa: action.payload.acceptSchufa,
				acceptPpi: action.payload.acceptPpi,
				acceptTermsAndConditions2: action.payload.acceptTermsAndConditions2,
				acceptSchufa2: action.payload.acceptSchufa2
			});
		default:
			return state;
	}
};

export default reducers;
