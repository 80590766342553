const defaultState = {
	countryCode: '49',
	mobileNumber: null,
	encryptedMobileNumber: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_COUNTRY_CODE':
			return { ...state, countryCode: action.payload };
		case 'SET_MOBILE_NUMBER':
			return { ...state, mobileNumber: action.payload };
		case 'SET_ENCRYPTED_MOBILE_NUMBER':
			return { ...state, encryptedMobileNumber: action.payload };
		default:
			return state;
	}
};

export default reducers;
