import React, { useEffect } from 'react';
import classNames from 'classnames';
import { CREDIT_RUNTIME_STEPS } from 'constants/Credit';
import { m } from 'utils/messages/messages';
import { useCreditUtils } from 'utils/credit/credit';

import RangeSliderNumberInput from 'components/Atoms/Form/RangeSlider/RangeSliderNumberInput/RangeSliderNumberInput';
import RangeSliderInput from 'components/Atoms/Form/RangeSlider/RangeSliderInput/RangeSliderInput';

import { useDispatch, useSelector } from 'react-redux';
import { updateRuntime } from 'store/App/actions';

import styles from './CreditCalculator.module.scss';

const CreditCalculatorRuntime = () => {
	const { getValidRuntimeLimit } = useCreditUtils();

	const dispatch = useDispatch();
	const storeRuntime = useSelector((state => state.app.runtime));
	const storeRuntimeLimit = useSelector((state => state.app.runtimeLimit));
	const storeRuntimeNotification = useSelector((state => state.app.runtimeNotification));
	const storeSidebarExtensionActive = useSelector((state => state.app.sidebarExtensionActive));
	const storeSidebarRuntimeAnimation = useSelector((state => state.app.sidebarRuntimeAnimation));

	useEffect(() => {
		let maxRuntimeValueObj = getValidRuntimeLimit(storeRuntimeLimit);
		let maxRuntimeIndex = maxRuntimeValueObj.validRuntimeIndex;
		let maxRuntimeValue = maxRuntimeValueObj.validRuntimeValue;

		if (maxRuntimeIndex < CREDIT_RUNTIME_STEPS.indexOf(storeRuntimeLimit)) {
			dispatch(updateRuntime(maxRuntimeValue));
		}
	}, [storeRuntimeLimit]);

	const checkChangingStep = value => {
		let newRuntimeInMonths = CREDIT_RUNTIME_STEPS[value];
		if (newRuntimeInMonths <= storeRuntimeLimit && storeRuntime !== newRuntimeInMonths) {
			dispatch(updateRuntime(newRuntimeInMonths));
		}
	};

	return (
		<div
			className={classNames(styles.runtimeWrapper, {
				[styles.hideRuntimeWrapper]: storeSidebarExtensionActive,
				[styles.isOnTop]: storeSidebarRuntimeAnimation
			})}
		>
			<div className={styles.rangeSlider}>
				<RangeSliderNumberInput
					label={m('wishRuntime.label', 'fields')}
					value={storeRuntime}
					suffix={m('month.plural', 'fields')}
					hasTooltipIcon
					tooltipIconContent={
						<>{m('wishRuntime.tooltip', 'fields')}</>
					}
					tooltipIconPosition='top'
				/>
				<RangeSliderInput
					min={0}
					max={CREDIT_RUNTIME_STEPS.length - 1}
					label={m('wishRuntime.label', 'fields')}
					onChangeHandler={checkChangingStep}
					value={CREDIT_RUNTIME_STEPS.indexOf(storeRuntime)}
					step={1}
				/>
				{storeRuntimeNotification && (
					<p className={styles.extraCopy}>
						{m('wishRuntime.extraCopy', 'fields')}
					</p>
				)}
			</div>
		</div>
	);
};
export default CreditCalculatorRuntime;
