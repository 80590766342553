import _ from 'lodash';

const defaultState = {
	amount: null,
	runtime: null,
	paymentType: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_AMOUNT':
			return _.merge({}, state, {
				amount: (action.payload || action.payload === 0) && !isNaN(action.payload)
					? parseInt(action.payload + '')
					: null
			});
		case 'SET_RUNTIME':
			return _.merge({}, state, {
				runtime: (action.payload || action.payload === 0) && !isNaN(action.payload)
					? parseInt(action.payload + '')
					: null
			});
		case 'SET_PAYMENT_TYPE':
			return _.merge({}, state, {
				paymentType: action.payload
			});
		default:
			return state;
	}
};

export default reducers;
