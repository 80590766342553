import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { useScrollUtils } from 'utils/scroll/scroll';

import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';

import styles from './Modal.module.scss';

const Modal = props => {
	const id = uuid();
	const { toggleBodyScroll } = useScrollUtils();

	useEffect(() => {
		toggleBodyScroll(props.isOpen);
	}, [props.isOpen]);

	useEffect(() => {
		ReactModal.setAppElement('body');
	}, []);

	return (
		<ReactModal
			id={id}
			isOpen={props.isOpen}
			onRequestClose={props.onClose}
			overlayClassName={styles.overlay}
			className={classNames(styles.content, props.smallWidth ? styles.smallWidth : null)}
			closeTimeoutMS={150}
		>
			<div
				className={styles.closeButton}
				onClick={props.onClose}
			>
				<SvgClose />
			</div>
			{props.content ?? null}
		</ReactModal>
	);
};

Modal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	content: PropTypes.element,
	smallWidth: PropTypes.bool
};

export default Modal;
