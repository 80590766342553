/** Actions Type */
const UPDATE_HAS_SECOND_APPLICANT = 'UPDATE_HAS_SECOND_APPLICANT';
const UPDATE_NAME_2 = 'UPDATE_NAME_2';
const UPDATE_BIRTHDATE_2 = 'UPDATE_BIRTHDATE_2';
const UPDATE_MOBILE_NUMBER_2 = 'UPDATE_MOBILE_NUMBER_2';
const SET_NATIONALITY_2 = 'SET_NATIONALITY_2';
const UPDATE_MARITAL_STATUS_2 = 'UPDATE_MARITAL_STATUS_2';
const UPDATE_OCCUPATION_2 = 'UPDATE_OCCUPATION_2';
const SET_REFERENCE_IBAN_2 = 'SET_REFERENCE_IBAN_2';
const INIT_DIGITAL_ACCOUNT_CHECK_2 = 'INIT_DIGITAL_ACCOUNT_CHECK_2';
const DAC_PROCESS_RESULT_SUCCESS_2 = 'DAC_PROCESS_RESULT_SUCCESS_2';
const DIGITAL_ACCOUNT_CHECK_FAILED_2 = 'DIGITAL_ACCOUNT_CHECK_FAILED_2';
const UPDATE_FINANCIAL_STATUS_2 = 'UPDATE_FINANCIAL_STATUS_2';

/** Actions Creators */
export const onUpdateHasSecondApplicant = payload => ({
	type: UPDATE_HAS_SECOND_APPLICANT,
	payload
});

export const onUpdateName2 = payload => ({
	type: UPDATE_NAME_2,
	payload
});

export const updateBirthdate2 = payload => ({
	type: UPDATE_BIRTHDATE_2,
	payload
});

export const onUpdateMobileNumber2 = payload => ({
	type: UPDATE_MOBILE_NUMBER_2,
	payload
});

export const setNationality2 = payload => ({
	type: SET_NATIONALITY_2,
	payload
});

export const onUpdateMaritalStatus2 = payload => ({
	type: UPDATE_MARITAL_STATUS_2,
	payload
});

export const onUpdateOccupation2 = payload => ({
	type: UPDATE_OCCUPATION_2,
	payload
});

export const setReferenceIban2 = payload => ({
	type: SET_REFERENCE_IBAN_2,
	payload
});

export const onInitDigitalAccountCheck2 = payload => ({
	type: INIT_DIGITAL_ACCOUNT_CHECK_2,
	payload
});

export const dacProcessResultSuccess2 = payload => ({
	type: DAC_PROCESS_RESULT_SUCCESS_2,
	payload
});

export const onDigitalAccountCheckFailed2 = payload => ({
	type: DIGITAL_ACCOUNT_CHECK_FAILED_2,
	payload
});

export const onUpdateFinancialStatus2 = payload => ({
	type: UPDATE_FINANCIAL_STATUS_2,
	payload
});
