import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from 'config/history';

import store from 'store';
import axios from 'axios';

import App from 'components/App/App';

import 'assets/styles/styles.scss';

import reportWebVitals from 'reportWebVitals';

axios.defaults.headers.common['Content-Type'] = 'application/json';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router history={history}>
				<App />
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();
