import React, { useEffect } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { Text, Title } from 'components/Atoms/Text';

const ThankYouSanctions = () => {
	const { getCurrentFlow } = useUtils();
	const { preventHistoryBackFor } = usePageUtils();
	const { ecommercePurchase } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.NATIONALITY);
		ecommercePurchase();
	}, []);

	return (
		<>
			<Title>{m('pages.thankYou.sanctions.title', 'global')}</Title>
			<Text>{m('pages.thankYou.sanctions.description', 'global', null, true)}</Text>
		</>
	);
};
export default ThankYouSanctions;
