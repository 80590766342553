import React, { useState } from 'react';
import C from 'constants/Constants';
import { C as Autocomplete } from 'constants/enums/Autocomplete';
import { DATE_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useDateUtils } from 'utils/date/date';
import { useFlowUtils } from 'utils/flow/flow';

import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import { TextInput } from 'components/Atoms/Form';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setBirthdate } from 'store/Birthdate/actions';

const Birthdate = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, isBirthdateValid } = useValidationUtils();
	const { formatFullDate, formatDateForInput } = useDateUtils();
	const { gmkHyundaiMsgPrefix } = useFlowUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeBirthdate = useSelector((state => state.birthdate.birthdate));

	const [birthdateError, setBirthdateError] = useState(null);

	const clearErrors = () => {
		setBirthdateError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isValidBirthdate = true;

		if (isEmpty(storeBirthdate)) {
			setBirthdateError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else {
			const formattedBirthdate = formatFullDate(storeBirthdate);
			isValidBirthdate = isBirthdateValid(formattedBirthdate, setBirthdateError);
			if (isValidBirthdate) {
				dispatch(setBirthdate(formattedBirthdate));
			}
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields && isValidBirthdate;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.EMAIL);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const onPrevButton = () => {
		if (currentFlow === C.FRONTEND.SPARBRIEF) {
			goToPage(currentFlow + C.ROUTES.NAME);
		} else {
			goToPage(currentFlow + C.ROUTES.INDEX);
		}
	};

	const autofill = () => {
		dispatch(setBirthdate('31.01.1990'));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title>{m('pages.birthdate.title', 'global')}</Title>
			<Text mb>{m(`${gmkHyundaiMsgPrefix}birthdate.subtitle`, currentFlow)}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={3}
				onPrevButton={onPrevButton}
			>
				<TextInput
					value={storeBirthdate}
					setValue={(value) => { dispatch(setBirthdate(value)); }}
					label={m('birthdate.label', 'fields')}
					hasError={!!birthdateError}
					message={birthdateError ?? m('birthdate.placeholder', 'fields')}
					maxLength={10}
					regex={DATE_INPUT}
					autoComplete={Autocomplete.BDAY}
					format={formatDateForInput}
					tabindex={1}
					testId="birthdate"
				/>
				<p>{m('acceptPrivacyPolicies.description', 'fields', null, true)}</p>
			</Form>
		</>
	);
};
export default Birthdate;
