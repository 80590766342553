import { SET_HAS_PPI, SET_PPI_ALREADY_EXISTS, SET_PROTECT, SET_PROTECT_RUNTIME, SET_PROTECT_MONTHLY_RATE } from './actions';

export const defaultState = {
	hasPpi: null,
	ppiAlreadyExists: false,
	protect: null,
	protectRuntime: null,
	protectMonthlyRate: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case SET_HAS_PPI:
			return { ...state, hasPpi: action.payload };
		case SET_PPI_ALREADY_EXISTS:
			return { ...state, ppiAlreadyExists: action.payload };
		case SET_PROTECT:
			return { ...state, protect: action.payload };
		case SET_PROTECT_RUNTIME:
			return { ...state, protectRuntime: action.payload };
		case SET_PROTECT_MONTHLY_RATE:
			return { ...state, protectMonthlyRate: action.payload };
		default:
			return state;
	}
};

export default reducers;
