import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M17.974 4H6.029A3.032 3.032 0 0 0 3 7.028v6.944A3.032 3.032 0 0 0 6 17v2.5a.5.5 0 0 0 .854.354L9.707 17h8.264A3.032 3.032 0 0 0 21 13.972V7.026A3.03 3.03 0 0 0 17.974 4zM20 13.972C20 15.09 19.09 16 17.971 16H9.5a.5.5 0 0 0-.354.146L7 18.293V16.5a.5.5 0 0 0-.5-.5h-.471A2.031 2.031 0 0 1 4 13.972V7.028C4 5.91 4.91 5 6.029 5h11.945C19.09 5 20 5.91 20 7.026v6.946z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
