export const useUrlUtils = () => {
	const replaceCharsForUrl = (url) => {
		return url
			.replace(/ /g, '%20')
			.replace(/\u00c4/g, '%C3%84')
			.replace(/\u00d6/g, '%C3%96')
			.replace(/\u00dc/g, '%C3%9C')
			.replace(/\u00df/g, '%C3%9F')
			.replace(/\u00e4/g, '%C3%A4')
			.replace(/\u00f6/g, '%C3%B6')
			.replace(/\u00fc/g, '%C3%BC');
	};

	return {
		replaceCharsForUrl
	};
};
