import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Divider.module.scss';

const Divider = (props) => {
	return (
		<div
			className={classNames([
				styles.divider,
				props.onlyMobile ? styles.dividerMobile : null,
				props.noMarginTop ? styles.noMarginTop : null,
				props.noMarginBottom ? styles.noMarginBottom : null,
				props.noLine ? styles.noLine : null
			])}
		/>
	);
};

Divider.propTypes = {
	onlyMobile: PropTypes.bool,
	noMarginTop: PropTypes.bool,
	noMarginBottom: PropTypes.bool,
	noLine: PropTypes.bool
};

export default Divider;
