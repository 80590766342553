export const CREDIT_AMOUNT_MIN_VALUE = 1000;
export const CREDIT_AMOUNT_MAX_VALUE = 75000;
export const CREDIT_AMOUNT_DEFAULT = 10000;
export const CREDIT_RUNTIME_STEPS = [12, 18, 24, 36, 48, 60, 72, 84, 96];
export const CREDIT_RUNTIME_DEFAULT = 84;

export const PPI_MAX_AGE_DEFAULT = 74;
export const PPI_MIN_SENIOR_AGE_DEFAULT = 57;

export const PPI_MAX_AGE_CREDIT = 78;
export const PPI_MIN_SENIOR_AGE_CREDIT = 74;
export const PROTECT_MAX_AGE_CREDIT = 65;
