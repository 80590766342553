import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { useKeycodeUtils } from 'utils/keycodes/keycodes';

import SvgCheckBold from 'components/Atoms/SVG/Icons/SvgCheckBold';

import inputStyles from 'components/Atoms/Form/index.module.scss';
import styles from './CheckboxInput.module.scss';

export const CheckboxInput = (props) => {
	const { getReturn } = useKeycodeUtils();
	const [id] = useState(uuid());

	const toggle = () => {
		if (!props.disabled) {
			props.setValue(!props.value);
		}
	};

	const handleKeyDown = (event) => {
		if (getReturn(event)) {
			event.preventDefault();
			toggle();
		}
	};

	return (
		<div className={styles.wrapper}>
			<input
				id={id}
				type="checkbox"
				className={classNames([
					styles.checkbox,
					props.hasError ? styles.error : null,
					props.hasError ? 'input--error' : null
				])}
				checked={!!props.value}
				onKeyDown={handleKeyDown}
				onChange={() => {}}
				disabled={props.disabled}
				tabIndex={props.tabindex}
				data-testid={props.testId ?? null}
			/>
			<div className={classNames([styles.pseudoCheckbox])} onClick={toggle}>
				<div className={styles.pseudoCheckboxCheck}>
					<SvgCheckBold />
				</div>
			</div>
			{props.label && (
				<label className={styles.label} onClick={toggle}>
					{props.label}
				</label>
			)}
			{!!props.message && (
				<div
					className={classNames([
						styles.message,
						inputStyles.message,
						props.message ? inputStyles.show : null
					])}
				>
					{props.message}
				</div>
			)}
		</div>
	);
};

CheckboxInput.propTypes = {
	value: PropTypes.bool,
	setValue: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	testId: PropTypes.string
};

export default CheckboxInput;
