import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M16.038 2c1.033 0 1.882.802 1.957 1.816l.005.146v16.076a1.965 1.965 0 0 1-1.816 1.957l-.146.005H7.962a1.965 1.965 0 0 1-1.957-1.816L6 20.038V3.962c0-1.032.802-1.882 1.816-1.957L7.962 2h8.076zm0 1H7.962c-.492 0-.9.372-.956.85L7 3.962v16.076c0 .492.372.9.85.956l.112.006h8.076c.492 0 .9-.372.956-.85l.006-.112V3.962c0-.492-.372-.9-.85-.956L16.038 3zM12.5 19a.5.5 0 0 1 .09.992L12.5 20h-1a.5.5 0 0 1-.09-.992L11.5 19h1zm1-15a.5.5 0 0 1 .09.992L13.5 5h-3a.5.5 0 0 1-.09-.992L10.5 4h3z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
