const defaultState = {
	hasStockProficiency: null,
	bonds: false,
	bondsFrequency: null,
	bondsVolume: null,
	stocks: false,
	stocksFrequency: null,
	stocksVolume: null,
	fundOfFunds: false,
	fundOfFundsFrequency: null,
	fundOfFundsVolume: null,
	openPropertyFunds: false,
	openPropertyFundsFrequency: null,
	openPropertyFundsVolume: null,
	certificates: false,
	certificatesFrequency: null,
	certificatesVolume: null,
	derivates: false,
	derivatesFrequency: null,
	derivatesVolume: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_HAS_STOCK_PROFICIENCY':
			return { ...state, hasStockProficiency: action.payload };

		case 'SET_BONDS':
			return { ...state, bonds: action.payload };
		case 'SET_BONDS_FREQUENCY':
			return { ...state, bondsFrequency: action.payload };
		case 'SET_BONDS_VOLUME':
			return { ...state, bondsVolume: action.payload };

		case 'SET_STOCKS':
			return { ...state, stocks: action.payload };
		case 'SET_STOCKS_FREQUENCY':
			return { ...state, stocksFrequency: action.payload };
		case 'SET_STOCKS_VOLUME':
			return { ...state, stocksVolume: action.payload };

		case 'SET_FUND_OF_FUNDS':
			return { ...state, fundOfFunds: action.payload };
		case 'SET_FUND_OF_FUNDS_FREQUENCY':
			return { ...state, fundOfFundsFrequency: action.payload };
		case 'SET_FUND_OF_FUNDS_VOLUME':
			return { ...state, fundOfFundsVolume: action.payload };

		case 'SET_OPEN_PROPERTY_FUNDS':
			return { ...state, openPropertyFunds: action.payload };
		case 'SET_OPEN_PROPERTY_FUNDS_FREQUENCY':
			return { ...state, openPropertyFundsFrequency: action.payload };
		case 'SET_OPEN_PROPERTY_FUNDS_VOLUME':
			return { ...state, openPropertyFundsVolume: action.payload };

		case 'SET_CERTIFICATES':
			return { ...state, certificates: action.payload };
		case 'SET_CERTIFICATES_FREQUENCY':
			return { ...state, certificatesFrequency: action.payload };
		case 'SET_CERTIFICATES_VOLUME':
			return { ...state, certificatesVolume: action.payload };

		case 'SET_DERIVATIVES':
			return { ...state, derivates: action.payload };
		case 'SET_DERIVATIVES_FREQUENCY':
			return { ...state, derivatesFrequency: action.payload };
		case 'SET_DERIVATIVES_VOLUME':
			return { ...state, derivatesVolume: action.payload };

		case 'RESET':
			return {
				...state,
				bonds: false,
				bondsFrequency: null,
				bondsVolume: null,
				stocks: false,
				stocksFrequency: null,
				stocksVolume: null,
				fundOfFunds: false,
				fundOfFundsFrequency: null,
				fundOfFundsVolume: null,
				openPropertyFunds: false,
				openPropertyFundsFrequency: null,
				openPropertyFundsVolume: null,
				certificates: false,
				certificatesFrequency: null,
				certificatesVolume: null,
				derivates: false,
				derivatesFrequency: null,
				derivatesVolume: null
			};
		default:
			return state;
	}
};

export default reducers;
