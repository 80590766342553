/** Actions Type */
const SET_HAS_UPSELL = 'SET_HAS_UPSELL';
const SET_UPSELL_ALREADY_DONE = 'SET_UPSELL_ALREADY_DONE';
const SET_MAX_UPSELL_AMOUNT = 'SET_MAX_UPSELL_AMOUNT';
const SET_MAX_MONTHLY_RATE = 'SET_MAX_MONTHLY_RATE';
const SET_CREDIT_AMOUNT_NET = 'SET_CREDIT_AMOUNT_NET';
const SET_INTEREST_AMOUNT = 'SET_INTEREST_AMOUNT';
const SET_RATE_MONTHLY_AMOUNT = 'SET_RATE_MONTHLY_AMOUNT';
const SET_RUNTIME_IN_MONTHS = 'SET_RUNTIME_IN_MONTHS';
const SET_REDEMTION_AMOUNT = 'SET_REDEMTION_AMOUNT';
const SET_EFFECTIVE_ANNUAL_INTEREST_RATE = 'SET_EFFECTIVE_ANNUAL_INTEREST_RATE';
const SET_FIXED_BORROWING_RATE = 'SET_FIXED_BORROWING_RATE';
const SET_UPSELL = 'SET_UPSELL';
const RESET_UPSELL = 'RESET_UPSELL';

/** Actions Creators */
export const setHasUpsell = payload => ({
	type: SET_HAS_UPSELL,
	payload
});

export const setUpsellAlreadyDone = payload => ({
	type: SET_UPSELL_ALREADY_DONE,
	payload
});

export const setMaxUpsellAmount = payload => ({
	type: SET_MAX_UPSELL_AMOUNT,
	payload
});

export const setMaxMonthlyRate = payload => ({
	type: SET_MAX_MONTHLY_RATE,
	payload
});

export const setCreditAmountNet = payload => ({
	type: SET_CREDIT_AMOUNT_NET,
	payload
});

export const setInterest = payload => ({
	type: SET_INTEREST_AMOUNT,
	payload
});

export const setRateMonthlyAmount = payload => ({
	type: SET_RATE_MONTHLY_AMOUNT,
	payload
});

export const setRuntimeInMonths = payload => ({
	type: SET_RUNTIME_IN_MONTHS,
	payload
});

export const setRedemtionAmount = payload => ({
	type: SET_REDEMTION_AMOUNT,
	payload
});

export const setEffectiveAnnualInterestRate = payload => ({
	type: SET_EFFECTIVE_ANNUAL_INTEREST_RATE,
	payload
});

export const setFixedBorrowingRate = payload => ({
	type: SET_FIXED_BORROWING_RATE,
	payload
});

export const setUpsell = payload => ({
	type: SET_UPSELL,
	payload
});

export const resetUpsell = payload => ({
	type: RESET_UPSELL,
	payload
});
