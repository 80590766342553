import React, { useEffect } from 'react';
import C from 'constants/Constants';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useApplicationApi } from 'api/application';

import LoadingPage from 'components/Atoms/Loading/Page/LoadingPage';

const ActivateCredit = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { postActivateAccountLoan } = useApplicationApi();

	const currentFlow = getCurrentFlow();

	useEffect(() => {
		postActivateAccountLoan(
			() => {
				continueValid();
				goToPage(currentFlow + C.ROUTES.LOAN_SUCCESS);
			},
			() => {
				continueInvalid();
				goToPage(currentFlow + C.ROUTES.LOAN_DONE);
			}
		);
	}, []);

	return <LoadingPage />;
};
export default ActivateCredit;
