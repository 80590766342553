export const defaultState = {
	email: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_EMAIL':
			return { ...state, email: action.payload };
		default:
			return state;
	}
};

export default reducers;
