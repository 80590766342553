import _ from 'lodash';

export const defaultState = {
	hasSecondApplicant: null,
	salutation2: '',
	firstName2: '',
	lastName2: '',
	day2: '',
	month2: '',
	year2: '',
	countryCode2: '49',
	mobileNumber2: '',
	nationality2: 'DE',
	maritalStatus2: '',
	occupation2: '',
	occupationMonth2: '',
	occupationYear2: '',
	occupationLimited2: '',
	occupationLimitedMonth2: '',
	occupationLimitedYear2: '',
	referenceIban2: '',
	dacSessionKey2: '',
	dacTransaction2: '',
	dacStatus2: null,
	dacEnabled2: true,
	income2: '',
	additionalIncome2: '',
	expenditure2: '',
	typeOfLiving2: '',
	otherExpenses2: '',
	financialStatusOption2: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_HAS_SECOND_APPLICANT':
			return { ...state, hasSecondApplicant: action.payload };
		case 'UPDATE_NAME_2':
			return _.merge({}, state, {
				salutation2: action.payload.salutation2,
				firstName2: action.payload.firstName2,
				lastName2: action.payload.lastName2
			});
		case 'UPDATE_BIRTHDATE_2':
			return _.merge({}, state, {
				day2: action.payload.day2,
				month2: action.payload.month2,
				year2: action.payload.year2
			});
		case 'UPDATE_MOBILE_NUMBER_2':
			return _.merge({}, state, {
				countryCode2: action.payload.countryCode2,
				mobileNumber2: action.payload.mobileNumber2
			});
		case 'SET_NATIONALITY_2':
			return _.merge({}, state, {
				nationality2: action.payload.nationality2
			});
		case 'UPDATE_MARITAL_STATUS_2':
			return _.merge({}, state, {
				maritalStatus2: action.payload.maritalStatus2
			});
		case 'UPDATE_OCCUPATION_2':
			return _.merge({}, state, {
				occupation2: action.payload.occupation2,
				occupationMonth2: action.payload.occupationMonth2,
				occupationYear2: action.payload.occupationYear2,
				occupationLimited2: action.payload.occupationLimited2,
				occupationLimitedMonth2: action.payload.occupationLimitedMonth2,
				occupationLimitedYear2: action.payload.occupationLimitedYear2
			});
		case 'SET_REFERENCE_IBAN_2':
			return _.merge({}, state, {
				referenceIban2: action.payload.referenceIban2
			});
		case 'INIT_DIGITAL_ACCOUNT_CHECK_2':
			return _.merge({}, state, {
				dacSessionKey2: action.payload.dacSessionKey2,
				dacTransaction2: action.payload.dacTransaction2
			});
		case 'DAC_PROCESS_RESULT_SUCCESS_2':
			return _.merge({}, state, {
				dacStatus2: action.payload.dacStatus2
			});
		case 'DIGITAL_ACCOUNT_CHECK_FAILED_2':
			return _.merge({}, state, {
				dacEnabled2: false
			});
		case 'UPDATE_FINANCIAL_STATUS_2':
			return _.merge({}, state, {
				income2: action.payload.income2,
				additionalIncome2: action.payload.additionalIncome2,
				expenditure2: action.payload.expenditure2,
				typeOfLiving2: action.payload.typeOfLiving2,
				otherExpenses2: action.payload.otherExpenses2,
				financialStatusOption2: action.payload.financialStatusOption2
			});
		default:
			return state;
	}
};

export default reducers;
