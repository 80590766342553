import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'components/Atoms/Grid';
import { Text, Link } from 'components/Atoms/Text';

import styles from './Footer.module.scss';

const Footer = (props) => {
	const openCookieLayer = () => {
		try {
			window.UC_UI.showSecondLayer();
		} catch(e) {
			console.log('usercentrics not active');
		}
	};

	return (
		<>
			{props.extension}
			<div className={styles.wrapper}>
				<div className={styles.inner}>
					<Container>
						<Row justifyContent='center'>
							<Col xs={12} md={10} lg={8} xl={7} xxl={6} xxxl={5}>
								<Text>
									<Link href="https://www.santander.de/privatkunden/service-kontakt/impressum/?_ga=2.203959888.1328201376.1613030133-1589327932.1613030133" target="_blank">
										Impressum
									</Link>
									&nbsp;&nbsp;<span className='link primary no-hover'>|</span>&nbsp;&nbsp;
									<Link href="https://www.santander.de/privatkunden/service-kontakt/datenschutz/?_ga=2.259360458.1328201376.1613030133-1589327932.1613030133" target="_blank">
										Datenschutz
									</Link>
									&nbsp;&nbsp;<span className='link primary no-hover'>|</span>&nbsp;&nbsp;
									<Link onClick={openCookieLayer}>
										Cookie Einstellungen
									</Link>
								</Text>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</>
	);
};

Footer.propTypes = {
	extension: PropTypes.any
};

export default Footer;
