import React from 'react';

const Svg = () => {
	const width = 243;
	const height = 183;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<g id="E1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="inbox-copy" fillRule="nonzero">
					<path d="M72.6526973,83.54021 C71.4086461,78.3447081 70.7486603,72.9117705 70.7486603,67.3205423 C70.7486603,30.165399 99.8938271,0 135.792336,0 C171.690845,0 200.836011,30.165399 200.836011,67.3205423 C200.836011,71.005193 200.549324,74.6211941 199.9976,78.1455686 C223.855207,78.8992921 243,99.1825997 243,124.057749 C243,149.409473 223.114641,169.991774 198.621441,169.991774 C195.882829,169.991774 191.928397,169.994516 186.758143,170 L41.0070945,170 L41.0070945,169.860716 C18.0860207,168.074133 0,148.234776 0,124.057749 C0,99.8807232 18.0860207,80.0413665 41.0070945,78.2547833 L41.0070945,78.1398949 L41.4798834,78.2204586 C42.4391648,78.1563481 43.4061207,78.1237255 44.3804767,78.1237255 C50.3968166,78.1237255 56.1349649,79.3653696 61.368531,81.6146263 L72.6526973,83.54021 Z" id="Primary-color" fill="#DEEDF2"></path>
					<rect id="Rectangle" fill="#A66555" x="126" y="109" width="23" height="51"></rect>
					<rect id="Rectangle" fillOpacity="0.403053977" fill="#F4A48D" x="143" y="109" width="6" height="51"></rect>
					<path d="M78,108 C59.7746032,108 45,123.080609 45,141.683484 L45,183 L111,183 L111,141.683484 C111,123.080609 96.2253968,108 78,108 Z M78.4885723,111.531107 C94.5802059,111.797412 107.544503,125.195104 107.544503,141.683484 L107.544503,179.472704 L48.4554974,179.472704 L48.4554974,141.683484 C48.4554974,125.028554 61.6830217,111.527066 78,111.527066 L78.4885723,111.531107 Z" id="Oval" fill="#137E84" transform="translate(78.000000, 145.500000) scale(-1, 1) rotate(-180.000000) translate(-78.000000, -145.500000) "></path>
					<path d="M109,59.2194094 C109,41.4251209 94.4492543,27 76.5,27 C58.5507457,27 44,41.4251209 44,59.2194094 C44,71.0822682 44,88.0091318 44,110 L109,110 C109,88.0091318 109,71.0822682 109,59.2194094 Z" id="Oval-Copy-13" fill="#137E84"></path>
					<g id="sobre-copy" transform="translate(34.000000, 60.000000)">
						<rect id="Primary-" fill="#FFE699" x="0" y="2.06896402e-07" width="85" height="47.9999998"></rect>
						<polygon id="Rectangle-Copy" fill="#FFEBAE" points="42.5 21.7553958 85 48 0 48"></polygon>
						<polygon id="Rectangle-Copy-2" fill="#FFCC33" transform="translate(42.500000, 17.266187) scale(-1, 1) rotate(-180.000000) translate(-42.500000, -17.266187) " points="42.5 0 85 34.532374 0 34.532374"></polygon>
						<polygon id="Rectangle" fill="#D39E00" points="84.6487603 2.06896402e-07 46.8543624 34.5323742 43.9049587 32.6113248"></polygon>
						<polygon id="Path" fill="#FFCC33" points="0 48 33.3677685 27.6258994 3.54407101 48"></polygon>
						<polygon id="Path-Copy-2" fill="#FFCC33" transform="translate(68.316116, 37.812950) scale(-1, 1) translate(-68.316116, -37.812950) " points="51.6322313 48 84.9999998 27.6258994 55.1763023 48"></polygon>
					</g>
					<path d="M111.070434,111 L188,111 L188,55.0329711 C187.343447,48.213152 184.755195,42.1156117 180.235248,36.7403503 C175.715301,31.3650886 169.237656,28.1183053 160.802312,27 L77,27 C87.8434005,28.3092526 95.7226166,31.556036 100.637648,36.7403503 C105.55268,41.9246645 108.444872,48.0222048 109.314226,55.0329711 L109.314226,111 L111.070434,111 Z" id="Path-7" fill="#137E84"></path>
					<path d="M178.572245,34 C178.935407,34.4286149 180.13938,35.5483584 180.481736,36.0042747 C186.278837,43.724276 187.417906,48.5248064 188,54.4087772 L187.992391,56.914415 L187.992391,60 L109.826377,60 L109.826526,55.7775807 C108.971469,48.0181321 106.146737,41.2593256 101.352327,35.5011615 L101.206439,35.326978 C100.822499,34.8712607 100.420353,34.4289345 100,34 L178.572245,34 Z" id="Combined-Shape" fill="#58AAAE"></path>
					<polygon id="Path-6" fill="#58AAAE" points="109 60 109 111 43 111 43 107.511641 105.5625 107.511641 105.5625 60"></polygon>
					<path d="M77.5,27 C96.3632856,27 111.690716,40.9359003 111.995378,59.4382345 L112,60 L108.443299,60 C108.443299,43.1727312 94.5895122,30.5021225 77.5,30.5021225 C60.581383,30.5021225 46.8341003,42.9012714 46.5608465,59.4961484 L46.556701,60 L43,60 C43,41.2385625 58.4461761,27 77.5,27 Z" id="Oval" fill="#58AAAE"></path>
					<g id="Group-5" transform="translate(115.000000, 14.000000)">
						<path d="M19,0 L19,8.1872681 L8.83352623,8.1872681 L8.83333693,64.1174898 C10.6523594,65.2015716 11.8824238,67.276631 11.8824238,69.658351 C11.8824238,73.160747 9.22245264,76 5.9412119,76 C2.65997117,76 0,73.160747 0,69.658351 C0,67.5503032 0.963623371,65.6824912 2.44634197,64.5294041 L2.44638138,0 L19,0 Z" id="Combined-Shape" fill="#137E84"></path>
						<ellipse id="Oval" fill="#FFFFFF" cx="5.9412119" cy="69.486955" rx="3.84431358" ry="4.11350206"></ellipse>
					</g>
					<path d="M108,142.708738 C108,125.196485 93.4492543,111 75.5,111 C57.5507457,111 43,125.196485 43,142.708738 C43,154.383573 43,167.48066 43,182 L108,182 C108,167.48066 108,154.383573 108,142.708738 Z" id="Oval" fill="#58AAAE" transform="translate(75.500000, 146.500000) scale(-1, 1) rotate(-180.000000) translate(-75.500000, -146.500000) "></path>
					<polygon id="Rectangle" fillOpacity="0.196809" fill="#79650C" points="88 60 107 60 107 107 96.9818182 107"></polygon>
					<g id="Group" transform="translate(165.000000, 90.000000)" fill="#58AAAE">
						<rect id="Rectangle" x="0" y="0" width="3.31578947" height="3.31578947"></rect>
						<rect id="Rectangle-Copy-5" x="5.5263158" y="0" width="3.31578947" height="3.31578947"></rect>
						<rect id="Rectangle-Copy-14" x="10.6842105" y="0" width="3.31578947" height="3.31578947"></rect>
						<rect id="Rectangle" x="0" y="5.5263158" width="3.31578947" height="3.31578947"></rect>
						<rect id="Rectangle-Copy-5" x="5.5263158" y="5.5263158" width="3.31578947" height="3.31578947"></rect>
						<rect id="Rectangle-Copy-14" x="10.6842105" y="5.5263158" width="3.31578947" height="3.31578947"></rect>
						<rect id="Rectangle" x="0" y="10.6842105" width="3.31578947" height="3.31578947"></rect>
						<rect id="Rectangle-Copy-5" x="5.5263158" y="10.6842105" width="3.31578947" height="3.31578947"></rect>
						<rect id="Rectangle-Copy-14" x="10.6842105" y="10.6842105" width="3.31578947" height="3.31578947"></rect>
					</g>
				</g>
			</g>
		</svg>
	);
};
export default Svg;
