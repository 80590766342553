import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { rootRoutes } from 'routes/rootRoutes';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';

import { Container, Row, Col } from 'components/Atoms/Grid';

import { useSelector } from 'react-redux';

import styles from 'components/Organisms/ProgressBar/ProgressBar.module.scss';

export const ProgressBar = props => {
	const { getCurrentFlow } = useUtils();

	const currentFlow = getCurrentFlow();

	const storeCurrentSubpage = useSelector((state => state.page.currentSubpage));
	const storeCurrentStepCategory = useSelector((state => state.page.currentStepCategory));

	const getStepsInCategory = (flow, category) => {
		return _.filter(rootRoutes, route => {
			return route.flow === flow && route.stepCategory === category;
		});
	};

	const getProgress = categoryStep => {
		if (storeCurrentStepCategory < categoryStep) {
			return 0;
		} else if (storeCurrentStepCategory > categoryStep) {
			return 100;
		} else {
			return calcProgress(
				getStepsInCategory(currentFlow, categoryStep)
			);
		}
	};

	const calcProgress = routes => {
		const index = _.findIndex(routes, route => {
			return route.subpage === storeCurrentSubpage;
		});
		return ((index + 1) / routes.length) * 100;
	};

	const categories = props.customCategories
		? props.customCategories
		: [
				m('progressBar.step1', currentFlow),
				m('progressBar.step2', currentFlow),
				m('progressBar.step3', currentFlow)
		  ];

	const progresses = [];
	for (let i = 0; i < categories.length; i++) {
		progresses.push(getProgress(i + 1));
	}

	return (
		<Container>
			<Row justifyContent="center">
				<Col xs={12} md={10} lg={8} xl={7} xxl={6} xxxl={5}>
					<div className={styles.progressBar}>
						<div className={styles.progressBarInner}>
							{categories.map((category, i) => (
								<p
									className={classNames([
										styles.progressBarText,
										storeCurrentStepCategory === i + 1
											? styles.active
											: null
									])}
									key={i}
								>
									{category}
								</p>
							))}
						</div>
						<div className={styles.progressBarInner}>
							{progresses.map((progress, i) => (
								<div className={styles.progressBarItem} key={i}>
									<span
										style={{
											width: progress + '%'
										}}
									/>
								</div>
							))}
						</div>
						{props.progressBarSubline && (
							<div className={styles.subline}>{props.progressBarSubline}</div>
						)}
					</div>
				</Col>
			</Row>
		</Container>
	);
};

ProgressBar.propTypes = {
	customCategories: PropTypes.array,
	progressBarSubline: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default ProgressBar;
