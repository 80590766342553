import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useDacUtils } from 'utils/dac/dac';

import { Text, Title } from 'components/Atoms/Text';
import Divider from 'components/Atoms/Divider/Divider';
import { Col, Row } from 'components/Atoms/Grid';
import SvgSecurity from 'components/Atoms/SVG/Icons/SvgSecurity';
import SvgSecurityVerified from 'components/Atoms/SVG/Icons/SvgSecurityVerified';

import { useSelector } from 'react-redux';

import styles from './DigitalAccountCheck.module.scss';

const DigitalAccountCheck = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage, preventHistoryBackWithNewLocation } = usePageUtils();
	const { continueInvalid, setApplicationInfoGtmSubStatus } = useTrackingUtils();
	const { initSession, finish, abort, login, error, bank, configure, init } = useDacUtils();

	const params = useParams();
	const currentFlow = getCurrentFlow();

	const storeDacSessionKey = useSelector((state => state.digitalAccountCheck.sessionKey));

	const [xs2aStatus, setXs2aStatus] = useState(C.DAC_XS2A_STATUS.BANK);
	const [isReminder, setIsReminder] = useState(false);
	const [dacIsLoaded, setDacIsLoaded] = useState(false);

	useEffect(() => {
		preventHistoryBackWithNewLocation(currentFlow + C.ROUTES.FINANCIAL_STATUS_DAC + (params.isReminder === 'reminder' ? '/reminder' : ''));
		parseParam();
		initSession(isReminder);
	}, []);

	const parseParam = () => {
		if (params.isReminder) {
			let isReminderParam = params.isReminder;
			if (
				isReminderParam !== undefined &&
				isReminderParam.length > 0 &&
				isReminderParam === 'reminder'
			) {
				setIsReminder(true);
			}
		}
	};

	useEffect(() => {
		setApplicationInfoGtmSubStatus(xs2aStatus);
	}, [xs2aStatus]);

	const onInvalid = () => {
		continueInvalid();
		goToPage(
			currentFlow +
			C.ROUTES.DIGITAL_ACCOUNT_CHECK_FAILURE +
			(isReminder ? '/reminder' : '')
		);
	};

	const onBack = () => {
		goToPage(
			currentFlow +
			C.ROUTES.FINANCIAL_STATUS_DAC +
			(isReminder ? '/reminder' : '')
		);
	};

	useEffect(() => {
		if (storeDacSessionKey && !dacIsLoaded) {
			finish(isReminder);
			abort(onInvalid);
			login(setXs2aStatus);
			error(onInvalid);
			bank();
			configure(onBack);
			init();
			setDacIsLoaded(true);
		}
	}, [storeDacSessionKey]);

	return (
		<>
			<Title>{m('pages.digitalAccountCheck.title', 'global')}</Title>
			<Text>{m('pages.digitalAccountCheck.subtitle', 'global')}</Text>

			<Divider noLine />
			<Row>
				<Col xs={12} sm={6}>
					<Row>
						<Col noGrow>
							<div className={styles.icon}>
								<SvgSecurity />
							</div>
						</Col>
						<Col grow>
							<Text>{m('pages.digitalAccountCheck.privacy', 'global')}</Text>
						</Col>
					</Row>
				</Col>
				<Col xs={12} sm={6}>
					<Row>
						<Col noGrow>
							<div className={styles.icon}>
								<SvgSecurityVerified />
							</div>
						</Col>
						<Col grow>
							<Text>{m('pages.digitalAccountCheck.ssl', 'global')}</Text>
						</Col>
					</Row>
				</Col>
			</Row>
			<Divider noLine noMarginBottom />

			<div
				className={classNames(styles.formWrapper, {
					[styles.isLogin]: xs2aStatus === C.DAC_XS2A_STATUS.ACCOUNT
				})}
			>
				<div
					id='XS2A-Form'
					className='XS2A-Form'
					data-testid='xs2a-entry-point'
					data-xs2a={storeDacSessionKey}
				/>
				{/*<DACTestForm />*/}
			</div>
		</>
	);
};
export default DigitalAccountCheck;
