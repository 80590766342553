/** Actions Type */
const UPDATE_CURRENT_FLOW = 'UPDATE_CURRENT_FLOW';
const UPDATE_CURRENT_SUBPAGE = 'UPDATE_CURRENT_SUBPAGE';
const UPDATE_CURRENT_STEP_CATEGORY = 'UPDATE_CURRENT_STEP_CATEGORY';

/** Actions Creators */
export const updateCurrentFlow = payload => ({
	type: UPDATE_CURRENT_FLOW,
	payload
});

export const updateCurrentSubpage = payload => ({
	type: UPDATE_CURRENT_SUBPAGE,
	payload
});

export const updateCurrentStepCategory = payload => ({
	type: UPDATE_CURRENT_STEP_CATEGORY,
	payload
});
