import React from 'react';
import PropTypes from 'prop-types';

import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

const Interest = (props) => {
	const { formatMoneyValue } = useNumberUtils();
	const { getInterest } = useCreditUtils();

	return (
		<>
			{props.isDecimal
				? formatMoneyValue(getInterest(), true)
				: getInterest()}
		</>
	);
};

Interest.propTypes = {
	isDecimal: PropTypes.bool
};

export default Interest;
