/**
 * The redux store
 *
 * Created by Perry Tang
 * Last modified by Perry Tang
 * Last modified date : 20-5-2019
 */

import { applyMiddleware, compose, createStore } from 'redux';
import reducer from './reducers';

const logger = store => next => action => {
	return next(action);
};

const errorHandler = store => next => action => {
	try {
		return next(action);
	} catch (e) {
		console.log('ERROR!', e);
	}
};

export const middleware = applyMiddleware(logger, errorHandler);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//const store = createStore(reducer, composeEnhancers(middleware));
//const store = createStore(reducer, middleware);

let store;

if (process.env.NODE_ENV === 'development') {
	store = createStore(reducer, composeEnhancers(middleware));
} else {
	store = createStore(reducer, middleware);
}

export default store;
