import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Title.module.scss';

const Title = (props) => {
	const color = () => {
		switch (props.color) {
			case 'light':
				return styles.light;
			case 'secondary':
				return styles.secondary;
			default:
				return styles.primary;
		}
	};

	const size = () => {
		switch (props.size) {
			case 's':
				return styles.s;
			default:
				return styles.m;
		}
	};

	return (
		<>
			{props.size === 's' ? (
				<h2 className={classNames([styles.title, color(), size(), props.mb ? styles.mb : null])}>{props.children}</h2>
			) : (
				<h1 className={classNames([styles.title, color(), size(), props.mb ? styles.mb : null])}>{props.children}</h1>
			)}
		</>
	);
};

Title.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
	mb: PropTypes.bool,
	children: PropTypes.any
};

export default Title;
