import { useSelector } from 'react-redux';

export const useDepotUtils = () => {
	const storeBonds = useSelector((state => state.stockProficiency.bonds));
	const storeBondsFrequency = useSelector((state => state.stockProficiency.bondsFrequency));
	const storeBondsVolume = useSelector((state => state.stockProficiency.bondsVolume));

	const storeStocks = useSelector((state => state.stockProficiency.stocks));
	const storeStocksFrequency = useSelector((state => state.stockProficiency.stocksFrequency));
	const storeStocksVolume = useSelector((state => state.stockProficiency.stocksVolume));

	const storeFundOfFunds = useSelector((state => state.stockProficiency.fundOfFunds));
	const storeFundOfFundsFrequency = useSelector((state => state.stockProficiency.fundOfFundsFrequency));
	const storeFundOfFundsVolume = useSelector((state => state.stockProficiency.fundOfFundsVolume));

	const storeOpenPropertyFunds = useSelector((state => state.stockProficiency.openPropertyFunds));
	const storeOpenPropertyFundsFrequency = useSelector((state => state.stockProficiency.openPropertyFundsFrequency));
	const storeOpenPropertyFundsVolume = useSelector((state => state.stockProficiency.openPropertyFundsVolume));

	const storeCertificates = useSelector((state => state.stockProficiency.certificates));
	const storeCertificatesFrequency = useSelector((state => state.stockProficiency.certificatesFrequency));
	const storeCertificatesVolume = useSelector((state => state.stockProficiency.certificatesVolume));

	const storeDerivates = useSelector((state => state.stockProficiency.derivates));
	const storeDerivatesFrequency = useSelector((state => state.stockProficiency.derivatesFrequency));
	const storeDerivatesVolume = useSelector((state => state.stockProficiency.derivatesVolume));

	const getStockProficiencies = () => {
		return {
			BONDS: { checked: storeBonds, frequency: storeBondsFrequency, volume: storeBondsVolume },
			STOCKS: { checked: storeStocks, frequency: storeStocksFrequency, volume: storeStocksVolume },
			FUND_OF_FUNDS: { checked: storeFundOfFunds, frequency: storeFundOfFundsFrequency, volume: storeFundOfFundsVolume },
			OPEN_PROPERTY_FUNDS: { checked: storeOpenPropertyFunds, frequency: storeOpenPropertyFundsFrequency, volume: storeOpenPropertyFundsVolume },
			CERTIFICATES: { checked: storeCertificates, frequency: storeCertificatesFrequency, volume: storeCertificatesVolume },
			DERIVATIVES: { checked: storeDerivates, frequency: storeDerivatesFrequency, volume: storeDerivatesVolume }
		};
	};

	return {
		getStockProficiencies
	};
};
