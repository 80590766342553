import moment from 'moment';
import 'moment-timezone';

export const useTimeUtils = () => {
	// const SHORT_DAY = ['08:00', '16:00'];
	const LONG_DAY = ['08:00', '20:00'];
	const OFFICE_HOURS = [
		[],
		LONG_DAY,
		LONG_DAY,
		LONG_DAY,
		LONG_DAY,
		LONG_DAY,
		[]
	];

	const checkOfficeHours = (officeDay = LONG_DAY) => {
		const datetime = new Date();
		const officeHours = [
			[],
			officeDay,
			officeDay,
			officeDay,
			officeDay,
			officeDay,
			[]
		];
		return isDuringOfficeHours(datetime, officeHours);
	};

	const isDuringOfficeHours = (datetime, officeHours = OFFICE_HOURS) => {
		// convert to german timezone (respect daylight saving time)
		const d = moment(datetime).tz('Europe/Berlin');
		const dateString = d.format('YYYY-MM-DD');
		const timeZoneOffset = d.format('Z');

		const officeHoursToday = officeHours[d.day()].map(time =>
			moment(`${dateString}T${time}:00${timeZoneOffset}`)
		);

		return (
			officeHoursToday.length === 2 &&
			d.isSameOrAfter(officeHoursToday[0]) &&
			d.isSameOrBefore(officeHoursToday[1])
		);
	};

	return {
		checkOfficeHours
	};
};
