import C from 'constants/Constants';
import history from 'config/history';

export const useUtils = () => {
	const getCurrentFlow = () => {
		const defaultFlow = C.FRONTEND.GIRO;
		if (history) {
			let pathArray = history.location.pathname.split('/');
			if (pathArray.length > 1) {
				return '/' + pathArray[1].toLowerCase();
			} else {
				return defaultFlow;
			}
		} else {
			return defaultFlow;
		}
	};

	const getProductType = (customFlow = null) => {
		const currentFlow = customFlow ?? getCurrentFlow();
		switch (currentFlow) {
			case C.FRONTEND.BEST_CARD_BASIC:
				return C.PRODUCT_TYPE.BEST_CARD_BASIC;
			case C.FRONTEND.BEST_CARD_SMART:
				return C.PRODUCT_TYPE.BEST_CARD_SMART;
			case C.FRONTEND.BEST_CARD_EXTRA:
				return C.PRODUCT_TYPE.BEST_CARD_EXTRA;
			case C.FRONTEND.BEST_CARD_PREMIUM:
				return C.PRODUCT_TYPE.BEST_CARD_PREMIUM;
			case C.FRONTEND.VISACARD:
				return C.PRODUCT_TYPE.VISACARD;
			case C.FRONTEND.CREDIT:
				return C.PRODUCT_TYPE.CREDIT;
			case C.FRONTEND.SHORT_CREDIT:
				return C.PRODUCT_TYPE.SHORT_CREDIT;
			case C.FRONTEND.GMK:
				return C.PRODUCT_TYPE.GMK;
			case C.FRONTEND.SPARBRIEF:
				return C.PRODUCT_TYPE.SPARBRIEF;
			case C.FRONTEND.GIRO:
				return C.PRODUCT_TYPE.GIRO;
			case C.FRONTEND.DEPOT:
				return C.PRODUCT_TYPE.DEPOT;
			default:
				return C.PRODUCT_TYPE.GIRO;
		}
	};

	const isCreditOrCreditCardSubType = (customFlow = null, exclude = []) => {
		return isCreditSubType(customFlow, exclude) || isCreditCardSubType(customFlow, exclude);
	};

	const isCreditSubType = (customFlow = null, exclude = []) => {
		const currentFlow = customFlow ?? getCurrentFlow();
		let flows = [C.FRONTEND.CREDIT];
		flows = flows.filter((flow) => !exclude.includes(flow));
		return flows.includes(currentFlow);
	};

	const isCreditCardSubType = (customFlow = null, exclude = []) => {
		const currentFlow = customFlow ?? getCurrentFlow();
		let flows = [
			C.FRONTEND.BEST_CARD_BASIC,
			C.FRONTEND.BEST_CARD_SMART,
			C.FRONTEND.BEST_CARD_EXTRA,
			C.FRONTEND.BEST_CARD_PREMIUM,
			C.FRONTEND.VISACARD
		];
		flows = flows.filter((flow) => !exclude.includes(flow));
		return flows.includes(currentFlow);
	};

	const isBestCardFamilySubType = (customFlow = null, exclude = []) => {
		const currentFlow = customFlow ?? getCurrentFlow();
		let flows = [
			C.FRONTEND.BEST_CARD_SMART,
			C.FRONTEND.BEST_CARD_EXTRA,
			C.FRONTEND.BEST_CARD_PREMIUM
		];
		flows = flows.filter((flow) => !exclude.includes(flow));
		return flows.includes(currentFlow);
	};

	const isStaticSubType = (customFlow = null, exclude = []) => {
		const currentFlow = customFlow ?? getCurrentFlow();
		let flows = [
			C.FRONTEND.GIRO,
			C.FRONTEND.GMK,
			C.FRONTEND.SPARBRIEF,
			C.FRONTEND.DEPOT
		];
		flows = flows.filter((flow) => !exclude.includes(flow));
		return flows.includes(currentFlow);
	};

	const isFeatureEnabledFor = (featureProducts) => {
		const productType = getProductType();
		return !!featureProducts.find(
			(product) => product === productType
		);
	};

	return {
		getCurrentFlow,
		getProductType,
		isCreditOrCreditCardSubType,
		isCreditSubType,
		isCreditCardSubType,
		isBestCardFamilySubType,
		isStaticSubType,
		isFeatureEnabledFor
	};
};
