import axios from 'axios';
import C from 'constants/Constants';
import fileDownload from 'js-file-download';
import { useUtils } from 'utils/utils';
import { useApiUtils } from 'utils/api/api';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { useDispatch, useSelector } from 'react-redux';
import { setHideTitle, updateAppLoading, updateWebIdAddressNeeded } from 'store/App/actions';
import { updateFeatures } from 'store/Features/actions';
import { setLoansAccountNumber, setNewAccountIban, setNewCreditCardPan } from 'store/NewAccount/actions';

export const useLegitimationApi = () => {
	const { getCurrentFlow, getProductType } = useUtils();
	const { addUserDataToStore, getApiBaseURL, startApiCall, stopApiCall, checkUnauthorized } = useApiUtils();
	const { error } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeVcJwtToken = useSelector((state => state.app.vcJwtToken));

	const getFeatures = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		await axios
			.get(getApiBaseURL() + '/features', {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then((response) => {
				onGetFeatureSuccess(
					response.data,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'GET /features call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const onGetFeatureSuccess = (
		data,
		onSuccess = () => {}
	) => {
		let { features } = data;
		features = JSON.parse(
			JSON.stringify(features).replace(/GIRO/g, 'BESTGIRO')
		);
		const getEnabledProductsFor = (featureName) => {
			const matchingFeature = features.find(
				currentFeature => currentFeature.featureName === featureName
			);
			return matchingFeature ? matchingFeature.productTypes : [];
		};

		const updatedFeatures = {
			webId: getEnabledProductsFor('WEBID'),
			postidentDigital: getEnabledProductsFor('POSTIDENT_DIGITAL'),
			postidentCoupon: getEnabledProductsFor('POSTIDENT_COUPON'),
			kontoident: getEnabledProductsFor('KONTOIDENT')
		};

		dispatch(updateFeatures(updatedFeatures));

		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess(updatedFeatures);
	};

	const postRegisterWithWebid = async (
		isKontoIdent,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('webid'));

		const data = {
			dataProtection: true,
			agb: true,
			productType: getProductType(),
			legiProvider: isKontoIdent ? 'KONTO_IDENT' : 'WEB_ID'
		};

		await axios
			.post(getApiBaseURL() + '/register-with-webid', data, {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then((response) => {
				registerWithWebIdSuccess(
					response.data,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST /register-with-webid call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const registerWithWebIdSuccess = (
		data,
		onSuccess = () => {}
	) => {
		// stopApiCall();
		// dispatch(updateAppLoading('none'));
		onSuccess(data);
	};

	const postRegisterWithPostident = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('postident'));

		const data = {
			dataProtection: true,
			agb: true
		};
		await axios
			.post(getApiBaseURL() + '/register-with-postident', data, {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then((response) => {
				registerWithPostidentSuccess(
					response.data,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST /register-with-postident call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const registerWithPostidentSuccess = (
		data,
		onSuccess = () => {}
	) => {
		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess(data);
	};

	const getPostidentCoupon = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();

		await axios
			.get(getApiBaseURL() + '/postident', {
				headers: {
					vcJwtToken: storeVcJwtToken
				},
				responseType: 'arraybuffer'
			})
			.then(response => {
				onPostidentCouponSuccess(
					response.data,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'GET /postident call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				onError(status);
			});
	};

	const onPostidentCouponSuccess = (
		data,
		onSuccess = () => {}
	) => {
		let fileName = 'postident_coupon.pdf';
		fileDownload(data, fileName);

		stopApiCall();
		onSuccess();
	};

	const getCheckAddressVerification = async (
		vcJwtToken,
		webIdStatus,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		await axios
			.get(`${getApiBaseURL()}/check-address-verification/${getProductType()}`, {
				headers: {
					vcJwtToken: vcJwtToken ?? storeVcJwtToken
				}
			})
			.then(async (response) => {
				await checkAddressVerificationSuccess(
					response.data,
					webIdStatus,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'GET /check-address-verification call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				if (currentFlow === C.FRONTEND.GMK) {
					dispatch(setHideTitle(true));
				}

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const checkAddressVerificationSuccess = async (
		data,
		webIdStatus,
		onSuccess = () => {}
	) => {
		addUserDataToStore(data);

		if (data.loansAccountNumber) {
			dispatch(setLoansAccountNumber(data.loansAccountNumber));
		}
		if (data.pan) {
			dispatch(setNewCreditCardPan(data.pan));
		}
		if (data.iban) {
			dispatch(setNewAccountIban(data.iban));
		}

		dispatch(updateWebIdAddressNeeded({
			addressSelectionNeeded: !!data.addressSelectionNeeeded,
			documentUploadNeeded: !!data.documentUploadNeeded
		}));

		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess(data, webIdStatus);
	};

	const getGetSelectedAddress = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		await axios
			.get(getApiBaseURL() + '/get-selected-address', {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then((response) => {
				onGetSelectedAddressSuccess(response.data, onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'GET /get-selected-address call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const onGetSelectedAddressSuccess = (
		data,
		onSuccess = () => {}
	) => {
		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess(data);
	};

	const postSetSelectedAddress = async (
		useWebIdAddress = true,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		const data = {
			useWebIdAddress: useWebIdAddress,
			productType: getProductType()
		};
		await axios
			.post(getApiBaseURL() + '/set-selected-address', data, {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then((response) => {
				onSetSelectedAddressSuccess(response.data, onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST /set-selected-address call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const onSetSelectedAddressSuccess = (
		data,
		onSuccess = () => {}
	) => {
		if (data.loansAccountNumber) {
			dispatch(setLoansAccountNumber(data.loansAccountNumber));
		}
		if (data.pan) {
			dispatch(setNewCreditCardPan(data.pan));
		}
		if (data.iban) {
			dispatch(setNewAccountIban(data.iban));
		}

		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess();
	};

	return {
		getFeatures,
		postRegisterWithWebid,
		postRegisterWithPostident,
		getPostidentCoupon,
		getCheckAddressVerification,
		getGetSelectedAddress,
		postSetSelectedAddress
	};
};
