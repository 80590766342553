import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M17.995 5a.999.999 0 0 1 .71 1.705l-5.291 5.293 5.29 5.293A1 1 0 0 1 17.995 19a1 1 0 0 1-.71-.29l-5.29-5.293-5.28 5.283a1 1 0 1 1-1.42-1.41l5.285-5.287-5.284-5.288a1.004 1.004 0 0 1 1.418-1.42L12 10.584l5.286-5.287A.999.999 0 0 1 17.995 5z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
