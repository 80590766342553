import _ from 'lodash';

export const defaultState = {
	paymentProtectionInsurance: -1,
	paymentProtectionInsuranceName: 'none'
};

const translateToPpiName = ppiNumber => {
	switch (ppiNumber) {
		case 1:
			return 'complete';
		case 2:
			return 'standard';
		case 3:
			return 'basic';
		default:
			return 'none';
	}
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_PAYMENT_PROTECTION_INSURANCE':
			return _.merge({}, state, {
				paymentProtectionInsuranceName: translateToPpiName(
					action.payload.paymentProtectionInsurance
				),
				paymentProtectionInsurance: action.payload.paymentProtectionInsurance
			});
		default:
			return state;
	}
};

export default reducers;
