import React from 'react';
import PropTypes from 'prop-types';

import styles from './CSSIcon.module.scss';

const CSSIcon = (props) => {
	return (
		<div
			className={styles.cssIcon}
			data-iconname={props.name}
			style={{ backgroundImage: 'url(/img/icons/' + props.name + '.svg)' }}
		/>
	);
};

CSSIcon.propTypes = {
	name: PropTypes.string
};

export default CSSIcon;
