import { BEST_CARD_LIMIT_DEFAULT } from 'constants/BestCard';

const defaultState = {
	requestedLimit: BEST_CARD_LIMIT_DEFAULT
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_REQUESTED_LIMIT':
			return { ...state, requestedLimit: action.payload };
		default:
			return state;
	}
};

export default reducers;
