const defaultState = {
	accountNumber: null,
	referenceIban: null,
	existingOldIban: null,
	isSantanderIban: false,
	referenceIbanSantander: null,
	paymentType: 'TEILZAHLUNG'
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_ACCOUNT_NUMBER':
			return { ...state, accountNumber: action.payload };
		case 'SET_REFERENCE_IBAN':
			return { ...state, referenceIban: action.payload };
		case 'SET_EXISTING_OLD_IBAN':
			return { ...state, existingOldIban: action.payload };
		case 'SET_IS_SANTANDER_IBAN':
			return { ...state, isSantanderIban: action.payload };
		case 'SET_REFERENCE_IBAN_SANTANDER':
			return { ...state, referenceIbanSantander: action.payload };
		case 'SET_PAYMENT_TYPE':
			return { ...state, paymentType: action.payload };
		default:
			return state;
	}
};

export default reducers;
