import React, { useState, useEffect } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useItemsUtils } from 'utils/items/items';
import { useFirstRender } from 'utils/mount/mount';

import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import { DropdownInput, RadioButtonInput } from 'components/Atoms/Form';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setMaritalStatus, setNumberOfAdults, setNumberOfChildren, setNumberOfChildrenWithBenefit } from 'store/MaritalStatus/actions';

const MaritalStatus = () => {
	const { getCurrentFlow, isCreditOrCreditCardSubType } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { getMaritalStatusItemsFull } = useItemsUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();

	const storeMaritalStatus = useSelector((state => state.maritalStatus.maritalStatus));
	const storeNumberOfAdults = useSelector((state => state.maritalStatus.numberOfAdults));
	const storeNumberChildren = useSelector((state => state.maritalStatus.numberOfChildren));
	const storeNumberChildrenWithBenefit = useSelector((state => state.maritalStatus.numberOfChildrenWithBenefit));

	const storeHasSecondApplicant = useSelector((state => state.secondApplicant.hasSecondApplicant));
	const storeHasPreviousAddress = useSelector((state => state.address.hasPreviousAddress));

	const [maritalStatusError, setMaritalStatusError] = useState(null);
	const [numberOfAdultsError, setNumberOfAdultsError] = useState(null);
	const [numberOfChildrenError, setNumberOfChildrenError] = useState(null);
	const [numberOfChildrenWithBenefitError, setNumberOfChildrenWithBenefitError] = useState(null);

	const [numberOfChildrenWithBenefitItems, setNumberOfChildrenWithBenefitItems] = useState([]);

	const maritalStatusDropdownItems = getMaritalStatusItemsFull();
	const maritalStatusItemSingle = maritalStatusDropdownItems[0];
	const maritalStatusItemMarried = maritalStatusDropdownItems[1];
	maritalStatusDropdownItems.shift();
	maritalStatusDropdownItems.shift();
	const maritalStatusItemOther = {
		text: m('maritalStatus.items.other', 'fields'),
		value: 'OTHER'
	};
	const maritalStatusRadioButtonItems = [
		maritalStatusItemSingle,
		maritalStatusItemMarried,
		maritalStatusItemOther
	];
	const numberOfAdultsButtonItems = [
		{ text: '1', value: '1' },
		{ text: m('more.label', 'fields'), value: '2' }
	];
	const numberOfChildrenButtonItems = [
		{ text: '0', value: '0' },
		{ text: '1', value: '1' },
		{ text: '2', value: '2' },
		{ text: m('more.label', 'fields'), value: '-1' }
	];
	const numberOfChildrenDropdownItems = [
		{ text: '3', value: '3' },
		{ text: '4', value: '4' },
		{ text: '5', value: '5' },
		{ text: '6', value: '6' },
		{ text: '7', value: '7' },
		{ text: '8', value: '8' },
		{ text: '9', value: '9' },
		{ text: '10', value: '10' }
	];

	const hasNumberAdults = isCreditOrCreditCardSubType() && !storeHasSecondApplicant;
	const showNumberAdults =
		hasNumberAdults &&
		(storeMaritalStatus === 'SINGLE' ||
		storeMaritalStatus === 'SEPARATED' ||
		storeMaritalStatus === 'DIVORCED' ||
		storeMaritalStatus === 'WIDOWED');
	const showChildren = isCreditOrCreditCardSubType();

	const isMaritalStatusButton = storeMaritalStatus === 'SINGLE' || storeMaritalStatus === 'MARRIED';
	const isChildrenButton = storeNumberChildren === 0 || storeNumberChildren === 1 || storeNumberChildren === 2;

	const isFirstRender = useFirstRender();

	useEffect(() => {
		if (isCreditOrCreditCardSubType() && storeHasSecondApplicant) {
			dispatch(setNumberOfAdults('2'));
		}
	}, []);

	useEffect(() => {
		if (!isFirstRender) {
			if (storeMaritalStatus === 'OTHER') {
				dispatch(setMaritalStatus(maritalStatusDropdownItems[0].value));
			}
			if (showNumberAdults) {
				dispatch(setNumberOfAdults('1'));
			} else if (storeMaritalStatus === 'PARTNERED' || storeMaritalStatus === 'MARRIED') {
				dispatch(setNumberOfAdults('2'));
			}
		}
	}, [storeMaritalStatus]);

	useEffect(() => {
		if (storeNumberChildren === -1) {
			dispatch(setNumberOfChildren('3'));
		}
		if (storeNumberChildren && storeNumberChildren > 0) {
			const tempItems = [];
			for (let i = 0; i <= storeNumberChildren; i++) {
				tempItems.push({ text: i + '', value: i + '' });
			}
			if (storeNumberChildrenWithBenefit > storeNumberChildren) {
				dispatch(setNumberOfChildrenWithBenefit(storeNumberChildren));
			}
			setNumberOfChildrenWithBenefitItems(tempItems);
		} else {
			dispatch(setNumberOfChildrenWithBenefit('0'));
			setNumberOfChildrenWithBenefitItems([]);
		}
	}, [storeNumberChildren]);

	const clearErrors = () => {
		setMaritalStatusError(null);
		setNumberOfAdultsError(null);
		setNumberOfChildrenError(null);
		setNumberOfChildrenWithBenefitError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(storeMaritalStatus)) {
			setMaritalStatusError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (showNumberAdults) {
			if (isEmpty(storeNumberOfAdults)) {
				setMaritalStatusError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
		}
		if (showChildren) {
			if (isEmpty(storeNumberChildren, true)) {
				setNumberOfChildrenError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.OCCUPATION);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const onPrevButton = () => {
		if (storeHasPreviousAddress && currentFlow !== C.FRONTEND.GMK && currentFlow !== C.FRONTEND.SPARBRIEF) {
			goToPage(currentFlow + C.ROUTES.PREVIOUS_ADDRESS);
		} else {
			goToPage(currentFlow + C.ROUTES.ADDRESS);
		}
	};

	const autofill = () => {
		dispatch(setMaritalStatus('SINGLE'));
		dispatch(setNumberOfAdults('1'));
		if (showChildren) {
			dispatch(setNumberOfChildren('0'));
			dispatch(setNumberOfChildrenWithBenefit('0'));
		} else {
			dispatch(setNumberOfChildren(null));
			dispatch(setNumberOfChildrenWithBenefit('0'));
		}
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title>{m('pages.maritalStatus.title', 'global')}</Title>
			{currentFlow !== C.FRONTEND.DEPOT && (
				<Text mb>
					{currentFlow === C.FRONTEND.GMK || currentFlow === C.FRONTEND.SPARBRIEF
						? m('maritalStatus.subtitle', currentFlow)
						: m('pages.maritalStatus.subtitle', 'global')}
				</Text>
			)}
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={8}
				onPrevButton={onPrevButton}
			>
				<RadioButtonInput
					value={storeMaritalStatus ? (isMaritalStatusButton ? storeMaritalStatus : 'OTHER') : null}
					setValue={(value) => { dispatch(setMaritalStatus(value)); }}
					items={maritalStatusRadioButtonItems}
					hasError={!!maritalStatusError}
					message={maritalStatusError}
					btnStyle="button"
					tabindex={1}
					testId="marital-status-buttons"
				/>
				{storeMaritalStatus && !isMaritalStatusButton && (
					<DropdownInput
						value={!isMaritalStatusButton ? storeMaritalStatus : null}
						setValue={(value) => { dispatch(setMaritalStatus(value)); }}
						items={maritalStatusDropdownItems}
						label={m('maritalStatus.label', 'fields')}
						tabindex={2}
						testId="marital-status-dropdown"
					/>
				)}

				{showNumberAdults && (
					<>
						<div className="mt-10" />
						<Text mb>{m('numberAdults.label', 'fields')}</Text>
						<RadioButtonInput
							value={storeNumberOfAdults ? storeNumberOfAdults + '' : null}
							setValue={(value) => { dispatch(setNumberOfAdults(value)); }}
							items={numberOfAdultsButtonItems}
							hasError={!!numberOfAdultsError}
							message={numberOfAdultsError}
							btnStyle="button"
							tabindex={3}
							testId="number-of-adults-buttons"
						/>
					</>
				)}

				{showChildren && (
					<>
						<div className="mt-10" />
						<Text mb>{m('pages.maritalStatus.children', 'global')}</Text>
						<RadioButtonInput
							value={storeNumberChildren || storeNumberChildren === 0 ? (isChildrenButton ? storeNumberChildren + '' : '-1') : null}
							setValue={(value) => { dispatch(setNumberOfChildren(value)); }}
							items={numberOfChildrenButtonItems}
							hasError={!!numberOfChildrenError}
							message={numberOfChildrenError}
							btnStyle="button"
							tabindex={5}
							testId="number-of-children-buttons"
						/>
						{(storeNumberChildren || storeNumberChildren === 0) && !isChildrenButton && (
							<DropdownInput
								value={!isChildrenButton ? storeNumberChildren + '' : null}
								setValue={(value) => { dispatch(setNumberOfChildren(value)); }}
								items={numberOfChildrenDropdownItems}
								label={m('numberOfChildren.label', 'fields')}
								tabindex={6}
								testId="number-of-children-dropdown"
							/>
						)}
						{numberOfChildrenWithBenefitItems.length > 0 && (
							<>
								<div className="mt-10" />
								<Text mb>{m('numberOfChildrenWithBenefit.label', 'fields')}</Text>
								<RadioButtonInput
									value={storeNumberChildrenWithBenefit + ''}
									setValue={(value) => { dispatch(setNumberOfChildrenWithBenefit(value)); }}
									items={numberOfChildrenWithBenefitItems}
									hasError={!!numberOfChildrenWithBenefitError}
									message={numberOfChildrenWithBenefitError}
									btnStyle="button"
									tabindex={7}
									testId="number-of-children-with-benefit"
								/>
							</>
						)}
					</>
				)}
			</Form>
		</>
	);
};
export default MaritalStatus;
