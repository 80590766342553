import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M17.974 4A3.026 3.026 0 0 1 21 7.026v6.945A3.029 3.029 0 0 1 17.97 17H9.708l-2.854 2.854A.5.5 0 0 1 6 19.5V17a3.03 3.03 0 0 1-3-3.029V7.029A3.03 3.03 0 0 1 6.029 4h11.945zm0 1H6.029a2.03 2.03 0 0 0-2.03 2.029v6.942A2.03 2.03 0 0 0 6.03 16h.47a.5.5 0 0 1 .5.5v1.793l2.147-2.147A.5.5 0 0 1 9.5 16h8.47c1.122 0 2.03-.908 2.03-2.029V7.026A2.026 2.026 0 0 0 17.974 5zM12 13.5a.5.5 0 0 1 .492.41l.008.09v.5a.5.5 0 0 1-.992.09l-.008-.09V14a.5.5 0 0 1 .5-.5zM9.502 8.52a2.507 2.507 0 0 1 2.947-2.48 2.515 2.515 0 0 1 2.01 2.029 2.531 2.531 0 0 1-1.102 2.568l-.15.091c-.397.221-.652.576-.699.97l-.007.131v.67a.5.5 0 0 1-.992.09l-.008-.09v-.67c0-.766.409-1.454 1.064-1.88l.155-.094c.56-.312.87-.947.754-1.615a1.516 1.516 0 0 0-1.199-1.215 1.507 1.507 0 0 0-1.774 1.495.5.5 0 1 1-1 0z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
