import React from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useNumberUtils } from 'utils/number/number';

import { Text, Title } from 'components/Atoms/Text';
import { Row, Col } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import SvgIllustrationDone from 'components/Atoms/SVG/Illustrations/SvgIllustrationDone';

import { useSelector } from 'react-redux';

import styles from './CreditSuccess.module.scss';

const CreditSuccess = () => {
	const { getCurrentFlow } = useUtils();
	const { formatMoneyValue } = useNumberUtils();

	const currentFlow = getCurrentFlow();

	const storeAmount = useSelector((state => state.app.amount));
	const storeLoansAccountNumber = useSelector((state => state.newAccount.loansAccountNumber));

	return (
		<>
			<Row>
				<Col xs={12} sm={4} md={5}>
					<div className={styles.illustrationWrapper}>
						<SvgIllustrationDone />
					</div>
				</Col>
				<Col xs={12} sm={8} md={7}>
					<div className={styles.textGroup}>
					<Title>
							{m('loanSuccess.title', currentFlow, { amount: storeAmount ? formatMoneyValue(storeAmount) : '' }, true)}
						</Title>
						<Text>{m('loanSuccess.subtitle', currentFlow)}</Text>
						<div className={styles.accountNr}>
							<Text>
								{m('loanSuccess.accountNr', currentFlow)}
							</Text>
							{storeLoansAccountNumber}
						</div>
					</div>
				</Col>
			</Row>
			<Form
				submitText={m('loanSuccess.button', currentFlow)}
				onSubmit={() => {
					window.open('https://santander.de', '_self');
					window.close();
				}}
				clearErrors={() => {}}
				submitTabindex={1}
			/>
		</>
	);
};
export default CreditSuccess ;
