import axios from 'axios';
import { cloneDeep, findIndex, reject } from 'lodash';
import fileDownload from 'js-file-download';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useApiUtils } from 'utils/api/api';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useFileUtils } from 'utils/file/file';

import { useDispatch, useSelector } from 'react-redux';
import { updateAppLoading, updateAmount } from 'store/App/actions';

export const useDocumentsApi = () => {
	const { getCurrentFlow, getProductType } = useUtils();
	const { goToPage } = usePageUtils();
	const { getApiBaseURL, startApiCall, stopApiCall, checkUnauthorized } = useApiUtils();
	const { continueValid, error } = useTrackingUtils();
	const { fileToBase64 } = useFileUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeVcJwtToken = useSelector((state => state.app.vcJwtToken));
	const storeSalutation = useSelector((state => state.name.salutation));
	const storeFirstName = useSelector((state => state.name.firstName));
	const storeLastName = useSelector((state => state.name.lastName));

	const getMissingDocuments = async (
		missingDocumentsDAC,
		setMissingDocumentsDAC,
		missingDocuments,
		setMissingDocuments,
		participant,
		isRegistrationCertificate,
		noDocumentsNeededDestination,
		excludeDocumentsWithExistingFiles = false
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		await axios
			.get(`${getApiBaseURL()}/${isRegistrationCertificate ? 'missing-registration-certificate' : 'missing-documents'}/${participant}`, {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then((response) => {
				onMissingDocumentsSuccess(
					response.data,
					missingDocumentsDAC,
					setMissingDocumentsDAC,
					missingDocuments,
					setMissingDocuments,
					noDocumentsNeededDestination,
					excludeDocumentsWithExistingFiles
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'GET /' + (isRegistrationCertificate ? 'missing-registration-certificate' : 'missing-documents') + ' call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
			});
	};

	const onMissingDocumentsSuccess = (
		data,
		missingDocumentsDAC,
		setMissingDocumentsDAC,
		missingDocuments,
		setMissingDocuments,
		noDocumentsNeededDestination,
		excludeDocumentsWithExistingFiles = false
	) => {
		if (
			(!data.missingDocumentsDAC || data.missingDocumentsDAC.length === 0) &&
			(!data.missingDocuments || data.missingDocuments.length === 0)
		) {
			if (noDocumentsNeededDestination) {
				continueValid();
				goToPage(currentFlow + noDocumentsNeededDestination);
			}
		} else {
			if (data.missingDocumentsDAC && data.missingDocumentsDAC.length > 0) {
				let tempMissingDocumentsDAC = cloneDeep(data.missingDocumentsDAC);
				if (excludeDocumentsWithExistingFiles) {
					for (const missingDocument of data.missingDocumentsDAC) {
						if (missingDocument.documentFiles && missingDocument.documentFiles.length > 0) {
							tempMissingDocumentsDAC = reject(tempMissingDocumentsDAC, (item) => {
								return item.documentType === missingDocument.documentType;
							});
						}
					}
				}
				setMissingDocumentsDAC(tempMissingDocumentsDAC);
			}
			if (data.missingDocuments && data.missingDocuments.length > 0) {
				let tempMissingDocuments = cloneDeep(data.missingDocuments);
				if (excludeDocumentsWithExistingFiles) {
					for (const missingDocument of data.missingDocuments) {
						if (missingDocument.documentFiles && missingDocument.documentFiles.length > 0) {
							tempMissingDocuments = reject(tempMissingDocuments, (item) => {
								return item.documentType === missingDocument.documentType;
							});
						}
					}
				}
				setMissingDocuments(tempMissingDocuments);
			}
		}

		stopApiCall();
		dispatch(updateAppLoading('none'));
	};

	const postDocument = async (
		documentType,
		droppedFiles,
		uploadedFiles,
		setUploadedFiles,
		missingDocuments,
		setMissingDocuments,
		participant = 1,
		successCb = () => {},
		errorCb = () => {}
	) => {
		const url = getApiBaseURL() + '/document';
		const maxFilesize = 10 * 1000000; // 10MB

		for (const file of droppedFiles) {
			if (file.size < maxFilesize) {
				await fileToBase64(file)
					.then(async (content) => {
						await axios
							.post(
								url,
								{
									files: [
										{
											filename: file.name,
											content: content
										}
									],
									participant,
									documentType
								},
								{
									headers: {
										vcJwtToken: storeVcJwtToken
									}
								}
							)
							.then((res) => {
								onPostDocumentSuccess(
									res.data,
									uploadedFiles,
									setUploadedFiles,
									missingDocuments,
									setMissingDocuments,
									successCb
								);
							})
							.catch((err) => {
								const status = err?.response?.status ?? 500;
								error(status, 'POST /document call failed');
								const unauthorized = checkUnauthorized(status);
								if (unauthorized) return null;

								onPostDocumentError(err, errorCb);
							});
					})
					.catch((err) => {
						console.log('Error converting file to base64:', err);
						onPostDocumentError(err, errorCb);
					});
			} else {
				console.log('File size too big');
				// TODO: replace with real status code
				const err = { response: { status: 632 } };
				onPostDocumentError(err, errorCb);
			}
		}
	};

	const onPostDocumentSuccess = (
		data,
		uploadedFiles,
		setUploadedFiles,
		missingDocuments,
		setMissingDocuments,
		successCb = () => {}
	) => {
		if (data.documentList && data.documentList.length > 0 && data.documentType) {
			const newUploadedFiles = cloneDeep(uploadedFiles);
			const newMissingDocuments = cloneDeep(missingDocuments);
			for (const file of data.documentList) {
				newUploadedFiles.push(file);

				// eslint-disable-next-line no-loop-func
				const index = findIndex(newMissingDocuments, (item) => {
					return item.documentType === data.documentType;
				});
				if (index !== -1) {
					if (!newMissingDocuments[index].documentFiles) {
						newMissingDocuments[index].documentFiles = [];
					}
					newMissingDocuments[index].documentFiles.push(file);
				}
			}
			setUploadedFiles(newUploadedFiles);
			setMissingDocuments(newMissingDocuments);
		}
		successCb();
	};

	const onPostDocumentError = (err, errorCb = () => {}) => {
		errorCb(err?.response?.status ?? undefined);
	};

	const deleteDocument = async (
		index,
		documentType,
		uploadedFiles,
		setUploadedFiles,
		missingDocuments,
		setMissingDocuments,
		successCb = () => {},
		errorCb = () => {}
	) => {
		if (uploadedFiles[index]?.id) {
			await axios
				.delete(getApiBaseURL() + '/document/' + uploadedFiles[index].id, {
					headers: {
						vcJwtToken: storeVcJwtToken
					}
				})
				.then((res) => {
					onDeleteDocumentSuccess(
						res.data,
						index,
						documentType,
						uploadedFiles,
						setUploadedFiles,
						missingDocuments,
						setMissingDocuments,
						successCb
					);
				})
				.catch((err) => {
					const status = err?.response?.status ?? 500;
					error(status, 'DELETE /document call failed');
					const unauthorized = checkUnauthorized(status);
					if (unauthorized) return null;

					onDeleteDocumentError(err, errorCb);
				});
		} else {
			onDeleteDocumentError(null, errorCb);
		}
	};

	const onDeleteDocumentSuccess = (
		data,
		index,
		documentType,
		uploadedFiles,
		setUploadedFiles,
		missingDocuments,
		setMissingDocuments,
		successCb = () => {}
	) => {
		const newUploadedFiles = cloneDeep(uploadedFiles);
		newUploadedFiles.splice(index, 1);
		setUploadedFiles(newUploadedFiles);

		const newMissingDocuments = cloneDeep(missingDocuments);
		const documentIndex = findIndex(newMissingDocuments, (item) => {
			return item.documentType === documentType;
		});

		if (documentIndex !== -1) {
			if (newMissingDocuments[documentIndex].documentFiles) {
				newMissingDocuments[documentIndex].documentFiles.splice(index, 1);
			}
		}
		setMissingDocuments(newMissingDocuments);
		successCb();
	};

	const onDeleteDocumentError = (err, errorCb = () => {}) => {
		errorCb();
	};

	const postForwardDocuments = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('sendingDocuments'));

		await axios
			.post(getApiBaseURL() + '/forward-documents', {}, {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then(() => {
				onForwardDocumentsSuccess(onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST /forward-documents call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const onForwardDocumentsSuccess = (
		onSuccess = () => {}
	) => {
		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess();
	};

	const postUploadLaterNotification = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		await axios
			.post(getApiBaseURL() + '/upload-later-notification', {}, {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then((response) => {
				onUploadLaterNotificationSuccess(onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST /upload-later-notification call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const onUploadLaterNotificationSuccess = (
		onSuccess = () => {}
	) => {
		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess();
	};

	const getContract = async (
		customVcJwtToken = null,
		customFlow = null,
		isInsuranceContract = false,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();

		await axios
			.get(getApiBaseURL() + (isInsuranceContract ? '/insurance-contract' : '/contract'), {
				headers: {
					vcJwtToken: customVcJwtToken ?? storeVcJwtToken
				},
				responseType: 'arraybuffer'
			})
			.then((response) => {
				onGetContractSuccess(response.data, customFlow, isInsuranceContract, onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'GET ' + (isInsuranceContract ? '/insurance-contract' : '/contract') + ' call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				onError(status);
			});
	};

	const onGetContractSuccess = (
		data,
		customFlow = null,
		isInsuranceContract = false,
		onSuccess = () => {}
	) => {
		const fileName = isInsuranceContract ? m('download.insuranceContract.pdftitle', 'global') : m('download.contract.pdftitle', customFlow ?? currentFlow);
		fileDownload(data, fileName);

		stopApiCall();
		onSuccess();
	};

	const getBorrowerSwitch = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		let options = {
			headers: { vcJwtToken: storeVcJwtToken }
		};
		const productType = getProductType();
		return await axios
			.get(`${getApiBaseURL()}/borrower-switch/${productType}`, options)
			.then((response) => {
				onGetBorrowerSwitchSuccess(response.data, onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST /borrower-switch call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError();
			});
	};

	const onGetBorrowerSwitchSuccess = (
		data,
		onSuccess = () => {}
	) => {
		dispatch(updateAmount(data.amount));

		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess(data);
	};

	const getDepotBasisInfo = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();

		const path = '/basisinfo/';
		let url = null;
		if (!storeFirstName) {
			url = path + 'Herr Test Name';
		} else {
			url = path +
				(storeSalutation === 'FEMALE'
					? m('salutation.items.female', 'fields')
					: m('salutation.items.male', 'fields')) +
				' ' + storeFirstName +
				' ' + storeLastName;
		}

		await axios
			.get(getApiBaseURL() + url, {
				responseType: 'arraybuffer',
				data: { registrationToken: storeVcJwtToken }
			})
			.then((response) => {
				onBasisInfoSuccess(response.data, onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'GET /basisinfo call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				onError();
			});
	};

	const onBasisInfoSuccess = (data, onSuccess = () => {}) => {
		const fileName = m('confirmStockBasicInfo.fileName', 'fields');
		fileDownload(data, fileName);

		stopApiCall();
		onSuccess();
	};

	return {
		getMissingDocuments,
		postDocument,
		deleteDocument,
		postForwardDocuments,
		postUploadLaterNotification,
		getContract,
		getBorrowerSwitch,
		getDepotBasisInfo
	};

};
