import React from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useDocumentsApi } from 'api/documents';

import SvgDownloadLink from 'components/Atoms/SVG/Icons/SvgDownloadLink';

import { useSelector } from 'react-redux';
import { Link, Text } from 'components/Atoms/Text';

const DownloadContracts = () => {
	const { getCurrentFlow, isCreditSubType } = useUtils();
	const { getContract } = useDocumentsApi();

	const currentFlow = getCurrentFlow();
	const storePpiValue = useSelector((state => state.app.bcoResults.ppiValue));
	const storeProtectValue = useSelector((state => state.app.bcoResults.protectValue));

	return (
		<>
			<Text mb>
				<Link
					onClick={async () => {
						await getContract();
					}}
				>
					<span data-testid='download-contract'>
						<SvgDownloadLink />
						{m('download.contract.application', currentFlow)}
					</span>
				</Link>
			</Text>
			{isCreditSubType() && (!!storePpiValue || !!storeProtectValue) && (
				<Link
					onClick={async () => {
						await getContract(null, null, true);
					}}
				>
					<span data-testid='download-insurance-contract'>
						<SvgDownloadLink />
						{m('download.insuranceContract.application', 'global')}
					</span>
				</Link>
			)}
		</>
	);
};

export default DownloadContracts;
