import React, { useState } from 'react';
import C from 'constants/Constants';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { NUMBER_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { usePageUtils } from 'utils/page/page';
import { useRegistrationApi } from 'api/registration';

import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { TextInput } from 'components/Atoms/Form';

import { useDispatch, useSelector } from 'react-redux';
import { setCustomerNumber } from 'store/Applicant/actions';
import { setAccountNumber } from 'store/BankAccount/actions';

const Verification = () => {
	const { getCurrentFlow } = useUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, addErrorMessage } = useValidationUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { goToPage } = usePageUtils();
	const { postVcVerifyData } = useRegistrationApi();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeCustomerNumber = useSelector((state => state.applicant.customerNumber));
	const storeAccountNumber = useSelector((state => state.bankAccount.accountNumber));

	const [customerNumberError, setCustomerNumberError] = useState(null);
	const [accountNumberError, setAccountNumberError] = useState(null);

	const clearErrors = () => {
		setCustomerNumberError(null);
		setAccountNumberError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(storeCustomerNumber)) {
			setCustomerNumberError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (isEmpty(storeAccountNumber)) {
			setAccountNumberError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}

		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postVcVerifyData(
				storeCustomerNumber,
				storeAccountNumber,
				onValid,
				() => {
					continueInvalid();
					addErrorMessage(
						FormErrorCode.OTHER,
						m('validation.error.visacard.notFound', 'fields')
					);
				}
			);
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.NAME);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	return (
		<>
			<Title>{m('verification.title', currentFlow)}</Title>
			<Text mb>{m('verification.subtitle', currentFlow)}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={3}
			>
				<TextInput
					value={storeCustomerNumber}
					setValue={(value) => { dispatch(setCustomerNumber(value)); }}
					label={m('customerNumber.label', 'fields')}
					hasError={!!customerNumberError}
					message={customerNumberError}
					regex={NUMBER_INPUT}
					tabindex={1}
					testId="customer-number"
				/>
				<TextInput
					value={storeAccountNumber}
					setValue={(value) => { dispatch(setAccountNumber(value)); }}
					label={m('accountNumber.label', 'fields')}
					hasError={!!accountNumberError}
					message={accountNumberError}
					regex={NUMBER_INPUT}
					maxLength={10}
					tabindex={2}
					testId="account-number"
				/>
			</Form>
		</>
	);
};
export default Verification;
