import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import SvgAlert from 'components/Atoms/SVG/Icons/SvgAlert';
import SvgInformation from 'components/Atoms/SVG/Icons/SvgInformation';

import styles from './Alert.module.scss';

const Alert = (props) => {
	const [id] = useState(uuid());

	const getTypeStyle = () => {
		switch (props.type) {
			case 'warning':
				return styles.warning;
			case 'success':
				return styles.success;
			case 'info':
				return styles.info;
			default:
				return styles.error;
		}
	};

	const getSizeStyle = () => {
		switch (props.size) {
			case 'm':
				return styles.m;
			case 's':
				return styles.s;
			default:
				return styles.m;
		}
	};

	const getIcon = () => {
		switch (props.type) {
			case 'error':
				return <SvgAlert />;
			default:
				return <SvgInformation />;
		}
	};


	return (
		<>
			{props.messages && props.messages.length > 0 && (
				<div
					className={classNames([
						styles.wrapper,
						props.removeMarginTop ? styles.removeMarginTop : null,
						props.removeMarginBottom ? styles.removeMarginBottom : null
					])}
				>
					<div className={classNames([styles.alert, getTypeStyle(), getSizeStyle()])}>
						{props.messages.map((errorMessage, index) => (
							<span
								key={'alert-message-' + id + '-' + index}
								className={styles.text}
								dangerouslySetInnerHTML={{ __html: errorMessage.message + '' }}
							/>
						))}
						<div className={styles.icon}>{getIcon()}</div>
					</div>
				</div>
			)}
		</>
	);
};

Alert.propTypes = {
	messages: PropTypes.array,
	type: PropTypes.string,
	size: PropTypes.string,
	removeMarginTop: PropTypes.bool,
	removeMarginBottom: PropTypes.bool
};

export default Alert;
