const defaultState = {
	hasUpsell: false,
	upsellAlreadyDone: false,
	maxUpsellAmount: null,
	maxMonthlyRate: null,
	creditAmountNet: null,
	interestAmount: null,
	rateMonthlyAmount: null,
	runtimeInMonths: null,
	redemptionAmount: null,
	effectiveAnnualInterestRate: null,
	fixedBorrowingRate: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_HAS_UPSELL':
			return { ...state, hasUpsell: !!action.payload };
		case 'SET_UPSELL_ALREADY_DONE':
			return { ...state, upsellAlreadyDone: !!action.payload };
		case 'SET_MAX_UPSELL_AMOUNT':
			return { ...state, maxUpsellAmount: action.payload ? parseInt(action.payload) : null };
		case 'SET_MAX_MONTHLY_RATE':
			return { ...state, maxMonthlyRate: action.payload ? parseFloat(action.payload) : null };
		case 'SET_CREDIT_AMOUNT_NET':
			return { ...state, creditAmountNet: action.payload ? parseFloat(action.payload) : null };
		case 'SET_INTEREST_AMOUNT':
			return { ...state, interestAmount: action.payload ? parseFloat(action.payload) : null };
		case 'SET_RATE_MONTHLY_AMOUNT':
			return { ...state, rateMonthlyAmount: action.payload ? parseFloat(action.payload) : null };
		case 'SET_RUNTIME_IN_MONTHS':
			return { ...state, runtimeInMonths: action.payload ? parseInt(action.payload) : null };
		case 'SET_REDEMTION_AMOUNT':
			return { ...state, redemptionAmount: action.payload ? parseInt(action.payload) : null };
		case 'SET_EFFECTIVE_ANNUAL_INTEREST_RATE':
			return { ...state, effectiveAnnualInterestRate: action.payload ? parseFloat(action.payload) : null };
		case 'SET_FIXED_BORROWING_RATE':
			return { ...state, fixedBorrowingRate: action.payload ? parseFloat(action.payload) : null };
		case 'SET_UPSELL':
			return {
				...state,
				hasUpsell: !!action.payload?.optionAmount?.kreditbetragNetto,
				upsellAlreadyDone: false,
				maxUpsellAmount: action.payload?.maxUpsellAmount ? parseInt(action.payload.maxUpsellAmount) : null,
				maxMonthlyRate: action.payload?.maxMonthlyRate ? parseFloat(action.payload.maxMonthlyRate) : null,
				creditAmountNet: action.payload?.optionAmount?.kreditbetragNetto ? parseFloat(action.payload.optionAmount.kreditbetragNetto) : null,
				interestAmount: action.payload?.optionAmount?.zinsenGesamt ? parseFloat(action.payload.optionAmount.zinsenGesamt) : null,
				rateMonthlyAmount: action.payload?.optionAmount?.ratenbetragMonat ? parseFloat(action.payload.optionAmount.ratenbetragMonat) : null,
				runtimeInMonths: action.payload?.optionAmount?.laufzeitInMonaten ? parseFloat(action.payload.optionAmount.laufzeitInMonaten) : null,
				redemptionAmount: action.payload?.optionAmount?.abloesebetrag ? parseFloat(action.payload.optionAmount.abloesebetrag) : null,
				effectiveAnnualInterestRate: action.payload?.optionAmount?.effektivzinsPaProz ? parseFloat(action.payload.optionAmount.effektivzinsPaProz) : null,
				fixedBorrowingRate: action.payload?.optionAmount?.nominalzinsPaProz ? parseFloat(action.payload.optionAmount.nominalzinsPaProz) : null
			};
		case 'RESET_UPSELL':
			return {
				...state,
				hasUpsell: false,
				upsellAlreadyDone: false,
				maxUpsellAmount: null,
				maxMonthlyRate: null,
				creditAmountNet: null,
				interestAmount: null,
				rateMonthlyAmount: null,
				runtimeInMonths: null,
				redemptionAmount: null,
				effectiveAnnualInterestRate: null,
				fixedBorrowingRate: null
			};
		default:
			return state;
	}
};

export default reducers;
