import C from 'constants/Constants';

import * as messagesGiro from 'messages/giro';
import * as messagesDepot from 'messages/depot';
import * as messagesGmk from 'messages/gmk';
import * as messagesSparbrief from 'messages/sparbrief';
import * as messagesBestCardBasic from 'messages/bestCardBasic';
import * as messagesBestCardSmart from 'messages/bestCardSmart';
import * as messagesBestCardExtra from 'messages/bestCardExtra';
import * as messagesBestCardPremium from 'messages/bestCardPremium';
import * as messagesVisacard from 'messages/visacard';
import * as messagesCredit from 'messages/credit';
import * as messagesShortCredit from 'messages/shortcredit';
import * as messagesFields from 'messages/fields';
import * as messagesGlobal from 'messages/global';

export const m = (
	messagePath,
	flow,
	replacementObject = null,
	convertToJsx = false
) => {
	let message = messagePath;
	let messagesObject = messagesGlobal;
	try {
		flow = flow ? flow : 'global';
		switch (flow) {
			case C.FRONTEND.BEST_CARD_BASIC:
				messagesObject = messagesBestCardBasic;
				break;
			case C.FRONTEND.BEST_CARD_SMART:
				messagesObject = messagesBestCardSmart;
				break;
			case C.FRONTEND.BEST_CARD_EXTRA:
				messagesObject = messagesBestCardExtra;
				break;
			case C.FRONTEND.BEST_CARD_PREMIUM:
				messagesObject = messagesBestCardPremium;
				break;
			case C.FRONTEND.VISACARD:
				messagesObject = messagesVisacard;
				break;
			case C.FRONTEND.CREDIT:
				messagesObject = messagesCredit;
				break;
			case C.FRONTEND.SHORT_CREDIT:
				messagesObject = messagesShortCredit;
				break;
			case C.FRONTEND.GMK:
				messagesObject = messagesGmk;
				break;
			case C.FRONTEND.SPARBRIEF:
				messagesObject = messagesSparbrief;
				break;
			case C.FRONTEND.GIRO:
				messagesObject = messagesGiro;
				break;
			case C.FRONTEND.DEPOT:
				messagesObject = messagesDepot;
				break;
			case 'fields':
				messagesObject = messagesFields;
				break;
			default:
				messagesObject = messagesGlobal;
				break;
		}
		message = getMessages(messagePath, replacementObject, messagesObject);
	} catch (e) {
		try {
				message = getMessages(messagePath, replacementObject, messagesGlobal);
		} catch (e) {
			console.log('Can\'t find message key "' + messagePath + '" of given flow "' + flow + '"');
			console.log(e);
		}
	}

	if (convertToJsx !== undefined && convertToJsx === true) {
		return <span dangerouslySetInnerHTML={{ __html: message }} />;
	} else {
		return message;
	}
};

const getMessages = (messagePath, replacementObject, messages) => {
	let tempMessage = convertStringToObjectPath(messagePath, messages);
	if (tempMessage !== undefined && typeof tempMessage === 'string') {
		return replaceSubstrings(tempMessage, replacementObject);
	} else {
		let tempMessage = convertStringToObjectPath(messagePath, messagesGlobal);
		return tempMessage !== undefined && typeof tempMessage === 'string'
			? replaceSubstrings(tempMessage, replacementObject)
			: messagePath;
	}
};

const convertStringToObjectPath = (path, obj) => {
	return path.split('.').reduce((obj, i) => {
		return obj[i];
	}, obj);
};

const replaceSubstrings = (message, replacementObject) => {
	let replacedMessage = message;

	if (
		replacementObject !== undefined &&
		typeof replacementObject === 'object'
	) {
		let key;

		for (key in replacementObject) {
			try {
				if (
					replacedMessage.includes('{' + key + '}') &&
					(typeof replacementObject[key] === 'string' ||
						typeof replacementObject[key] === 'number')
				) {
					replacedMessage = replacedMessage.replace(
						'{' + key + '}',
						replacementObject[key]
					);
				}
			} catch (e) {
				console.log(e);
			}
		}
	}

	return replacedMessage;
};

export const mapToObject = (map) => {
	const obj = {};
	for (let [k, v] of map) obj[k] = v;
	return obj;
};
