export const defaultState = {
	customerNumber: null,
	currentApplicant: null,
	firstApplicantName: null,
	firstApplicantDocumentUploadStatus: null,
	firstApplicantContractSigned: null,
	secondApplicantName: null,
	secondApplicantDocumentUploadStatus: null,
	secondApplicantContractSigned: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_CUSTOMER_NUMBER':
			return { ...state, customerNumber: action.payload };
		case 'SET_CURRENT_APPLICANT':
			return { ...state, currentApplicant: action.payload };
		case 'SET_FIRST_APPLICANT_NAME':
			return { ...state, firstApplicantName: action.payload };
		case 'SET_FIRST_APPLICANT_DOCUMENT_UPLOAD_STATUS':
			return { ...state, firstApplicantDocumentUploadStatus: action.payload };
		case 'SET_FIRST_APPLICANT_CONTRACT_SIGNED':
			return { ...state, firstApplicantContractSigned: action.payload };
		case 'SET_SECOND_APPLICANT_NAME':
			return { ...state, secondApplicantName: action.payload };
		case 'SET_SECOND_APPLICANT_DOCUMENT_UPLOAD_STATUS':
			return { ...state, secondApplicantDocumentUploadStatus: action.payload };
		case 'SET_SECOND_APPLICANT_CONTRACT_SIGNED':
			return { ...state, secondApplicantContractSigned: action.payload };
		default:
			return state;
	}
};

export default reducers;
