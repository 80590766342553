import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 12;
	const height = 12;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="m11.7,7.8c.17,0,.3.13.3.3v1.52c0,1.31-1.07,2.38-2.38,2.38H2.38c-1.31,0-2.38-1.06-2.38-2.38v-1.52c0-.17.13-.3.3-.3s.3.13.3.3v1.52c0,.98.8,1.78,1.78,1.78h7.23c.98,0,1.78-.8,1.78-1.78v-1.52c0-.17.13-.3.3-.3ZM6,0c.15,0,.29.11.32.26v.06s0,7.62,0,7.62l1.83-1.78c.13-.12.33-.12.45,0,.11.11.12.28.04.4l-.04.04-2.38,2.31c-.12.11-.29.12-.42.03l-.03-.03-2.38-2.31c-.12-.12-.13-.31,0-.43,0,0,0,0,0,0,.11-.11.28-.12.41-.04l.04.04,1.83,1.78V.31C5.68.14,5.82,0,6,0Z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
