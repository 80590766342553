import { clone, isEmpty } from 'lodash';
import { C as PPITarif } from 'constants/enums/PPITarif';
import { PPI_MIN_SENIOR_AGE_DEFAULT, CREDIT_AMOUNT_MAX_VALUE, CREDIT_AMOUNT_DEFAULT, CREDIT_RUNTIME_STEPS, CREDIT_RUNTIME_DEFAULT, PPI_MIN_SENIOR_AGE_CREDIT } from 'constants/Credit';
import { useUtils } from 'utils/utils';
import { useIbanUtils } from 'utils/iban/iban';
import { useNumberUtils } from 'utils/number/number';
import { useDateUtils } from 'utils/date/date';

import { useDispatch, useSelector } from 'react-redux';
import { setRuntimeLimit, setSidebarActive, updateRuntime, updateSidebarRuntimeAnimation } from 'store/App/actions';

export const useCreditUtils = () => {
	const { isCreditSubType } = useUtils();
	const { getAge, getAgeWithRuntime, monthDiff } = useDateUtils();
	const { trimIban } = useIbanUtils();
	const { roundUp } = useNumberUtils();

	const dispatch = useDispatch();
	const storeAmount = useSelector((state => state.app.amount));
	const storeRuntime = useSelector((state => state.app.runtime));
	const storeIsSidebarRateChecked = useSelector((state => state.app.isSidebarRateChecked));
	const storeCcbData = useSelector((state => state.app.ccbData));
	const storeExternalCredits = useSelector((state => state.externalCredit.externalCredits));
	const storeHasPpi = useSelector((state => state.insurance.hasPpi));
	const storeProtect = useSelector((state => state.insurance.protect));
	const storeProtectMonthlyRate = useSelector((state => state.insurance.protectMonthlyRate));
	const storeProtectRuntime = useSelector((state => state.insurance.protectRuntime));
	const storeBirthdate = useSelector((state => state.birthdate.birthdate));
	const storeIncome = useSelector((state => state.financialStatus.income));
	const storeAdditionalIncome = useSelector((state => state.financialStatus.additionalIncome));
	const storeOccupation = useSelector((state => state.occupation.occupation));
	const storeOccupationLimitedMonth = useSelector((state => state.occupation.occupationLimitedMonth));
	const storeOccupationLimitedYear = useSelector((state => state.occupation.occupationLimitedYear));

	const storeBcoTotalAmount = useSelector((state => state.app.bcoResults.creditAmountTotal));

	const getValidRuntimeLimit = (value) => {
		const sliderValues = CREDIT_RUNTIME_STEPS;
		let validRuntimeLimitObj = {
			validRuntimeLimit: 12,
			validRuntimeIndex: 0
		};

		for (
			let sliderValueIndex = 0;
			sliderValueIndex < sliderValues.length;
			sliderValueIndex++
		) {
			let item = sliderValues[sliderValueIndex];
			let itemBefore = sliderValues[sliderValueIndex - 1];

			if (value < item) {
				validRuntimeLimitObj = {
					validRuntimeLimit: itemBefore,
					validRuntimeIndex: sliderValueIndex
				};
				break;
			} else if (value >= CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1]) {
				validRuntimeLimitObj = {
					validRuntimeLimit: CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1],
					validRuntimeIndex: sliderValues.length
				};
			}
		}

		return validRuntimeLimitObj;
	};

	const getSummedExternalCreditAmount = (customExternalCredits = null) => {
		const externalCredits = customExternalCredits ? customExternalCredits : storeExternalCredits;
		let summedRemainingBalance = 0;
		for (const externalCredit of externalCredits) {
			summedRemainingBalance += externalCredit.remainingBalance ? parseInt(externalCredit.remainingBalance) : 0;
		}
		return summedRemainingBalance;
	};

	const getExternalCreditsWithTrimmedIbans = () => {
		let externalCredits = clone(storeExternalCredits);
		for (let externalCredit of externalCredits) {
			externalCredit.iban = trimIban(externalCredit.iban);
		}
		return externalCredits;
	};

	const summedNetAmount = () => {
		const amount = storeAmount ?? CREDIT_AMOUNT_DEFAULT;
		return amount + getSummedExternalCreditAmount();
	};

	const getNormalizedAmount = (amount) => {
		return roundUp(amount, 500, CREDIT_AMOUNT_MAX_VALUE);
	};

	const getTotalAmount = (customAmount = null) => {
		const netAmount = customAmount ?? (storeBcoTotalAmount !== null ? storeBcoTotalAmount : summedNetAmount());
		return netAmount + (storeHasPpi ? getPpiValue() : 0) + getProtectValue() + getInterest();
	};

	const summedRuntime = (customRuntime = null) => {
		const runtime = customRuntime ?? storeRuntime ?? CREDIT_RUNTIME_DEFAULT;
		return runtime ?? 0;
	};

	const getNormalizedRuntime = (runtime) => {
		let normalizedRuntime;
		const index = CREDIT_RUNTIME_STEPS.findIndex((defaultRuntime) => {
			return runtime < defaultRuntime;
		});

		if (index === -1) {
			normalizedRuntime = CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1];
		} else if (index === 0) {
			normalizedRuntime = CREDIT_RUNTIME_STEPS[0];
		} else {
			normalizedRuntime = CREDIT_RUNTIME_STEPS[index - 1];
		}

		return normalizedRuntime;
	};

	const getJsonData = () => {
		return storeCcbData && storeCcbData.credit_values ? storeCcbData.credit_values : {};
	};

	const getMonthlyRateJsonData = () => {
		const jsonData = getJsonData();
		if (!isEmpty(jsonData)) {
			const normalizedAmountString = getNormalizedAmount(summedNetAmount() + (storeHasPpi ? getPpiValue() : 0) + getProtectValue()).toString();
			const normalizedRuntimeString = getNormalizedRuntime(summedRuntime()).toString();
			try {
				return jsonData['0'][normalizedAmountString][normalizedRuntimeString];
			} catch (e) {
				return null;
			}
		} else {
			return null;
		}
	};

	const getMonthlyRate = (customAmount = null, customRuntime = null) => {
		try {
			if (storeHasPpi || storeProtect || customAmount || customRuntime) {
				return getTotalAmount(customAmount) / getNormalizedRuntime(summedRuntime(customRuntime));
			} else {
				const jsonData = getMonthlyRateJsonData();
				return jsonData[jsonData.length - 1];
			}
		} catch (e) {
			return 0;
		}
	};

	const isPpiSenior = (withRuntime = true) => {
		let age;
		if (withRuntime) {
			age = getAgeWithRuntime(storeBirthdate, mapCurrentRuntimeToYears());
		} else {
			age = getAge(storeBirthdate);
		}
		const minSeniorAge = isCreditSubType() ? PPI_MIN_SENIOR_AGE_CREDIT : PPI_MIN_SENIOR_AGE_DEFAULT;
		return age >= minSeniorAge;
	};

	const getPpiTarif = () => {
		const age = getAge(storeBirthdate);
		const ageWithDuration = getAgeWithRuntime(storeBirthdate, mapCurrentRuntimeToYears());
		if (!age) {
			return null;
		}

		if (age > 17 && age < 65 && ageWithDuration < 74) {
			if (
				storeOccupation === 'OFFICER' ||
				storeOccupation === 'FREELANCER' ||
				storeOccupation === 'PENSINER'
			) {
				return PPITarif.TARIF_1_2;
			} else if (
				storeOccupation === 'CIVIL_SERVICE_EMPLOYEE' ||
				storeOccupation === 'SOLDIER'
			) {
				return PPITarif.TARIF_1_3;
			} else {
				return PPITarif.TARIF_1_1;
			}
		} else if (age > 64 && age < 70 && ageWithDuration < 74) {
			return PPITarif.TARIF_2;
		} else if (age > 63 && age < 78 && ageWithDuration < 78) {
			return PPITarif.TARIF_3;
		} else {
			return null;
		}
	};

	const getPpiValue = () => {
		const amount = getNormalizedAmount(summedNetAmount() + getProtectValue()).toString();
		const tarif = getPpiTarif();
		if (!tarif) {
			return 0;
		}
		switch (tarif) {
			case PPITarif.TARIF_3:
				return amount * 0.1026; // SENIOR
			case PPITarif.TARIF_2:
				return amount * 0.042; // BASE TOD
			case PPITarif.TARIF_1_1:
			case PPITarif.TARIF_1_2:
			case PPITarif.TARIF_1_3:
				return amount * 0.0487; // BASE
			default:
				return 0;
		}
	};

	const getPpiMonthlyRate = () => {
		const runtime = summedRuntime();
		return getPpiValue() / runtime;
	};

	const getProtectValues = () => {
		let jsonData = require('data/protect.json');
		if (storeProtectMonthlyRate && storeProtectRuntime) {
			return jsonData[storeProtectMonthlyRate + ''][storeProtectRuntime + ''];
		} else {
			return null;
		}
	};

	const getProtectMonthlyRate = (protectValue) => {
		return protectValue ? (protectValue / (storeRuntime ?? 84)) : 0;
	};

	const getProtectValue = () => {
		const protectValues = getProtectValues();
		const selectedProtect = storeProtect;
		let protectValue = 0;
		if (selectedProtect === 'standard') {
			protectValue = protectValues[1];
		} else if (selectedProtect === 'premium') {
			protectValue = protectValues[2];
		} else if (selectedProtect === 'basic') {
			protectValue = protectValues[0];
		}
		return protectValue;
	};

	const getEffectiveYearlyInterestRate = () => {
		try {
			const jsonData = getMonthlyRateJsonData();
			return jsonData[0];
		} catch (e) {
			return 0;
		}
	};

	const getFixedBorrowingRate = () => {
		try {
			const jsonData = getMonthlyRateJsonData();
			return jsonData[1];
		} catch (e) {
			return 0;
		}
	};

	const getInterest = () => {
		try {
			const jsonData = getMonthlyRateJsonData();
			return jsonData[2];
		} catch (e) {
			return 0;
		}
	};

	const getMonthlyIncome = () => {
		const income = storeIncome || 0;
		const additionalIncome = storeAdditionalIncome || 0;
		return parseFloat(income) + parseFloat(additionalIncome);
	};

	const occupationCalculateMaxRuntimeValue = (month, year) => {
		let today = new Date();
		let dateFrom = new Date(today.getFullYear(), today.getMonth() + 1);
		let dateTo = new Date(year, month);

		return monthDiff(dateFrom, dateTo) + 6;
	};

	const occupationCheckRunTimeLimit = () => {
		let currentRuntime = storeRuntime;
		let runtimeLimit = CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1];
		let isValid = true;

		if (storeOccupationLimitedMonth && storeOccupationLimitedMonth.length === 2 && storeOccupationLimitedYear && storeOccupationLimitedYear.length === 4) {
			runtimeLimit = occupationCalculateMaxRuntimeValue(storeOccupationLimitedMonth, storeOccupationLimitedYear);
			runtimeLimit = runtimeLimit < CREDIT_RUNTIME_STEPS[0] ? CREDIT_RUNTIME_STEPS[0] : runtimeLimit;
			runtimeLimit = runtimeLimit > CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1] ? CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1] : runtimeLimit;
			runtimeLimit = storeIsSidebarRateChecked ? runtimeLimit - 3 : runtimeLimit;
			runtimeLimit = getValidRuntimeLimit(runtimeLimit).validRuntimeLimit;
			dispatch(setRuntimeLimit(runtimeLimit));
		}

		if (currentRuntime > runtimeLimit) {
			dispatch(setSidebarActive(true));
			dispatch(updateRuntime(runtimeLimit));
			dispatch(updateSidebarRuntimeAnimation(true));
			isValid = false;
		}

		return isValid;
	};

	const mapCurrentRuntimeToYears = () => {
		const runtime = storeRuntime ?? CREDIT_RUNTIME_DEFAULT;
		return Math.ceil(runtime / 12);
	};

	return {
		getValidRuntimeLimit,
		getSummedExternalCreditAmount,
		getExternalCreditsWithTrimmedIbans,
		summedNetAmount,
		getTotalAmount,
		summedRuntime,
		getMonthlyRate,
		isPpiSenior,
		getPpiTarif,
		getPpiValue,
		getPpiMonthlyRate,
		getProtectValues,
		getProtectMonthlyRate,
		getProtectValue,
		getEffectiveYearlyInterestRate,
		getFixedBorrowingRate,
		getInterest,
		getMonthlyIncome,
		occupationCheckRunTimeLimit,
		mapCurrentRuntimeToYears
	};
};
