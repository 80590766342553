import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="m5.89,21c-.76,0-1.5-.3-2.04-.84l7.49-6.64c.38-.34.95-.34,1.32,0l7.48,6.64c-.54.54-1.27.84-2.04.84H5.89Zm-.89-10.09l-1.77-1.19,1.77-2.21v3.4Zm-1.72,8.42c-.18-.38-.28-.8-.28-1.22v-7.34l5.65,3.79s-5.37,4.77-5.37,4.77ZM6,3h12v8.5s.01.05.02.07l-3.44,2.31-1.25-1.11c-.76-.67-1.9-.67-2.65,0l-1.25,1.11-3.44-2.31s.01-.05.01-.07V3h0Zm14.77,6.72l-1.77,1.19v-3.4l1.77,2.21h0Zm.23,8.39c0,.44-.1.85-.28,1.22l-5.37-4.76,5.65-3.8v7.34h0Zm1,0v-8.32s-.01-.06-.01-.06l-.02-.06-.02-.04-.04-.08h-.01s-.01-.03-.01-.03l-2.89-3.61v-3.41c0-.28-.22-.5-.5-.5H5.5c-.28,0-.5.22-.5.5v3.41l-2.89,3.61v.02s-.02.01-.02.01l-.02.04s-.04.07-.05.11c0,.02,0,.05-.01.07v.05s-.01,8.28-.01,8.28c0,.81.25,1.57.68,2.19.02.06.05.11.09.15.03.03.06.05.09.07.71.89,1.8,1.48,3.03,1.48h12.22c1.2,0,2.34-.57,3.07-1.53l.04-.03.04-.07c.48-.66.74-1.45.74-2.26h0Z" />
			<path d="m15.18,4.18c.29,0,.53.24.53.53,0,.14-.06.28-.16.38l-2.81,2.81,2.81,2.81c.21.21.21.54,0,.75-.1.1-.24.16-.38.16-.14,0-.28-.05-.38-.15l-2.81-2.81-2.8,2.8c-.21.21-.54.21-.75,0-.21-.21-.21-.54,0-.75l2.81-2.81-2.8-2.81c-.2-.22-.19-.55.03-.75.2-.19.52-.19.73,0l2.81,2.81,2.81-2.81c.1-.1.23-.16.38-.16Z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
