import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import Dropzone from 'react-dropzone';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { m } from 'utils/messages/messages';
import { useDocumentsApi } from 'api/documents';

import { Text } from 'components/Atoms/Text';
import Alert from 'components/Atoms/Alert/Alert';
import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';
import SvgCheck from 'components/Atoms/SVG/Icons/SvgCheck';
import SvgLoader from 'components/Atoms/SVG/Icons/SvgLoader';
import SvgAlert from 'components/Atoms/SVG/Icons/SvgAlert';
import SvgAddCircle from 'components/Atoms/SVG/Icons/SvgAddCircle';
import SvgUploadToCloud from 'components/Atoms/SVG/Icons/SvgUploadToCloud';

import styles from './MultipleFileUpload.module.scss';

const MultipleFileUpload = (props) => {
	const { postDocument, deleteDocument } = useDocumentsApi();

	const id = uuid();
	const maxFiles = 5;
	const maxFilesAtATime = 1;
	const [loadingFiles, setLoadingFiles] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [isShowError, setIsShowError] = useState(false);
	const defaultErrorMessage = 'upload.defaultError';
	const fileSizeTooBigErrorMessage = 'upload.maxUploadSize';
	const tooManyFilesMessage = 'upload.tooManyFiles';
	const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

	useEffect(() => {
		if (props.init && props.init.length > 0) {
			setUploadedFiles(props.init);
		}
	}, []);

	const onDrop = async (droppedFiles) => {
		if (droppedFiles.length > 0) {
			if (loadingFiles.length + uploadedFiles.length < maxFiles) {
				const allowedFiles = maxFiles - (loadingFiles.length + uploadedFiles.length);
				if (allowedFiles > 0) {
					const droppedFilesSliced = droppedFiles.slice(0, allowedFiles);
					setLoadingFiles(droppedFilesSliced);
					setIsShowError(false);
					setErrorMessage(defaultErrorMessage);
					await postDocument(
						props.label,
						droppedFilesSliced,
						uploadedFiles,
						setUploadedFiles,
						props.missingDocuments,
						props.setMissingDocuments,
						props.participant ?? 1,
						() => {
							setLoadingFiles([]);
						},
						(statusCode) => {
							// TODO: replace with real status code
							if (statusCode && statusCode === 632) {
								setErrorMessage(fileSizeTooBigErrorMessage);
							} else {
								setErrorMessage(defaultErrorMessage);
							}

							setIsShowError(true);
							setLoadingFiles([]);
						}
					);
				}
			}
		} else {
			setIsShowError(true);
			setErrorMessage(tooManyFilesMessage);
		}
	};

	const customOnDropWrapper = (droppedFiles) => {
		props.onDrop(droppedFiles, setUploadedFiles);
	};

	const deleteItem = async (index) => {
		await deleteDocument(
			index,
			props.label,
			uploadedFiles,
			setUploadedFiles,
			props.missingDocuments,
			props.setMissingDocuments
		);
		setIsShowError(false);
		setErrorMessage(defaultErrorMessage);
	};

	const getFileTypes = () => {
		const mimeTypeArray = [];

		if (props.allowJpg) {
			mimeTypeArray.push('image/jpeg');
		}
		if (props.allowPng) {
			mimeTypeArray.push('image/png');
		}
		if (props.allowPdf) {
			mimeTypeArray.push('application/pdf');
		}

		return mimeTypeArray.join(',').trim();
	};

	const getFileTypesPrint = () => {
		const mimeTypeArray = [];

		if (props.allowJpg) {
			mimeTypeArray.push('JPG');
		}
		if (props.allowPng) {
			mimeTypeArray.push('PNG');
		}
		if (props.allowPdf) {
			mimeTypeArray.push('PDF');
		}

		return mimeTypeArray.join(', ').trim();
	};

	const getUploadedFileItem = (file, index, isLoading) => {
		return (
			<div
				key={id + '-files-' + index + (isLoading ? '-loading' : '')}
				className={styles.itemWrapper}
			>
				<div className={styles.item}>
					<div className={styles.itemText}>
						<Text>{file.name}</Text>
					</div>
					{!isLoading && (
						<div
							className={styles.itemIcon}
							data-testid="delete-item"
							onClick={() => {
								props.deleteItem
									? props.deleteItem(setUploadedFiles)
									: deleteItem(index);
							}}
						>
							<SvgClose color="#6F7779" />
						</div>
					)}
				</div>
				<div
					className={classNames([
						styles.itemUploadedFileIcon,
						isLoading ? styles.itemUploadedFileIconLoading : null
					])}
				>
					{isLoading ? <SvgLoader color="#6F7779" /> : <SvgCheck color="#ffffff" />}
				</div>
			</div>
		);
	};

	return (
		<div
			className={classNames([
				styles.wrapper,
				!(loadingFiles.length > 0 || uploadedFiles.length > 0)
					? styles.hasNoFilesMobile
					: null
			])}
		>
			<div className={styles.content}>
				{props.label && (
					<div className={styles.title}>
						<h3>{props.label}</h3>
					</div>
				)}
				{props.description && <Text size="s" mb>{props.description}</Text>}
			</div>

			{(loadingFiles.length > 0 || uploadedFiles.length > 0) && (
				<div className={styles.list}>
					{uploadedFiles.length > 0 && (
						<>
							{uploadedFiles.map((file, index) =>
								getUploadedFileItem(file, index, false)
							)}
						</>
					)}
					{loadingFiles && loadingFiles.length > 0 && (
						<>
							{loadingFiles.map((file, index) =>
								getUploadedFileItem(file, index, true)
							)}
						</>
					)}
				</div>
			)}

			{loadingFiles.length + uploadedFiles.length < maxFiles && (
				<Dropzone
					maxFiles={maxFilesAtATime}
					onDrop={props.onDrop ? customOnDropWrapper : onDrop}
					accept={getFileTypes()}
				>
					{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
						<div className={styles.dropzoneWrapper} {...getRootProps()}>
							<input {...getInputProps()} multiple data-testid="dropzone" />
							<div
								className={classNames([
									styles.dropzone,
									!(loadingFiles.length > 0 || uploadedFiles.length > 0)
										? styles.dropzoneBg
										: null
								])}
							>
								<div
									className={
										styles.dropzoneDragActiveOverlay +
										(isDragActive ? ' ' + styles.dropzoneIsDragActive : '')
									}
								>
									<p>
										<SvgCheck color="#1BB3BC" />
										{m('dropzone.drop', 'fields')}
									</p>
								</div>

								<div
									className={
										styles.dropzoneDragRejectedOverlay +
										(isDragReject ? ' ' + styles.dropzoneIsDragReject : '')
									}
								>
									<p>
										<SvgAlert color="#EC0000" />
										{m('dropzone.reject', 'fields')} {getFileTypesPrint()}
									</p>
								</div>
								{loadingFiles.length > 0 || uploadedFiles.length > 0 ? (
									<div className={styles.dropzoneContentWrapperAdditional}>
										<div
											className={styles.dropzoneContentWrapperAdditionalIcon}
										>
											<SvgAddCircle color="#9E3667" />
										</div>
										<div
											className={styles.dropzoneContentWrapperAdditionalText}
										>
											<p>
												{m(
													'dropzone.addAdditionalFile',
													'fields',
													null,
													true
												)}
											</p>
										</div>
									</div>
								) : (
									<div className={styles.dropzoneContentWrapper}>
										<div className={styles.dropzoneIcon}>
											<SvgUploadToCloud />
										</div>
										<div className={styles.dropzoneText}>
											<p className={'link'}>
												{m('dropzone.default', 'fields')}
											</p>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</Dropzone>
			)}

			{isShowError && errorMessage && (
				<div className={styles.uploadErrorWrapper}>
					<Alert messages={[{ code: FormErrorCode.OTHER, message: m(errorMessage, 'fields') }]} type="error" />
				</div>
			)}
		</div>
	);
};

MultipleFileUpload.propTypes = {
	index: PropTypes.number,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	allowJpg: PropTypes.bool,
	allowPng: PropTypes.bool,
	allowPdf: PropTypes.bool,
	init: PropTypes.array,
	missingDocuments: PropTypes.array,
	setMissingDocuments: PropTypes.func,
	participant: PropTypes.number,
	onDrop: PropTypes.func,
	deleteItem: PropTypes.func
};

export default MultipleFileUpload;
