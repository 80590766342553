import axios from 'axios';
import C from 'constants/Constants';
import { useValidationUtils } from 'utils/validation/validation';
import { useApiUtils } from 'utils/api/api';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useDateUtils } from 'utils/date/date';
import { usePhoneUtils } from 'utils/phone/phone';
import { useCreditUtils } from 'utils/credit/credit';

import { useDispatch, useSelector } from 'react-redux';
import { updateAppLoading, updateToken, updateSanc, updateUid, updateIsRequestFromCaps, updateRelogin } from 'store/App/actions';
import { setCountryCode, setMobileNumber, setEncryptedMobileNumber } from 'store/MobileNumber/actions';

export const useRegistrationApi = () => {
	const { getCurrentFlow, getProductType, isCreditSubType } = useUtils();
	const { isEmpty } = useValidationUtils();
	const { addUserDataToStore, getApiBaseURL, startApiCall, stopApiCall, checkUnauthorized } = useApiUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, error } = useTrackingUtils();
	const { formatFullDateForApi } = useDateUtils();
	const { addSpaceAfterCountryCode, removeNonDigitsAndLeadingZeroesFrom, splitMobileNumberAndCountryCode } = usePhoneUtils();
	const { getSummedExternalCreditAmount } = useCreditUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeAccessToken = useSelector((state => state.app.accessToken));

	const storeAllowAdvertising = useSelector((state => state.consent.allowAdvertising));
	const storeSalutation = useSelector((state => state.name.salutation));
	const storeFirstName = useSelector((state => state.name.firstName));
	const storeLastName = useSelector((state => state.name.lastName));
	const storeBirthdate = useSelector((state => state.birthdate.birthdate));
	const storeEmail = useSelector((state => state.email.email));
	const storeCountryCode = useSelector((state => state.mobileNumber.countryCode));
	const storeMobileNumber = useSelector((state => state.mobileNumber.mobileNumber));

	const storeSanc = useSelector((state => state.app.sanc));
	const storeUid = useSelector((state => state.app.uid));

	const storeCreditAmount = useSelector((state => state.app.amount));
	const storeCreditRuntime = useSelector((state => state.app.runtime));
	const storeCreditCondition = useSelector((state => state.app.condition));
	const storeIncome = useSelector((state => state.financialStatus.income));
	const storeAdditionalIncome = useSelector((state => state.financialStatus.additionalIncome));

	const storeInvestmentAmount = useSelector((state => state.investment.amount));
	const storeInvestmentRuntime = useSelector((state => state.investment.runtime));
	const storeInvestmentPaymentType = useSelector((state => state.investment.paymentType));

	const storeCustomerNumber = useSelector((state => state.applicant.customerNumber));
	const storeAccountNumber = useSelector((state => state.bankAccount.accountNumber));

	const postRegistration = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		const data = {
			productType: getProductType(),
			sanc: !isEmpty(storeSanc) ? storeSanc : undefined,
			uid: !isEmpty(storeUid) ? storeUid : undefined,
			allowAdvertising: !!storeAllowAdvertising,
			salutation: !isEmpty(storeSalutation) ? storeSalutation.trim() : undefined,
			givenname: !isEmpty(storeFirstName) ? storeFirstName.trim() : undefined,
			surname: !isEmpty(storeLastName) ? storeLastName.trim() : undefined,
			dateOfBirth: !isEmpty(storeBirthdate) ? formatFullDateForApi(storeBirthdate) : undefined,
			email: !isEmpty(storeEmail) ? storeEmail.trim() : undefined,
			mobilePhone: !isEmpty(storeCountryCode) && !isEmpty(storeMobileNumber) ?
				(
					'+' + storeCountryCode +
					' ' + removeNonDigitsAndLeadingZeroesFrom(storeMobileNumber)
				) : undefined
		};

		if (isCreditSubType()) {
			data.additionalData = {
				amount: !isEmpty(storeCreditAmount) ? parseInt(storeCreditAmount) : 0,
				runtime: !isEmpty(storeCreditRuntime) ? parseInt(storeCreditRuntime) : 0,
				redemptionAmount: getSummedExternalCreditAmount(),
				condition: !isEmpty(storeCreditCondition) ? storeCreditCondition.toUpperCase() : C.CONDITIONS.CCB,
				income: !isEmpty(storeIncome, true) ? parseInt(storeIncome) : undefined,
				additionalIncome: !isEmpty(storeAdditionalIncome, true) ? parseInt(storeAdditionalIncome) : undefined
			};
		}

		if (currentFlow === C.FRONTEND.SPARBRIEF) {
			data.additionalData = {
				amount: !isEmpty(storeInvestmentAmount, true) ? storeInvestmentAmount : undefined,
				runtime: !isEmpty(storeInvestmentRuntime, true) ? storeInvestmentRuntime : undefined,
				paymentType: !isEmpty(storeInvestmentPaymentType) ? storeInvestmentPaymentType : undefined
			};
		}

		if (currentFlow === C.FRONTEND.VISACARD) {
			data.additionalData = {
				fnumber: storeCustomerNumber.trim(),
				accountNumber: storeAccountNumber.trim()
			};
		}

		await axios
			.post(getApiBaseURL() + '/registration', data)
			.then(() => {
				onRegistrationSuccess(onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST /registration call failed');
				const unauthorized = checkUnauthorized(err.response?.status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const onRegistrationSuccess = (
		onSuccess = () => {}
	) => {
		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess();
	};

	const patchEmailConfirmation = async (
		accessToken,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		const data = {
			registrationToken: accessToken,
			productType: getProductType()
		};
		await axios
			.patch(getApiBaseURL() + '/email-confirmation', data)
			.then((response) => {
				onEmailConfirmSuccess(
					response.data,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'PATCH /email-confirmation call failed');
				const unauthorized = checkUnauthorized(err.response?.status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const onEmailConfirmSuccess = (
		data,
		onSuccess = () => {}
	) => {
		addUserDataToStore(data);

		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess();
	};

	const patchResendInvitationEmail = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();

		const data = {
			email: storeEmail,
			productType: getProductType()
		};

		await axios
			.patch(getApiBaseURL() + '/resend-invitation-email', data)
			.then(() => {
				onResendInvitationEmailSuccess(onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'PATCH /resend-invitation-email call failed');
				const unauthorized = checkUnauthorized(err.response?.status);
				if (unauthorized) return null;

				stopApiCall();
				onError(status);
			});
	};

	const onResendInvitationEmailSuccess = (
		onSuccess = () => {}
	) => {
		stopApiCall();
		onSuccess();
	};

	const patchGenerateOtp = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();

		const tempCountryCode = storeCountryCode ?? undefined;
		const tempMobileNumber = storeMobileNumber ? removeNonDigitsAndLeadingZeroesFrom(storeMobileNumber) : undefined;
		const data = {
			registrationToken: storeAccessToken,
			productType: getProductType(),
			mobileNumber: !isEmpty(tempCountryCode) && !isEmpty(tempMobileNumber) ?
				(
					'+' + tempCountryCode +
					' ' + removeNonDigitsAndLeadingZeroesFrom(tempMobileNumber)
				) : undefined
		};

		await axios
			.patch(getApiBaseURL() + '/generate-otp', data)
			.then(() => {
				onGenerateOtpSuccess(onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'PATCH /generate-otp call failed');
				const unauthorized = checkUnauthorized(err.response?.status);
				if (unauthorized) return null;

				stopApiCall();
				onError(status);
			});
	};

	const onGenerateOtpSuccess = (
		onSuccess = () => {}
	) => {
		stopApiCall();
		onSuccess();
	};

	const patchVerifyOtp = async (
		passcode,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();

		const data = {
			registrationToken: storeAccessToken,
			otp: passcode.trim(),
			productType: getProductType()
		};

		await axios
			.patch(getApiBaseURL() + '/verify-otp', data)
			.then((response) => {
				onVerifyOtpSuccess(
					response.data,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'PATCH /verify-otp call failed');
				const unauthorized = checkUnauthorized(err.response?.status);
				if (unauthorized) return null;

				stopApiCall();
				onError(status, err?.response?.data?.blockedUntil);
			});
	};

	const onVerifyOtpSuccess = (
		data,
		onSuccess = () => {}
	) => {
		if (data.redirectVcUrl) {
			continueValid();
			window.location.href = data.redirectVcUrl;
		} else {
			addUserDataToStore(data);

			stopApiCall();
			onSuccess(data);
		}
	};

	const postLogin = async (
		email,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();

		const data = {
			email: email.trim(),
			productType: getProductType(),
			sanc: !isEmpty(storeSanc) ? storeSanc : undefined,
			uid: !isEmpty(storeUid) ? storeUid : undefined
		};
		await axios
			.post(getApiBaseURL() + '/login', data)
			.then((response) => {
				onLoginSuccess(
					response,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST /login call failed');
				const unauthorized = checkUnauthorized(err.response?.status);
				if (unauthorized) return null;

				stopApiCall();
				onError(status);
			});
	};

	const onLoginSuccess = (
		response,
		onSuccess = () => {}
	) => {
		dispatch(updateToken(response.data));
		dispatch(updateSanc(response.data.sanc));
		dispatch(updateUid(response.data.uid));

		if (response.data.isRequestFromCaps) {
			dispatch(updateIsRequestFromCaps(response.data.isRequestFromCaps));
		}

		if (response.data.encryptedMobileNumber) {
			dispatch(setEncryptedMobileNumber(
				addSpaceAfterCountryCode(response.data.encryptedMobileNumber)
			));
		}

		if (response.status === 201) {
			dispatch(updateRelogin(true));
			if (response.data.mobilePhone) {
				let mobilePhoneArray = splitMobileNumberAndCountryCode(response.data.mobilePhone);
				dispatch(setCountryCode(mobilePhoneArray[0]));
				dispatch(setMobileNumber(mobilePhoneArray[1]));
			}
			goToPage(currentFlow + C.ROUTES.OTP);
		} else {
			goToPage(currentFlow + C.ROUTES.MOBILE_NUMBER);
		}

		stopApiCall();
		onSuccess();
	};

	const postVcVerifyData = async (
		customerNumber,
		accountNumber,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();

		const data = {
			fnumber: customerNumber.trim(),
			accountNumber: accountNumber.trim()
		};
		await axios
			.post(getApiBaseURL() + '/vc-verify-data', data)
			.then(() => {
				stopApiCall();
				onSuccess();
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST /vc-verify-data call failed');
				const unauthorized = checkUnauthorized(err.response?.status);
				if (unauthorized) return null;

				stopApiCall();
				onError(status);
			});
	};

	return {
		postRegistration,
		patchEmailConfirmation,
		patchResendInvitationEmail,
		patchGenerateOtp,
		patchVerifyOtp,
		postLogin,
		postVcVerifyData
	};

};
