/** Actions Type */
const UPDATE_FINANCIAL_STATUS = 'UPDATE_FINANCIAL_STATUS';
const UPDATE_INCOME = 'UPDATE_INCOME';
const UPDATE_ADDITIONAL_INCOME = 'UPDATE_ADDITIONAL_INCOME';
const UPDATE_INCOME_ERROR = 'UPDATE_INCOME_ERROR';
const UPDATE_FINANCIAL_STATUS_OPTION = 'UPDATE_FINANCIAL_STATUS_OPTION';

const SET_INCOME = 'SET_INCOME';
const SET_ADDITIONAL_INCOME = 'SET_ADDITIONAL_INCOME';
const SET_EXPENDITURE = 'SET_EXPENDITURE';
const SET_OTHER_EXPENSES = 'SET_OTHER_EXPENSES';
const SET_TYPE_OF_LIVING = 'SET_TYPE_OF_LIVING';

/** Actions Creators */
export const onUpdateFinancialStatus = payload => ({
	type: UPDATE_FINANCIAL_STATUS,
	payload
});

export const onUpdateIncome = payload => ({
	type: UPDATE_INCOME,
	payload
});

export const onUpdateAdditionalIncome = payload => ({
	type: UPDATE_ADDITIONAL_INCOME,
	payload
});

export const onUpdateIncomeError = payload => ({
	type: UPDATE_INCOME_ERROR,
	payload
});

export const onUpdateFinancialStatusOption = payload => ({
	type: UPDATE_FINANCIAL_STATUS_OPTION,
	payload
});

export const setIncome = payload => ({
	type: SET_INCOME,
	payload
});

export const setAdditionalIncome = payload => ({
	type: SET_ADDITIONAL_INCOME,
	payload
});

export const setExpenditure = payload => ({
	type: SET_EXPENDITURE,
	payload
});

export const setOtherExpenses = payload => ({
	type: SET_OTHER_EXPENSES,
	payload
});

export const setTypeOfLiving = payload => ({
	type: SET_TYPE_OF_LIVING,
	payload
});
