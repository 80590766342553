import React from 'react';

const Svg = () => {
	const width = 332;
	const height = 308;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<defs>
				<circle id='hand_holding_OK_svg__a' cx={154} cy={154} r={154}/>
			</defs>
			<g fill='none' fillRule='evenodd' transform='translate(-49 -60)'>
				<circle cx={227} cy={214} r={154} fill='#DEEDF2'/>
				<path fill='#FFF' d='M299 312h45v-49h-45z'/>
				<path fill='#FFF' d='M341 312h17v-57h-17z'/>
				<path
					fill='#C3DDE6'
					d='M299 274h37v-2h-37zm0 8h37v-2h-37zm36 29h5.472l.528-49z'
				/>
				<path
					fill='#FFF'
					d='M86.686 218.111v-.004h-9.745a8.34 8.34 0 00.505-2.84c0-4.566-3.66-8.267-8.173-8.267-3.842 0-7.056 2.685-7.93 6.298a5.816 5.816 0 00-1.429-.185c-2.927 0-5.347 2.172-5.788 5.008-2.852.136-5.126 2.513-5.126 5.433 0 3.008 2.41 5.446 5.384 5.446.422 0 .831-.055 1.225-.148h29.782c.394.093.804.148 1.225.148 2.973 0 5.384-2.438 5.384-5.446 0-2.984-2.373-5.404-5.314-5.443M162 221h-30.057L119 235.58V311h42.613zm-63 91h20v-48H99z'
				/>
				<path fill='#FFF' d='M161 311h28v-73h-28z'/>
				<path
					fill='#D1ECEF'
					d='M77.538 254.033s8.372-1.145 7.377 10.107c0 0-.598 2.861-1.195 6.295-.598 3.433-1.394 5.15.201 8.964 1.595 3.815 3.192 16.593-9.166 17.548-12.358.956-12.958-11.25-10.767-15.828 2.192-4.578 5.58-6.295 5.979-10.492.397-4.195-2.794-15.257 7.57-16.594'
				/>
				<path
					fill='#C3DDE6'
					d='M119 312v-48l-8 47.748zm-21-37h13v-2H98zm0 8h13v-2H98zm21-33h17v-2h-17zm0 10h17v-2h-17zm0 14h17v-2h-17z'
				/>
				<path fill='#D1ECEF' d='M49 317h321v-6H49z'/>
				<path fill='#C3DDE6' d='M73 311h1v-28h-1z'/>
				<path fill='#FFF' d='M214 312h51v-83h-51zm-40-1h40v-60h-40z'/>
				<path
					fill='#C3DDE6'
					d='M214 251v60h-11zm4-13h47v-2h-47zm0 7h47v-2h-47zm0 8h47v-2h-47z'
				/>
				<g transform='matrix(-1 0 0 1 381 60)'>
					<mask id='hand_holding_OK_svg__b' fill='#fff'>
						<use xlinkHref='#hand_holding_OK_svg__a'/>
					</mask>
					<use
						fill='#DEEDF2'
						fillOpacity={0}
						xlinkHref='#hand_holding_OK_svg__a'
					/>
					<path
						fill='#9E3667'
						d='M124.088 202L70 255.854 98.5 330l107.5-6.398z'
						mask='url(#hand_holding_OK_svg__b)'
					/>
				</g>
				<path
					fill='#F8BDAF'
					d='M279 243.394l7.975-38.223a7.35 7.35 0 01.084-.397l.075-.36.017.003a6.377 6.377 0 011.115-2.114l-.014-.012L316.835 168 327 175.932l-27.642 33.162L291.656 246 279 243.394z'
				/>
				<path
					fill='#E59C27'
					d='M242 127.5c0 37.28 30.22 67.5 67.5 67.5s67.5-30.22 67.5-67.5S346.78 60 309.5 60 242 90.22 242 127.5'
				/>
				<path
					fill='#92702B'
					d='M342 150l-52 41.877s8.853 3.793 19.052 3.019L342 150z'
				/>
				<path
					fill='#F8C7BC'
					d='M309.625 285l42.527-44.734L304.338 198 267 258.85z'
				/>
				<path
					fill='#F8C7BC'
					d='M339.145 230.924l22.207-29.092c2.663-3.462 2.03-8.49-1.406-11.172-3.437-2.682-8.428-2.045-11.092 1.416l-22.205 29.092c-2.664 3.462-2.032 8.49 1.404 11.172 3.438 2.682 8.428 2.045 11.092-1.416'
				/>
				<path
					fill='#F8C7BC'
					d='M352.815 239.398l15.869-24.017c2.436-3.678 1.393-8.662-2.32-11.076-3.71-2.415-8.742-1.38-11.179 2.297l-15.869 24.017c-2.436 3.678-1.393 8.662 2.32 11.076 3.712 2.415 8.742 1.381 11.179-2.297m-35.449-31.458l37.96-48.175c2.705-3.443 2.063-8.444-1.428-11.113-3.49-2.67-8.559-2.036-11.264 1.408l-37.96 48.175c-2.705 3.444-2.063 8.445 1.428 11.114 3.49 2.668 8.559 2.034 11.264-1.409'
				/>
				<path
					fill='#F8C7BC'
					d='M329.366 217.94l37.96-48.175c2.705-3.443 2.062-8.445-1.427-11.113-3.49-2.67-8.56-2.035-11.265 1.408l-37.96 48.175c-2.705 3.444-2.063 8.445 1.428 11.114 3.49 2.668 8.559 2.034 11.264-1.409'
				/>
				<path
					stroke='#FFF'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth={7.924}
					d='M285 130.054L300.964 146 335 112'
				/>
				<path fill='#F8C7BC' d='M267 258.85l-10 13.996L285.988 326l23.637-41z'/>
				<path fill='#F8BDAF' d='M286.141 326.395L257 273l33.407 45.25z'/>
			</g>
		</svg>
	);
};
export default Svg;
