import * as React from 'react';

const SvgProtectAccidentalDeath = () => {
	const width = 30;
	const height = 30;
	const style1 = { fill: '#9b3667' };
	const style2 = { fill: '#ffffff' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<rect style={style1} width="30" height="30" rx="4.62"/>
			<path style={style2} d="M16,7a.39.39,0,0,0,.55,0,.38.38,0,0,0,0-.55L15,4.92a.39.39,0,1,0-.53.56Z"/>
			<path style={style2} d="M20.45,7.21a.39.39,0,0,0,.27-.11l1.77-1.77a.39.39,0,1,0-.55-.55L20.17,6.54a.4.4,0,0,0,0,.56A.43.43,0,0,0,20.45,7.21Z"/>
			<path style={style2} d="M18.39,6.25a.39.39,0,0,0,.39-.39V3.8a.4.4,0,0,0-.39-.39A.39.39,0,0,0,18,3.8V5.86A.38.38,0,0,0,18.39,6.25Z"/>
			<path style={style2} d="M26.14,17c0-1.76-1-1.86-1.59-1.92l-.15,0a8.52,8.52,0,0,0-1.11,0c-.21-.59-.73-2-1.18-3.18a.36.36,0,0,0-.07-.17c-.12-.33-.25-.63-.35-.88-.41-1-.77-1.43-1.52-1.61V8.75a1.7,1.7,0,0,0-3.39,0v.6a2.34,2.34,0,0,0-.57.37,1.94,1.94,0,0,0-1.33-.64h-9a1.87,1.87,0,0,0-2,1.59v8.22a1.72,1.72,0,0,0,.49,1.29,1.65,1.65,0,0,0,1.12.42H7.4v.15a2.23,2.23,0,1,0,4.45,0,1.36,1.36,0,0,0,0-.29h7.3a2.64,2.64,0,0,0,0,.29,2.23,2.23,0,1,0,4.45,0c0-.07,0-.15,0-.22h.88a1.63,1.63,0,0,0,1.71-1.37V17.78h0ZM9.62,22.2a1.45,1.45,0,1,1,1.44-1.45A1.45,1.45,0,0,1,9.62,22.2Zm2-2.52a2.22,2.22,0,0,0-4,.15H5.49a.93.93,0,0,1-.61-.22,1,1,0,0,1-.24-.72V10.75a1.11,1.11,0,0,1,1.23-.89h9c.09,0,.72.08.86.55v9.27Zm10.91-4.62c-.77,0-2.15.06-2.91.06-.18,0-.73,0-.79-.59v-1.6a.64.64,0,0,1,.71-.64h2C21.83,13.28,22.24,14.42,22.48,15.06ZM17.56,8.75a.92.92,0,0,1,1.83,0v.33c-.4,0-.87,0-1.46,0a2.21,2.21,0,0,0-.37,0ZM21.33,22.2a1.45,1.45,0,1,1,0-2.89,1.47,1.47,0,0,1,1.33.87.28.28,0,0,0,.06.18,1.6,1.6,0,0,1,.05.39A1.45,1.45,0,0,1,21.33,22.2Zm4-4.8H23.83a.39.39,0,0,0-.39.39.4.4,0,0,0,.39.39h1.53v.9a.87.87,0,0,1-.94.67H23.31a2.24,2.24,0,0,0-2-1.22,2.2,2.2,0,0,0-1.94,1.15H16.49V10.56A1.8,1.8,0,0,1,18,9.86c2.47-.08,2.53.07,3,1.23l.17.42H19.49a1.59,1.59,0,0,0-1.11.43,1.35,1.35,0,0,0-.39,1v1.65a1.45,1.45,0,0,0,1.56,1.31c.87,0,2.52,0,3.19-.06v0l.28,0h0a5.86,5.86,0,0,1,1.24,0l.17,0c.53.05.88.08.88,1.14v.42Z"/>
			<path style={style2} d="M12.35,13.12h-1v-1a.5.5,0,0,0-.49-.52H9.67a.5.5,0,0,0-.49.52v1h-1a.51.51,0,0,0-.49.52v1a.5.5,0,0,0,.49.52h1v1a.5.5,0,0,0,.49.52h1.18a.5.5,0,0,0,.49-.52v-1h1a.5.5,0,0,0,.49-.52v-1A.51.51,0,0,0,12.35,13.12Zm0,1.43H10.69v1.6H9.83v-1.6H8.22v-.78H9.83V12.14h.86v1.63H12.3Z"/>
		</svg>
	);
};

export default SvgProtectAccidentalDeath;
