import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useNumberUtils } from 'utils/number/number';

import { Link } from 'components/Atoms/Text';
import SvgChevronDownLink from 'components/Atoms/SVG/Icons/SvgChevronDownLink';

import styles from './CreditDetails.module.scss';

const CreditDetails = props => {
	const { formatMoneyValue } = useNumberUtils();

	const [isCreditDetailsOpen, setIsCreditDetailsOpen] = useState(false);

	return (
		<>
			<div
				className={classNames([
					styles.toggleButton,
					isCreditDetailsOpen ? styles.toggleButtonActive : null
				])}
				onClick={() => {
					setIsCreditDetailsOpen(!isCreditDetailsOpen);
				}}
			>
				<Link
					onClick={() => {
						setIsCreditDetailsOpen(!isCreditDetailsOpen);
					}}
				>
					{m('creditDetails', 'fields')}
					<SvgChevronDownLink />
				</Link>
			</div>
			<div
				className={classNames([
					styles.upsellDownsellMoreDetailsWrapper,
					isCreditDetailsOpen
						? styles.upsellDownsellMoreDetailsWrapperActive
						: null
				])}
			>
				<div className={styles.detailsTable}>
					<div className={styles.detailsTableRow}>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellLabel
							])}
						>
							{m('runtime.label', 'fields')}:
						</div>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellValue
							])}
							data-testid='runtime'
						>
							{props.runtimeInMonths + ' Monate'}
						</div>
					</div>
					{!!props.effectiveAnnualInterestRate && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								{m('effectiveYearlyInterest.label', 'fields')}:
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='effectiveAnnualInterestRate'
							>
								{`${formatMoneyValue(props.effectiveAnnualInterestRate, true)} %`}
							</div>
						</div>
					)}
					{!!props.fixedBorrowingRate && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								{m('fixedBorrowingRate.label', 'fields')}:
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='fixed-borrowing-rate'
							>
								{`${formatMoneyValue(props.fixedBorrowingRate, true)} %`}
							</div>
						</div>
					)}
					{!!props.firstRateDate && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								{m('firstRate.date', 'fields')}:
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='first-rate-date'
							>
								{props.firstRateDate}
							</div>
						</div>
					)}
					{!!props.interest && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								{m('interest.label', 'fields')}:
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='interest'
							>
								{`${formatMoneyValue(props.interest, true)} €`}
							</div>
						</div>
					)}
					<div className={styles.detailsTableRow}>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellLabel
							])}
						>
							{m('paymentProtectionInsurance.labelLong', 'fields')}:
						</div>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellValue
							])}
							data-testid='payment-protection-insurance'
						>
							{!props.ppiAmount || props.ppiAmount === 0
								? m('none', 'fields')
								: `${formatMoneyValue(props.ppiAmount, true)} €`}
						</div>
					</div>

					<div className={styles.detailsTableRow}>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellLabel
							])}
						>
							{m('protect.label', 'fields')}:
						</div>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellValue
							])}
							data-testid='protect'
						>
							{!props.protectAmount || props.protectAmount === 0
								? 'kein'
								: `${formatMoneyValue(props.protectAmount, true)} €`}
						</div>
					</div>

					<div className={styles.detailsTableRow}>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellLabel
							])}
						>
							{m('totalAmount.label', 'fields')}:
						</div>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellValue
							])}
							data-testid='total-amount'
						>
							{`${formatMoneyValue(props.totalAmount, true)} €`}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

CreditDetails.propTypes = {
	runtimeInMonths: PropTypes.number,
	totalAmount: PropTypes.number,
	ppiAmount: PropTypes.number,
	protectAmount: PropTypes.number,
	interest: PropTypes.number,
	firstRateDate: PropTypes.string,
	effectiveAnnualInterestRate: PropTypes.number,
	fixedBorrowingRate: PropTypes.number
};

export default CreditDetails;
