import React, { useState, useEffect, useMemo } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useLegitimationApi } from 'api/legitimation';
import { useApplicationApi } from 'api/application';

import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { RadioBoxInput } from 'components/Atoms/Form';
import SvgAlert from 'components/Atoms/SVG/Icons/SvgAlert';

import { useDispatch, useSelector } from 'react-redux';
import { setGtmSubStatus } from 'store/Tracking/actions';

const AddressSelection = () => {
	const { getCurrentFlow, isStaticSubType, isCreditCardSubType, isCreditSubType } = useUtils();
	const { goToPage, preventHistoryBackFor } = usePageUtils();
	const { isEmpty, focusFirstErrorInput } = useValidationUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { getGetSelectedAddress, postSetSelectedAddress } = useLegitimationApi();
	const { postDepot } = useApplicationApi();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeVcJwtToken = useSelector((state) => state.app.vcJwtToken);
	const storeHasSecondApplicant = useSelector((state) => state.secondApplicant.hasSecondApplicant);
	const storeDacStatus = useSelector((state) => state.digitalAccountCheck.status);

	const [type, setType] = useState(null);

	const [typeError, setTypeError] = useState(null);

	const [webIdAddress, setWebIdAddress] = useState(null);
	const [originalAddress, setOriginalAddress] = useState(null);

	const typeItems = useMemo(() => {
		let webIdAddressTextExtension = '';
		if (webIdAddress) {
			webIdAddressTextExtension +=
				(webIdAddress.street ? webIdAddress.street : '') +
				(webIdAddress.streetNr ? ' ' + webIdAddress.streetNr : '') +
				'<br/>';
			webIdAddressTextExtension +=
				(webIdAddress.zip ? webIdAddress.zip : '') +
				(webIdAddress.city ? ' ' + webIdAddress.city : '') +
				'<br/>';
			webIdAddressTextExtension = webIdAddressTextExtension.trim();
		}

		let originalAddressTextExtension = '';
		if (originalAddress) {
			originalAddressTextExtension +=
				(originalAddress.street ? originalAddress.street : '') +
				(originalAddress.streetNr
					? ' ' + originalAddress.streetNr
					: '') +
				'<br/>';
			originalAddressTextExtension +=
				(originalAddress.zip ? originalAddress.zip : '') +
				(originalAddress.city ? ' ' + originalAddress.city : '') +
				'<br/>';
			originalAddressTextExtension = originalAddressTextExtension.trim();
		}

		return [
			{
				title: m('pages.addressSelection.items.webIdAddress.title', 'global'),
				description: m('addressSelection.items.webIdAddress.subtitle', currentFlow),
				value: 'WEB_ID',
				separatedDescription: <span dangerouslySetInnerHTML={{ __html: webIdAddressTextExtension }} />
			},
			{
				title: m('pages.addressSelection.items.originalAddress.title', 'global'),
				descriptionIconBefore: <SvgAlert color="#EC0000" />,
				description: m('addressSelection.items.originalAddress.subtitle', currentFlow),
				value: 'ORIGINAL',
				separatedDescription: <span dangerouslySetInnerHTML={{ __html: originalAddressTextExtension }} />
			}
		];
	}, [webIdAddress, originalAddress]);

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.WEB_ID_DONE);

		getGetSelectedAddress(
			(data) => {
				if (data.useWebIdAddress === undefined) {
					setType(null);
				} else if (data.useWebIdAddress) {
					setType('WEB_ID');
				} else {
					setType('ORIGINAL');
				}

				setWebIdAddress(data.webIdAddress);
				setOriginalAddress(data.originalAddress);

				redirectIfAdressesAreEqual(data.webIdAddress, data.originalAddress);
			},
			() => {}
		);
	}, []);

	const redirectIfAdressesAreEqual = (tempWebIdAddress, tempOriginalAddress) => {
		if (adressesAreEqual(tempWebIdAddress, tempOriginalAddress)) {
			if (currentFlow === C.FRONTEND.DEPOT) {
				goToPage(currentFlow + C.ROUTES.DEPOT_PURCHASE_SUCCESS);
			} else if (isStaticSubType(null, [C.FRONTEND.DEPOT])) {
				goToPage(currentFlow + C.ROUTES.ACCOUNT_SUCCESS);
			} else if (isCreditCardSubType()) {
				goToPage(currentFlow + C.ROUTES.ACTIVATE_CREDIT_CARD_ACCOUNT);
			} else if (storeDacStatus === C.DAC_STATUS.GREEN) {
				goToPage(currentFlow + C.ROUTES.ACTIVATE_LOAN);
			} else {
				goToPage(currentFlow + C.ROUTES.LOAN_DONE);
			}
		}
	};

	const adressesAreEqual = (tempWebIdAddress, tempOriginalAddress) => {
		return (
			!!tempWebIdAddress &&
			!!tempOriginalAddress &&
			tempWebIdAddress.street === tempOriginalAddress.street &&
			tempWebIdAddress.streetNr === tempOriginalAddress.streetNr &&
			tempWebIdAddress.zip === tempOriginalAddress.zip &&
			tempWebIdAddress.city === tempOriginalAddress.city &&
			tempWebIdAddress.country === tempOriginalAddress.country &&
			tempWebIdAddress.state === tempOriginalAddress.state
		);
	};

	const clearErrors = () => {};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(type)) {
			setTypeError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postSetSelectedAddress(type === 'WEB_ID', onSuccess, onError);
		} else {
			continueInvalid();
		}
	};

	const onSuccess = async () => {
		if (type === 'ORIGINAL') {
			continueValid();
			goToPage(currentFlow + C.ROUTES.ADDRESS_VERIFICATION);
		} else {
			if (isCreditCardSubType()) {
				continueValid();
				goToPage(currentFlow + C.ROUTES.ACTIVATE_CREDIT_CARD_ACCOUNT);
			} else if (currentFlow === C.FRONTEND.DEPOT) {
				await postDepot(
					storeVcJwtToken,
					() => {
						continueValid();
						goToPage(currentFlow + C.ROUTES.DEPOT_PURCHASE_SUCCESS);
					},
					(statusCode) => {
						continueInvalid();
						let nextPage = C.ROUTES.THANKYOU;
						if (statusCode === 400) {
							nextPage = C.ROUTES.CLEARING_ACCOUNT_ERROR;
						}
						goToPage(currentFlow + nextPage);
					}
				);
			} else if (isCreditSubType()) {
				continueValid();
				if (storeHasSecondApplicant) {
					goToPage(currentFlow + C.ROUTES.FINAL_STEPS_TWO_BORROWERS);
				} else {
					if (storeDacStatus === C.DAC_STATUS.GREEN) {
						goToPage(currentFlow + C.ROUTES.ACTIVATE_LOAN);
					} else {
						goToPage(currentFlow + C.ROUTES.LOAN_DONE);
					}
				}
			} else {
				continueValid();
				goToPage(currentFlow + C.ROUTES.ACCOUNT_SUCCESS);
			}
		}
	};

	const onError = (statusCode) => {
		dispatch(setGtmSubStatus(statusCode));

		// TODO: add depot, bestcards & credit

		let nextPage = C.ROUTES.THANKYOU;
		if (statusCode === 409) {
			if (currentFlow === C.FRONTEND.GIRO) {
				nextPage = C.ROUTES.GIRO_EXISTS;
			} else if (
				currentFlow === C.FRONTEND.GMK ||
				currentFlow === C.FRONTEND.SPARBRIEF
			) {
				nextPage = C.ROUTES.GMK_EXISTS;
			}
		} else if (statusCode === 512) {
			if (
				currentFlow === C.FRONTEND.GMK ||
				currentFlow === C.FRONTEND.SPARBRIEF
			) {
				nextPage = C.ROUTES.THANKYOU2;
			}
		}

		continueInvalid();
		goToPage(currentFlow + nextPage);
	};

	return (
		<>
			<Title>{m('pages.addressSelection.title', 'global')}</Title>
			<Text mb>{m('pages.addressSelection.subtitle', 'global')}</Text>

			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={2}
			>
				<RadioBoxInput
					value={type}
					setValue={setType}
					items={typeItems}
					hasEqualHeight
					hasError={!!typeError}
					message={typeError}
					itemStyle="SMALL"
					tabindex={1}
					testId="address"
				/>
			</Form>
		</>
	);
};
export default AddressSelection;
