const defaultState = {
	depotNumber: null,
	clearingAccountIban: null,
	bankCode: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_DEPOT_NUMBER':
			return { ...state, depotNumber: action.payload };
		case 'SET_CLEARING_ACCOUNT_IBAN':
			return { ...state, clearingAccountIban: action.payload };
		case 'SET_BANK_CODE':
			return { ...state, bankCode: action.payload };
		default:
			return state;
	}
};

export default reducers;
