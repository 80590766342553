import { SET_EXTERNAL_CREDITS } from './actions';

const defaultState = {
	externalCredits: [] // Items: { iban: string, remainingBalance: 'string' }
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case SET_EXTERNAL_CREDITS:
			return { ...state, externalCredits: action.payload };
		default:
			return state;
	}
};

export default reducers;
