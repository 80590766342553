import React, { useState, useEffect, useMemo } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useNumberUtils } from 'utils/number/number';

import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import NextSteps from 'components/Atoms/Partials/NextSteps/NextSteps';
import Modal from 'components/Atoms/Modal/Modal';
import Button from 'components/Atoms/Button/Button';
import DownloadContracts from 'components/Atoms/Partials/DownloadContracts/DownloadContracts';
import ApplicationApprovedCreditContent from './ApplicationApprovedCreditContent';
// import SvgChevronRightLink from 'components/Atoms/SVG/Icons/SvgChevronRightLink';
import SvgIllustrationInvestment from 'components/Atoms/SVG/Illustrations/SvgIllustrationInvestment';

import { useSelector } from 'react-redux';

import styles from './ApplicationApprovedCredit.module.scss';

const ApplicationApprovedCredit = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage, preventHistoryBackFor } = usePageUtils();
	const { continueValid } = useTrackingUtils();
	const { isEmpty } = useValidationUtils();
	const { formatMoneyValue } = useNumberUtils();

	const currentFlow = getCurrentFlow();

	const storeHasSecondApplicant = useSelector((state => state.secondApplicant.hasSecondApplicant));
	const storeDacStatus = useSelector((state => state.digitalAccountCheck.status));
	const storeDacTransaction = useSelector((state => state.digitalAccountCheck.transaction));

	const storeHasUpsell = useSelector((state => state.upsell.hasUpsell));
	const storeUpsellAlreadyDone = useSelector((state => state.upsell.upsellAlreadyDone));
	const storeUpsellCreditAmountNet = useSelector((state => state.upsell.creditAmountNet));
	const storeUpsellRateMonthlyAmount = useSelector((state => state.upsell.rateMonthlyAmount));

	const [isOpenPopup, setIsOpenPopup] = useState(false);

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
	}, []);

	useEffect(() => {
		if (storeHasUpsell && !storeUpsellAlreadyDone) {
			// TODO: Reactivate if upsell is ready
			/*setTimeout(() => {
				setIsOpenPopup(true);
			}, 1500);*/
		}
	}, [storeHasUpsell]);

	const onSubmit = () => {
		continueValid();
		if (!storeHasSecondApplicant && isEmpty(storeDacTransaction)) {
			goToPage(currentFlow + C.ROUTES.FINANCIAL_STATUS_DAC + '/reminder');
		} else if (!isEmpty(storeDacTransaction) && storeDacStatus !== C.DAC_STATUS.GREEN) {
			goToPage(currentFlow + C.ROUTES.UPLOAD_DOCUMENTS);
		} else {
			goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
		}
	};

	const handleUpsellPopupButtonClick = () => {
		goToPage(currentFlow + C.ROUTES.UPSELL);
	};

	const showDocumentUploadSubmitText = useMemo(() => {
		return (!storeHasSecondApplicant && isEmpty(storeDacTransaction))
			|| (!isEmpty(storeDacTransaction) && storeDacStatus !== C.DAC_STATUS.GREEN);
	}, [storeHasSecondApplicant, storeDacTransaction, storeDacStatus]);

	const getPopupContent = () => {
		return (
			<>
				<Title>
					<span className={styles.popupHeadline}>
						{m('pages.upsellDownsell.upsell.wantMore', 'global')}
					</span>
				</Title>
				<Text mb>
					{m('pages.upsellDownsell.upsell.biggerPlans', 'global')}
				</Text>
				<Text mb>
					{m('pages.upsellDownsell.upsell.howAboutThisOffer', 'global')}
				</Text>
				<div className={styles.popUpIcon}>
					<SvgIllustrationInvestment />
				</div>
				<div className={styles.divider} />
				<Row>
					<Col xs={6}>
						<span className={styles.popupAmountLabel}>
							{m('general.creditAmount', 'global')}
						</span>
					</Col>
					<Col xs={6} textAlign="right">
						<span className={styles.popupAmountValue}>
							{formatMoneyValue(storeUpsellCreditAmountNet ?? 0, true)} €
						</span>
					</Col>
				</Row>
				<Row>
					<Col xs={6}>
						<span className={styles.popupRateLabel}>
							{m('monthlyRate.label', 'fields')}
						</span>
					</Col>
					<Col xs={6} textAlign="right">
						<span className={styles.popupRateValue}>
							{formatMoneyValue(storeUpsellRateMonthlyAmount ?? 0, true)} €
						</span>
					</Col>
				</Row>
				<Row>
					<Col xs={12} textAlign="center">
						<div className={styles.popUpButton}>
							<Button onClick={handleUpsellPopupButtonClick}>
								{m('general.moreInfo', 'global')}
							</Button>
						</div>
					</Col>
				</Row>
			</>
		);
	};

	const getNextSteps = () => {
		const nextSteps = [];
		if (storeDacStatus !== C.DAC_STATUS.GREEN) {
			nextSteps.push(m('bcoResponseGreen.nextSteps.step1', currentFlow));
		}
		nextSteps.push(m('bcoResponseGreen.nextSteps.step2', currentFlow));
		// nextSteps.push(m('bcoResponseGreen.nextSteps.step3', currentFlow));
		return nextSteps;
	};

	return (
		<>
			<ApplicationApprovedCreditContent />
			{/*TODO: Reactivate if upsell is ready*/}
			{/*{storeHasUpsell && !storeUpsellAlreadyDone && (
				<Row>
					<Col xs={12}>
						<div className={styles.toUpsellDownsellLink}>
							<Link
								onClick={() =>
									goToPage(currentFlow + C.ROUTES.UPSELL)
								}
							>
								{m('pages.upsellDownsell.toUpsellDownsell', 'global')}
								<SvgChevronRightLink />
							</Link>
						</div>
					</Col>
				</Row>
			)}*/}
			<NextSteps
				title={m('bcoResponseGreen.nextSteps.title', currentFlow)}
				steps={getNextSteps()}
				hideUpload={storeDacStatus === C.DAC_STATUS.GREEN}
			/>
			<Form
				onSubmit={onSubmit}
				clearErrors={() => {}}
				submitTabindex={1}
				prevButtonExtension={<DownloadContracts />}
				submitText={m(`bcoResponseGreen.${showDocumentUploadSubmitText ? 'submitDocumentUpload' : 'submitIdentification'}`, currentFlow)}
			/>
			<Modal
				isOpen={isOpenPopup}
				onClose={() => setIsOpenPopup(false)}
				content={getPopupContent()}
				smallWidth={true}
			/>
		</>
	);
};
export default ApplicationApprovedCredit;
