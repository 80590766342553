import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="m22,18.11v-8.33s-.02-.07-.02-.07l-.02-.05-.02-.04-.04-.08h-.01s-.01-.03-.01-.03l-2.89-3.61v-3.41c0-.28-.22-.5-.5-.5H5.5c-.28,0-.5.22-.5.5v3.41l-2.89,3.61v.02s-.02.01-.02.01l-.02.04s-.04.07-.05.11c0,.02,0,.05-.01.07v.05s-.01,8.28-.01,8.28c0,.81.25,1.57.68,2.19.02.06.05.11.09.15.03.03.06.05.09.07.71.89,1.8,1.48,3.03,1.48h12.22c1.2,0,2.34-.57,3.07-1.53l.04-.03.04-.07c.48-.66.74-1.45.74-2.26h0Zm-3-10.6l1.77,2.21h0s-1.77,1.19-1.77,1.19v-3.4Zm-13,3.99V3h12v8.5s.01.05.02.07l-3.44,2.31-1.25-1.11c-.76-.67-1.9-.67-2.65,0l-1.25,1.11-3.44-2.31s.01-.04.01-.07Zm-1-3.99v3.4l-1.77-1.19,1.77-2.21Zm-1.72,11.82c-.18-.38-.28-.8-.28-1.22v-7.34l5.65,3.79-5.37,4.77Zm14.83,1.67H5.89c-.76,0-1.5-.3-2.04-.84l7.49-6.64c.38-.34.95-.34,1.32,0l7.48,6.64c-.54.54-1.27.84-2.04.84Zm2.89-2.89h0c0,.44-.1.85-.28,1.22l-5.37-4.76,5.65-3.8v7.34Z" />
			<path d="m11.3,11.07s.04,0,.06,0c.17-.02.32-.11.4-.24l4.12-5.59c.08-.12.11-.27.07-.42-.03-.14-.12-.27-.24-.35-.12-.08-.28-.11-.42-.07-.14.03-.26.11-.34.23l-3.72,5.01-1.93-1.99c-.21-.22-.56-.22-.78-.01-.22.21-.23.56-.01.78l2.4,2.47c.1.11.25.17.39.17Z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
