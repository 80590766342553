/** Actions Type */
const SET_OCCUPATION = 'SET_OCCUPATION';
const SET_OCCUPATION_MONTH = 'SET_OCCUPATION_MONTH';
const SET_OCCUPATION_YEAR = 'SET_OCCUPATION_YEAR';
const SET_OCCUPATION_LIMITED = 'SET_OCCUPATION_LIMITED';
const SET_OCCUPATION_LIMITED_MONTH = 'SET_OCCUPATION_LIMITED_MONTH';
const SET_OCCUPATION_LIMITED_YEAR = 'SET_OCCUPATION_LIMITED_YEAR';

/** Actions Creators */
export const setOccupation = payload => ({
	type: SET_OCCUPATION,
	payload
});

export const setOccupationMonth = payload => ({
	type: SET_OCCUPATION_MONTH,
	payload
});

export const setOccupationYear = payload => ({
	type: SET_OCCUPATION_YEAR,
	payload
});

export const setOccupationLimited = payload => ({
	type: SET_OCCUPATION_LIMITED,
	payload
});

export const setOccupationLimitedMonth = payload => ({
	type: SET_OCCUPATION_LIMITED_MONTH,
	payload
});

export const setOccupationLimitedYear = payload => ({
	type: SET_OCCUPATION_LIMITED_YEAR,
	payload
});
