/** Actions UPDATE_ADDRESS */
const SET_STREET = 'SET_STREET';
const SET_NUMBER = 'SET_NUMBER';
const SET_POSTCODE = 'SET_POSTCODE';
const SET_CITY = 'SET_CITY';
const SET_STATE = 'SET_STATE';
const SET_HAS_PREVIOUS_ADDRESS = 'SET_HAS_PREVIOUS_ADDRESS';
const SET_PREVIOUS_STREET = 'SET_PREVIOUS_STREET';
const SET_PREVIOUS_NUMBER = 'SET_PREVIOUS_NUMBER';
const SET_PREVIOUS_POSTCODE = 'SET_PREVIOUS_POSTCODE';
const SET_PREVIOUS_CITY = 'SET_PREVIOUS_CITY';

/** Actions Creators */
export const setStreet = payload => ({
	type: SET_STREET,
	payload
});

export const setNumber = payload => ({
	type: SET_NUMBER,
	payload
});

export const setPostcode = payload => ({
	type: SET_POSTCODE,
	payload
});

export const setCity = payload => ({
	type: SET_CITY,
	payload
});

export const setState = payload => ({
	type: SET_STATE,
	payload
});

export const setHasPreviousAddress = payload => ({
	type: SET_HAS_PREVIOUS_ADDRESS,
	payload
});

export const setPreviousStreet = payload => ({
	type: SET_PREVIOUS_STREET,
	payload
});

export const setPreviousNumber = payload => ({
	type: SET_PREVIOUS_NUMBER,
	payload
});

export const setPreviousPostcode = payload => ({
	type: SET_PREVIOUS_POSTCODE,
	payload
});

export const setPreviousCity = payload => ({
	type: SET_PREVIOUS_CITY,
	payload
});
