import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useFlowUtils } from 'utils/flow/flow';

import { Container } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Button from 'components/Atoms/Button/Button';
import Modal from 'components/Atoms/Modal/Modal';
import SvgLogo from 'components/Atoms/SVG/Logo/SvgLogo';
import SvgLogoIcon from 'components/Atoms/SVG/Logo/SvgLogoIcon';
import StickyHeaderMobile from 'components/Organisms/Header/StickyHeaderMobile/StickyHeaderMobile';
import MonthlyRate from 'components/Atoms/Partials/Values/MonthlyRate';
import NetAmount from 'components/Atoms/Partials/Values/NetAmount';
import Runtime from 'components/Atoms/Partials/Values/Runtime';
import SvgEdit from 'components/Atoms/SVG/Icons/SvgEdit';
import SvgSecureConnection from 'components/Atoms/SVG/Icons/SvgSecureConnection';

import { useDispatch, useSelector } from 'react-redux';
import { setSidebarActive } from 'store/App/actions';

import styles from './Header.module.scss';
import PartnerLogo from 'components/Organisms/Header/PartnerLogo/PartnerLogo';

const Header = props => {
	const { getCurrentFlow, isCreditSubType } = useUtils();
	const { isGmkHyundai } = useFlowUtils();

	const dispatch = useDispatch();
	const storeHideTitle = useSelector((state => state.app.hideTitle));
	const storeSalutation = useSelector((state => state.name.salutation));
	const storeFirstName = useSelector((state => state.name.firstName));
	const storeLastName = useSelector((state => state.name.lastName));

	const currentFlow = getCurrentFlow();

	const [logoPopUpIsOpen, setLogoPopUpIsOpen] = useState(false);

	const openSidebar = () => {
		dispatch(setSidebarActive(true));
		window.scrollTo(0, 0);
	};

	const title = useMemo(() => {
		if (isGmkHyundai)
			return m('hyundai.title', currentFlow);
		return m('title', currentFlow);
	}, [isGmkHyundai]);

	return (
		<>
			<div className={styles.headerBorder}>
				<Container>
					<div className={styles.headerWrapper}>
						<div
							className={classNames(styles.logo, props.hideFlowTitle || storeHideTitle ? styles.logoLoading : null)}
							onClick={() => { setLogoPopUpIsOpen(true); }}
						>
							<div className={styles.isDesktop}>
								<SvgLogo />
							</div>
							<div className={styles.isMobile}>
								<SvgLogoIcon />
							</div>
						</div>

						{!props.hideFlowTitle && !storeHideTitle && (
							<div className={styles.headerHeadline}>
								<h2 className={styles.headerTitle}>
									<strong>{title}</strong>
									<br />
									<>
										{storeSalutation && storeFirstName && storeLastName
											? m('salutation.for', 'fields', {
												salutation:
													storeSalutation === 'FEMALE'
														? m('salutation.items.female', 'fields')
														: m(
															'salutation.items.maleExt',
															'fields'
														),
												firstName: storeFirstName,
												lastName: storeLastName
											})
											: ''}
									</>
								</h2>
							</div>
						)}

						{isCreditSubType() && props.hasRightSidebar && (
							<div className={styles.headerRight}>
								<div
									className={styles.amountTrigger}
									onClick={openSidebar}
								>
									<div className={styles.amountTriggerText}>
										<div>
											<h6>{m('amount.label', 'fields')}</h6>
											<p><NetAmount isDecimal /> €</p>
										</div>
									</div>
									<div className={styles.amountTriggerText}>
										<div>
											<h6>{m('runtime.label', 'fields')}</h6>
											<p><Runtime showMonthLabel /></p>
										</div>
									</div>
									<div className={styles.amountTriggerText}>
										<div>
											<h6>{m('rate.label', 'fields')}</h6>
											<p><MonthlyRate isDecimal /> €</p>
										</div>
									</div>
									<SvgEdit />
								</div>
							</div>
						)}

						<PartnerLogo />
					</div>

					{isCreditSubType() && props.hasRightSidebar && (
						<StickyHeaderMobile>
							<div className={styles.mobileCalcRow}>
								<div className={styles.mobileCalcStickyContainer}>
									<div
										className={styles.amountTrigger}
										onClick={openSidebar}
									>
										<div className={styles.amountTriggerText}>
											<div className={styles.stickyInfoField}>
												<h6>{m('amount.label', 'fields')}</h6>
												<p><NetAmount isDecimal /> €</p>
											</div>
											<div className={styles.stickyInfoField}>
												<h6>{m('runtime.label', 'fields')}</h6>
												<p><Runtime showMonthLabel /></p>
											</div>
											<div className={styles.stickyInfoField}>
												<h6>{m('rate.label', 'fields')}</h6>
												<p><MonthlyRate isDecimal /> €</p>
											</div>
										</div>

										<SvgEdit />
									</div>
								</div>
							</div>
						</StickyHeaderMobile>
					)}
				</Container>
				<div className={styles.secureConnection}>
					<SvgSecureConnection />
				</div>
			</div>
			<Modal
				isOpen={logoPopUpIsOpen}
				onClose={() => {
					setLogoPopUpIsOpen(false);
				}}
				content={
					<div className="text-align-center">
						<Title size="s" mb>
							<strong>{m('logoExitPopup.title', 'global')}</strong>
						</Title>
						<Text>
							{m('logoExitPopup.description', 'global')}
						</Text>
						<div className="mt-30">
							<Button
								link="https://www.santander.de/privatkunden"
								fullWidthMobile
							>
								{m('logoExitPopup.button', 'global')}
							</Button>
						</div>
					</div>
				}
			/>
		</>
	);
};

Header.propTypes = {
	hasRightSidebar: PropTypes.bool,
	hideFlowTitle: PropTypes.bool
};

export default Header;
