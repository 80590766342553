import React from 'react';

const Svg = () => {
	const width = 270;
	const height = 253;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#D2F0F2'
					d='M99.892 242.375s-69.65-.022-84.1-73.117c0 0-18.215-90.708 85.532-100.787L125.61 67s15.064.608 24.512-21.496C172.556-6.983 231.519-11.203 255.735 19c0 0 17.828 19.132 12.914 48.394 0 0-2.727 23.908-33.344 40.233-46.5 24.794-28.78 39.364-45.496 85.139 0 0-14.952 47.211-89.917 49.61'
					opacity={0.624}
				/>
				<path fill='#FFF' d='M83.364 157.876H65.593l-7.652 8.65v44.75h25.195z'/>
				<path
					fill='#FFF'
					d='M45.802 211.443h12.144v-28.281H45.802zm37.169 0H99.36v-43.546H82.97z'
				/>
				<path
					fill='#D2F0F2'
					d='M20.958 177.28s4.968-.679 4.378 5.995c0 0-.355 1.697-.71 3.734-.354 2.036-.827 3.054.12 5.316.946 2.262 1.894 9.841-5.44 10.408-7.335.567-7.69-6.673-6.39-9.388 1.301-2.715 3.312-3.734 3.548-6.222.236-2.489-1.658-9.05 4.494-9.843'
				/>
				<path
					fill='#C3DEE7'
					d='M57.946 211.442v-28.28l-4.968 28.132zM45.82 189.896h7.748v-1.3H45.82zm0 4.752h7.748v-1.3H45.82zm12.164-20.036h9.878v-1.301h-9.878zm0 6.335h9.878v-1.3h-9.878zm0 7.921h9.878v-1.3h-9.878z'
				/>
				<path fill='#D2F0F2' d='M2.008 214.873h105.606v-3.597H2.008z'/>
				<path fill='#C3DEE7' d='M18.585 211.257h.572v-16.709h-.572z'/>
				<path fill='#FFF' d='M114.19 211.443h30.482v-49.15H114.19z'/>
				<path fill='#FFF' d='M90.504 211.443h23.863v-35.87H90.504z'/>
				<path
					fill='#C3DEE7'
					d='M114.367 175.574v35.868h-6.753zm2.157-7.871h28.179v-1.132h-28.18zm0 4.344h28.179v-1.132h-28.18zm0 4.345h28.179v-1.132h-28.18z'
				/>
				<path
					fill='#FFF'
					d='M14.34 152.788a7.17 7.17 0 11-14.34 0 7.17 7.17 0 0114.34 0'
				/>
				<path
					fill='#FFF'
					d='M24.229 153.586a6.373 6.373 0 11-12.747-.001 6.373 6.373 0 0112.747 0m10.047 2.543a3.83 3.83 0 11-7.66 0 3.83 3.83 0 017.66 0'
				/>
				<path
					fill='#FFF'
					d='M7.045 159.943h23.427v-7.71H7.045zm208.182 2.887a6.224 6.224 0 11-12.447 0 6.224 6.224 0 0112.447 0'
				/>
				<path
					fill='#FFF'
					d='M223.81 163.522a5.532 5.532 0 11-11.063 0 5.532 5.532 0 0111.063 0m8.721 2.207a3.325 3.325 0 11-6.65 0 3.325 3.325 0 016.65 0'
				/>
				<path fill='#FFF' d='M208.895 169.04h20.334v-6.692h-20.334z'/>
				<path
					fill='#FC3'
					d='M188.184 136.347l-5.442 5.442-5.443-5.442 5.443-5.443z'
				/>
				<path
					fill='#F9C8BC'
					d='M115.974 169.426l34.851 50.03 42.607-30.54-32.086-38.548zM65.441 70.108l-8.895 31.362s-.782 1.84 1.02 2.796c0 0 9.517 2.51 13.886-5.49l7.47-14.45-13.48-14.218z'
				/>
				<path
					fill='#F9C8BC'
					d='M67.226 90.676l-1.602 31.538s.013 2.386 1.718 2.953c0 0 6.203 1.764 12.595-8.713l2.217-29.383-14.928 3.605z'
				/>
				<path
					fill='#F9BEB0'
					d='M65.441 70.108l-2.743 9.67 6.92 8.696s-5.513 11.995-10.436 16.065c2.904.324 9.014.2 12.27-5.762l7.47-14.45-13.48-14.219z'
				/>
				<path
					fill='#F9BEB0'
					d='M79.937 116.454l2.16-33.246-14.828 16.438-.833 6.582 8.595-5.78-2.15 17.106c-1.08 3.539-3.067 6.069-4.785 7.716 1.838.1 6.778-.518 11.841-8.816m59.793 86.986l48.645-20.468-39.982 32.906z'
				/>
				<path
					fill='#FFF'
					d='M198.97 174.107l-57.1 43.176 10.31 12.237 61.857-35.65z'
				/>
				<path
					fill='#EBF4F7'
					d='M148.127 224.712l63.436-34.093 3.629 4.582-65.449 31.427z'
				/>
				<path
					fill='#9E3667'
					d='M219 187.904l-72.363 40.368 20.601 24.48s48.839 4.8 76.48-37.256L219 187.904z'
				/>
				<path
					fill='#C3DEE7'
					d='M203.677 189.059a4.575 4.575 0 11-8.926-2.01 4.575 4.575 0 018.926 2.01'
				/>
				<path
					fill='#F9C8BC'
					d='M78.492 65.133l9.278-3.908s3.497-2.212 8.726-.134l43.02 26.03s4.706 2.41 6.928 7.858l5.245 13.327-50.173-4.251-23.024-38.922z'
				/>
				<path
					fill='#F9BEB0'
					d='M116.122 102.39l-17.84-26.35L82.46 63.46l-1.483.618 23.227 35.827z'
				/>
				<path
					fill='#FFF'
					d='M139.198 83.766H32.122a2.354 2.354 0 01-2.354-2.354V14.85c0-1.3 1.054-2.353 2.354-2.353h107.076c1.3 0 2.354 1.053 2.354 2.353v66.562c0 1.3-1.054 2.354-2.354 2.354'
				/>
				<path
					fill='#DEEDF2'
					d='M35.121 61.93h27.551v-3.38h-27.55zm0 5.544h32.865v-3.379H35.121z'
				/>
				<path
					fill='#C3DEE7'
					d='M35.121 72.76h6.128v-1.913H35.12zm54.149 0h10.783v-1.913H89.271z'
				/>
				<path
					fill='#DEEDF2'
					d='M35.122 77.438h48.78v-3.38h-48.78zm54.148 0h9.374v-3.38h-9.373z'
				/>
				<path
					fill='#EC0000'
					fillOpacity={0.2}
					d='M127.241 69.287a7.283 7.283 0 11-14.566 0 7.283 7.283 0 0114.566 0'
				/>
				<path
					fill='#C3DEE7'
					d='M136.285 69.287a7.283 7.283 0 11-14.566 0 7.283 7.283 0 0114.566 0'
					opacity={0.4}
				/>
				<path
					fill='#C3DEE7'
					d='M129.663 28.11c-.055-.818-.273-1.636-.709-2.344l-3.706-6.486a5.56 5.56 0 01-.6-1.58l-.162.273a5.167 5.167 0 000 5.177l2.997 5.177a5.17 5.17 0 010 5.178l-.164.272a5.574 5.574 0 00-.6-1.58l-2.724-4.742-1.744-3.051a5.58 5.58 0 01-.6-1.58l-.163.271c-.927 1.58-.927 3.543 0 5.178l2.998 5.177a5.17 5.17 0 010 5.177l-.164.273a5.56 5.56 0 00-.6-1.58l-3.76-6.486c-.49-.871-.709-1.853-.709-2.833-3.978 1.035-6.758 3.27-6.758 5.83 0 3.598 5.342 6.486 11.936 6.486s11.935-2.888 11.935-6.485c.055-2.452-2.67-4.688-6.703-5.723'
				/>
				<path
					fill='#63818E'
					d='M75.383 58.696l34.633 25.07-17.011-.005z'
					opacity={0.4}
				/>
				<path
					fill='#F9C8BC'
					d='M115.974 169.426s-7.89-4.343-22.378-24.814c0 0-6.034-8.89-13.805-35.285 0 0-2.654-7.23-5.902-17.194l-9.982-17.56s-4.383-7.61 2.017-13.73l2.266-2.075s4.08-3.841 8.566.924l14.3 16.573S94.82 80.051 97 86.208l7.89 13.146 45.949 6.57 16.664 51.812-51.53 11.69z'
				/>
				<path
					fill='#1BB3BE'
					d='M102.73 235.775a6.269 6.269 0 11-12.538 0 6.269 6.269 0 0112.537 0'
				/>
				<path
					fill='#732645'
					d='M146.64 228.271l10.907-6.084 19.746 21.539c22.482-.475 42.448-9.535 61.522-21.508 0 0-22.365 32.262-71.558 30.51l-20.616-24.457z'
				/>
				<path fill='#C3DEE7' d='M2.774 173.126h4.687v-4.686H2.774z'/>
				<path
					fill='#FFF'
					d='M52.815 138.375L49.5 141.69l-3.313-3.315 3.313-3.314z'
				/>
			</g>
		</svg>
	);
};
export default Svg;
