/** Actions Type */
export const SET_ACCOUNT_NUMBER = 'SET_ACCOUNT_NUMBER';
export const SET_REFERENCE_IBAN = 'SET_REFERENCE_IBAN';
export const SET_EXISTING_OLD_IBAN = 'SET_EXISTING_OLD_IBAN';
export const SET_IS_SANTANDER_IBAN = 'SET_IS_SANTANDER_IBAN';
export const SET_REFERENCE_IBAN_SANTANDER = 'SET_REFERENCE_IBAN_SANTANDER';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';

/** Actions Creators */
export const setAccountNumber = payload => ({
	type: SET_ACCOUNT_NUMBER,
	payload
});

export const setReferenceIban = payload => ({
	type: SET_REFERENCE_IBAN,
	payload
});

export const setExistingOldIban = payload => ({
	type: SET_EXISTING_OLD_IBAN,
	payload
});

export const setIsSantanderIban = payload => ({
	type: SET_IS_SANTANDER_IBAN,
	payload
});

export const setReferenceIbanSantander = payload => ({
	type: SET_REFERENCE_IBAN_SANTANDER,
	payload
});

export const setPaymentType = payload => ({
	type: SET_PAYMENT_TYPE,
	payload
});
