import React from 'react';

const Svg = () => {
	const width = 280;
	const height = 200;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' 180'}>
			<defs>
				<path id='icon_purchase_credit_svg__a' d='M0 .06h191.757v70.477H0z' />
				<path
					id='icon_purchase_credit_svg__c'
					d='M.008.388h109.925v109.926H.008z'
				/>
				<path id='icon_purchase_credit_svg__e' d='M.1.074h48.242v61.571H.1z' />
				<path id='icon_purchase_credit_svg__g' d='M.1.074h48.242v61.571H.1z' />
				<path id='icon_purchase_credit_svg__i' d='M.101.878h48.24v10.824H.102z' />
			</defs>
			<g fill='none' fillRule='evenodd'>
				<g transform='translate(49 111.868)'>
					<mask id='icon_purchase_credit_svg__b' fill='#fff'>
						<use xlinkHref='#icon_purchase_credit_svg__a' />
					</mask>
					<path
						fill='#F8C7BC'
						d='M83.177 32.442c-3.564-.004-7.626-.33-10.412-2.551l-17.12-10.198L18.16 2.705a15.93 15.93 0 00-14.95.98 6.647 6.647 0 00-.034 11.36L86.305 65.58a29.495 29.495 0 0022.897 4.227l59.446-13.496 10.482 3.345c3.206.751 4.937-1.796 7.783-1.796 2.844 0 4.845 4.181 4.845 1.335V8.588a5.154 5.154 0 00-5.153-5.153l-10.046 2.346c-2.846 0-7.91-.038-7.91 2.807v1.466s-5.572-3.87-9.287-5.733c-3.716-1.863-32.08-8.005-51.358-.964l-42.2 16.405a8.678 8.678 0 00-2.331 14.58 10.576 10.576 0 007.507 1.918l52.268-8.518.315.23-40.385 4.47z'
						mask='url(#icon_purchase_credit_svg__b)'
					/>
				</g>
				<g transform='translate(71 -3.082)'>
					<mask id='icon_purchase_credit_svg__d' fill='#fff'>
						<use xlinkHref='#icon_purchase_credit_svg__c' />
					</mask>
					<path
						fill='#FC3'
						d='M.008 55.35c0 30.356 24.607 54.964 54.963 54.964 30.354 0 54.962-24.608 54.962-54.963S85.325.388 54.97.388.008 24.996.008 55.35'
						mask='url(#icon_purchase_credit_svg__d)'
					/>
				</g>
				<path
					fill='#F7BDAF'
					d='M172.248 139.61l-35.853 9.93s-27.27 6.503-25.938-9.235c1.33-15.737 41.535-10.301 41.535-10.301l20.256 9.606z'
				/>
				<path
					fill='#F8C7BC'
					d='M217.647 121.922s-5.572-3.87-9.286-5.733c-3.716-1.863-32.08-8.005-51.358-.963l-42.2 16.404a8.677 8.677 0 00-2.33 14.58 10.576 10.576 0 007.506 1.918l52.268-8.518'
				/>
				<path
					fill='#F7BDAF'
					d='M221.21 143.728s-6.927 15.533-15.675 19.908c-8.749 4.374-36.089 10.571-47.39 12.758-11.3 2.188-17.862-2.187-33.173-12.03-15.31-9.842-75.69-42.285-75.69-42.285l-.113.608a6.624 6.624 0 003.009 4.226l83.125 50.534a29.498 29.498 0 0022.899 4.227l59.445-13.497 4.792 1.531-1.229-25.98z'
				/>
				<path
					fill='#FEFEFE'
					d='M214.648 114.946l4.376 57.328h48.24l-10.777-57.328z'
				/>
				<path
					fill='#F3C032'
					d='M84.067 52.27c0 23.142 18.76 41.902 41.904 41.902 23.141 0 41.903-18.76 41.903-41.903 0-23.143-18.762-41.903-41.903-41.903-23.143 0-41.904 18.76-41.904 41.903'
				/>
				<path
					fill='#92702A'
					d='M143.293 66.38c-5.1 5.708-13.614 8.275-21.17 6.399-5.622-1.4-9.88-5.051-12.03-10.203h23.488a2.283 2.283 0 000-4.566h-26.633c-.701 0-1.364.323-1.797.874a2.294 2.294 0 00-.421 1.956c2.05 8.335 7.989 14.302 16.289 16.37 2.065.514 4.166.764 6.257.764 7.33 0 14.524-3.069 19.42-8.55a2.283 2.283 0 00-3.403-3.043m-36.345-19.023h26.633a2.284 2.284 0 000-4.567h-23.489c2.15-5.152 6.409-8.804 12.03-10.203 7.557-1.885 16.07.69 21.171 6.398a2.277 2.277 0 003.221.18 2.282 2.282 0 00.182-3.222c-6.298-7.047-16.384-10.099-25.677-7.787-8.3 2.068-14.238 8.035-16.289 16.372-.167.68-.01 1.401.42 1.954a2.289 2.289 0 001.798.875'
					opacity={0.456}
				/>
				<path
					fill='#FEFEFE'
					d='M143.293 65.966c-5.1 5.707-13.614 8.273-21.17 6.398-5.622-1.4-9.88-5.052-12.03-10.203h23.488a2.283 2.283 0 000-4.566h-26.633c-.701 0-1.364.323-1.797.874a2.294 2.294 0 00-.421 1.956c2.05 8.335 7.989 14.302 16.289 16.37 2.065.514 4.166.764 6.257.764 7.33 0 14.524-3.069 19.42-8.55a2.283 2.283 0 00-3.403-3.043m-36.345-19.023h26.633a2.284 2.284 0 000-4.567h-23.489c2.15-5.152 6.409-8.804 12.03-10.203 7.557-1.885 16.07.69 21.171 6.398a2.277 2.277 0 003.221.18 2.282 2.282 0 00.182-3.222c-6.298-7.048-16.384-10.099-25.677-7.787-8.3 2.068-14.238 8.035-16.289 16.371-.167.68-.01 1.402.42 1.955a2.289 2.289 0 001.798.875'
				/>
				<path
					fill='#92702A'
					d='M125.97 13.262c-22.656 0-41.1 17.985-41.866 40.455-.016-.48-.037-.962-.037-1.448 0-23.142 18.76-41.903 41.904-41.903 23.141 0 41.903 18.76 41.903 41.903 0 .486-.02.967-.037 1.448-.765-22.47-19.21-40.455-41.866-40.455'
					opacity={0.199}
				/>
				<path
					fill='#EBF3F6'
					d='M229.291 114.946l-.546 57.328h48.241l-10.777-57.328zm-4.922 8.306a3.16 3.16 0 11-6.319-.001 3.16 3.16 0 016.319.001'
				/>
				<path
					fill='#C3DDE6'
					d='M224.369 122.079a3.16 3.16 0 11-6.319-.002 3.16 3.16 0 016.319.002'
				/>
				<g transform='translate(230.363 112.868)'>
					<mask id='icon_purchase_credit_svg__f' fill='#fff'>
						<use xlinkHref='#icon_purchase_credit_svg__e' />
					</mask>
					<path
						fill='#9E3667'
						d='M1.101.074l-1 61.571h48.24L37.565.074z'
						mask='url(#icon_purchase_credit_svg__f)'
					/>
				</g>
				<g transform='translate(230.363 112.868)'>
					<mask id='icon_purchase_credit_svg__h' fill='#fff'>
						<use xlinkHref='#icon_purchase_credit_svg__g' />
					</mask>
					<path
						fill='#9E3667'
						d='M1.101.074l-1 61.571h48.24L37.565.074z'
						mask='url(#icon_purchase_credit_svg__h)'
					/>
				</g>
				<g transform='translate(230.363 162.812)'>
					<mask id='icon_purchase_credit_svg__j' fill='#fff'>
						<use xlinkHref='#icon_purchase_credit_svg__i' />
					</mask>
					<path
						fill='#732645'
						d='M.101 11.702h48.241L46.447.878.237 3.31z'
						mask='url(#icon_purchase_credit_svg__j)'
					/>
				</g>
			</g>
		</svg>
	);
};
export default Svg;
