export const C = {
	ON: 'on',
	OFF: 'off',
	NAME: 'name',
	HONORIFIC_PREFIX: 'honorific-prefix',
	HONORIFIC_SUFFIX: 'honorific-suffix',
	GIVEN_NAME: 'given-name',
	ADDITIONAL_NAME: 'additional-name',
	FAMILY_NAME: 'family-name',
	NICKNAME: 'nickname',
	EMAIL: 'email',
	USERNAME: 'username',
	NEW_PASSWORD: 'new-password',
	CURRENT_PASSWORD: 'current-password',
	ONE_TIME_CODE: 'one-time-code',
	ORGANIZATION_TITLE: 'organization-title',
	ORGANIZATION: 'organization',
	STREET_ADDRESS: 'street-address',
	ADDRESS_LINE_1: 'address-line1',
	ADDRESS_LINE_2: 'address-line2',
	ADDRESS_LINE_3: 'address-line3',
	ADDRESS_LEVEL_4: 'address-level4',
	ADDRESS_LEVEL_3: 'address-level3',
	ADDRESS_LEVEL_2: 'address-level2',
	ADDRESS_LEVEL_1: 'address-level1',
	COUNTRY: 'country',
	COUNTRY_NAME: 'country-name',
	POSTAL_CODE: 'postal-code',
	CC_NAME: 'cc-name',
	CC_GIVEN_NAME: 'cc-given-name',
	CC_ADDITIONAL_NAME: 'cc-additional-name',
	CC_FAMILY_NAME: 'cc-family-name',
	CC_NUMBER: 'cc-number',
	CC_EXP: 'cc-exp',
	CC_EXP_MONTH: 'cc-exp-month',
	CC_EXP_YEAR: 'cc-exp-year',
	CC_CSC: 'cc-csc',
	CC_TYPE: 'cc-type',
	TRANSACTION_CURRENCY: 'transaction-currency',
	TRANSACTION_AMOUNT: 'transaction-amount',
	LANGUAGE: 'language',
	BDAY: 'bday',
	BDAY_DAY: 'bday-day',
	BDAY_MONTH: 'bday-month',
	BDAY_YEAR: 'bday-year',
	SEX: 'SEX',
	TEL: 'tel',
	TEL_COUNTRY_CODE: 'tel-country-code',
	TEL_NATIONAL: 'tel-national',
	TEL_AREA_CODE: 'tel-area-code',
	TEL_LOCAL: 'tel-local',
	TEL_EXTENSION: 'tel-extension',
	Impp: 'impp',
	URL: 'url',
	PHOTO: 'photo'
};
