import C from 'constants/Constants';

import giro from 'routes/routes/giro';
import gmk from 'routes/routes/gmk';
import sparbrief from 'routes/routes/sparbrief';
import depot from 'routes/routes/depot';
import bestCard from 'routes/routes/bestCard';
import visacard from 'routes/routes/visacard';
import credit from 'routes/routes/credit';
import shortCredit from 'routes/routes/shortCredit';

export const rootRoutes = [
	...giro(C.FRONTEND.GIRO, 'BestGiro'),
	...gmk(C.FRONTEND.GMK, 'GMK'),
	...sparbrief(C.FRONTEND.SPARBRIEF, 'Sparbrief'),
	...depot(C.FRONTEND.DEPOT, 'Depot'),
	...bestCard(C.FRONTEND.BEST_CARD_BASIC, 'BestCard Basic'),
	...bestCard(C.FRONTEND.BEST_CARD_SMART, 'BestCard Smart'),
	...bestCard(C.FRONTEND.BEST_CARD_EXTRA, 'BestCard Extra'),
	...bestCard(C.FRONTEND.BEST_CARD_PREMIUM, 'BestCard Premium'),
	...visacard(C.FRONTEND.VISACARD, 'VisaCard'),
	...credit(C.FRONTEND.CREDIT, 'BestCredit'),
	...shortCredit(C.FRONTEND.SHORT_CREDIT, 'BestCredit_Short')
];

export default rootRoutes;
