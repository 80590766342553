import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SvgCheckBold from 'components/Atoms/SVG/Icons/SvgCheckBold';

import styles from './Check.module.scss';

const Check = (props) => {
	return (
		<span className={classNames(styles.wrapper, props.active ? styles.active : null)}>
			<SvgCheckBold color="#ffffff" />
		</span>
	);
};

Check.propTypes = {
	active: PropTypes.bool
};

export default Check;
