import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useNumberUtils } from 'utils/number/number';

import { Row, Col } from 'components/Atoms/Grid';
import { Link } from 'components/Atoms/Text';
import SvgChevronDownLink from 'components/Atoms/SVG/Icons/SvgChevronDownLink';

import { useSelector } from 'react-redux';

import styles from './ApplicationApprovedCreditDetails.module.scss';

const ApplicationApprovedCreditDetails = () => {
	const { formatMoneyValue } = useNumberUtils();

	const storeBcoResults = useSelector((state => state.app.bcoResults));
	const storeFirstRateDate = useSelector((state => state.app.firstRateDate));

	const [detailsAreOpen, setDetailsAreOpen] = useState(false);

	const getEffectiveYearlyInterest = () => {
		let yearlyInterest = 0;
		if (
			storeBcoResults.effectiveAnnualInterestRate ||
			storeBcoResults.effectiveAnnualInterestRate === 0
		) {
			yearlyInterest = storeBcoResults.effectiveAnnualInterestRate;
		}
		return formatMoneyValue(yearlyInterest, true) + ' %';
	};

	const getFixedBorrowingRate = () => {
		let fixedBorrowingRate = 0;
		if (
			storeBcoResults.fixedBorrowingRate ||
			storeBcoResults.fixedBorrowingRate === 0
		) {
			fixedBorrowingRate = storeBcoResults.fixedBorrowingRate;
		}
		return formatMoneyValue(fixedBorrowingRate, true) + ' %';
	};

	const getFirstRateDate = () =>
		storeFirstRateDate
			? moment(storeFirstRateDate, 'DD.MM.YYYY').format('DD.MM.YYYY')
			: null;

	const getInterest = () => {
		let interest = 0;
		if (
			storeBcoResults.interest ||
			storeBcoResults.interest === 0
		) {
			interest = storeBcoResults.interest;
		}
		return `${formatMoneyValue(interest, true)} €`;
	};

	const getPpiAmount = () => {
		let ppiAmount = 0;
		if (storeBcoResults.ppiValue) {
			ppiAmount = storeBcoResults.ppiValue;
		}
		if (ppiAmount === 0) {
			return 'Keine';
		} else {
			return `${formatMoneyValue(ppiAmount, true)} €`;
		}
	};

	const getProtectAmount = () => {
		let protectAmount = 0;
			if (
				storeBcoResults.protect &&
				(storeBcoResults.protectValue ||
					storeBcoResults.protectValue === 0)
			) {
				protectAmount = storeBcoResults.protectValue;
			}
		if (protectAmount === 0) {
			return 'Kein';
		} else {
			return `${formatMoneyValue(protectAmount, true)} €`;
		}
	};

	const getNetAmount = () => {
		let netAmount = 0;
		if (
			storeBcoResults.creditAmountNet ||
			storeBcoResults.creditAmountNet === 0
		) {
			netAmount = storeBcoResults.creditAmountNet;
		}
		return `${formatMoneyValue(netAmount, true)} €`;
	};

	const getTotalAmount = () => {
		let totalAmount = 0;
		if (
			storeBcoResults.creditAmountTotal ||
			storeBcoResults.creditAmountTotal === 0
		) {
			totalAmount = storeBcoResults.creditAmountTotal;
		}
		return `${formatMoneyValue(totalAmount, true)} €`;
	};

	return (
		<>
			<div
				className={classNames([
					styles.moreDetailsLink,
					detailsAreOpen ? styles.moreDetailsLinkActive : null
				])}
			>
				<Link
					onClick={() => {
						setDetailsAreOpen(!detailsAreOpen);
					}}
				>
					{detailsAreOpen
						? m('lessCreditDetails', 'fields')
						: m('moreCreditDetails', 'fields')}
					<SvgChevronDownLink />
				</Link>
			</div>
			<div
				className={classNames([
					styles.moreDetailsWrapper,
					detailsAreOpen ? styles.moreDetailsWrapperActive : null
				])}
			>
				<Row>
					<Col xs={12} md={6}>
						<div className={styles.table}>
							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									{m('firstRate.date', 'fields')}:
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'first-rate-date'}
								>
									{getFirstRateDate()}
								</div>
							</div>
							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									{m('netAmount.label', 'fields')}:
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'net-amount'}
								>
									{getNetAmount()}
								</div>
							</div>
							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									{m('paymentProtectionInsurance.labelLong', 'fields')}:
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'rsv-amount'}
								>
									{getPpiAmount()}
								</div>
							</div>
							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									{m('protect.label', 'fields')}:
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'protect-amount'}
								>
									{getProtectAmount()}
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} md={6}>
						<div className={styles.table}>
							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									{m('effectiveYearlyInterest.label', 'fields')}:
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'effective-yearly-interest'}
								>
									{getEffectiveYearlyInterest()}
								</div>
							</div>
							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									{m('fixedBorrowingRate.label', 'fields')}:
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'fixed-borrowing-rate'}
								>
									{getFixedBorrowingRate()}
								</div>
							</div>
							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									{m('interest.label', 'fields')}:
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'interest'}
								>
									{getInterest()}
								</div>
							</div>
							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									{m('totalAmount.label', 'fields')}:
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'total-amount'}
								>
									{getTotalAmount()}
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default ApplicationApprovedCreditDetails;
