import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import C from 'constants/Constants';

import { rootRoutes } from 'routes/rootRoutes';

import RouteInit from 'components/Routes/RouteInit';

import Version from 'components/Pages/Version/Version';
import Error404 from 'components/Pages/Error/404/Error404';
import Layout from 'components/Molecules/Layout/Layout';

const Routes = () => {
	const location = useLocation();
	const routeComponents = rootRoutes.map(
		(
			{
				component,
				flow,
				subpage,
				stepCategory,
				gtmType,
				gtmSubType,
				gtmStep,
				gtmStatus,
				gtmSubStatus,
				gtmIdentificationDocument,
				showDurationHint,
				hasExitWindowPopup,
				hasRightSidebar,
				creditCalculatorBlocked,
				footerExtension,
				layoutWide,
				showAppointmentButton,
				hideAppointmentLink,
				background,
				needsRegistrationToken,
				needsVcJwtToken,
				hideFlowTitle
			},
			key
		) => (
			<Route exact path={flow + subpage} key={key}>
				<RouteInit
					flow={flow}
					subpage={subpage}
					stepCategory={stepCategory}
					gtmType={gtmType}
					gtmSubType={gtmSubType}
					gtmStep={gtmStep}
					gtmStatus={gtmStatus}
					gtmSubStatus={gtmSubStatus}
					gtmIdentificationDocument={gtmIdentificationDocument}
					needsRegistrationToken={needsRegistrationToken}
					needsVcJwtToken={needsVcJwtToken}
				>
					<Layout
						showDurationHint={showDurationHint}
						hasExitWindowPopup={hasExitWindowPopup}
						hasRightSidebar={hasRightSidebar}
						creditCalculatorBlocked={creditCalculatorBlocked}
						footerExtension={
							footerExtension
								? React.createElement(footerExtension)
								: undefined
						}
						layoutWide={layoutWide}
						showAppointmentButton={showAppointmentButton}
						hideAppointmentLink={hideAppointmentLink}
						background={background}
						hideFlowTitle={hideFlowTitle}
					>
						{React.createElement(component, { location })}
					</Layout>
				</RouteInit>
			</Route>
		)
	);

	return (
		<Switch>
			<Route exact path='/' render={() => <Redirect to={C.FRONTEND.GIRO} />} />
			<Route path='/version'>
				<Version />
			</Route>
			{routeComponents}
			<Route component={Error404} status={404} />
		</Switch>
	);
};
export default Routes;
