import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 12;
	const height = 12;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="m4.55,0c.17,0,.3.13.3.3,0,.15-.1.27-.25.3h-.05s-2.12,0-2.12,0c-.94,0-1.72.74-1.78,1.68v.1s0,7.23,0,7.23c0,.95.74,1.73,1.68,1.78h.1s7.23,0,7.23,0c.95,0,1.73-.74,1.78-1.68v-.1s0-2.12,0-2.12c0-.17.13-.3.3-.3.15,0,.27.1.3.25v.05s0,2.12,0,2.12c0,1.27-1,2.32-2.26,2.38h-.12s-7.23,0-7.23,0c-1.27,0-2.32-1-2.38-2.26v-.12s0-7.23,0-7.23C.05,1.12,1.05.07,2.32,0h.12s2.12,0,2.12,0ZM11.72,0c.09-.01.18.02.25.08.05.05.08.12.08.19v.05s0,2.97,0,2.97c0,.17-.13.3-.3.3-.15,0-.27-.1-.3-.25v-.05s0-2.28,0-2.28l-5.18,5.18c-.12.12-.31.12-.42,0-.1-.1-.12-.26-.03-.38l.03-.04L11.03.6h-2.28c-.17,0-.3-.13-.31-.29,0-.15.1-.28.25-.3h.05s2.97,0,2.97,0h0Z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
