import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 12;
	const height = 12;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="m8.62.42c.52-.52,1.37-.56,1.93-.07l.08.07.96.96c.53.53.55,1.37.07,1.93l-.07.08L3.05,11.91s-.1.07-.16.08h-.05s-2.54,0-2.54,0c-.14,0-.27-.1-.3-.25v-.05s0-2.54,0-2.54c0-.06.02-.12.06-.18l.03-.04L8.62.42Zm-1.54,2.39L.6,9.28v2.12h2.12l6.48-6.48s-2.12-2.12-2.12-2.12Zm3.12-1.96c-.3-.29-.76-.32-1.09-.06l-.07.06-1.54,1.54,2.12,2.12,1.54-1.54c.3-.3.32-.77.06-1.09l-.06-.07-.96-.96h0Z"/>
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
