import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M12.002 4.5a1 1 0 0 1 .993.883l.007.117L13 11l5.5.002a1 1 0 0 1 .993.883l.007.117a1 1 0 0 1-.884.993l-.116.007L12.999 13v5.5a1 1 0 0 1-.884.993l-.117.007a1 1 0 0 1-.993-.884l-.007-.117L11 13h-5.5a1 1 0 0 1-.992-.884l-.007-.117a1 1 0 0 1 .884-.993l.116-.007H11l.002-5.499a1 1 0 0 1 .883-.993l.117-.006z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
