import C from 'constants/Constants';

import Login from 'components/Pages/Registration/Login/Login';
import Verification from 'components/Pages/Flows/Visacard/Verification/Verification';
import Name from 'components/Pages/Registration/Name/Name';
import Birthdate from 'components/Pages/Registration/Birthdate/Birthdate';
import Email from 'components/Pages/Registration/Email/Email';
import EmailSent from 'components/Pages/Registration/EmailSent/EmailSent';
import EmailConfirmedOld from 'components/Pages/Registration/EmailConfirmed/EmailConfirmed';
import EmailNotConfirmed from 'components/Pages/Registration/EmailNotConfirmed/EmailNotConfirmed';
import MobileNumber from 'components/Pages/Registration/MobileNumber/MobileNumber';
import Otp from 'components/Pages/Registration/Otp/Otp';
import Nationality from 'components/Pages/ApplicationData/Nationality/Nationality';
import Address from 'components/Pages/ApplicationData/Address/Address';
import PreviousAddress from 'components/Pages/ApplicationData/PreviousAddress/PreviousAddress';
import BankAccount from 'components/Pages/ApplicationData/BankAccount/BankAccount';
import PPI from 'components/Pages/Insurance/Insurance';
import TermsAndConditions from 'components/Pages/Application/TermsAndConditions/TermsAndConditions';
import ApplicationApprovedCreditCard from 'components/Pages/Application/Approved/CreditCard/ApplicationApprovedCreditCard';
import ThankYou from 'components/Pages/Application/ThankYou/ThankYou';
import Identification from 'components/Pages/Legitimation/Identification/Identification';
import PostIdent from 'components/Pages/Legitimation/PostIdent/PostIdent';
import DigitalPostident from 'components/Pages/Legitimation/DigitalPostident/DigitalPostident';
import DigitalPostidentConfirmation from 'components/Pages/Legitimation/DigitalPostidentConfirmation/DigitalPostidentConfirmation';
import WebIdDone from 'components/Pages/Legitimation/WebIdDone/WebIdDone';
import AddressSelection from 'components/Pages/Legitimation/AddressSelection/AddressSelection';
import AddressVerification from 'components/Pages/Legitimation/AddressVerification/AddressVerification';
import AddressVerificationPostponed from 'components/Pages/Legitimation/AddressVerificationPostponed/AddressVerificationPostponed';
import ActivateCreditCardAccount from 'components/Pages/Done/Activate/CreditCard/ActivateCreditCard';
import CreditCardSuccess from 'components/Pages/Done/Success/CreditCard/CreditCardSuccess';
import ThankYouRejected from 'components/Pages/Flows/Visacard/ThankYou/ThankYouRejected';
import UploadDocuments from 'components/Pages/Documents/UploadDocuments/UploadDocuments';

export const routes = (flow, gtmType) => {
	let routes = [];
	routes = routes.concat([
		{
			subpage: C.ROUTES.LOGIN,
			component: Login,
			gtmStep: 'login',
			gtmStatus: 'login_pending'
		},
		{
			subpage: C.ROUTES.INDEX,
			component: Verification,
			stepCategory: 1,
			gtmStep: 'userdata_verification',
			gtmStatus: 'registration_pending',
			showDurationHint: true
		},
		{
			subpage: C.ROUTES.NAME,
			component: Name,
			stepCategory: 1,
			gtmStep: 'userdata_name',
			gtmStatus: 'registration_pending'
		},
		{
			subpage: C.ROUTES.BIRTHDATE,
			component: Birthdate,
			stepCategory: 1,
			gtmStep: 'userdata_birthdate',
			gtmStatus: 'registration_pending'
		},
		{
			subpage: C.ROUTES.EMAIL,
			component: Email,
			stepCategory: 1,
			gtmStep: 'userdata_email',
			gtmStatus: 'registration_pending'
		},
		{
			subpage: C.ROUTES.EMAIL_SENT,
			component: EmailSent,
			stepCategory: 1,
			gtmStep: 'userdata_email_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'email_confirmation_pending'
		},
		{
			subpage: C.ROUTES.EMAIL_CONFIRMED + '/:accessToken',
			component: EmailConfirmedOld,
			stepCategory: 1,
			gtmStep: 'userdata_email_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'email_confirmed'
		},
		{
			subpage: C.ROUTES.EMAIL_NOT_CONFIRMED,
			component: EmailNotConfirmed,
			stepCategory: 1,
			gtmStep: 'userdata_email_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'email_not_confirmed'
		},
		{
			subpage: C.ROUTES.MOBILE_NUMBER,
			component: MobileNumber,
			stepCategory: 1,
			gtmStep: 'userdata_mobile_number',
			gtmStatus: 'registration_pending',
			needsRegistrationToken: true
		},
		{
			subpage: C.ROUTES.OTP,
			component: Otp,
			stepCategory: 1,
			gtmStep: 'userdata_mobile_number_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'mobile_number_confirmation_pending',
			needsRegistrationToken: true
		},
		{
			subpage: C.ROUTES.OTP + '/:isRegistration',
			component: Otp,
			stepCategory: 1,
			gtmStep: 'userdata_mobile_number_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'mobile_number_confirmation_pending',
			needsRegistrationToken: true
		},
		{
			subpage: C.ROUTES.NATIONALITY,
			component: Nationality,
			stepCategory: 1,
			gtmStep: 'personaldata_nationality',
			gtmStatus: 'application_pending'
		},
		{
			subpage: C.ROUTES.ADDRESS,
			component: Address,
			stepCategory: 1,
			gtmStep: 'personaldata_address',
			gtmStatus: 'application_pending'
		},
		{
			subpage: C.ROUTES.PREVIOUS_ADDRESS,
			component: PreviousAddress,
			stepCategory: 1,
			gtmStep: 'personaldata_previous_address',
			gtmStatus: 'application_pending'
		},
		{
			subpage: C.ROUTES.BANK_ACCOUNT,
			component: BankAccount,
			stepCategory: 1,
			gtmStep: 'personaldata_current_account',
			gtmStatus: 'application_pending'
		},
		{
			subpage: C.ROUTES.PAYMENT_PROTECTION_INSURANCE,
			component: PPI,
			stepCategory: 2,
			gtmStep: 'personaldata_ppi',
			gtmStatus: 'application_pending'
		},
		{
			subpage: C.ROUTES.TERMS_AND_CONDITIONS,
			component: TermsAndConditions,
			stepCategory: 3,
			gtmStep: 'personaldata_terms_conditions',
			gtmStatus: 'application_pending'
		},
		{
			subpage: C.ROUTES.BCO_RESPONSE_GREEN,
			component: ApplicationApprovedCreditCard,
			gtmStep: 'bco_response',
			gtmStatus: 'bco_response',
			gtmSubStatus: 'bco_green'
		},
		{
			subpage: C.ROUTES.IDENTIFICATION,
			component: Identification,
			gtmStep: 'identification_selection',
			gtmStatus: 'identification_pending',
			needsVcJwtToken: true
		},
		{
			subpage: C.ROUTES.POSTIDENT,
			component: PostIdent,
			gtmStep: 'postident',
			gtmStatus: 'identification_pending',
			needsVcJwtToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_POSTIDENT_CONFIRMATION,
			component: DigitalPostidentConfirmation,
			gtmStep: 'digital_postident_confirmation',
			gtmStatus: 'identification_pending',
			needsVcJwtToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_POSTIDENT,
			component: DigitalPostident,
			gtmStep: 'digital_postident',
			gtmStatus: 'identification_pending',
			needsVcJwtToken: true
		},
		{
			subpage: C.ROUTES.WEB_ID_DONE,
			component: WebIdDone,
			hideFlowTitle: true
		},
		{
			subpage: C.ROUTES.ADDRESS_SELECTION,
			component: AddressSelection,
			gtmStep: 'address_selection',
			gtmStatus: 'identification_pending',
			gtmIdentificationDocument: 'idcard',
			needsVcJwtToken: true
		},
		{
			subpage: C.ROUTES.ADDRESS_VERIFICATION,
			component: AddressVerification,
			gtmStep: 'address_verification',
			gtmStatus: 'identification_pending',
			gtmSubStatus: 'upload_pending',
			gtmIdentificationDocument: 'idcard',
			needsVcJwtToken: true
		},
		{
			subpage: C.ROUTES.ADDRESS_VERIFICATION_POSTPONED,
			component: AddressVerificationPostponed,
			gtmStep: 'address_verification_postponed',
			gtmStatus: 'identification_pending',
			gtmSubStatus: 'address_verification_pending',
			gtmIdentificationDocument: 'idcard',
			needsVcJwtToken: true
		},
		{
			subpage: C.ROUTES.ACTIVATE_CREDIT_CARD_ACCOUNT,
			component: ActivateCreditCardAccount,
			gtmStep: 'activate_account',
			gtmStatus: 'activate_account'
		},
		{
			subpage: C.ROUTES.CREDIT_CARD_SUCCESS,
			component: CreditCardSuccess,
			gtmStep: 'creditcard_success',
			gtmStatus: 'creditcard_success'
		},
		{
			subpage: C.ROUTES.UPLOAD_DOCUMENTS + '/:isReminder',
			component: UploadDocuments,
			gtmStep: 'upload_documents_reminder',
			gtmStatus: 'documents_pending'
		},
		{
			subpage: C.ROUTES.THANKYOU2,
			component: ThankYouRejected,
			gtmStep: 'thank_you_rejected',
			gtmStatus: 'application_end'
		},
		{
			subpage: C.ROUTES.THANKYOU,
			component: ThankYou,
			gtmStep: 'thank_you',
			gtmStatus: 'application_end'
		}
	]);

	for (const route of routes) {
		route.flow = flow;
		route.gtmType = gtmType;
	}

	return routes;
};
export default routes;
