import React from 'react';
import PropTypes from 'prop-types';
import { m } from 'utils/messages/messages';

import { Link, Text } from 'components/Atoms/Text';
import SvgLocation from 'components/Atoms/SVG/Icons/SvgLocation';
import SvgShareLink from 'components/Atoms/SVG/Icons/SvgShareLink';

import styles from './SantanderBranchHint.module.scss';

const SantanderBranchHint = (props) => {
	return (
		<div className={styles.hintWrapper}>
			<div className={styles.icon}>
				<SvgLocation color="#63818E" />
			</div>
			<div className={styles.hintText}>
				<Text>
					{m('general.contactSubsidiary', 'global')}
					<Link
						href={props.link ?? 'https://service.santander.de/webapps/apps/filialen'}
						target='_blank'
					>
						{m('general.contactSubsidiaryLinkText', 'global')}
						<SvgShareLink />
					</Link>
				</Text>
			</div>
		</div>
	);
};

SantanderBranchHint.propTypes = {
	link: PropTypes.string
};

export default SantanderBranchHint;
