/** Actions Type */
export const SET_ERROR_MESSAGES = 'SET_ERROR_MESSAGES';
export const SET_SUCCESS_MESSAGES = 'SET_SUCCESS_MESSAGES';

/** Actions Creators */
export const setErrorMessages = payload => ({
	type: SET_ERROR_MESSAGES,
	payload
});

export const setSuccessMessages = payload => ({
	type: SET_SUCCESS_MESSAGES,
	payload
});
