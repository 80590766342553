import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useFlowUtils } from 'utils/flow/flow';

import { Text } from 'components/Atoms/Text';
import SvgUrgent from 'components/Atoms/SVG/Icons/SvgUrgent';

import styles from './DurationHint.module.scss';

const DurationHint = (props) => {
	const { getCurrentFlow } = useUtils();
	const { isGmkHyundai } = useFlowUtils();

	const currentFlow = getCurrentFlow();

	return (
		<div className={styles.wrapper}>
			<div className={classNames([styles.col, styles.icon])}>
				<SvgUrgent color="#ffffff" />
			</div>
			<div className={styles.col}>
				<Text size="s" color="white">
					{m(
						(isGmkHyundai ? 'hyundai.' : '') + 'progressBar.subline.letsGo',
						currentFlow,
						{ minutes: props.duration ?? '4-6' },
						true
					)}
				</Text>
			</div>
		</div>
	);
};

DurationHint.propTypes = {
	duration: PropTypes.string
};

export default DurationHint;
