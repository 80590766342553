import React from 'react';

const Svg = () => {
	const width = 321;
	const height = 415;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<defs>
				<path
					id='data_collection_failure_svg__a'
					d='M.167.751h126.63v43.566H.168z'
				/>
			</defs>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#DEEDF2'
					d='M72.445 354.486s-75.138-25.07-72.37-112.11c0 0 3.113-108.085 124.196-92.286l28.109 4.708s17.036 4.653 33.646-18.035c39.44-53.873 107.858-43.177 127.54-2.414 0 0 15.31 26.474 1.988 58.505 0 0-7.104 31.7-46.355 42.242-59.612 16.01-42.28 38.573-73.423 86.306 0 0-37.127 50.062-123.33 33.084'
				/>
				<path
					fill='#FFF'
					d='M308.462 218.657v-.006h-13.825c.456-1.248.717-2.593.717-4.001 0-6.435-5.191-11.65-11.595-11.65-5.45 0-10.009 3.783-11.25 8.874a8.303 8.303 0 00-2.026-.26c-4.153 0-7.585 3.06-8.21 7.056-4.048.193-7.273 3.541-7.273 7.656 0 4.238 3.419 7.674 7.637 7.674.6 0 1.18-.078 1.739-.208h42.248c.56.13 1.14.208 1.739.208 4.217 0 7.637-3.436 7.637-7.674 0-4.205-3.366-7.615-7.538-7.67'
				/>
				<g transform='translate(68.761 369.835)'>
					<mask id='data_collection_failure_svg__b' fill='#fff'>
						<use xlinkHref='#data_collection_failure_svg__a'/>
					</mask>
					<path
						fill='#63818E'
						d='M61.803.751l64.995 20.016-61.444 23.55L.167 20.81z'
						mask='url(#data_collection_failure_svg__b)'
					/>
				</g>
				<path
					fill='#D2F0F2'
					d='M131.543 364.246l-59.265 22.62v-61.978l59.265-22.62z'
				/>
				<path
					fill='#9BC3D3'
					d='M195.783 386.867l-64.24-22.62v-61.979l64.24 22.62z'
				/>
				<path
					fill='#D2F0F2'
					d='M196.235 386.867l-59.716 22.62v-61.98l59.716-22.618z'
				/>
				<path
					fill='#F2F8FA'
					d='M180.588 358.462l-36.22 13.104v-3.686l36.22-13.102zm-14.538 14.594l-21.682 7.835v-3.684l21.682-7.836z'
				/>
				<path
					fill='#9BC3D3'
					d='M136.52 409.487l-64.242-22.62v-61.979l64.241 22.62z'
				/>
				<path
					fill='#C3DEE7'
					d='M85.976 365.987l-7.662-2.698v-7.392l7.662 2.698z'
				/>
				<path
					fill='#DEEDF2'
					d='M131.543 302.268l15.388-16.274 65.59 20.804-16.285 18.09z'
				/>
				<path
					fill='#C3DEE7'
					d='M136.52 347.508l-23.978 12.667-62.424-23.984 22.16-11.303zm-4.977-45.24l-13.139-14.89-59.257 21.714 13.131 15.796z'
				/>
				<path
					fill='#C3DEE7'
					d='M196.235 324.889l21.263 7.69-58.81 23.525-22.169-8.596zM158.227 143.613l-15.57-8.662V118l15.57 8.662z'
				/>
				<path
					fill='#D2F0F2'
					d='M93.467 283.731L66.22 268.572v-29.664l27.247 15.16z'
				/>
				<path stroke='#9BC3D3' d='M177.164 54.035v-2.5'/>
				<path
					stroke='#9BC3D3'
					strokeDasharray='4.952,4.952'
					d='M142.164 339.197V32.138'
				/>
				<path
					stroke='#FFF'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth={2}
					d='M78.47 253.431v16.187m7.008-4.047l-14.018-8.093'
				/>
				<path
					fill='#C3DEE7'
					d='M113.17 183.416l-6-3.338v-6.532l6 3.338zm0 16.764l-6-3.339v-6.531l6 3.338zm0 15.79l-6-3.337V206.1l6 3.338z'
				/>
				<path
					fill='#D2F0F2'
					d='M206.173 280.018l-88.093-48.96v-62.88l88.093 48.96z'
				/>
				<path
					fill='#FFF'
					d='M176.583 218.201l-51.071-28.4v-5.982l51.07 28.401zm0 15.894l-51.071-28.4v-5.982l51.07 28.4zm24.071 29.409l-75.143-41.83v-5.983l75.143 41.832z'
				/>
				<path fill='#FC3' d='M53.66 191.815l-15.57-8.662v-16.95l15.57 8.661z'/>
				<path fill='#FFF' d='M185.752 112.455L97.658 63.494V.614l88.094 48.96z'/>
				<path
					fill='#D2F0F2'
					d='M177.04 62.227l-71.95-39.99v-5.982l71.95 39.99z'
				/>
				<path
					fill='#C3DEE7'
					d='M156.16 50.638l-51.07-28.4v-5.983l51.07 28.4zm20.88 28.688l-71.95-39.99v-5.982l71.95 39.99z'
				/>
				<path
					fill='#D2F0F2'
					d='M129.042 52.698L105.09 39.336v-5.983l23.952 13.363zm47.998 43.726l-71.95-39.99v-5.982l71.95 39.99z'
				/>
				<path
					fill='#C3DEE7'
					d='M153.06 83.15l-47.97-26.715v-5.983l47.97 26.716z'
				/>
			</g>
		</svg>
	);
};
export default Svg;
