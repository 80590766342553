const defaultState = {
	street: null,
	number: null,
	postcode: null,
	city: null,
	state: null,
	hasPreviousAddress: false,
	previousStreet: null,
	previousNumber: null,
	previousPostcode: null,
	previousCity: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_STREET':
			return { ...state, street: action.payload?.length > 0 ? action.payload : null };
		case 'SET_NUMBER':
			return { ...state, number: action.payload?.length > 0 ? action.payload : null };
		case 'SET_POSTCODE':
			return { ...state, postcode: action.payload?.length > 0 ? action.payload : null };
		case 'SET_CITY':
			return { ...state, city: action.payload?.length > 0 ? action.payload : null };
		case 'SET_STATE':
			return { ...state, state: action.payload?.length > 0 ? action.payload : null };
		case 'SET_HAS_PREVIOUS_ADDRESS':
			return { ...state, hasPreviousAddress: action.payload };
		case 'SET_PREVIOUS_STREET':
			return { ...state, previousStreet: action.payload?.length > 0 ? action.payload : null };
		case 'SET_PREVIOUS_NUMBER':
			return { ...state, previousNumber: action.payload?.length > 0 ? action.payload : null };
		case 'SET_PREVIOUS_POSTCODE':
			return { ...state, previousPostcode: action.payload?.length > 0 ? action.payload : null };
		case 'SET_PREVIOUS_CITY':
			return { ...state, previousCity: action.payload?.length > 0 ? action.payload : null };
		default:
			return state;
	}
};

export default reducers;
