/* eslint-disable no-undef */
export const useTargetUtils = () => {
	const src = '/scripts/adobe-target/at.js';

	const initTarget = (enabled) => {
		if (enabled) {
			const script = document.createElement('script');
			script.src = src;
			script.async = true;
			document.head.appendChild(script);
		} else {
			const existingScript = document.head.querySelector(`script[src="${src}"]`);
			if (existingScript) {
				document.head.removeChild(existingScript);
			}
		}
	};

	const targetView = (viewName) => {
		if (window.adobe && adobe?.target?.triggerView && viewName) {
			adobe.target.triggerView(viewName, { page: true });
		}
	};

	return {
		initTarget,
		targetView
	};
};
