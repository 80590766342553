import React, { useEffect } from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useNumberUtils } from 'utils/number/number';
import { useDateUtils } from 'utils/date/date';

import { Col, Row } from 'components/Atoms/Grid';
import { Text } from 'components/Atoms/Text';
import { DropdownInput } from 'components/Atoms/Form';
import Table from 'components/Pages/Insurance/Protect/Table/Table';

import { useDispatch, useSelector } from 'react-redux';
import { setProtectRuntime, setProtectMonthlyRate } from 'store/Insurance/actions';

const Protect = () => {
	const { getCurrentFlow } = useUtils();
	const { getAge } = useDateUtils();
	const { roundUp } = useNumberUtils();

	const currentFlow = getCurrentFlow();
	const dispatch = useDispatch();
	const storeProtectRuntime = useSelector((state => state.insurance.protectRuntime));
	const storeProtectMonthlyRate = useSelector((state => state.insurance.protectMonthlyRate));
	const storeIncome = useSelector((state => state.financialStatus.income));

	const storeBirthdate = useSelector((state => state.birthdate.birthdate));

	const age = getAge(storeBirthdate);

	const formatRuntimeDropdown = () => {
		if (age >= 63) { // if max runtime = 36
			runtimeItems.pop();
		}
		if (age >= 64) { // if max runtime = 48
			runtimeItems.pop();
		}
		if (age >= 65) { // if max runtime = 60
			runtimeItems.pop();
		}
	};

	const runtimeItems = [
		{ value: '12', text: '12 ' + m('month.plural', 'fields') },
		{ value: '24', text: '24 ' + m('month.plural', 'fields') },
		{ value: '36', text: '36 ' + m('month.plural', 'fields') },
		{ value: '48', text: '48 ' + m('month.plural', 'fields') },
		{ value: '60', text: '60 ' + m('month.plural', 'fields') }
		/*{ value: '72', text: '72 ' + m('month.plural', 'fields') },
		{ value: '84', text: '84 ' + m('month.plural', 'fields') },
		{ value: '96', text: '96 ' + m('month.plural', 'fields') },
		{ value: '108', text: '108 ' + m('month.plural', 'fields') },
		{ value: '120', text: '120 ' + m('month.plural', 'fields') }*/
	];

	formatRuntimeDropdown();

	const monthlyProtectItems = [
		/*{ value: '100', text: '100 €' },*/
		{ value: '200', text: '200 €' },
		{ value: '300', text: '300 €' },
		{ value: '400', text: '400 €' },
		{ value: '500', text: '500 €' },
		{ value: '600', text: '600 €' },
		{ value: '700', text: '700 €' },
		{ value: '800', text: '800 €' },
		{ value: '900', text: '900 €' },
		{ value: '1000', text: '1.000 €' }
	];

	useEffect(() => {
		if(storeProtectRuntime === null) {
			if(runtimeItems.length >= 3) {
				dispatch(setProtectRuntime(parseInt(runtimeItems[2].value)));
			} else {
				dispatch(setProtectRuntime(runtimeItems.length <= 0 ? null : parseInt(runtimeItems[runtimeItems.length - 1].value)));
			}
		}
		if(storeProtectMonthlyRate === null) {
			if (storeIncome) {
				dispatch(setProtectMonthlyRate(roundUp((parseInt(storeIncome) * 0.3), 100, 1000)));
			} else {
				dispatch(setProtectMonthlyRate(parseInt(monthlyProtectItems[0].value)));
			}
		}
	}, []);

	const setProtectRuntimeDropdown = (value) => {
		dispatch(setProtectRuntime(parseInt(value)));
	};

	const setProtectMonthlyRateDropdown = (value) => {
		dispatch(setProtectMonthlyRate(parseInt(value)));
	};

	return (
		<>
			<Text mb>{m('insurance.protect.body.title', currentFlow)}:</Text>
			<Text mb>{m('insurance.protect.body.subtitle', currentFlow)}</Text>
			<Row>
				<Col xs={12} sm={6}>
					<DropdownInput
						value={storeProtectRuntime + ''}
						setValue={setProtectRuntimeDropdown}
						items={runtimeItems}
						label={m('insurance.protect.body.runtime.label', currentFlow)}
						size="s"
						tabindex={2}
						testId="protect-runtime"
					/>
				</Col>
				<Col xs={12} sm={6}>
					<DropdownInput
						value={storeProtectMonthlyRate + ''}
						setValue={setProtectMonthlyRateDropdown}
						items={monthlyProtectItems}
						label={m('insurance.protect.body.monthlyProtect.label', currentFlow)}
						size="s"
						tabindex={3}
						testId="protect-monthly-rate"
					/>
				</Col>
			</Row>
			<Text mb>{m('insurance.protect.body.selectTitle', currentFlow)}:</Text>
			<Table />
		</>
	);
};
export default Protect;
