import React from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import SvgEmailClose from 'components/Atoms/SVG/Icons/SvgEmailClose';

import styles from './EmailNotConfirmed.module.scss';

const EmailNotConfirmed = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	const onSubmit = async () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.INDEX);
	};

	return (
		<>
			<Row flexColumnMobile>
				<Col noShrink noGrow>
					<div className={styles.icon}>
						<SvgEmailClose />
					</div>
				</Col>
				<Col grow>
					<Title>{m('pages.emailNotConfirmed.title', 'global')}</Title>
					<Text>{m('pages.emailNotConfirmed.subtitle', 'global')}</Text>
				</Col>
			</Row>
			<Form
				onSubmit={onSubmit}
				submitText={m('pages.emailNotConfirmed.submit', 'global')}
				submitTabindex={1}
			/>
		</>
	);
};
export default EmailNotConfirmed;
