import * as React from 'react';

const SvgProtectUnemployment = () => {
	const width = 30;
	const height = 30;
	const style1 = { fill: '#9b3667' };
	const style2 = { fill: '#ffffff' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<rect style={style1} width="30" height="30" rx="4.62"/>
			<path style={style2} d="M25.93,7.7a.4.4,0,0,0-.56,0L24.25,8.8,23.12,7.7a.39.39,0,0,0-.55,0,.38.38,0,0,0,0,.55l1.12,1.1-1.12,1.1a.38.38,0,0,0,0,.55.36.36,0,0,0,.28.11.38.38,0,0,0,.28-.11l1.12-1.1L25.38,11a.36.36,0,0,0,.28.11.38.38,0,0,0,.28-.11.39.39,0,0,0,0-.55l-1.13-1.1,1.13-1.1A.38.38,0,0,0,25.93,7.7Z"/>
			<path style={style2} d="M28,9.35a3.8,3.8,0,0,0-7.47-.88H19.33V6.72a1.15,1.15,0,0,0-1.17-1.15H11.77A1.15,1.15,0,0,0,10.6,6.72V8.47h-4a2.41,2.41,0,0,0-2.42,2.38V20.5a2.41,2.41,0,0,0,2.42,2.38h16.8a2.41,2.41,0,0,0,2.43-2.38V12.74h0A3.71,3.71,0,0,0,28,9.35ZM11.39,6.72a.37.37,0,0,1,.38-.37h6.39a.37.37,0,0,1,.38.37V8.48H11.39ZM25,20.5a1.63,1.63,0,0,1-1.64,1.61H6.56A1.62,1.62,0,0,1,4.93,20.5V17.22H7.7v.29a.69.69,0,0,0,.66.7h1.82a.68.68,0,0,0,.66-.7v-.29H19v.29a.69.69,0,0,0,.66.7h1.82a.68.68,0,0,0,.66-.7v-.29H25V20.5ZM8.57,17.36V16.31H10v1.05Zm11.3,0V16.31h1.39v1.05ZM25,16.45H22.13v-.29a.68.68,0,0,0-.65-.71H19.65a.68.68,0,0,0-.65.71v.29H10.84v-.29a.68.68,0,0,0-.65-.71H8.36a.68.68,0,0,0-.65.71v.29H4.93v-5.6a1.62,1.62,0,0,1,1.63-1.6H20.44s0,.07,0,.1a3.76,3.76,0,0,0,3.8,3.72A3.94,3.94,0,0,0,25,13Zm-.77-4a3.07,3.07,0,1,1,3.12-3.07A3.1,3.1,0,0,1,24.23,12.42Z"/>
		</svg>
	);
};

export default SvgProtectUnemployment;
