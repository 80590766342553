import React from 'react';
import PropTypes from 'prop-types';

import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

const ProtectValue = (props) => {
	const { formatMoneyValue } = useNumberUtils();
	const { getProtectValue } = useCreditUtils();

	return (
		<>
			{props.isDecimal
				? formatMoneyValue(getProtectValue(), true)
				: getProtectValue()}
		</>
	);
};

ProtectValue.propTypes = {
	isDecimal: PropTypes.bool
};

export default ProtectValue;
