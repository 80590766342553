module.exports = {
	title: 'Sparbrief',
	progressBar: {
		step1: 'Ihre Angaben',
		step2: 'Ihre Person',
		step3: 'Antrag senden',
		subline: {
			letsGo: "<strong>Los geht's:</strong> In nur {minutes} Minuten bis zum Sparbrief."
		}
	},
	investment: {
		amount: {
			title: 'Einlagebetrag und Laufzeit',
			subtitle: 'Die Mindesteinlage je Sparbrief beträgt 2.500 €.',
			error: {
				required: 'Bitte geben Sie eine Mindesteinlage an.',
				amountTooSmall: 'Die Mindesteinlage je Sparbrief beträgt 2.500 €.'
			}
		},
		runtime: {
			title: 'Wählen Sie bitte die Laufzeit für Ihren Sparbrief aus.',
			error: {
				required: 'Bitte geben Sie eine Laufzeit an.'
			}
		},
		paymentType: {
			title: 'Welche Art der Zinszahlung wählen Sie?',
			true: 'Jährlich',
			false: 'Zum Laufzeitende',
			warning: '<strong>Hinweis:</strong> Bei einer Laufzeit von einem Jahr oder weniger erfolgt die Zinszahlung automatisch zum Laufzeitende.'
		}
	},
	birthdate: {
		subtitle: 'Für die Eröffnung eines Sparbriefs müssen Sie volljährig sein.'
	},
	email: {
		subtitle:
			'Ihre E-Mail-Adresse wird benötigt, damit Sie Ihren Sparbrief digital eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	maritalStatus: {
		subtitle:
			'Diese Angabe wird für eine Berechnung steuerfreier Zinserträge benötigt.'
	},
	occupation: {
		subtitle: 'Diese Angabe wird für die Eröffnung des Sparbriefs benötigt.'
	},
	bankAccount: {
		subtitle:
			'Für die Anlage eines Sparbriefs wird ein gebührenfreies Tagesgeldkonto als Abrechnungskonto benötigt. Geben Sie hier die IBAN des Girokontos an, auf das zukünftig Überweisungen vom Abrechnungskonto erfolgen sollen.'
	},
	termsAndConditions: {
		acceptTermsAndConditions: {
			description:
				'Ich habe die vorvertraglichen Informationen, die produktspezifischen Bedingungen und Datenschutzhinweise, die Werbehinweise, den Informationsbogen für den Einleger sowie die AGB und das Preisverzeichnis der Santander erhalten und lege diese meinem Antrag zugrunde.',
			linkText: 'Bedingungen und Informationen zu Santander Geldeinlagen'
		},
		summary:
			'Mit dem Anklicken des Buttons beantragen Sie verbindlich bei Santander, für Sie ein Sparbriefkonto anzulegen und ein gebührenfreies Tagesgeldkonto (als Abrechnungskonto) zu eröffnen sowie damit verbundene Services (z.B. Zugang zum Online Banking) einzurichten. Das Sparbriefkonto wird aktiviert, sobald der gewünschte Anlagebetrag auf Ihrem Tagesgeldkonto eingegangen ist. Santander wird Ihren Antrag annehmen, wenn Sie sich vollständig legitimiert haben. Über die Annahme informiert Santander Sie gesondert in Textform.',
		submit: 'Tagesgeldkonto und Sparbrief eröffnen'
	},
	identification: {
		title: 'Ihr letzter Schritt bis zur Kontoeröffnung: Jetzt identifizieren',
		subtitle:
			'Mit dem Ident Verfahren unseres Partners WebID Solutions können Sie sicher, einfach und bequem Ihre Identität bestätigen.',
		kontoIdent: {
			title: 'Konto-Ident',
			subtitle: 'Identifizieren mit einer Überweisung von 1 Cent',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<ol class="list"><li>Fotos von Ausweis und Gesicht machen</li><li>Log-in ins Online-Banking, Überweisung von 1 Cent bestätigen</li><li>TAN-Eingabe</li></ol>'
		},
		videoIdent: {
			title: 'Video-Ident',
			subtitle: 'Videogespräch mit einem WebID-Agenten',
			list:
				'<li>Kurze Wartezeit</li><li>Von 7:00 bis 22:00 Uhr von überall möglich</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<ol class="list"><li>Ausweis bereit halten</li><li>Videocall mit WebID-Agenten durchführen</li><li>TAN-Eingabe</li></ol>'
		}
	},
	download: {
		contract: {
			application: 'Eröffnungsantrag / Auftrag',
			pdftitle: 'Kontoeröffnungsantrag.pdf'
		}
	},
	addressVerification: {
		title: 'Bitte bestätigen Sie Ihre Adresse.',
		subtitle:
			'Um den Vorgang abzuschließen benötigen wir von Ihnen noch eines der folgenden Dokumente: Meldebescheinigung, oder eine Rechnung für Telefon, Strom oder Gas.',
		submitExtension1: 'Kein Dokument zur Hand?',
		submitExtension2: 'Später hochladen'
	},
	addressSelection: {
		title: 'Welche Adresse ist korrekt?',
		subtitle:
			'Die von Ihnen angegebene Adresse stimmt nicht mit der Adresse aus dem Video-Ident Verfahren überein. Welche Adresse ist aktueller?',
		items: {
			webIdAddress: {
				title: 'Adresse in Ihrem Ausweis',
				subtitle: 'Bereits bestätigt. Direkte Kontoeröffnung.'
			},
			originalAddress: {
				title: 'Angegebene Adresse',
				subtitle: 'Erfordert einen weiteren Nachweis.'
			}
		}
	},
	addressVerificationPostponed: {
		title:
			'Wir haben Ihnen eine E-Mail geschickt. So können Sie jederzeit zurückkehren.',
		subtitle:
			'Wir können Ihren Antrag erst dann bearbeiten, wenn Sie alle benötigten Dokumente vollständig hochgeladen haben.'
	},
	activateGiroAccount: {
		title: 'Sie haben sich erfolgreich identifiziert',
		button: 'Jetzt Konto abschliessen'
	},
	accountSuccess: {
		title: 'Geschafft! Ihr Konto ist angelegt.',
		subtitle:
			'In wenigen Tagen bekommen Sie die Zugangsdaten für das Online Banking zugestellt.',
		contract: 'Eröffnungsantrag / Auftrag',
		hint: {
			title: 'Sparbrief Anlage',
			description: 'Ihr Tagesgeldkonto wurde erfolgreich als Verrechnungskonto für Ihren Sparbrief eröffnet. Bitte überweisen Sie den Betrag in Höhe von {amount} € auf ihr neues Tagesgeldkonto mit obenstehender IBAN. Sobald Sie den Betrag überwiesen haben, wird Ihr Sparbrief automatisch angelegt.'
		}
	},
	thankYou: {
		title:
			'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.',
		hint: '<strong class="text--highlight">Wichtiger Hinweis:</strong> Aufgrund der aktuell hohen Nachfrage kann es zu Verzögerungen in der Antragsbearbeitung kommen – <strong>diese kann 10 Werktage dauern.</strong><br />Keine Sorge, der Aktionszins gilt erst ab Kontoeröffnung. Über die Kontoeröffnung informieren wir Sie separat schriftlich.<br />Vielen Dank vorab für Ihr Verständnis.'
	},
	accountExists: {
		title: 'Vielen Dank für Ihre Anfrage! Mit Ihren Daten existiert bereits ein Tagesgeldkonto bei uns. Legen Sie jetzt in wenigen Schritten Ihren Sparbrief an.',
		onlineBanking: {
			title: 'Online Banking',
			description: 'Einfach mit Ihren Zugangsdaten im Online Banking Bereich anmelden und Sparbrief anlegen.',
			button: 'Jetzt anmelden'
		},
		support: {
			title: 'Persönliche Unterstützung',
			description: 'Kontaktieren Sie unser Team einfach telefonisch unter <a class="link primary" href="tel:+4921619060819">02161 - 90 60 819</a>',
			descriptionSmall: 'Montags - Freitags: 08:00 - 19:00 Uhr',
			button: 'Jetzt anrufen'
		}
	},
	exitPopup: {
		title: 'Sparbrief Antrag',
		subhead: 'Gehen Sie noch nicht!',
		description: 'Der Santander Sparbrief - eine zuverlässige Geldanlage, gebührenfrei und ohne Kursrisiko.',
		continueButton: 'Fortfahren'
	}
};
