import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0 1c-4.962 0-9 4.038-9 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9zm0 7a.5.5 0 0 1 .492.41l.008.09v7a.5.5 0 0 1-.992.09l-.008-.09v-7a.5.5 0 0 1 .5-.5zm0-4a.5.5 0 0 1 .492.41l.008.09v1a.5.5 0 0 1-.992.09L11.5 7.5v-1A.5.5 0 0 1 12 6z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
