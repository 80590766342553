import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import C from 'constants/Constants';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useLegitimationApi } from 'api/legitimation';
import { useApplicationApi } from 'api/application';

import LoadingPage from 'components/Atoms/Loading/Page/LoadingPage';

import { useDispatch } from 'react-redux';
import { setVcJwtToken } from 'store/App/actions';
import { setGtmStatus, setGtmSubStatus } from 'store/Tracking/actions';

const WebIdDone = () => {
	const { getCurrentFlow, isCreditSubType, isCreditCardSubType } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { getCheckAddressVerification } = useLegitimationApi();
	const { postDepot } = useApplicationApi();

	const currentFlow = getCurrentFlow();

	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		let state;
		let webidtoken;
		if (location.search !== undefined) {
			let search = new URLSearchParams(location.search);
			state = search.get('state');
			webidtoken = search.get('webidtoken');
			if (state && webidtoken) {
				dispatch(setVcJwtToken(webidtoken));
			}
		}

		// for VISACARD we don't need the checkAddressVerification so we take a shortcut here
		if (currentFlow === C.FRONTEND.VISACARD) {
			goToPage(currentFlow + C.ROUTES.ACTIVATE_CREDIT_CARD_ACCOUNT);
		} else {
			await getCheckAddressVerification(
				webidtoken ?? null,
				state ?? null,
				onSuccess,
				onError
			);
		}
	};

	const onSuccess = async (data, webIdStatus) => {
		if (
			data.vcJwtToken &&
			!data.capsUploadDone &&
			!data.webIdStarted &&
			!data.postIdentStarted &&
			!data.webIdFinished &&
			!data.postIdentFinished
		) {
			continueValid();
			if (data.hasSecondApplicant) {
				goToPage(currentFlow + C.ROUTES.FINAL_STEPS_TWO_BORROWERS);
			} else {
				goToPage(currentFlow + C.ROUTES.BCO_RESPONSE_GREEN);
			}
		} else if (
			data.vcJwtToken &&
			data.capsUploadDone &&
			!data.webIdStarted &&
			!data.postIdentStarted &&
			!data.webIdFinished &&
			!data.postIdentFinished
		) {
			continueValid();
			if (data.hasSecondApplicant) {
				goToPage(currentFlow + C.ROUTES.FINAL_STEPS_TWO_BORROWERS);
			} else {
				goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
			}
		} else if (
			data.vcJwtToken &&
			data.capsUploadDone &&
			(data.webIdStarted || data.postIdentStarted) &&
			!data.webIdFinished &&
			!data.postIdentFinished
		) {
			continueValid();
			if (data.hasSecondApplicant) {
				goToPage(currentFlow + C.ROUTES.FINAL_STEPS_TWO_BORROWERS);
			} else {
				if (data.postIdentStarted) {
					goToPage(currentFlow + C.ROUTES.DIGITAL_POSTIDENT);
				} else {
					goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
				}
			}
		} else if (
			data.vcJwtToken &&
			data.capsUploadDone &&
			(data.webIdStarted || data.postIdentStarted) &&
			(data.webIdFinished || data.postIdentFinished) &&
			webIdStatus === 'NOK'
		) {
			continueValid();
			if (data.hasSecondApplicant) {
				goToPage(currentFlow + C.ROUTES.FINAL_STEPS_TWO_BORROWERS);
			} else {
				goToPage(currentFlow + C.ROUTES.POSTIDENT);
			}
		} else {
			if (!data.addressSelectionNeeded) {
				if (!data.documentUploadNeeded) {
					if (currentFlow === C.FRONTEND.DEPOT) {
						await postDepot(
							data.vcJwtToken,
							() => {
								continueValid();
								goToPage(currentFlow + C.ROUTES.DEPOT_PURCHASE_SUCCESS);
							},
							(statusCode) => {
								continueInvalid();
								let nextPage = C.ROUTES.THANKYOU;
								if (statusCode === 400) {
									nextPage = C.ROUTES.CLEARING_ACCOUNT_ERROR;
								}
								goToPage(currentFlow + nextPage);
							}
						);
					} else if (
						currentFlow === C.FRONTEND.GIRO ||
						currentFlow === C.FRONTEND.GMK ||
						currentFlow === C.FRONTEND.SPARBRIEF
					) {
						continueValid();
						goToPage(currentFlow + C.ROUTES.ACCOUNT_SUCCESS);
					} else if (isCreditCardSubType()) {
						continueValid();
						goToPage(currentFlow + C.ROUTES.ACTIVATE_CREDIT_CARD_ACCOUNT);
					} else if (data.dacStatus === C.DAC_STATUS.GREEN) {
						continueValid();
						if (data.secondBorrower) {
							goToPage(currentFlow + C.ROUTES.FINAL_STEPS_TWO_BORROWERS);
						} else {
							goToPage(currentFlow + C.ROUTES.ACTIVATE_LOAN);
						}
					} else {
						continueValid();
						if (data.secondBorrower) {
							goToPage(currentFlow + C.ROUTES.FINAL_STEPS_TWO_BORROWERS);
						} else {
							goToPage(currentFlow + C.ROUTES.LOAN_DONE);
						}
					}
				} else {
					continueValid();
					goToPage(currentFlow + C.ROUTES.ADDRESS_VERIFICATION);
				}
			} else {
				continueValid();
				goToPage(currentFlow + C.ROUTES.ADDRESS_SELECTION);
			}
		}
	};

	const onError = (statusCode) => {
		dispatch(setGtmStatus('webid_failed'));
		dispatch(setGtmSubStatus(statusCode));

		let nextPage = C.ROUTES.THANKYOU;
		if (isCreditSubType()) {
			nextPage = C.ROUTES.ERROR_TECHNICAL;
		}

		// TODO: add depot, bestcards & credit
		if (statusCode === 409) {
			if (currentFlow === C.FRONTEND.GIRO) {
				nextPage = C.ROUTES.GIRO_EXISTS;
			} else if (
				currentFlow === C.FRONTEND.GMK ||
				currentFlow === C.FRONTEND.SPARBRIEF
			) {
				nextPage = C.ROUTES.GMK_EXISTS;
			}
		} else if (statusCode === 512) {
			if (
				currentFlow === C.FRONTEND.GMK ||
				currentFlow === C.FRONTEND.SPARBRIEF
			) {
				nextPage = C.ROUTES.THANKYOU2;
			}
		}

		continueInvalid();
		goToPage(currentFlow + nextPage);
	};

	return <LoadingPage />;
};
export default WebIdDone;
