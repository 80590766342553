/** Actions Type */
const SET_MARITAL_STATUS = 'SET_MARITAL_STATUS';
const SET_NUMBER_OF_ADULTS = 'SET_NUMBER_OF_ADULTS';
const SET_NUMBER_OF_CHILDREN = 'SET_NUMBER_OF_CHILDREN';
const SET_NUMBER_OF_CHILDREN_WITH_BENEFIT = 'SET_NUMBER_OF_CHILDREN_WITH_BENEFIT';

/** Actions Creators */
export const setMaritalStatus = payload => ({
	type: SET_MARITAL_STATUS,
	payload
});

export const setNumberOfAdults = payload => ({
	type: SET_NUMBER_OF_ADULTS,
	payload
});

export const setNumberOfChildren = payload => ({
	type: SET_NUMBER_OF_CHILDREN,
	payload
});

export const setNumberOfChildrenWithBenefit = payload => ({
	type: SET_NUMBER_OF_CHILDREN_WITH_BENEFIT,
	payload
});
