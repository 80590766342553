module.exports = {
	title: 'BestCredit',
	footNote: 'Bonität vorausgesetzt',
	amount: {
		headline: 'Jetzt BestCredit<sup>1</sup> unverbindlich anfragen',
		bullet1: 'Günstige Zinsen',
		bullet2: 'Schnelle Auszahlung',
		bullet3: 'Sondertilgung, Ratenpause möglich',
		submit: 'Weiter zu Kontaktdaten',
		label: 'Mein Wunschbetrag ist',
		hint: 'Der Antrag dauert nur 1 Minute.'
	},
	shortApplication: {
		birthdateLabel: {
			day: 'Geburtstag',
			month: 'Geburtsmonat',
			year: 'Geburtsjahr'
		},
		phoneLabel: 'Telefonnummer',
		submitText: 'Jetzt Kredit anfragen',
		hint: {
			text1:
				'<strong>Hinweis:</strong> Mit dem Absenden der Kreditanfrage findet eine',
			text2:
				'statt. Die neutrale Konditionsanfrage hat keinen Einfluss auf Ihr Schufa-Scoring.'
		},
		checkboxSecurityPackage: {
			text1: 'Ich möchte die Vorteile des ',
			textLink: 'BestCredit Sicherheitspaketes ',
			text2: 'nutzen. Bitte machen Sie mir ein entsprechendes Angebot.',
			popUp: {
				title: 'Das BestCredit Sicherheitspaket',
				text:
					'Der ausstehende Sollsaldo Ihres Kredites wird aufgrund der Ratenschutzversicherung im Todesfall vollständig übernommen.'
			},
			popUpInfoIcon: {
				title: 'Die BestCredit Ratenschutzversicherung',
				text1:
					'Die Ratenschutzversicherung ist nicht Voraussetzung für die Gewährung des Darlehens, bietet Ihnen und Ihrer Familie jedoch im Falle des Todes (und ggf. der Arbeitslosigkeit sowie Arbeitsunfähigkeit) ein hohes Maß an Sicherheit vor unvorhergesehenen finanziellen Belastungen. Die Kosten für den Versicherungsschutz, die im Produktinformationsblatt aufgeführt sind, sind im effektiven Jahreszins des Darlehens nicht enthalten.',
				text2:
					'Besonders wichtig: Den Versicherungsschutz erhalten Sie ohne eine vorherige Gesundheitsprüfung. Bitte beachten Sie, dass für die versicherten Risiken Tod, Arbeitsunfähigkeit und Arbeitslosigkeit eine Wartezeit von 3 Monaten ab Versicherungsbeginn gilt. Während dieser Wartezeit besteht kein Versicherungsschutz. Für den Tod durch einen Unfall gemäß der Bedingungen RSV-Unfall besteht keine Wartezeit. Unwiderruflich bezugsberechtigt hinsichtlich der Versicherungsleistungen ist bis zur vollständigen Rückzahlung des Darlehens die Santander Consumer Bank AG.',
				text3:
					'Sollten Sie keine weitere Absicherung haben und auch keine Absicherung wünschen, tragen Sie bei Eintreten unvorhersehbarer Ereignisse alle Kosten selber. Bei weiteren Fragen können Sie sich gerne an unsere Filialen wenden. Die nächstgelegene Filiale finden Sie auch auf unserer Homepage unter "Filialsuche".'
			}
		},
		checkboxOtherPPI: {
			text:
				'Es bestehen anderweitige Absicherungen, die meine Ratenverpflichtungen im Falle unvorhersehbarer Ereignisse absichern können.'
		},
		checkboxSchufa: {
			text1: 'Hinweis: ',
			text2:
				'Im Rahmen dieser Finanzierungsanfrage werden Daten über die Beantragung eines Darlehens zum Beispiel an die Schutzgemeinschaft für Allgemeine Kreditsicherung (SCHUFA) übermittelt und dort gespeichert. Zur weiteren Bearbeitung Ihrer Finanzierungsanfrage bitten wir Sie, durch "Klicken" der Schaltfläche Ihr Einverständnis zur Übermittlung und zur Behandlung Ihrer persönlichen Daten gemäß ',
			linkText: 'Erklärung ',
			link:
				'https://www.santander.de/content/pdf/datenschutzhinweise/informationen-zur-datenuebermittlung-und-datenerfassung-an-auskunfteien.pdf',
			text3: 'zu erteilen. ',
			linkText2: 'Produktspezifische Datenschutzhinweise (PDF)',
			link2:
				'https://www.santander.de/content/pdf/datenschutzhinweise/produktspezifische-datenschutzhinweise-bestcredit.pdf'
		},
		checkboxDataStorage: {
			text1: 'Hiermit willige ich (Darlehensnehmer) ',
			text2: 'in die ',
			linkText: 'Erhebung, Speicherung und Verwendung personenbezogener Daten ',
			link:
				'https://www.santander.de/content/pdf/informationen/informationsblatt-datenverarbeitung-und-weitergabe-fuer-rsv.pdf',
			text3:
				'in die Erhebung, Speicherung und Verwendung personenbezogener Daten für die Ratenschutzversicherung durch Santander Consumer Bank AG sowie die Versicherer CNP Santander Insurance Life DAC und/oder CNP Santander Insurance Europe DAC ein und bin zudem mit der Datenweitergabe an aufgabenausführende Stellen sowie selbstständige Vermittler und Rückversicherer einverstanden. Darüber hinaus bestätige ich hiermit, dass ich mit der Entbindung der Schweigepflicht einverstanden bin.'
		}
	},
	thankYou: {
		title: 'Vielen Dank, Ihre unverbindliche Kreditanfrage ist erfolgreich eingegangen.',
		subtitle: 'Wir kontaktieren Sie in Kürze. Für eine Auszahlung<sup>1</sup> innerhalb von 48 Stunden, rufen Sie uns jetzt gerne an:',
		contact: 'Sie erreichen uns Montags bis Freitags, 8:00-20:00 Uhr. Wir freuen uns auf Ihren Anruf und führen gemeinsam mit Ihnen digital die finale Prüfung durch. Dafür benötigen Sie ein internetfähiges Endgerät und Ihre aktuellen Gehaltseinkünfte.'
	},
	serviceHotline: {
		content1: 'Sie erreichen uns unter',
		content2:
			', Montags bis Freitags, 08:00-20:00 Uhr. Wir freuen uns auf Ihren Anruf.'
	}
};
