import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 12;
	const height = 12;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="m11.99,4.64v-.04s-.02-.02-.02-.02l-.02-.05h0v-.02s-1.74-2.17-1.74-2.17V.3c0-.17-.13-.3-.3-.3H2.1C1.93,0,1.8.13,1.8.3v2.05L.07,4.52H.07s-.01.02-.01.02v.02s-.04.04-.04.07c0,.01,0,.03,0,.04v5c0,.49.14.94.4,1.31.01.04.03.07.05.09.02.02.04.03.05.04.43.53,1.08.89,1.82.89h7.33c.72,0,1.4-.34,1.84-.92l.02-.02.02-.04c.29-.4.44-.87.44-1.36v-5.02Zm-1.79-1.33l1.06,1.33-1.06.71v-2.04Zm-7.8,2.39V.6h7.2v5.1s0,.03.01.04l-2.06,1.39-.75-.67c-.46-.4-1.14-.4-1.59,0l-.75.67-2.06-1.39s0-.03,0-.04Zm-.6-2.39v2.04l-1.06-.71,1.06-1.33Zm-1.03,7.09c-.11-.23-.17-.48-.17-.73v-4.4l3.39,2.27-3.22,2.86Zm8.89,1H2.33c-.46,0-.9-.18-1.22-.5l4.49-3.98c.23-.2.57-.2.79,0l4.49,3.98c-.32.32-.76.5-1.22.5Zm1.74-1.73c0,.26-.06.51-.17.73l-3.22-2.86,3.39-2.28v4.4Z"/>
			<path d="m6,5.5c1.1,0,1.99-.89,1.99-1.99,0-.9-.6-1.67-1.43-1.91h.01c.12-.08.16-.23.08-.36v-.02s-.02,0-.02-.01c-.08-.09-.21-.11-.32-.05l-.71.41c-.06.03-.1.09-.12.15-.02.07,0,.13.04.22,0,0,.03.03.04.04s.02.02.03.02l.67.47s.03.02.04.02c.11.05.24.01.31-.09,0,0,.02-.03.02-.04.03-.06.03-.13,0-.19.49.24.83.75.83,1.34,0,.82-.67,1.49-1.48,1.49s-1.48-.67-1.48-1.49c0-.46.21-.89.57-1.17,0,0,.02-.02.03-.03.08-.08.1-.22.03-.31-.08-.12-.23-.15-.36-.07l-.06.05c-.46.38-.72.94-.72,1.53,0,1.1.89,1.99,1.99,1.99Z"/>
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
