import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from 'components/Atoms/Modal/Modal';
import SvgInformation from 'components/Atoms/SVG/Icons/SvgInformation';

import styles from './PopupIcon.module.scss';

const PopupIcon = props => {
	const [isOpenPopup, setIsOpenPopup] = useState(false);

	const openPopup = (event) => {
		event.stopPropagation();
		setIsOpenPopup(true);
	};

	return (
		<>
			<div
				onClick={openPopup}
				className={classNames(
					styles.icon,
					props.inHeadline ? styles.inHeadline : null
				)}
			>
				<SvgInformation />
			</div>
			<Modal
				isOpen={isOpenPopup}
				onClose={() => {
					setIsOpenPopup(false);
				}}
				content={props.content}
			/>
		</>
	);
};

PopupIcon.propTypes = {
	content: PropTypes.any,
	inHeadline: PropTypes.any
};

export default PopupIcon;
