import React, { useEffect, useRef, useState } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { find, findIndex } from 'lodash';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { useKeycodeUtils } from 'utils/keycodes/keycodes';
import { useViewUtils } from 'utils/view/view';

import SvgChevronDownLink from 'components/Atoms/SVG/Icons/SvgChevronDownLink';

import CSSIcon from 'components/Atoms/Icon/CSS/CSSIcon';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';

import styles from './DropdownInput.module.scss';
import inputStyles from '../index.module.scss';

export const DropdownInput = (props) => {
	const { getReturn, getTab, getArrowUp, getArrowDown, getAuml, getOuml, getUuml } = useKeycodeUtils();
	const { isMobile, isDesktop } = useViewUtils();
	const [id] = useState(uuid());
	const [pseudoItemsOpen, setPseudoItemsOpen] = useState(false);
	const [cursor, setCursor] = useState(-1);

	const [isMobileView, _setIsMobileView] = useState(false);
	const isMobileViewRef = useRef(isMobileView);
	const setIsMobileView = (data) => {
		isMobileViewRef.current = data;
		_setIsMobileView(data);
	};

	const wrapperRef = useRef(null);
	const selectRef = useRef(null);
	const selectPseudoRef = useRef(null);
	const selectPseudoItemsRef = useRef(null);

	useEffect(() => {
		checkMobile();
		document.addEventListener('mousedown', handleClickOutside);
		window.addEventListener('resize', checkMobile);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			window.removeEventListener('resize', checkMobile);
		};
	}, []);

	const checkMobile = () => {
		if (isMobile() && !isMobileViewRef.current) {
			setIsMobileView(true);
		} else if (isDesktop() && isMobileViewRef.current) {
			setIsMobileView(false);
		}
	};

	const handleClickOutside = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setPseudoItemsOpen(false);
		}
	};

	const onChange = (event) => {
		props.setValue(event.target.value);
	};

	const togglePseudoItems = () => {
		setPseudoItemsOpen(!pseudoItemsOpen);
	};

	useEffect(() => {
		if (pseudoItemsOpen) {
			setCursorByValue(props.value);
		} else {
			setCursor(-1);
		}
	}, [pseudoItemsOpen]);

	const onChangePseudoItem = (value, setNewCursor = false) => {
		props.setValue(value);
		setPseudoItemsOpen(false);
		if (selectPseudoRef.current) {
			selectPseudoRef.current.focus();
		}
		if (setNewCursor) {
			setCursorByValue(value);
		}
	};

	const handleKeyDownPseudoSelect = (event) => {
		if (getTab(event)) {
			setPseudoItemsOpen(false);
		} else if (pseudoItemsOpen) {
			if (getReturn(event)) {
				if (cursor >= 0) {
					onChangePseudoItem(props.items[cursor].value, false);
				} else {
					setPseudoItemsOpen(false);
				}
			} else if (cursor >= 0 && getArrowUp(event)) {
				event.preventDefault();
				const newCursor = cursor - 1;
				setCursor(newCursor);
				scrollToItem(newCursor, true);
			} else if (cursor < props.items.length - 1 && getArrowDown(event)) {
				event.preventDefault();
				const newCursor = cursor + 1;
				setCursor(newCursor);
				scrollToItem(newCursor, true);
			} else {
				let char = '';
				if (getAuml(event)) {
					char = 'ä';
				} else if (getOuml(event)) {
					char = 'ö';
				} else if (getUuml(event)) {
					char = 'ü';
				} else {
					char = String.fromCharCode(event.keyCode).toLowerCase();
				}

				if (char && char.length === 1) {
					const newCursor = findIndex(props.items, (item) => {
						const formattedItem = (item.text + '')
							.replace(/[^a-zA-ZäöüßÄÖÜ0-9]/g, '')
							.toLocaleLowerCase('de-DE');
						return formattedItem.charAt(0) === char;
					});
					if (newCursor > -1) {
						setCursor(newCursor);
						scrollToItem(newCursor, true);
					}
				}
			}
		} else if (getReturn(event)) {
			setPseudoItemsOpen(true);
		}
	};

	const scrollToItem = (index, directly = false) => {
		const scrollBoxNode = findDOMNode(selectPseudoItemsRef.current);
		const offset = 38 * 2;
		if (scrollBoxNode.children?.length > index) {
			const itemNode = scrollBoxNode.children[index];
			if (itemNode) {
				scrollBoxNode.scrollTo({
					top: itemNode.offsetTop - offset,
					behavior: directly ? 'auto' : 'smooth'
				});
			} else {
				scrollBoxNode.scrollTo({ top: -offset, behavior: 'smooth' });
			}
		} else {
			scrollBoxNode.scrollTo({ top: -offset, behavior: 'smooth' });
		}
	};

	const inputClasses = (additionalClasses) => {
		let classes = [
			inputStyles.input,
			styles.input,
			props.size === 's' ? inputStyles.s : inputStyles.m,
			props.size === 's' ? styles.s : styles.m,
			!props.value || props.value.length === 0 ? styles.default : null,
			props.value && props.value.length > 0 ? inputStyles.active : null,
			props.value && props.value.length > 0 ? styles.active : null,
			props.hasError ? inputStyles.error : null,
			props.hasError ? 'input--error' : null,
			props.tooltip ? inputStyles.hasTooltip : null
		];
		if (additionalClasses && additionalClasses.length > 0) {
			classes = classes.concat(additionalClasses);
		}
		return classes;
	};

	const getLabelByValue = (value) => {
		const item = find(props.items, { value: value });
		return item ? item.text : '';
	};

	const getIconByValue = (value) => {
		const item = find(props.items, { value: value });
		if (item && item.icon) {
			return (
				<div className={styles.pseudoItemIcon}>
					<CSSIcon name={item.icon} />
				</div>
			);
		} else {
			return <></>;
		}
	};

	const setCursorByValue = (value) => {
		const index = findIndex(props.items, { value: value });
		setCursor(index);
		scrollToItem(index, true);
	};

	return (
		<div ref={wrapperRef} className={classNames([
			inputStyles.wrapper,
			!!props.message ? inputStyles.hasMessage : null
		])}>
			<select
				ref={selectRef}
				id={id}
				className={classNames(inputClasses())}
				value={props.value ?? ''}
				onChange={onChange}
				data-testid={props.testId ?? null}
				tabIndex={
					!props.disabled && (props.tabindex || props.tabindex === 0) && isMobileView
						? props.tabindex
						: undefined
				}
				disabled={props.disabled}
			>
				<option disabled value="">
					Bitte wählen
				</option>
				{props.items && props.items.length > 0 && (
					<>
						{props.items.map((item, index) => (
							<option key={id + '-' + index} value={item.value}>
								{item.text}
							</option>
						))}
					</>
				)}
			</select>

			<div
				ref={selectPseudoRef}
				className={classNames(
					inputClasses([
						styles.pseudoSelect,
						pseudoItemsOpen ? styles.pseudoSelectOpen : null
					])
				)}
				tabIndex={
					!props.disabled && (props.tabindex || props.tabindex === 0) && !isMobileView
						? props.tabindex
						: undefined
				}
				onClick={togglePseudoItems}
				onKeyDown={handleKeyDownPseudoSelect}
			>
				<div className={styles.pseudoSelectValue}>
					{getIconByValue(props.value)}
					<div className={styles.pseudoSelectValueText}>
						{getLabelByValue(props.value)}
					</div>
				</div>
			</div>
			<div
				ref={selectPseudoItemsRef}
				className={classNames([
					styles.pseudoItems,
					props.smallItemBox ? styles.smallItemBox : null,
					props.hasError ? styles.error : null,
					pseudoItemsOpen ? styles.pseudoItemsOpen : null
				])}
			>
				<>
					{props.items.map((item, index) => (
						<div
							key={id + '-pseudo-item-' + index}
							className={classNames([
								styles.pseudoItem,
								props.value === item.value ? styles.pseudoItemActive : null,
								cursor === index ? styles.pseudoItemCursor : null
							])}
							onClick={() => onChangePseudoItem(item.value, true)}
						>
							{item.icon ? (
								<div className={styles.pseudoItemIcon}>
									<CSSIcon name={item.icon} />
								</div>
							) : (
								<></>
							)}
							<div className={styles.pseudoSelectValueText}>
								{item.text}
							</div>
						</div>
					))}
				</>
			</div>

			<label htmlFor={id} className={classNames([inputStyles.label, styles.label])}>
				{props.label}
			</label>
			<div className={styles.arrow}>
				<SvgChevronDownLink />
			</div>
			{props.tooltip && (
				<div className={inputStyles.tooltip}>
					<TooltipIcon text={props.tooltip} />
				</div>
			)}
			{!!props.message && (
				<div
					className={classNames([
						inputStyles.message,
						props.message ? inputStyles.show : null
					])}
				>
					{props.message}
				</div>
			)}
		</div>
	);
};

DropdownInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	placeholder: PropTypes.string,
	items: PropTypes.array,
	size: PropTypes.string,
	smallItemBox: PropTypes.bool,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	testId: PropTypes.string
};

export default DropdownInput;
