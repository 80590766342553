import React from 'react';
import { m } from 'utils/messages/messages';

import { Title } from 'components/Atoms/Text';
import SantanderBranchHint from 'components/Atoms/Partials/SantanderBranchHint/SantanderBranchHint';

const NoUs = () => {
	return (
		<>
			<Title>{m('pages.noUs.title', 'global')}</Title>
			<SantanderBranchHint link={m('general.contactSubsidiaryLink', 'global')} />
		</>
	);
};
export default NoUs;
