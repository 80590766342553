import React from 'react';
import PropTypes from 'prop-types';

import { m } from 'utils/messages/messages';
import { useCreditUtils } from 'utils/credit/credit';

const Runtime = (props) => {
	const { summedRuntime } = useCreditUtils();

	return (
		<>
			{summedRuntime()}{props.showMonthLabel && ' ' + m('month.plural', 'fields')}
		</>
	);
};

Runtime.propTypes = {
	showMonthLabel: PropTypes.bool
};

export default Runtime;
