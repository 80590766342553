import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Text } from 'components/Atoms/Text';
import SvgChevronRightThin from 'components/Atoms/SVG/Icons/SvgChevronRightThin';

import styles from './NextSteps.module.scss';

const NextSteps = (props) => {
	return (
		<div className={styles.wrapper}>
			{props.title && (
				<Text size="l"><strong>{props.title}:</strong></Text>
			)}
			{props.subtitle && (
				<Text>{props.subtitle}</Text>
			)}
			<div className={styles.row}>
				{props.steps.map((step, index) => (
					<div
						key={'next-steps-' + index}
						className={classNames([
							styles.itemWrapper,
							!step ? styles.hideItem : null,
							props.steps.length < 3 ? styles.noGrow : null
						])}
					>
						{step && (
							<>
								<div className={classNames([
									styles.item,
									props.steps.length < 3 ? styles.noGrow : null
								])}>
									<div className={styles.title}>
										<Text>{step}</Text>
									</div>
								</div>

								{index < props.steps.length - 1 && (
									<div className={styles.arrow}>
										<SvgChevronRightThin />
									</div>
								)}
							</>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

NextSteps.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	steps: PropTypes.array
};

export default NextSteps;
