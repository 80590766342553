export const useTinUtils = () => {
	const isTinValid = (tin) => {
		// See https://de.wikipedia.org/wiki/Steuerliche_Identifikationsnummer#Aufbau_der_Identifikationsnummer for details
		if (tin.charAt(0) === '0') {
			return false;
		}

		let firstTenDigits = tin.substring(0, 10);

		let product = 0;
		[...firstTenDigits].forEach((n) => {
			let sum = (parseInt(n) + product) % 10;
			if (sum === 0) {
				sum = 10;
			}
			product = (sum * 2) % 11;
		});

		let checkDigit = 11 - product === 10 ? 0 : 11 - product;

		return checkDigit === parseInt(tin.charAt(10));
	};

	return {
		isTinValid
	};
};
