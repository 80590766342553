import history from 'config/history';
import C from 'constants/Constants';
import { useEnvUtils } from 'utils/env/env';
import { useSelector } from 'react-redux';
import { useUtils } from 'utils/utils';

export const usePageUtils = () => {
	const { getCurrentFlow } = useUtils();
	const { isLocal } = useEnvUtils();

	const currentFlow = getCurrentFlow();

	const storeInitialPageLoad = useSelector((state => state.app.initialPageLoad));
	const storeVcJwtToken = useSelector((state => state.app.vcJwtToken));
	const storeCapsUploadDone = useSelector((state => state.app.capsUploadDone));
	const storeWebIdStarted = useSelector((state => state.app.webIdStarted));
	const storeWebIdFinished = useSelector((state => state.app.webIdFinished));

	const goToPage = (path, queryString) => {
		history.push({
			pathname: path,
			search: queryString,
			state: { undefined, isFromNav: true } // TODO: remove when setting reload from store
		});
	};

	const replaceSubpageVariable = (subpage) => {
		let formattedSubapage = subpage;
		if (subpage) {
			formattedSubapage = formattedSubapage.replace(
				':accessToken',
				'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTWF4IE11c3Rlcm1hbm4iLCJlbWFpbCI6InRlc3RAdGVzdC5kZSJ9.pGmlFu0fesZMd3NyUa9e_KGMfwSMDHyi_HFy4psH0hk'
			);
			formattedSubapage = formattedSubapage.replace(':isRegistration', 'registrierung');
			formattedSubapage = formattedSubapage.replace(':isReminder', 'reminder');
			formattedSubapage = formattedSubapage.replace(':retry', 'retry');
		}
		return formattedSubapage;
	};

	const preventHistoryBackFor = (preventedLocation) => {
		history.listen((newLocation, action) => {
			if (action === 'POP' && newLocation.pathname === preventedLocation) {
				history.go(1);
			}
		});
	};

	const preventHistoryBackWithNewLocation = (newLocation) => {
		if (history.action === 'POP') {
			goToPage(newLocation);
		}
	};

	const checkIsFromNavDestination = (destination) => {
		if (!isLocal()) {
			checkIsFromNavPush(destination);
		}
	};

	const checkIsFromNav = () => {
		if (!isLocal()) {
			if (
				storeVcJwtToken &&
				!storeCapsUploadDone &&
				!storeWebIdStarted &&
				!storeWebIdFinished
			) {
				checkIsFromNavPush(currentFlow + C.ROUTES.BCO_RESPONSE_GREEN);
			} else if (
				storeVcJwtToken &&
				storeCapsUploadDone &&
				!storeWebIdStarted &&
				!storeWebIdFinished
			) {
				checkIsFromNavPush(currentFlow + C.ROUTES.IDENTIFICATION);
			} else if (
				storeVcJwtToken &&
				storeCapsUploadDone &&
				storeWebIdStarted &&
				!storeWebIdFinished
			) {
				checkIsFromNavPush(currentFlow + C.ROUTES.IDENTIFICATION);
			} else if (
				storeVcJwtToken &&
				storeCapsUploadDone &&
				storeWebIdStarted &&
				storeWebIdFinished
			) {
				checkIsFromNavPush(currentFlow + C.ROUTES.WEB_ID_DONE);
			} else {
				checkIsFromNavPush(currentFlow + C.ROUTES.NATIONALITY);
			}
		}
	};

	const checkIsFromNavPush = (destination) => {
		if ((history && typeof history.location.state === 'undefined') || storeInitialPageLoad) {
			if (history.location.pathname !== destination) {
				history.push(destination);
			}
		}
	};

	return {
		goToPage,
		replaceSubpageVariable,
		preventHistoryBackFor,
		preventHistoryBackWithNewLocation,
		checkIsFromNavDestination,
		checkIsFromNav
	};
};
