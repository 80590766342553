import { animateScroll as scroll } from 'react-scroll';

export const useScrollUtils = () => {
	const scrollTo = (ref, offset) => {
		try {
			const rect = ref.current.getBoundingClientRect();
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			const finalOffset = offset ? offset : 0;
			scroll.scrollTo(rect.top + scrollTop - finalOffset, {
				duration: 500
			});
		} catch (e) {}
	};

	const toggleBodyScroll = (disabled) => {
		let body = document.getElementsByTagName('body')[0];

		if (disabled) {
			body.classList.add('no-scroll');
		} else {
			body.classList.remove('no-scroll');
		}
	};

	return {
		scrollTo,
		toggleBodyScroll
	};
};
