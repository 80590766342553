import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 135.35;
	const height = 14.22;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>>
			<path d="M4.76,9.52c-.66,0-1.19-.54-1.19-1.19s.53-1.19,1.19-1.19,1.19,.53,1.19,1.19-.53,1.19-1.19,1.19m3.57,1.78V5.35H1.19v5.95h7.14m0-7.14c.66,0,1.19,.53,1.19,1.19v5.95c0,.66-.53,1.19-1.19,1.19H1.19c-.66,0-1.19-.54-1.19-1.19V5.35c0-.66,.53-1.19,1.19-1.19h.59v-1.19C1.78,1.33,3.12,0,4.76,0s2.97,1.33,2.97,2.97v1.19h.59M4.76,1.19c-.99,0-1.78,.8-1.78,1.78v1.19h3.57v-1.19c0-.99-.8-1.78-1.78-1.78Z" />
			<path d="M20.65,11.17c-1.03,0-1.88-.19-2.53-.58,0-.37,.05-.7,.15-.99,.72,.4,1.52,.6,2.41,.6,.65,0,1.17-.14,1.55-.42,.38-.28,.57-.67,.57-1.17,0-.47-.14-.83-.41-1.1-.27-.26-.73-.52-1.37-.77l-.72-.29c-.69-.27-1.22-.61-1.59-1.02s-.56-.96-.56-1.63c0-.79,.26-1.39,.79-1.82,.53-.43,1.3-.64,2.31-.64,.87,0,1.66,.15,2.37,.45-.04,.33-.1,.65-.19,.95-.74-.28-1.46-.42-2.15-.42-.65,0-1.13,.13-1.46,.4-.33,.26-.5,.62-.5,1.07,0,.48,.12,.83,.37,1.07,.25,.23,.67,.47,1.26,.7l.74,.29c.7,.27,1.25,.61,1.66,1.02,.41,.41,.61,.97,.61,1.68,0,.8-.29,1.43-.86,1.92s-1.4,.72-2.47,.72Z" />
			<path d="M26.85,2.22c-.13,.13-.3,.2-.52,.2s-.39-.07-.52-.2-.21-.31-.21-.52,.07-.4,.21-.54,.31-.21,.52-.21,.38,.07,.52,.21c.13,.14,.2,.32,.2,.54s-.07,.4-.2,.53Zm-1.07,8.75V3.8h1.11v7.17h-1.11Z" />
			<path d="M32.1,11.14c-1.03,0-1.85-.32-2.46-.96s-.91-1.57-.91-2.78,.31-2.2,.92-2.83c.61-.63,1.45-.95,2.5-.95,.61,0,1.14,.1,1.6,.3,0,.3-.04,.61-.11,.93-.44-.19-.92-.28-1.43-.28-.73,0-1.31,.25-1.72,.74-.41,.49-.62,1.19-.62,2.09s.2,1.55,.61,2.05c.41,.5,.98,.76,1.71,.76,.59,0,1.11-.14,1.55-.41,0,.25-.06,.57-.16,.98-.37,.23-.87,.34-1.5,.34Z" />
			<path d="M38.85,3.63c.76,0,1.34,.22,1.73,.66s.59,1.05,.59,1.84v4.85h-1.11V6.46c0-.63-.12-1.1-.37-1.43-.25-.33-.66-.49-1.25-.49-.67,0-1.26,.29-1.78,.87v5.55h-1.11V.92c.3-.11,.67-.17,1.11-.17V4.89c.51-.84,1.24-1.26,2.19-1.26Z" />
			<path d="M49,6.82c0,.42-.02,.74-.07,.94h-4.81c.12,1.64,.94,2.47,2.44,2.47,.78,0,1.5-.21,2.17-.62-.02,.35-.07,.7-.17,1.03-.62,.34-1.31,.5-2.07,.5-1.18,0-2.06-.33-2.65-1-.59-.67-.89-1.6-.89-2.8,0-1.1,.26-2,.79-2.68,.53-.68,1.32-1.03,2.37-1.03,.94,0,1.65,.29,2.14,.87,.49,.58,.74,1.35,.74,2.33Zm-2.92-2.28c-.58,0-1.04,.2-1.37,.6-.33,.4-.53,.97-.59,1.7h3.74v-.04c0-.69-.15-1.24-.44-1.65-.3-.41-.74-.62-1.33-.62Z" />
			<path d="M53.91,3.64c.33,0,.61,.04,.85,.12,0,.41-.04,.73-.12,.98-.25-.06-.54-.09-.87-.09-.72,0-1.29,.27-1.71,.81v5.51h-1.11V3.8h1.05l.05,1.07c.42-.82,1.05-1.23,1.87-1.23Z" />
			<path d="M61.46,6.82c0,.42-.02,.74-.07,.94h-4.81c.12,1.64,.94,2.47,2.44,2.47,.78,0,1.5-.21,2.17-.62-.02,.35-.07,.7-.17,1.03-.62,.34-1.31,.5-2.07,.5-1.18,0-2.06-.33-2.65-1-.59-.67-.89-1.6-.89-2.8,0-1.1,.26-2,.79-2.68,.53-.68,1.32-1.03,2.37-1.03,.94,0,1.65,.29,2.14,.87,.49,.58,.74,1.35,.74,2.33Zm-2.92-2.28c-.58,0-1.04,.2-1.37,.6-.33,.4-.53,.97-.59,1.7h3.74v-.04c0-.69-.15-1.24-.44-1.65-.3-.41-.74-.62-1.33-.62Z" />
			<path d="M72.07,1.52h1.15l-3.06,9.45h-1.29l-3.08-9.45h1.23l2.49,8.3,2.55-8.3Z" />
			<path d="M79.66,6.82c0,.42-.02,.74-.07,.94h-4.81c.12,1.64,.94,2.47,2.44,2.47,.78,0,1.5-.21,2.17-.62-.02,.35-.08,.7-.17,1.03-.62,.34-1.31,.5-2.07,.5-1.17,0-2.06-.33-2.65-1-.59-.67-.89-1.6-.89-2.8,0-1.1,.26-2,.79-2.68,.53-.68,1.32-1.03,2.37-1.03,.94,0,1.65,.29,2.14,.87,.49,.58,.74,1.35,.74,2.33Zm-2.92-2.28c-.58,0-1.04,.2-1.37,.6-.33,.4-.53,.97-.59,1.7h3.74v-.04c0-.69-.15-1.24-.44-1.65-.3-.41-.74-.62-1.33-.62Z" />
			<path d="M84.56,3.64c.33,0,.61,.04,.85,.12,0,.41-.04,.73-.12,.98-.25-.06-.54-.09-.87-.09-.72,0-1.29,.27-1.71,.81v5.51h-1.11V3.8h1.05l.05,1.07c.42-.82,1.05-1.23,1.87-1.23Z" />
			<path d="M89.89,3.63c.88,0,1.58,.31,2.09,.94s.77,1.54,.77,2.74-.28,2.16-.86,2.83c-.57,.67-1.44,1-2.6,1-.91,0-1.74-.14-2.48-.41V.92c.31-.11,.68-.17,1.11-.17V4.75c.45-.75,1.1-1.13,1.96-1.13Zm-.61,6.6c1.56,0,2.35-.96,2.35-2.88s-.68-2.81-2.05-2.81c-.62,0-1.17,.26-1.64,.78v4.71c.37,.13,.82,.2,1.35,.2Z" />
			<path d="M95.76,2.22c-.13,.13-.3,.2-.52,.2s-.39-.07-.52-.2-.21-.31-.21-.52,.07-.4,.21-.54,.31-.21,.52-.21,.38,.07,.52,.21,.2,.32,.2,.54-.07,.4-.2,.53Zm-1.07,8.75V3.8h1.11v7.17h-1.11Z" />
			<path d="M101.48,3.63c.76,0,1.34,.22,1.73,.66,.39,.44,.59,1.05,.59,1.84v4.85h-1.11V6.46c0-.63-.12-1.1-.37-1.43-.25-.33-.66-.49-1.25-.49-.67,0-1.26,.29-1.78,.87v5.55h-1.11V3.8h1.03l.07,1.11c.52-.86,1.25-1.29,2.2-1.29Z" />
			<path d="M110.49,.92c.32-.11,.69-.17,1.11-.17V10.97h-1.03l-.07-.98c-.42,.77-1.08,1.15-1.98,1.15s-1.58-.32-2.09-.95c-.51-.63-.77-1.54-.77-2.74s.28-2.16,.86-2.83c.57-.67,1.44-1,2.62-1,.45,0,.9,.04,1.35,.11V.92Zm-1.64,9.3c.63,0,1.18-.26,1.64-.77V4.74c-.44-.13-.89-.2-1.34-.2-1.57,0-2.36,.96-2.36,2.88,0,.92,.18,1.62,.53,2.09,.35,.48,.86,.72,1.52,.72Z" />
			<path d="M118.43,3.8h1.11v7.17h-1.03l-.07-1.09c-.53,.84-1.27,1.26-2.21,1.26-.76,0-1.33-.22-1.72-.66-.39-.44-.58-1.05-.58-1.84V3.8h1.11v4.51c0,.63,.12,1.1,.37,1.43,.25,.33,.66,.49,1.23,.49,.66,0,1.26-.29,1.79-.87V3.8Z" />
			<path d="M125.23,3.63c.76,0,1.34,.22,1.73,.66,.39,.44,.59,1.05,.59,1.84v4.85h-1.11V6.46c0-.63-.12-1.1-.37-1.43-.25-.33-.66-.49-1.25-.49-.67,0-1.26,.29-1.78,.87v5.55h-1.11V3.8h1.03l.07,1.11c.52-.86,1.25-1.29,2.2-1.29Z" />
			<path d="M132.89,3.63c.93,0,1.75,.14,2.46,.42v6.88c0,1.05-.28,1.86-.83,2.43s-1.38,.85-2.48,.85c-.71,0-1.39-.15-2.05-.44,.02-.39,.06-.72,.12-.99,.58,.31,1.22,.46,1.91,.46s1.25-.19,1.64-.56c.39-.37,.58-.92,.58-1.64v-1.03c-.43,.75-1.09,1.13-1.96,1.13s-1.58-.32-2.09-.95c-.51-.63-.77-1.54-.77-2.74s.28-2.16,.86-2.83c.57-.67,1.44-1,2.62-1Zm-.29,6.6c.63,0,1.18-.26,1.64-.77V4.74c-.42-.13-.87-.2-1.34-.2-1.57,0-2.36,.96-2.36,2.88,0,.92,.18,1.62,.53,2.09,.35,.48,.86,.72,1.52,.72Z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
