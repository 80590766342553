import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';

import SvgChevronDownLink from 'components/Atoms/SVG/Icons/SvgChevronDownLink';

import styles from './BoxCollapsible.module.scss';
import { Link, Text } from 'components/Atoms/Text';

const BoxCollapsible = props => {
	const [active, setActive] = useState(!!props.showOnMount);

	return (
		<div className={classNames([styles.box, active ? styles.active : null, !!props.contentBody ? styles.hasBody : null, props.stretch ? styles.stretch : null])}>
			<div className={styles.header}>
				{props.icon && (
					<div className={styles.headerIcon}>
						{props.icon}
					</div>
				)}
				<div className={styles.headerContent}>
					<div className="mb-10">
						<Text size="l"><strong>{props.title}</strong></Text>
					</div>
					{props.subtitle && <Text>{props.subtitle}</Text>}
					{props.contentHeader && (
						<div className={styles.headerContentExtension}>
							{props.contentHeader}
						</div>
					)}
				</div>
			</div>
			{props.contentBody && (
				<>
					<div className={styles.body}>
						<div className={styles.divider} />
						{props.contentBody}
					</div>
					<div className={styles.toggle}>
						<div className={active ? styles.active : null}>
							<Text>
								<Link onClick={() => { setActive(!active); }}>
									{active ? m('box.close', 'fields') : m('box.readMore', 'fields') }
									<SvgChevronDownLink />
								</Link>
							</Text>
						</div>
					</div>
				</>
			)}
		</div>
	);

};

BoxCollapsible.propTypes = {
	icon: PropTypes.element,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	contentHeader: PropTypes.element,
	contentBody: PropTypes.element,
	showOnMount: PropTypes.bool,
	stretch: PropTypes.bool
};

export default BoxCollapsible;
