import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useItemsUtils } from 'utils/items/items';

import { Text } from 'components/Atoms/Text';
import { CheckboxInput, RadioButtonInput } from 'components/Atoms/Form';

import styles from './StockProficiencyExperiences.module.scss';

const StockProficiencyExperiences = (props) => {
	const { getCurrentFlow } = useUtils();
	const { getStockProficiencyFrenquencyItems, getStockProficiencyVolumeItems } = useItemsUtils();

	const currentFlow = getCurrentFlow();

	const stockProficiencyFrenquencyItems = getStockProficiencyFrenquencyItems();
	const stockProficiencyVolumeItems = getStockProficiencyVolumeItems();

	useEffect(() => {
		if (!props.value) {
			props.setValueFrequency(null);
			props.setValueVolume(null);
		}
		if (props.value && props.valueFrequency === 'ZERO') {
			props.setValueVolume(null);
		}
	}, [props.value, props.valueFrequency]);

	return (
		<>
			<CheckboxInput
				value={props.value}
				setValue={props.setValue}
				label={props.label}
				hasError={!!props.message}
				message={props.message}
				tabindex={props.tabindex}
				testId={props.testId}
			/>
			{props.value && (
				<div className={styles.details}>
					<div className={styles.box}>
						<Text mb><strong>{m('stockProficiency.experiences', currentFlow)}</strong></Text>

						<Text size="s" mb>{m('stockProficiency.frequencies.label', 'fields')}</Text>
						<RadioButtonInput
							value={props.valueFrequency}
							setValue={props.setValueFrequency}
							items={stockProficiencyFrenquencyItems}
							hasError={!!props.frequencyError}
							message={props.frequencyError}
							btnStyle="button"
							tabindex={props.tabindex + 1}
							testId={`${props.testId}-frequency`}
						/>

						{props.valueFrequency && props.valueFrequency !== 'ZERO' && (
							<>
								<Text size="s" mb>{m('stockProficiency.volumes.label', 'fields')}</Text>
								<RadioButtonInput
									value={props.valueVolume}
									setValue={props.setValueVolume}
									items={stockProficiencyVolumeItems}
									hasError={!!props.volumeError}
									message={props.volumeError}
									btnStyle="button"
									tabindex={props.tabindex + 2}
									testId={`${props.testId}-volume`}
								/>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

StockProficiencyExperiences.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	value: PropTypes.bool,
	setValue: PropTypes.func,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	valueFrequency: PropTypes.string,
	setValueFrequency: PropTypes.func,
	frequencyError: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	valueVolume: PropTypes.string,
	setValueVolume: PropTypes.func,
	volumeError: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	testId: PropTypes.string
};

export default StockProficiencyExperiences;
