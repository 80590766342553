import React, { useMemo } from 'react';
import { useFlowUtils } from 'utils/flow/flow';

import srcHyundaiLogoDesktop from 'assets/img/hyundai_capital_direkt_bank_logo.png';
import srcHyundaiLogoMobile from 'assets/img/hyundai_capital_direkt_bank_logo_icon.png';

import styles from './PartnerLogo.module.scss';

const PartnerLogo = (props) => {
	const { isGmkHyundai } = useFlowUtils();

	const alt = useMemo(() => {
		if (isGmkHyundai) return 'Hyundai Capital Direkt Bank';
		return null;
	}, [isGmkHyundai]);

	return (
		<>
			{isGmkHyundai && (
				<div className={styles.wrapper}>
					<img className={styles.logoDesktop} src={srcHyundaiLogoDesktop} alt={alt} />
					<img className={styles.logoMobile} src={srcHyundaiLogoMobile} alt={alt} />
				</div>
			)}
		</>
	);
};
export default PartnerLogo;
