import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 12;
	const height = 12;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="m3.32.6s.02,0,.03,0c.02,0,.04,0,.07.01h.02s.02.02.02.02c.02,0,.03.02.05.03l.02.02.04.05.02.04.02.06v.05s0,3.04,0,3.04c.03.16-.08.32-.24.35-.16.03-.32-.08-.35-.24,0-.02,0-.04,0-.05v-.05s0-2.46,0-2.46C1.79,2.45.93,4.26.93,6c0,2.81,2.27,5.1,5.07,5.1.17,0,.3.13.3.3,0,.17-.13.3-.3.3C2.88,11.7.33,9.14.33,6c0-1.74.79-3.54,1.96-4.67l.13-.12H.3C.13,1.2,0,1.07,0,.9,0,.75.11.63.25.6h.05s3.02,0,3.02,0Zm2.68-.3c3.12,0,5.67,2.54,5.67,5.67,0,1.73-.79,3.52-1.96,4.64l-.13.12h2.12c.17,0,.3.13.3.3,0,.15-.1.27-.25.3h-.05s-3.02,0-3.02,0c-.05,0-.09-.01-.13-.04h-.01s-.01-.02-.01-.02c-.03-.02-.06-.05-.08-.08l-.02-.02-.02-.04-.02-.06v-.05s0-3.02,0-3.02c0-.17.13-.3.3-.3.15,0,.27.1.3.25v.05s0,2.44,0,2.44c1.23-.97,2.08-2.76,2.08-4.49,0-2.79-2.27-5.07-5.07-5.07-.17,0-.3-.13-.3-.3s.13-.3.3-.3Z"/>
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
