import React from 'react';
import PropTypes from 'prop-types';

import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

const EffectiveYearlyInterestRate = (props) => {
	const { formatMoneyValue } = useNumberUtils();
	const { getEffectiveYearlyInterestRate } = useCreditUtils();

	return (
		<>
			{props.isDecimal
				? formatMoneyValue(getEffectiveYearlyInterestRate(), true)
				: getEffectiveYearlyInterestRate()}
		</>
	);
};

EffectiveYearlyInterestRate.propTypes = {
	isDecimal: PropTypes.bool
};

export default EffectiveYearlyInterestRate;
