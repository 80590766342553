import React from 'react';
import PropTypes from 'prop-types';

import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

const NetAmount = (props) => {
	const { formatMoneyValue } = useNumberUtils();
	const { summedNetAmount } = useCreditUtils();

	return (
		<>
			{props.isDecimal
				? formatMoneyValue(summedNetAmount(), true)
				: summedNetAmount()}
		</>
	);
};

NetAmount.propTypes = {
	isDecimal: PropTypes.bool
};

export default NetAmount;
