import React from 'react';

const Svg = () => {
	const width = 83;
	const height = 43;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<defs>
				<path id='postidentLetter_svg__a' d='M0 41.944h82.353V0H0z' />
			</defs>
			<g fill='none' fillRule='evenodd' transform='translate(0 1)'>
				<path
					fill='#D2F0F2'
					d='M73.318 41.944H22.723c-2.277 0-4.123-1.705-4.123-3.809V9.331c0-2.104 1.846-3.809 4.123-3.809h50.595c2.276 0 4.122 1.705 4.122 3.809v28.804c0 2.104-1.846 3.809-4.122 3.809'
				/>
				<path
					fill='#FFF'
					d='M77.73 36.922H27.135c-2.276 0-4.123-1.704-4.123-3.808V4.309c0-2.105 1.847-3.81 4.123-3.81H77.73c2.276 0 4.123 1.705 4.123 3.81v28.805c0 2.104-1.847 3.809-4.123 3.809'
				/>
				<path
					stroke='#C3DEE7'
					d='M77.73 36.922H27.135c-2.276 0-4.123-1.704-4.123-3.808V4.309c0-2.105 1.847-3.81 4.123-3.81H77.73c2.276 0 4.123 1.705 4.123 3.81v28.805c0 2.104-1.847 3.809-4.123 3.809z'
				/>
				<path
					stroke='#9BC3D3'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth={2.166}
					d='M56.116 9.757h15.701'
				/>
				<path
					stroke='#DEEDF2'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth={1.444}
					d='M56.116 15.547h15.701m-15.701 5.791h15.701'
				/>
				<path
					fill='#9BC3D3'
					d='M45.451 11.604l-.862 6.165c-.636 1.755-1.948 2.62-3.934 2.594-1.987-.027-3.12-.923-3.402-2.69l-.746-6.186c.244-2.465 1.751-3.679 4.52-3.643 2.77.036 4.243 1.29 4.424 3.76m-4.574 16.483c3.679 0 6.436-1.248 9-3.495-1.791-2.468-3.567-3.053-5.831-2.36l-2.601 1.149c-.476.21-.99.442-1.59.264-.647-.194-1.5-1.022-2.873-1.495-1.913-.661-3.516-.727-5.86 1.747a13.532 13.532 0 009.755 4.19'
				/>
				<path
					stroke='#9BC3D3'
					strokeLinecap='round'
					strokeLinejoin='round'
					d='M.5 3.562c2.733 0 2.733 2.411 5.467 2.411 2.732 0 2.732-2.41 5.465-2.41 2.734 0 2.734 2.41 5.468 2.41 2.737 0 2.737-2.41 5.473-2.41 2.736 0 2.736 2.41 5.473 2.41M.5 6.888c2.733 0 2.733 2.411 5.467 2.411 2.732 0 2.732-2.41 5.465-2.41 2.734 0 2.734 2.41 5.468 2.41 2.737 0 2.737-2.41 5.473-2.41 2.736 0 2.736 2.41 5.473 2.41M.5 10.214c2.733 0 2.733 2.412 5.467 2.412 2.732 0 2.732-2.412 5.465-2.412 2.734 0 2.734 2.412 5.468 2.412 2.737 0 2.737-2.412 5.473-2.412 2.736 0 2.736 2.412 5.473 2.412M.5 13.54c2.733 0 2.733 2.412 5.467 2.412 2.732 0 2.732-2.411 5.465-2.411 2.734 0 2.734 2.41 5.468 2.41 2.737 0 2.737-2.41 5.473-2.41 2.736 0 2.736 2.41 5.473 2.41'
				/>
				<mask id='postidentLetter_svg__b' fill='#fff'>
					<use xlinkHref='#postidentLetter_svg__a' />
				</mask>
				<path
					fill='#FFF'
					d='M46.025 34.526H81.32v-3.09H46.025z'
					mask='url(#postidentLetter_svg__b)'
				/>
			</g>
		</svg>
	);
};
export default Svg;

