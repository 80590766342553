import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0 1c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9zm4.001 4.23a.501.501 0 1 1 .885.459l-.042.08-5.153 8.071a.497.497 0 0 1-.372.227l-.05.003a.499.499 0 0 1-.299-.099l-.06-.054-3.77-3.916a.5.5 0 0 1 .652-.753l.068.06 3.33 3.458L16 7.23z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
