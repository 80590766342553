import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 12;
	const height = 12;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="m11.96,9.67v-5s0-.04,0-.04v-.03s-.02-.02-.02-.02l-.02-.05h0s0-.02,0-.02l-1.73-2.17V.3c0-.17-.13-.3-.3-.3H2.06c-.17,0-.3.13-.3.3v2.04L.03,4.51h0s0,.02,0,.02v.03s-.03.04-.04.07c0,.01,0,.03,0,.04v.03s0,4.97,0,4.97c0,.49.15.94.41,1.31.01.03.03.07.05.09.02.02.04.03.06.04.43.54,1.08.89,1.82.89h7.33c.72,0,1.4-.34,1.84-.92l.03-.02.02-.04c.29-.39.44-.87.44-1.36h0Zm-1.8-6.36l1.06,1.33h0s-1.06.71-1.06.71v-2.04Zm-7.8,2.4V.6h7.2v5.1s0,.03,0,.04l-2.06,1.38-.75-.67c-.45-.4-1.14-.4-1.59,0l-.75.67-2.06-1.38s0-.03,0-.04Zm-.6-2.4v2.04l-1.06-.71,1.06-1.33Zm-1.03,7.09c-.11-.23-.17-.48-.17-.73v-4.4l3.39,2.28-3.22,2.86Zm8.9,1H2.29c-.46,0-.9-.18-1.22-.51l4.49-3.99c.23-.2.57-.2.79,0l4.49,3.98c-.32.32-.76.51-1.22.51Zm1.73-1.73h0c0,.26-.06.51-.17.73l-3.22-2.86,3.39-2.28v4.4Z"/>
			<path d="m5.54,5.44s.02,0,.03,0c.1,0,.19-.06.24-.14l2.47-3.35c.05-.07.06-.16.04-.25-.02-.09-.07-.16-.14-.21-.07-.05-.17-.06-.25-.04-.08.02-.16.07-.2.14l-2.23,3.01-1.16-1.19c-.13-.13-.34-.13-.47,0-.13.13-.14.34,0,.47l1.44,1.48c.06.07.15.1.24.1Z"/>
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
