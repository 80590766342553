/** Actions Type */
export const SET_HAS_STOCK_PROFICIENCY = 'SET_HAS_STOCK_PROFICIENCY';

export const SET_BONDS = 'SET_BONDS';
export const SET_BONDS_FREQUENCY = 'SET_BONDS_FREQUENCY';
export const SET_BONDS_VOLUME = 'SET_BONDS_VOLUME';

export const SET_STOCKS = 'SET_STOCKS';
export const SET_STOCKS_FREQUENCY = 'SET_STOCKS_FREQUENCY';
export const SET_STOCKS_VOLUME = 'SET_STOCKS_VOLUME';

export const SET_FUND_OF_FUNDS = 'SET_FUND_OF_FUNDS';
export const SET_FUND_OF_FUNDS_FREQUENCY = 'SET_FUND_OF_FUNDS_FREQUENCY';
export const SET_FUND_OF_FUNDS_VOLUME = 'SET_FUND_OF_FUNDS_VOLUME';

export const SET_OPEN_PROPERTY_FUNDS = 'SET_OPEN_PROPERTY_FUNDS';
export const SET_OPEN_PROPERTY_FUNDS_FREQUENCY = 'SET_OPEN_PROPERTY_FUNDS_FREQUENCY';
export const SET_OPEN_PROPERTY_FUNDS_VOLUME = 'SET_OPEN_PROPERTY_FUNDS_VOLUME';

export const SET_CERTIFICATES = 'SET_CERTIFICATES';
export const SET_CERTIFICATES_FREQUENCY = 'SET_CERTIFICATES_FREQUENCY';
export const SET_CERTIFICATES_VOLUME = 'SET_CERTIFICATES_VOLUME';

export const SET_DERIVATIVES = 'SET_DERIVATIVES';
export const SET_DERIVATIVES_FREQUENCY = 'SET_DERIVATIVES_FREQUENCY';
export const SET_DERIVATIVES_VOLUME = 'SET_DERIVATIVES_VOLUME';

export const RESET = 'RESET';

/** Actions Creators */
export const setHasStockProficiency = (payload) => ({
	type: SET_HAS_STOCK_PROFICIENCY,
	payload
});

export const setBonds = (payload) => ({
	type: SET_BONDS,
	payload
});
export const setBondsFrequency = (payload) => ({
	type: SET_BONDS_FREQUENCY,
	payload
});
export const setBondsVolume = (payload) => ({
	type: SET_BONDS_VOLUME,
	payload
});

export const setStocks = (payload) => ({
	type: SET_STOCKS,
	payload
});
export const setStocksFrequency = (payload) => ({
	type: SET_STOCKS_FREQUENCY,
	payload
});
export const setStocksVolume = (payload) => ({
	type: SET_STOCKS_VOLUME,
	payload
});

export const setFundOfFunds = (payload) => ({
	type: SET_FUND_OF_FUNDS,
	payload
});
export const setFundOfFundsFrequency = (payload) => ({
	type: SET_FUND_OF_FUNDS_FREQUENCY,
	payload
});
export const setFundOfFundsVolume = (payload) => ({
	type: SET_FUND_OF_FUNDS_VOLUME,
	payload
});

export const setOpenPropertyFunds = (payload) => ({
	type: SET_OPEN_PROPERTY_FUNDS,
	payload
});
export const setOpenPropertyFundsFrequency = (payload) => ({
	type: SET_OPEN_PROPERTY_FUNDS_FREQUENCY,
	payload
});
export const setOpenPropertyFundsVolume = (payload) => ({
	type: SET_OPEN_PROPERTY_FUNDS_VOLUME,
	payload
});

export const setCertificates = (payload) => ({
	type: SET_CERTIFICATES,
	payload
});
export const setCertificatesFrequency = (payload) => ({
	type: SET_CERTIFICATES_FREQUENCY,
	payload
});
export const setCertificatesVolume = (payload) => ({
	type: SET_CERTIFICATES_VOLUME,
	payload
});

export const setDerivates = (payload) => ({
	type: SET_DERIVATIVES,
	payload
});
export const setDerivatesFrequency = (payload) => ({
	type: SET_DERIVATIVES_FREQUENCY,
	payload
});
export const setDerivatesVolume = (payload) => ({
	type: SET_DERIVATIVES_VOLUME,
	payload
});

export const reset = (payload) => ({
	type: RESET,
	payload
});
