import * as React from 'react';

const SvgLogoIconGrey = props => (
	<svg width={20} height={19} viewBox='0 0 20 19' {...props}>
		<path
			fill='#ADBDC0'
			d='M14.337 8.7a4.339 4.339 0 00-.576-1.978l-3.132-5.417A4.355 4.355 0 0110.133 0L10 .226a4.329 4.329 0 000 4.333l2.505 4.335a4.329 4.329 0 010 4.334l-.133.226a4.355 4.355 0 00-.496-1.31L9.588 8.17 8.124 5.643a4.342 4.342 0 01-.496-1.31l-.133.226a4.33 4.33 0 00-.008 4.32L10 13.229a4.329 4.329 0 010 4.333l-.133.226a4.355 4.355 0 00-.496-1.31l-3.126-5.421A4.338 4.338 0 015.668 8.7C2.316 9.576 0 11.439 0 13.583 0 16.574 4.477 19 10 19s10-2.426 10-5.417c.003-2.144-2.31-4.007-5.663-4.883z'
		/>
	</svg>
);

export default SvgLogoIconGrey;
