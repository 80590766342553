import React from 'react';

const Svg = () => {
	const width = 237;
	const height = 155;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<defs>
				<filter
					x='-14.1%'
					y='-22.2%'
					width='128.2%'
					height='144.4%'
					filterUnits='objectBoundingBox'
					id='card_svg__a'
				>
					<feOffset dy={7} in='SourceAlpha' result='shadowOffsetOuter1' />
					<feGaussianBlur
						stdDeviation={4.5}
						in='shadowOffsetOuter1'
						result='shadowBlurOuter1'
					/>
					<feColorMatrix
						values='0 0 0 0 0.607843137 0 0 0 0 0.764705882 0 0 0 0 0.82745098 0 0 0 0.359320367 0'
						in='shadowBlurOuter1'
						result='shadowMatrixOuter1'
					/>
					<feMerge>
						<feMergeNode in='shadowMatrixOuter1' />
						<feMergeNode in='SourceGraphic' />
					</feMerge>
				</filter>
				<path id='card_svg__b' d='M0 .768h219.227v136.895H0z' />
			</defs>
			<g
				filter='url(#card_svg__a)'
				transform='translate(9 1)'
				fill='none'
				fillRule='evenodd'
			>
				<g transform='translate(0 .361)'>
					<mask id='card_svg__c' fill='#fff'>
						<use xlinkHref='#card_svg__b' />
					</mask>
					<path
						d='M210.95 137.663H8.277c-4.572 0-8.278-3.669-8.278-8.193V8.96C0 4.437 3.705.769 8.277.769H210.95c4.57 0 8.277 3.668 8.277 8.193V129.47c0 4.524-3.706 8.193-8.277 8.193'
						fill='#FFF'
						mask='url(#card_svg__c)'
					/>
				</g>
				<path
					d='M144.801 41.706c-.196-2.34-.785-4.678-2.062-6.63l-10.6-18.424c-.786-1.365-1.374-2.924-1.768-4.486l-.49.78c-2.651 4.485-2.651 10.236 0 14.723l8.637 14.72c2.65 4.486 2.65 10.237 0 14.723l-.49.778c-.294-1.557-.981-3.116-1.767-4.482l-7.853-13.454-5.006-8.678c-.785-1.364-1.375-2.924-1.767-4.485l-.49.782c-2.651 4.482-2.651 10.041 0 14.72l8.637 14.723c2.65 4.483 2.65 10.234 0 14.72l-.49.78c-.294-1.558-.982-3.118-1.767-4.484l-10.798-18.427c-1.374-2.439-2.061-5.265-2.061-8.09-11.387 2.922-19.337 9.26-19.337 16.572 0 10.236 15.312 18.427 34.257 18.427s34.258-8.19 34.258-18.427c.294-7.021-7.558-13.455-19.043-16.381'
					fill='#9BC3D3'
				/>
				<path
					fill='#9BC2D2'
					opacity={0.352}
					d='M10.747 99.546H65.05v-6.432H10.747zM10.747 110.113h64.777v-6.432H10.747zM10.747 129.715h95.844v-6.431H10.747z'
				/>
				<path
					fill='#2D4369'
					opacity={0.479}
					d='M10.747 120.726h11.97v-3.748h-11.97zM117.187 120.726h21.145v-3.748h-21.145z'
				/>
				<path
					fill='#9BC2D2'
					opacity={0.352}
					d='M117.34 129.715h18.283v-6.431H117.34z'
				/>
				<g opacity={0.474} stroke='#4B6066' strokeWidth={0.75}>
					<path d='M46.06 68.779v3.385h-3.42' />
					<path d='M35.059 72.164H23.686' strokeDasharray='6.651,6.651' />
					<path d='M19.896 72.164h-3.42v-3.385' />
					<path d='M16.476 63.163v-8.424' strokeDasharray='4.977,4.977' />
					<path d='M16.476 51.932v-3.385h3.42' />
					<path d='M27.477 48.547H38.85' strokeDasharray='6.651,6.651' />
					<path d='M42.64 48.547h3.42v3.385' />
					<path d='M46.06 57.547v8.424' strokeDasharray='4.977,4.977' />
				</g>
				<path
					d='M191.862 113.835c0 7.86-6.437 14.233-14.377 14.233s-14.38-6.372-14.38-14.233c0-7.86 6.44-14.232 14.38-14.232s14.377 6.372 14.377 14.232'
					fill='#E51E25'
					opacity={0.577}
				/>
				<path
					d='M209.46 113.835c0 7.86-6.437 14.233-14.378 14.233s-14.378-6.372-14.378-14.233c0-7.86 6.437-14.232 14.378-14.232 7.94 0 14.378 6.372 14.378 14.232'
					fill='#DDEBF0'
					opacity={0.693}
				/>
			</g>
		</svg>
	);
};
export default Svg;
