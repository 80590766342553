import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Text.module.scss';

const Text = (props) => {
	const color = () => {
		switch (props.color) {
			case 'light':
				return 'text--light';
			case 'highlight':
				return 'text--highlight';
			case 'white':
				return 'text--white';
			default:
				return 'text--primary';
		}
	};

	const size = () => {
		switch (props.size) {
			case 'xl':
				return 'text--size--xl';
			case 'l':
				return 'text--size--l';
			case 's':
				return 'text--size--s';
			case 'xs':
				return 'text--size--xs';
			default:
				return 'text--size--m';
		}
	};

	return (
		<div
			className={classNames([
				styles.wrapper,
				props.prependIcon ? styles.textPrependIcon : null,
				props.appendIcon ? styles.textAppendIcon : null,
				props.isSmallIcon ? styles.smallIcon : null,
				props.noWrap ? styles.noWrap : null,
				props.mb ? styles.mb : null
			])}
		>
			<p className={classNames([styles.text, size(), color()])}>
				{props.prependIcon && (
					<span className={classNames([styles.icon, styles.prependIcon])}>
						{props.prependIcon}
					</span>
				)}
				{props.children}
				{props.appendIcon && (
					<span className={classNames([styles.icon, styles.appendIcon])}>
						{props.appendIcon}
					</span>
				)}
			</p>
		</div>
	);
};

Text.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
	prependIcon: PropTypes.any,
	appendIcon: PropTypes.any,
	isSmallIcon: PropTypes.bool,
	mb: PropTypes.bool,
	noWrap: PropTypes.bool,
	children: PropTypes.any
};

export default Text;
