import React from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useLegitimationApi } from 'api/legitimation';

import { Link, Title, Text } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import DownloadContracts from 'components/Atoms/Partials/DownloadContracts/DownloadContracts';
import SvgDownloadLink from 'components/Atoms/SVG/Icons/SvgDownloadLink';

const PostIdent = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid } = useTrackingUtils();
	const { getPostidentCoupon } = useLegitimationApi();

	const currentFlow = getCurrentFlow();

	const getCoupon = async () => {
		await getPostidentCoupon(
			() => {},
			() => {}
		);
	};

	const onSubmit = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
	};

	return (
		<>
			<Title>{m('pages.postIdent.title', 'global')}</Title>
			<Text mb>{m('pages.postIdent.subtitle', 'global')}</Text>
			<Text mb>
				<Link onClick={getCoupon}>
					<SvgDownloadLink />
					{m('pages.postIdent.link.coupon', 'global')}
				</Link>
			</Text>
			<DownloadContracts />
			<Form
				onSubmit={onSubmit}
				submitText={m('pages.postIdent.submit', 'global')}
				submitTabindex={1}
			/>
		</>
	);
};
export default PostIdent;
