import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { DEFAULT_INPUT } from 'constants/Regex';
import { useRefUtils } from 'utils/refs/refs';

import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';

import inputStyles from '../index.module.scss';

export const TextInput = forwardRef((props, ref) => {
	const [id] = useState(uuid());
	const inputRef = ref ? ref : useRef(null);

	const { focusRef } = useRefUtils();

	const [prevValue, setPrevValue] = useState(props.value ?? '');

	useEffect(() => {
		setPrevValue(props.value);
	}, [props.value]);

	const getType = () => {
		return props.type ?? 'text';
	};

	const onChange = (event) => {
		if (props.format) {
			props.format(event, prevValue, ref, (formattedValue) => {
				onChangeValue(formattedValue);
			});
		} else {
			onChangeValue(event.target.value);
		}
	};

	const onChangeValue = (value) => {
		if (props.regex) {
			if (props.regex.test(value) || value === '') {
				props.setValue(value);
				jumpToNextOnMaxLength(value);
			}
		} else {
			if (DEFAULT_INPUT.test(value) || value === '') {
				props.setValue(value);
				jumpToNextOnMaxLength(value);
			}
		}
	};

	const jumpToNextOnMaxLength = (value) => {
		if (props.nextRefOnMaxLength && props.maxLength && value.length >= props.maxLength) {
			focusRef(inputRef, props.nextRefOnMaxLength);
		}
	};

	return (
		<div
			className={
				classNames([
					inputStyles.wrapper,
					!!props.message ? inputStyles.hasMessage : null
				])
			}
		>
			<input
				ref={inputRef}
				id={id}
				className={
					classNames([
						inputStyles.input,
						props.value && props.value.length > 0 ? inputStyles.active : null,
						props.hasError ? inputStyles.error : null,
						props.hasError ? 'input--error' : null,
						props.tooltip ? inputStyles.hasTooltip : null,
						props.suffix ? inputStyles.hasSuffix : null
					])
				}
				value={props.value ?? ''}
				onChange={onChange}
				type={getType()}
				disabled={props.disabled}
				maxLength={props.maxLength}
				pattern={props.type === 'number' ? '\\d*' : null}
				name={props.autoComplete ?? null}
				autoComplete={props.autoComplete ?? id ?? 'off'}
				tabIndex={props.tabindex}
				data-testid={props.testId ?? null}
			/>
			<label htmlFor={id} className={inputStyles.label}>
				{props.label}
			</label>
			{props.tooltip && (
				<div className={inputStyles.tooltip}>
					<TooltipIcon text={props.tooltip} />
				</div>
			)}
			{props.suffix && <div className={inputStyles.suffix}>{props.suffix}</div>}
			{!!props.message && (
				<div
					className={classNames([
						inputStyles.message,
						props.message ? inputStyles.show : null
					])}
				>
					{props.message}
				</div>
			)}
		</div>
	);
});

TextInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	type: PropTypes.string,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	maxLength: PropTypes.number,
	nextRefOnMaxLength: PropTypes.any,
	autoComplete: PropTypes.string,
	name: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	regex: PropTypes.any,
	format: PropTypes.func,
	testId: PropTypes.string
};

export default TextInput;
