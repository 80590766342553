import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M2 5.028c0-1.612 1.26-2.93 2.85-3.023L5.028 2h13.946a3.026 3.026 0 0 1 3.02 2.848l.006.178v9.462a3.028 3.028 0 0 1-3.029 3.028h-4.839L14.906 21l.599.001a.5.5 0 0 1 .492.41l.008.09a.5.5 0 0 1-.41.492l-.09.008h-7a.5.5 0 0 1-.09-.992l.09-.008h.599l.774-3.484H5.026A3.026 3.026 0 0 1 2 14.49V5.028zm11.107 12.488h-2.204L10.128 21h3.754l-.775-3.483zM21 14H3v.49c0 1.12.907 2.026 2.026 2.026h13.945c1.12 0 2.029-.908 2.029-2.028V14zM19.125 3.006L18.974 3H5.028a2.028 2.028 0 0 0-2.022 1.877L3 5.028V13l4.015.001A1.251 1.251 0 0 1 7 12.807v-.41c0-1.158 1.29-2.013 2.761-2.013.165 0 .349.02.574.057l.518.093c.15.028.301.06.452.098l.325.09.146.043.128.03a.556.556 0 0 0 .095.008l.037-.002.113-.02.17-.043.41-.114c.561-.148 1.085-.24 1.508-.24 1.557 0 2.763.832 2.763 2.014v.41c0 .065-.005.13-.015.193H21V5.025a2.027 2.027 0 0 0-1.72-2.003l-.155-.017zm-4.888 8.378c-.273 0-.642.059-1.048.156l-.315.081-.172.049c-.332.094-.512.133-.703.133a1.69 1.69 0 0 1-.459-.066l-.285-.084a5.982 5.982 0 0 0-.578-.134l-.503-.091a2.725 2.725 0 0 0-.413-.044C8.777 11.384 8 11.9 8 12.396v.41c0 .112.065.184.122.184h7.756c.057 0 .122-.071.122-.183v-.41c0-.533-.696-1.013-1.763-1.013zm-2.24-7.394c1.369 0 2.57 1.059 2.46 2.352l-.264 2.186c-.171 1.118-1.057 1.881-2.195 1.881-1.081 0-1.933-.688-2.164-1.73l-.03-.167-.24-2c-.225-1.38 1.014-2.522 2.434-2.522zm0 1c-.806 0-1.482.582-1.455 1.262l.252 2.125c.096.631.561 1.032 1.204 1.032.598 0 1.042-.346 1.178-.888l.026-.129.246-2.04c.115-.709-.595-1.362-1.45-1.362z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
