import React from 'react';
import SvgIllustrationShortCredit from 'components/Atoms/SVG/Illustrations/SvgIllustrationShortCredit';

import styles from './CreditAmount.module.scss';

const CreditAmountBackground = () => {
	return (
		<div className={styles.background}>
			<div className={styles.backgroundInner}>
				<SvgIllustrationShortCredit />
			</div>
		</div>
	);
};
export default CreditAmountBackground;
