/** Actions Type */
export const SET_HAS_PPI = 'SET_HAS_PPI';
export const SET_PPI_ALREADY_EXISTS = 'SET_PPI_ALREADY_EXISTS';
export const SET_PROTECT = 'SET_PROTECT';
export const SET_PROTECT_RUNTIME = 'SET_PROTECT_RUNTIME';
export const SET_PROTECT_MONTHLY_RATE = 'SET_PROTECT_MONTHLY_RATE';

/** Actions Creators */
export const setHasPpi = payload => ({
	type: SET_HAS_PPI,
	payload
});

export const setPpiAlreadyExists = payload => ({
	type: SET_PPI_ALREADY_EXISTS,
	payload
});

export const setProtect = payload => ({
	type: SET_PROTECT,
	payload
});

export const setProtectRuntime = payload => ({
	type: SET_PROTECT_RUNTIME,
	payload
});

export const setProtectMonthlyRate = payload => ({
	type: SET_PROTECT_MONTHLY_RATE,
	payload
});
