import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import Check from 'components/Atoms/Partials/Check/Check';
import SvgCheckMedium from 'components/Atoms/SVG/Icons/SvgCheckMedium';
import PopupIcon from 'components/Atoms/Partials/PopupIcon/PopupIcon';

import styles from './Table.module.scss';

const Col = props => {
	const id = uuid();

	const rowType = row => {
		if (row.type === 'text') {
			return (
				<div className={styles.colLabel}>
					<p>
						{row.strong ? <strong>{row.content}</strong> : <>{row.content}</>}
					</p>
					{row.popup && (
						<div className={styles.popupIcon}>
							<PopupIcon
								content={row.popup}
							/>
						</div>
					)}
				</div>
			);
		} else if (row.type === 'headline') {
			return (
				<div className={styles.colHeadline}>
					<p>{row.strong ? <strong>{row.content}</strong> : <>{row.content}</>}</p>
				</div>
			);
		} else if (row.type === 'check') {
			return (
				<div className={styles.colCheck}>
					<SvgCheckMedium />
				</div>
			);
		} else if (
			row.type === 'select' &&
			props.onClick
		) {
			return (
				<div className={styles.colBtn}>
					<div className={styles.colBtnInner}>
						<p className={styles.colBtnHeadline}>{row.text}</p>
						<input
							className={styles.colBtnInput}
							id={id}
							type="radio"
							name="protect"
							value={props.value}
							onChange={() => { props.onClick(props.value); }}
							checked={props.selected}
							tabIndex={props.tabindex}
						/>
						<label htmlFor={id} className={styles.colBtnLabel}>
							<Check active={props.selected} />
						</label>
					</div>
				</div>
			);
		} else {
			return <div className={styles.colEmpty} />;
		}
	};

	return (
		<div
			className={classNames([
				styles.col,
				props.isFirst ? styles.colIsFirst : null,
				props.highlightLabel ? styles.colWithHighlightLabel : null
			])}
			onClick={() => {
				if (props.onClick) {
					props.onClick(props.value);
				}
			}}
			data-testid={props.testId ? props.testId : null}
		>
			{props.highlightLabel && (
				<div className={styles.highlightLabel}>
					<p>{props.highlightLabel}</p>
				</div>
			)}
			{props.rows.map((row, i) => (
				<div
					key={id + '-' + i}
					className={styles.colRow}
				>
					{rowType(row)}
				</div>
			))}
		</div>
	);
};

Col.propTypes = {
	value: PropTypes.string,
	onClick: PropTypes.func,
	rows: PropTypes.array,
	highlightLabel: PropTypes.string,
	isFirst: PropTypes.bool,
	selected: PropTypes.bool,
	tabindex: PropTypes.number,
	testId: PropTypes.string
};

export default Col;
