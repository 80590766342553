/** Actions Type */
export const UPDATE_DISPO_AMOUNT = 'UPDATE_DISPO_AMOUNT';
export const UPDATE_DISPO_STATUS = 'UPDATE_DISPO_STATUS';
const UPDATE_LIMIT_APPLICATION_ID = 'UPDATE_LIMIT_APPLICATION_ID';

/** Actions Creators */
export const onUpdateDispoAmount = payload => ({
	type: UPDATE_DISPO_AMOUNT,
	payload
});

export const onUpdateDispoStatus = payload => ({
	type: UPDATE_DISPO_STATUS,
	payload
});

export const updateLimitApplicationId = payload => ({
	type: UPDATE_LIMIT_APPLICATION_ID,
	payload
});
