import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './StickyHeaderMobile.module.scss';

const StickyHeaderMobile = (props) => {
	const [isSticky, setIsSticky] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const offset = window.scrollY;
			if (offset > 85) {
				setIsSticky(true);
			} else {
				setIsSticky(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<div className={classNames(styles.header, isSticky ? styles.headerSticky : null)}>
				{props.children}
			</div>
			<div className={classNames(styles.spacer, isSticky ? styles.spacerSticky : null)}>
				{props.children}
			</div>
		</>
	);
};

StickyHeaderMobile.propTypes = {
	children: PropTypes.any
};

export default StickyHeaderMobile;
