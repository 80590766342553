import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M18.079 5c1.034 0 1.974.4 2.675 1.054a.45.45 0 0 1 .152.151 3.904 3.904 0 0 1 1.087 2.475L22 8.92v6.188a3.89 3.89 0 0 1-3.69 3.886l-.201.005H5.89a3.89 3.89 0 0 1-3.886-3.69L2 15.108V8.921a3.922 3.922 0 0 1 3.72-3.916L5.92 5H18.08zm-3.56 7.642l-1.192 1.099a1.996 1.996 0 0 1-2.521.107l-.139-.113-1.187-1.093-5.484 4.65a2.879 2.879 0 0 0 1.725.703l.17.005h12.218a2.88 2.88 0 0 0 1.896-.708l-5.485-4.65zM3.566 7.194c-.316.43-.517.95-.557 1.513L3 8.921v6.188c0 .51.132.99.364 1.406L8.74 11.96 3.565 7.194zm16.872.004l-5.175 4.762 5.374 4.554c.206-.37.333-.789.359-1.235l.005-.17V8.921c0-.573-.165-1.107-.45-1.558l-.113-.165zM18.08 6H5.92c-.606 0-1.168.184-1.635.5l7.052 6.494a.995.995 0 0 0 1.222.081l.096-.075 7.06-6.498a2.904 2.904 0 0 0-1.412-.493L18.08 6z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
