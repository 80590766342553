import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M8.65 18.35a.48.48 0 0 0 .7 0l6-6a.48.48 0 0 0 0-.7l-6-6a.48.48 0 0 0-.7 0 .48.48 0 0 0 0 .7L14.29 12l-5.64 5.65a.48.48 0 0 0 0 .7z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
