import _ from 'lodash';
import { UPDATE_DISPO_AMOUNT, UPDATE_DISPO_STATUS } from './actions';
import C from 'constants/Constants';

const defaultState = {
	amount: 500,
	status: C.DISPO_STATUS.NA,
	limitApplicationId: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case UPDATE_DISPO_AMOUNT:
			return _.merge({}, state, { amount: action.payload });
		case UPDATE_DISPO_STATUS:
			return _.merge({}, state, { status: action.payload });
		case 'UPDATE_LIMIT_APPLICATION_ID':
			return _.merge({}, state, {
				limitApplicationId: action.payload
			});
		default:
			return state;
	}
};

export default reducers;
