import React, { useState } from 'react';
import classNames from 'classnames';
import C from 'constants/Constants';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { IBAN_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { usePageUtils } from 'utils/page/page';
import { useIbanUtils } from 'utils/iban/iban';
import { useFlowUtils } from 'utils/flow/flow';

import { Link, Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { RadioButtonInput, TextInput } from 'components/Atoms/Form';
import Divider from 'components/Atoms/Divider/Divider';
import SvgChevronDownLink from 'components/Atoms/SVG/Icons/SvgChevronDownLink';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setReferenceIban, setIsSantanderIban, setPaymentType } from 'store/BankAccount/actions';

import styles from './BankAccount.module.scss';

const FinancialStatus = () => {
	const { getCurrentFlow, isStaticSubType, isCreditCardSubType, isCreditSubType, isBestCardFamilySubType } = useUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, addErrorMessage, hasIbanError } = useValidationUtils();
	const { continueInvalid, continueValid, ecommerceAddPaymentInfo, ecommerceAddToCart } = useTrackingUtils();
	const { goToPage } = usePageUtils();
	const { ibanContainsSantanderBlz, formatIbanForInput, isGermanIban, trimIban } = useIbanUtils();
	const { gmkHyundaiMsgPrefix } = useFlowUtils();

	const paymentTypeItems = [
		{ text: m('paymentType.items.teilzahlung', 'fields'), value: 'TEILZAHLUNG' },
		{ text: m('paymentType.items.vollzahlung', 'fields'), value: 'VOLLZAHLUNG' }
	];

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeReferenceIban = useSelector((state => state.bankAccount.referenceIban));
	const storePaymentType = useSelector((state => state.bankAccount.paymentType));
	const storeHasPreviousAddress = useSelector((state => state.address.hasPreviousAddress));
	const storeExistingOldIban = useSelector((state => state.bankAccount.existingOldIban));

	const [paymentTypeIsOpen, setLaterPayoutIsOpen] = useState(true);

	const [ibanError, setIbanError] = useState(null);
	const [paymentTypeError, setPaymentTypeError] = useState(null);

	const clearErrors = () => {
		setIbanError(null);
		setPaymentTypeError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isIbanValid = true;

		if (isEmpty(storeReferenceIban)) {
			setIbanError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else {
			isIbanValid = !(hasIbanError(storeReferenceIban));
			if (!isIbanValid) {
				setIbanError(m('validation.error.invalid', 'fields'));
			} else if (isStaticSubType(null, [C.FRONTEND.GIRO, C.FRONTEND.DEPOT])) {
				let isGermanIbanValid = isGermanIban(storeReferenceIban);
				if (!isGermanIbanValid) {
					isIbanValid = false;
					setIbanError(m('validation.error.invalid', 'fields'));
					addErrorMessage(FormErrorCode.IBAN, m('validation.error.iban.notGerman', 'fields'));
				}
			} else if (currentFlow === C.FRONTEND.VISACARD) {
				if (trimIban(storeExistingOldIban).slice(-10) === trimIban(storeReferenceIban).slice(-10)) {
					isIbanValid = false;
					setIbanError(m('validation.error.invalid', 'fields'));
					addErrorMessage(FormErrorCode.IBAN, m('validation.error.iban.sameLikeExisting', 'fields'));
				}
			}
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields && isIbanValid;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = async () => {
		continueValid();

		if (isBestCardFamilySubType()) {
			const isSantanderIban = await ibanContainsSantanderBlz(storeReferenceIban);
			await dispatch(setIsSantanderIban(isSantanderIban));
			ecommerceAddToCart(true);
		}

		ecommerceAddPaymentInfo();

		let nextPage = C.ROUTES.TAX_INFORMATION;
		if (currentFlow === C.FRONTEND.GMK || currentFlow === C.FRONTEND.SPARBRIEF) {
			nextPage = C.ROUTES.TIN;
		} else if (currentFlow === C.FRONTEND.VISACARD) {
			nextPage = C.ROUTES.PAYMENT_PROTECTION_INSURANCE;
		} else if (isCreditCardSubType(null, [C.FRONTEND.VISACARD, C.FRONTEND.BEST_CARD_EXTRA, C.FRONTEND.BEST_CARD_PREMIUM])) {
			nextPage = C.ROUTES.FINANCIAL_STATUS;
		} else if (isCreditCardSubType(null, [C.FRONTEND.VISACARD, C.FRONTEND.BEST_CARD_BASIC, C.FRONTEND.BEST_CARD_SMART])) {
			nextPage = process.env.REACT_APP_ENABLE_BEST_CARD_LIMIT === 'true' ? C.ROUTES.CARD_OPTIONS : C.ROUTES.FINANCIAL_STATUS;
		} else if (isCreditSubType()) {
			nextPage = C.ROUTES.FINANCIAL_STATUS_DAC;
		}
		goToPage(currentFlow + nextPage);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const onPrevButton = () => {
		if (currentFlow === C.FRONTEND.VISACARD) {
			if (storeHasPreviousAddress) {
				goToPage(currentFlow + C.ROUTES.PREVIOUS_ADDRESS);
			} else {
				goToPage(currentFlow + C.ROUTES.ADDRESS);
			}
		} else {
			goToPage(currentFlow + C.ROUTES.OCCUPATION);
		}
	};

	const autofill = () => {
		dispatch(setReferenceIban(C.DUMMY_IBAN_2));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title>
				{
					isCreditSubType()
						? m('bankAccount.title', currentFlow)
						: m('pages.bankAccount.title', 'global')
				}
			</Title>
			<Text mb>{m(`${gmkHyundaiMsgPrefix}bankAccount.subtitle`, currentFlow)}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={2}
				onPrevButton={onPrevButton}
			>
				<TextInput
					value={storeReferenceIban}
					setValue={(value) => { dispatch(setReferenceIban(value)); }}
					label={m('iban.label', 'fields')}
					hasError={!!ibanError}
					message={ibanError ?? m('iban.placeholder', 'fields')}
					regex={IBAN_INPUT}
					format={formatIbanForInput}
					maxLength={40}
					tabindex={1}
					testId="iban"
				/>
				{isBestCardFamilySubType() && (
					<>
						<Divider />
						<div
							className={classNames([
								styles.paymentTypeLink,
								paymentTypeIsOpen ? styles.paymentTypeLinkActive : null
							])}
						>
							<Text>
								<Link
									onClick={() => {
										setLaterPayoutIsOpen(!paymentTypeIsOpen);
									}}
								>
									{m('paymentType.label', 'fields')}
									<SvgChevronDownLink />
								</Link>
							</Text>
						</div>
						{paymentTypeIsOpen && (
							<>
							<Divider noLine noMarginBottom />
							<RadioButtonInput
								value={storePaymentType}
								setValue={(value) => { dispatch(setPaymentType(value)); }}
								items={paymentTypeItems}
								hasError={!!paymentTypeError}
								message={paymentTypeError}
								tabindex={2}
								testId="payment-type"
							/>
							{storePaymentType && (
								<>
									{storePaymentType === 'TEILZAHLUNG' ? (
										<Text mb>{m('paymentType.hint.teilzahlung', 'fields')}</Text>
									) : (
										<Text mb>{m('paymentType.hint.vollzahlung', 'fields')}</Text>
									)}
								</>
							)}
							</>
						)}
					</>
				)}
			</Form>
		</>
	);
};
export default FinancialStatus;
