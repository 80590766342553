import React, { useEffect } from 'react';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useEnvUtils } from 'utils/env/env';

import Routes from 'components/Routes/Routes';
import Dev from 'components/Dev/Dev';

const App = () => {
	const { showDebug } = useEnvUtils();
	const { initConsent } = useTrackingUtils();

	useEffect(() => {
		initConsent();
	}, []);

	return (
		<>
			<Routes />
			{showDebug() && <Dev />}
		</>
	);
};
export default App;
