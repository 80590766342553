import React from 'react';
import { m } from 'utils/messages/messages';

import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import SvgEmail from 'components/Atoms/SVG/Icons/SvgEmail';
import SvgIllustrationPostbox from 'components/Atoms/SVG/Illustrations/SvgIllustrationPostbox';

import { useSelector } from 'react-redux';

import styles from './EmailSent.module.scss';

const EmailSent = () => {
	const storeEmail = useSelector((state => state.email.email));

	return (
		<>
			<Row>
				<Col noShrink noGrow>
					<SvgEmail color="#ec0000" />
				</Col>
				<Col grow>
					<Text mb>
						{m('pages.emailSent.pretitle', 'global', { email: storeEmail }, true)}
					</Text>
				</Col>
			</Row>
			<Row>
				<Col grow>
					<Title>{m('pages.emailSent.title', 'global', null, true)}</Title>
				</Col>
				<Col noShrink noGrow>
					<div className={styles.illustration}>
						<SvgIllustrationPostbox />
					</div>
				</Col>
			</Row>
			<Text>{m('pages.emailSent.subtitle', 'global')}</Text>
		</>
	);
};
export default EmailSent;
