import React from 'react';
import PropTypes from 'prop-types';
import { useEnvUtils } from 'utils/env/env';

import SvgDocumentEdit from 'components/Atoms/SVG/Icons/SvgDocumentEdit';

import styles from './Autofill.module.scss';

const Autofill = (props) => {
	const { showAutofill } = useEnvUtils();

	const clickAutofill = () => {
		if (props.autofill) {
			props.autofill();
		}
	};

	return (
		<>
			{showAutofill() ? (
				<div
					className={styles.button}
					onClick={clickAutofill}
					title="Formular automatisch ausfüllen"
				>
					<SvgDocumentEdit />
				</div>
			) : <></>}
		</>
	);
};

Autofill.propTypes = {
	autofill: PropTypes.func
};

export default Autofill;
