import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from 'components/Atoms/Tooltip/Tooltip';
import Modal from 'components/Atoms/Modal/Modal';
import SvgInformation from 'components/Atoms/SVG/Icons/SvgInformation';
import SvgHelp from 'components/Atoms/SVG/Icons/SvgHelp';

import styles from './TooltipIcon.module.scss';

const TooltipIcon = (props) => {

	const [modalIsOpen, setModalIsOpen] = useState(false);

	const getIcon = () => {
		if (props.type === 'question') {
			return <SvgHelp />;
		} else {
			return <SvgInformation />;
		}
	};

	const toggleModal = (event) => {
		event.stopPropagation();
		setModalIsOpen(!modalIsOpen);
	};

	return (
		<>
			<div
				className={classNames(
					styles.icon,
					props.inHeadline ? styles.inHeadline : null
				)}
			>
				<span className={styles.iconTooltip}>
					<Tooltip content={props.text} clickable={!!props.clickable}>{getIcon()}</Tooltip>
				</span>
				<span className={styles.iconModal} onClick={toggleModal}>{getIcon()}</span>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onClose={() => {
					setModalIsOpen(false);
				}}
				content={props.text}
				smallWidth
			/>
		</>
	);
};

TooltipIcon.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	type: PropTypes.string,
	inHeadline: PropTypes.any,
	clickable: PropTypes.bool
};

export default TooltipIcon;
