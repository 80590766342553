import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M20.598 4.5c1.008 0 1.805.866 1.876 1.936l.005.155v8.786c0 1.089-.751 2.004-1.738 2.085l-.143.006-.103-.001v.938c0 1.088-.748 2.004-1.732 2.085l-.143.005H4.375c-1.006 0-1.8-.865-1.87-1.936l-.005-.154v-8.84c0-1.09.748-2.005 1.733-2.086l.142-.005.108-.001v-.882c0-1.09.752-2.004 1.739-2.085l.142-.006h14.234zM4.483 8.473h-.108c-.433 0-.816.408-.869.961l-.006.13v8.84c0 .575.352 1.023.776 1.084l.099.007H18.62c.433 0 .817-.407.87-.96l.005-.13v-.938H6.364c-1.008 0-1.805-.865-1.875-1.936l-.005-.154-.001-6.904zm16.995 6.011H5.483v.893c0 .573.355 1.022.781 1.084l.1.007h14.234c.437 0 .822-.408.875-.96l.006-.13-.001-.894zm0-1.99H5.483v.99h15.995v-.99zm-.88-6.994H6.364c-.436 0-.821.408-.874.96l-.006.13-.001 4.904h15.995V6.591c0-.574-.353-1.023-.78-1.084l-.1-.007z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
