const defaultState = {
	nationality: 'DE'
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_NATIONALITY':
			return { ...state, nationality: action.payload };
		default:
			return state;
	}
};

export default reducers;
