const defaultState = {
	nationalId: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_NATIONAL_ID':
			return { ...state, nationalId: action.payload };
		default:
			return state;
	}
};

export default reducers;
