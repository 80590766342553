import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Link = (props) => {
	const color = () => {
		switch (props.color) {
			case 'light':
				return 'light';
			default:
				return 'primary';
		}
	};

	const getClasses = [
		'link',
		color(),
		props.disabled ? 'link-disabled' : null,
		props.noWrap ? 'no-wrap' : null,
		props.underline ? 'underline' : null,
		props.noHover ? 'no-hover' : null
	];

	return (
		<>
			{props.onClick ? (
				<span
					className={classNames(getClasses)}
					onClick={(event) => {
						event.stopPropagation();
						props.onClick(event);
					}}
				>
					{props.children}
				</span>
			) : (
				<a
					href={props.href}
					className={classNames(getClasses)}
					target={props.target}
					rel="noopener noreferrer"
					onClick={(event) => {
						event.stopPropagation();
					}}
					download={props.download ?? null}
				>
					{props.children}
				</a>
			)}
		</>
	);
};

Link.propTypes = {
	href: PropTypes.string,
	target: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	noWrap: PropTypes.bool,
	underline: PropTypes.bool,
	noHover: PropTypes.bool,
	download: PropTypes.bool,
	children: PropTypes.any
};

export default Link;
