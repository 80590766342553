import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M14.0029297,4 C15.0544212,4 15.9181645,4.81639314 15.9945141,5.84868454 L16,6 L19.0654297,6 C20.6258022,6 21.9055861,7.22405628 21.995009,8.76239648 L22,8.9345703 L22,16 C22,18.1387311 20.3131313,19.8908689 18.1993767,19.9950951 L18,20 L6,20 C3.86079544,20 2.10910239,18.3126722 2.00490355,16.1993349 L2,16 L2,8.9345703 C2,7.37419778 3.22405628,6.09441391 4.76239648,6.00499105 L4.9345703,6 L8,5.9975586 C8,4.94606712 8.81594822,4.08187891 9.84819918,4.00548885 L9.9970703,4 L14.0029297,4 Z M14.0029297,5 L9.9970703,5 C9.48653736,5 9.06441824,5.38565248 9.00672027,5.88134107 L9,5.9975586 L9,6.5 C9,6.74565973 8.82330248,6.94965278 8.58993485,6.99195173 L8.5,7 L4.9345703,7 C3.91894535,7 3.08313182,7.78700753 3.00583354,8.78358421 L3,8.9345703 L3,16 C3,17.5952149 4.25129791,18.9034847 5.82398199,18.9948979 L6,19 L18,19 C19.5952149,19 20.9034847,17.7487021 20.9948979,16.176018 L21,16 L21,8.9345703 C21,7.9184803 20.2125496,7.08308753 19.2163525,7.00583038 L19.0654297,7 L15.5,7 C15.2543403,7 15.0503472,6.82330248 15.0080483,6.58993485 L15,6.5 L15,5.9975586 C15,5.48657224 14.6143475,5.06442074 14.1190499,5.00672045 L14.0029297,5 Z M12,9 C14.2060547,9 16,10.7944336 16,13 C16,15.2055664 14.2060547,17 12,17 C9.7939453,17 8,15.2055664 8,13 C8,10.7944336 9.7939453,9 12,9 Z M12,10 C10.3457031,10 9,11.3457031 9,13 C9,14.6542969 10.3457031,16 12,16 C13.6542969,16 15,14.6542969 15,13 C15,11.3457031 13.6542969,10 12,10 Z M18.5,9 C18.7763672,9 19,9.2236328 19,9.5 C19,9.74565973 18.8233025,9.94965278 18.5899348,9.99195173 L18.5,10 L16.5,10 C16.2236328,10 16,9.7763672 16,9.5 C16,9.25434027 16.1766975,9.05034722 16.4100652,9.00804827 L16.5,9 L18.5,9 Z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
