/** Actions Type */
const SET_TIN_TYPE = 'SET_TIN_TYPE';
const SET_GERMAN_TIN = 'SET_GERMAN_TIN';
const SET_FATCA_COUNTRY = 'SET_FATCA_COUNTRY';
const SET_TAX_IDENTIFICATION_NUMBER = 'SET_TAX_IDENTIFICATION_NUMBER';
const SET_TAX_IDENTIFICATION_NUMBER_REASON = 'SET_TAX_IDENTIFICATION_NUMBER_REASON';

/** Actions Creators */
export const setTinType = payload => ({
	type: SET_TIN_TYPE,
	payload
});

export const setGermanTin = payload => ({
	type: SET_GERMAN_TIN,
	payload
});

export const setFatcaCountry = payload => ({
	type: SET_FATCA_COUNTRY,
	payload
});

export const setTaxIdentificationNumber = payload => ({
	type: SET_TAX_IDENTIFICATION_NUMBER,
	payload
});

export const setTaxIdentificationNumberReason = payload => ({
	type: SET_TAX_IDENTIFICATION_NUMBER_REASON,
	payload
});
