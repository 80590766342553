import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Col.module.scss';

const Col = (props) => {
	const classes = classNames(
		styles.col,
		{
			[styles['col-' + props.xs]]: props.xs,
			[styles['col-sm-' + props.sm]]: props.sm,
			[styles['col-md-' + props.md]]: props.md,
			[styles['col-lg-' + props.lg]]: props.lg,
			[styles['col-xl-' + props.xl]]: props.xl,
			[styles['col-xxl-' + props.xxl]]: props.xxl,
			[styles['col-xxxl-' + props.xxxl]]: props.xxxl,
			[styles['col-xxxxl-' + props.xxxxl]]: props.xxxxl,
			[styles['order-' + props.order]]: props.order,
			[styles['order-sm-' + props.orderSm]]: props.orderSm,
			[styles['order-md-' + props.orderMd]]: props.orderMd,
			[styles['order-lg-' + props.orderLg]]: props.orderLg,
			[styles['order-xl-' + props.orderXl]]: props.orderXl,
			[styles['order-xxl-' + props.orderXxl]]: props.orderXxl,
			[styles['order-xxxl-' + props.orderXxxl]]: props.orderXxxl,
			[styles['order-xxxxl-' + props.orderXxxxl]]: props.orderXxxxl,
			['text-align-' + props.textAlign]: props.textAlign,
			['text-align-sm-' + props.textAlignSm]: props.textAlignSm,
			['text-align-md-' + props.textAlignMd]: props.textAlignMd,
			['text-align-lg-' + props.textAlignLg]: props.textAlignLg,
			['text-align-xl-' + props.textAlignXl]: props.textAlignXl,
			['text-align-xxl-' + props.textAlignXxl]: props.textAlignXxl,
			['text-align-xxxl-' + props.textAlignXxxl]: props.textAlignXxxl,
			['text-align-xxxxl-' + props.textAlignXxxxl]: props.textAlignXxxxl
		},
		props.isSpacer ? styles.isSpacer : null,
		props.grow ? styles.grow : null,
		props.noGrow ? styles.noGrow : null,
		props.noGrowDesktop ? styles.noGrowDesktop : null,
		props.shrink ? styles.shrink : null,
		props.noShrink ? styles.noShrink : null,
		props.alignSelfCenter ? styles.alignSelfCenter : null
	);

	return <div className={classes}>{props.children}</div>;
};

Col.propTypes = {
	xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	xxl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	xxxl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	xxxxl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	orderSm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	orderMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	orderLg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	orderXl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	orderXxl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	orderXxxl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	orderXxxxl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	textAlign: PropTypes.string,
	textAlignSm: PropTypes.string,
	textAlignMd: PropTypes.string,
	textAlignLg: PropTypes.string,
	textAlignXl: PropTypes.string,
	textAlignXxl: PropTypes.string,
	textAlignXxxl: PropTypes.string,
	textAlignXxxxl: PropTypes.string,
	isSpacer: PropTypes.bool,
	grow: PropTypes.bool,
	noGrow: PropTypes.bool,
	noGrowDesktop: PropTypes.bool,
	shrink: PropTypes.bool,
	noShrink: PropTypes.bool,
	alignSelfCenter: PropTypes.bool,
	children: PropTypes.any
};

export default Col;
