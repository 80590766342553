import React from 'react';

const Svg = () => {
	const width = 159.53;
	const height = 99.43;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path fill="#b7bbbc" d="m152.08,99.43H7.46c-4.12,0-7.46-3.34-7.46-7.46V7.46C0,3.34,3.34,0,7.46,0h144.62c4.12,0,7.46,3.34,7.46,7.46v84.52c0,4.12-3.34,7.46-7.46,7.46"/>
			<path fill="#cedee7" d="m24.67,42.31h-9.07c-1.63,0-2.95,1.32-2.95,2.95v9.07c0,1.63,1.32,2.95,2.95,2.95h9.07c1.63,0,2.95-1.32,2.95-2.95v-9.07c0-1.63-1.32-2.95-2.95-2.95"/>
			<path fill="#6f7779" d="m116.48,69.82h30.55v4.59h-30.55v-4.59Zm-34.54,0h30.55v4.59h-30.55v-4.59Zm-34.54,0h30.55v4.59h-30.55v-4.59Zm-34.74,0h30.55v4.59H12.65v-4.59Zm15.27,13.28h11.78v5.99h-11.78v-5.99Zm-15.17,0h11.78v5.99h-11.78v-5.99Z"/>
			<path fill="#ffffff" d="m137.91,20.34c.13-1.3-.14-2.64-.82-3.8l-.91-1.56-3.65-6.25c-.35-.6-.59-1.24-.73-1.89l-.19.33c-1.11,1.95-1.09,4.34.04,6.27h0s3.79,6.49,3.79,6.49c0,0,0,0,0,0,1,1.89.97,4.17-.09,6.04l-.19.33c-.14-.66-.38-1.3-.73-1.89l.34.59-3.08-5.27-2.82-4.83.08.14c-.35-.6-.59-1.24-.73-1.89l-.19.33c-1.11,1.95-1.09,4.34.04,6.27h0s.12.2.12.2l2.62,4.48h0s.91,1.56.91,1.56c1.13,1.93,1.14,4.33.04,6.27l-.19.33c-.14-.66-.38-1.3-.73-1.89l.34.59-4.91-8.4h0c-.45-.77-.72-1.61-.82-2.46-5.38,1.25-9.12,4.02-9.1,7.22.02,4.33,6.93,7.8,15.42,7.75,8.49-.05,15.35-3.6,15.33-7.93-.02-3.2-3.79-5.93-9.19-7.12"/>
		</svg>
	);
};
export default Svg;
