import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import C from 'constants/Constants';
import { C as PPITarif } from 'constants/enums/PPITarif';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useViewUtils } from 'utils/view/view';
import { useScrollUtils } from 'utils/scroll/scroll';
import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

import { Text } from 'components/Atoms/Text';
import { RadioButtonInput } from 'components/Atoms/Form';
import Modal from 'components/Atoms/Modal/Modal';

import { useDispatch, useSelector } from 'react-redux';
import { setHasPpi } from 'store/Insurance/actions';

import styles from './PPI.module.scss';

const PPI = (props) => {
	const { getCurrentFlow, isCreditSubType, isCreditOrCreditCardSubType } = useUtils();
	const { getPpiMonthlyRate, isPpiSenior, getPpiTarif } = useCreditUtils();
	const { formatMoneyValue } = useNumberUtils();
	const { isMobile } = useViewUtils();
	const { scrollTo } = useScrollUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeHasPpi = useSelector((state => state.insurance.hasPpi));
	const storePpiAlreadyExists = useSelector((state => state.insurance.ppiAlreadyExists));

	const ppiMonthlyRate = getPpiMonthlyRate();

	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [popUpIsOpen, setPopUpIsOpen] = useState(false);

	useEffect(() => {
		if (storePpiAlreadyExists) {
			dispatch(setHasPpi(true));
		}
		if (isFirstLoad) {
			setIsFirstLoad(false);
		}
	}, []);

	useEffect(() => {
		if (!isFirstLoad && storeHasPpi === false) {
			setPopUpIsOpen(true);
		}
	}, [storeHasPpi]);

	const setValue = (value) => {
		if (value === 'death') {
			dispatch(setHasPpi(true));
		} else if (value === 'noPpi') {
			dispatch(setHasPpi(false));
		} else {
			dispatch(setHasPpi(null));
		}
	};

	const getValue = () => {
		if (props.protectAnchorRef && isMobile()) {
			scrollTo(props.protectAnchorRef, 0);
		}
		if (storeHasPpi === true) {
			return 'death';
		} else if (storeHasPpi === false) {
			return 'noPpi';
		} else {
			return null;
		}
	};

	const getLabelTrue = () => {
		if (isCreditSubType()) {
			const tarif = getPpiTarif();
			switch (tarif) {
				case PPITarif.TARIF_3:
				case PPITarif.TARIF_2:
					return m('insurance.ppi.body.death.tarif_23', currentFlow, null, true);
				case PPITarif.TARIF_1_2:
					return m('insurance.ppi.body.death.tarif_1_2', currentFlow, null, true);
				case PPITarif.TARIF_1_3:
					return m('insurance.ppi.body.death.tarif_1_3', currentFlow, null, true);
				case PPITarif.TARIF_1_1:
					return m('insurance.ppi.body.death.tarif_1_1', currentFlow, null, true);
				default:
					return m('insurance.ppi.body.death.tarif_1_1', currentFlow, null, true);
			}
		} else if (C.FRONTEND.VISACARD === currentFlow && storePpiAlreadyExists) {
			return m('insurance.ppi.body.death.alreadyExists', currentFlow, null, true);
		} else {
			if (isPpiSenior(false)) {
				return m('insurance.ppi.body.death.labelSenior', currentFlow);
			} else {
				return m('insurance.ppi.body.death.label', currentFlow);
			}
		}
	};

	const getLabelFalse = () => {
		if (C.FRONTEND.VISACARD === currentFlow && storePpiAlreadyExists) {
			return m('insurance.ppi.body.noPpi.alreadyExists', currentFlow);
		} else {
			return m('insurance.ppi.body.noPpi.label', currentFlow);
		}
	};

	const ppiItems = [
		{ text: getLabelTrue(), textExtension: isCreditSubType() ? formatMoneyValue(ppiMonthlyRate, true) + ' €/' + m('month.label', 'fields') : null, value: 'death' },
		{ text: getLabelFalse(), value: 'noPpi' }
	];

	return (
		<>
			<Text mb>{m('insurance.ppi.body.title', currentFlow)}:</Text>
			<div className={styles.radioWrapper}>
				<RadioButtonInput
					value={getValue()}
					setValue={(value) => { setValue(value); }}
					items={ppiItems}
					tabindex={1}
					testId="ppi"
				/>
			</div>
			{isCreditOrCreditCardSubType() && (
				<Modal
					isOpen={popUpIsOpen}
					onClose={() => {
						setPopUpIsOpen(false);
					}}
					content={
						<>
							<Text size="l" mb>
								<strong>{m('insurance.ppi.noPopup.title', currentFlow)}</strong>
							</Text>
							<Text>{m('insurance.ppi.noPopup.description', currentFlow, null, true)}</Text>
						</>
					}
				/>
			)}
		</>
	);
};

PPI.propTypes = {
	protectAnchorRef: PropTypes.any
};

export default PPI;
