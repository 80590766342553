import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { m } from 'utils/messages/messages';
import C from 'constants/Constants';
import { NUMBER_INPUT } from 'constants/Regex';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { usePageUtils } from 'utils/page/page';
import { useScrollUtils } from 'utils/scroll/scroll';
import { useViewUtils } from 'utils/view/view';
import { useItemsUtils } from 'utils/items/items';

import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { TextInput, DropdownInput, RadioBoxInput } from 'components/Atoms/Form';
import Divider from 'components/Atoms/Divider/Divider';
import DownloadContracts from 'components/Atoms/Partials/DownloadContracts/DownloadContracts';
import TinkLogo from 'components/Atoms/Partials/TinkLogo/TinkLogo';
import SvgFasterSingle from 'components/Atoms/SVG/Icons/SvgFasterSingle';

import { useDispatch, useSelector } from 'react-redux';
import { setIncome, setAdditionalIncome, setExpenditure, setOtherExpenses, setTypeOfLiving } from 'store/FinancialStatus/actions';

import styles from 'components/Pages/DAC/FinancialStatusDAC/FinancialStatusDAC.module.scss';

const FinancialStatusDAC = () => {
	const { getCurrentFlow } = useUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { continueInvalid, continueValid } = useTrackingUtils();
	const { goToPage } = usePageUtils();
	const { scrollTo } = useScrollUtils();
	const { isMobile } = useViewUtils();
	const { getTypeOfLivingItems } = useItemsUtils();

	const currentFlow = getCurrentFlow();

	const params = useParams();
	const dispatch = useDispatch();
	const storeHasSecondApplicant = useSelector((state => state.secondApplicant.hasSecondApplicant));
	const storeDacStatus = useSelector((state => state.digitalAccountCheck.status));
	const storeReferenceIban = useSelector((state => state.bankAccount.referenceIban));
	const storeIncome = useSelector((state => state.financialStatus.income));
	const storeAdditionalIncome = useSelector((state => state.financialStatus.additionalIncome));
	const storeExpenditure = useSelector((state => state.financialStatus.expenditure));
	const storeOtherExpenses = useSelector((state => state.financialStatus.otherExpenses));
	const storeTypeOfLiving = useSelector((state => state.financialStatus.typeOfLiving));
	const storeBcoResults = useSelector((state => state.app.bcoResults));

	const disableAutomatic = !!(storeDacStatus === C.DAC_STATUS.RED || storeDacStatus === C.DAC_STATUS.YELLOW || storeHasSecondApplicant);

	const [type, setType] = useState(disableAutomatic ? 'MANUALLY' : null);
	const [isReminder, setIsReminder] = useState(false);

	const [typeError, setTypeError] = useState(null);
	const [incomeError, setIncomeError] = useState(null);
	const [additionalIncomeError, setAdditionalIncomeError] = useState(null);
	const [expenditureError, setExpenditureError] = useState(null);
	const [otherExpensesError, setOtherExpensesError] = useState(null);
	const [typeOfLivingError, setTypeOfLivingError] = useState(null);

	const typeOfLivingItems = getTypeOfLivingItems();

	const scrollAnchorRef = useRef(null);

	useEffect(() => {
		if (params.isReminder && params.isReminder === 'reminder') {
			setIsReminder(true);
			if (storeDacStatus === C.DAC_STATUS.GREEN) {
				goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
			}
		}
	}, []);

	useEffect(() => {
		if (type !== null) {
			let offset = -20;
			if (isMobile()) {
				offset = 50;
			}
			scrollTo(scrollAnchorRef, offset);
		}
	}, [type]);

	const clearErrors = () => {
		setTypeError(null);
		setIncomeError(null);
		setAdditionalIncomeError(null);
		setExpenditureError(null);
		setOtherExpensesError(null);
		setTypeOfLivingError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(type)) {
			setTypeError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else if (!isReminder && type === 'MANUALLY') {
			if (isEmpty(storeIncome, true)) {
				setIncomeError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
			if (isEmpty(storeExpenditure, true)) {
				setExpenditureError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
			if (isEmpty(storeTypeOfLiving)) {
				setTypeOfLivingError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		if (type === 'AUTOMATIC') {
			goToPage(currentFlow + C.ROUTES.DIGITAL_ACCOUNT_CHECK + (isReminder ? '/reminder' : ''));
		} else if (type === 'MANUALLY') {
			if (isReminder) {
				goToPage(currentFlow + C.ROUTES.UPLOAD_DOCUMENTS);
			} else {
				goToPage(currentFlow + C.ROUTES.EXTERNAL_CREDIT);
			}
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const automaticTooltip = (
		<div className={styles.popup}>
			<p
				className={styles.content}
				dangerouslySetInnerHTML={{ __html: m('pages.financialStatusDAC.automatic.box.tooltip', 'global') }}
			/>
		</div>
	);

	const automaticBanner = useMemo(() => {
		if (isReminder && storeBcoResults.aaReady) return m('paymentToday.label', 'fields');
		else if (!isReminder) return m('everySecondCustomer.label', 'fields');
		return undefined;
	}, [isReminder, storeBcoResults.aaReady]);

	const typeItems = () => {
		const items = [
			{
				title: m('pages.financialStatusDAC.manually.box.title', 'global'),
				description:
					isReminder
						? m('pages.financialStatusDAC.manually.box.descriptionReminder', 'global')
						: m('pages.financialStatusDAC.manually.box.description', 'global'),
				value: 'MANUALLY'
			}
		];
		if (!disableAutomatic) {
			items.unshift({
				title: m('pages.financialStatusDAC.automatic.box.title', 'global'),
				banner: automaticBanner,
				description:
					isReminder
						? m('pages.financialStatusDAC.automatic.box.descriptionReminder', 'global')
						: m('pages.financialStatusDAC.automatic.box.description', 'global'),
				tooltip: automaticTooltip,
				iconAfter: <SvgFasterSingle />,
				iconAfterText: 'Schneller',
				additionalContentBottom: <span className={styles.tinkLogo}>In Kooperation mit<TinkLogo /></span>,
				value: 'AUTOMATIC'
			});
		}
		return items;
	};

	return (
		<>
			<Title>{isReminder ? m('pages.financialStatusDAC.titleReminder', 'global') : m('pages.financialStatusDAC.title', 'global')}</Title>
			<Text mb>{isReminder ? m('pages.financialStatusDAC.subtitleReminder', 'global', null, true) : ''}</Text>

			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={7}
				onPrevButton={!isReminder ? () => { goToPage(currentFlow + C.ROUTES.BANK_ACCOUNT); } : null}
				prevButtonExtension={isReminder ? <DownloadContracts /> : null}
			>
				<Row>
					<Col xs={12}>
						<RadioBoxInput
							value={type}
							setValue={setType}
							items={typeItems()}
							hasEqualHeight
							hasError={!!typeError}
							message={typeError}
							itemStyle="SMALL"
							tabindex={1}
							testId="dac-type"
						/>
					</Col>
					<div className="anchor" ref={scrollAnchorRef} />
					{type === 'AUTOMATIC' && (
						<Col xs={12}>
							<Divider noLine />
							<Title size="s">{m('pages.financialStatusDAC.automatic.description.title', 'global')}</Title>
							<Text mb>{m('pages.financialStatusDAC.automatic.description.description', 'global')}</Text>
							{storeReferenceIban && (
								<Text mb>
									<strong>
										{m('iban.label', 'fields')}: {storeReferenceIban}
									</strong>
								</Text>
							)}
						</Col>
					)}
					{!isReminder && type === 'MANUALLY' && (
						<>
							<Col xs={12}>
								<Divider noLine />
								<Text size="xl">{m('pages.financialStatusDAC.manually.description.title', 'global')}</Text>
								<Text mb>{m('pages.financialStatusDAC.manually.description.income', 'global')}</Text>
							</Col>
							<Col xs={12} sm={6}>
								<TextInput
									value={storeIncome || storeIncome === 0 ? storeIncome + '' : null}
									setValue={(value) => { dispatch(setIncome(value)); }}
									label={m('income.labelWithoutNet', 'fields')}
									hasError={!!incomeError}
									message={incomeError ?? m('income.placeholder', 'fields')}
									regex={NUMBER_INPUT}
									tooltip={
										<>
											<strong>{m('income.labelWithoutNet', 'fields')}</strong>
											<br />
											{m('income.tooltip', 'fields')}
										</>
									}
									suffix="€"
									tabindex={2}
									testId="income"
								/>
							</Col>
							<Col xs={12} sm={6}>
								<TextInput
									value={storeAdditionalIncome || storeAdditionalIncome === 0 ? storeAdditionalIncome + '' : null}
									setValue={(value) => { dispatch(setAdditionalIncome(value)); }}
									label={m('additionalIncome.monthlyNet', 'fields')}
									hasError={!!additionalIncomeError}
									message={additionalIncomeError ?? m('additionalIncome.placeholder', 'fields')}
									regex={NUMBER_INPUT}
									tooltip={
										<>
											<strong>{m('additionalIncome.label', 'fields')}</strong>
											<br />
											{m('additionalIncome.tooltip', 'fields')}
										</>
									}
									suffix="€"
									tabindex={3}
									testId="additional-income"
								/>
							</Col>
							<Col xs={12}>
								<Divider noLine />
								<Text mb>{m('pages.financialStatusDAC.manually.description.costs', 'global')}</Text>
							</Col>
							<Col xs={12} sm={6}>
								<TextInput
									value={storeExpenditure || storeExpenditure === 0 ? storeExpenditure + '' : null}
									setValue={(value) => { dispatch(setExpenditure(value)); }}
									label={storeHasSecondApplicant ? m('expenditure.monthlyBoth', 'fields') : m('expenditure.monthly', 'fields')}
									hasError={!!expenditureError}
									message={expenditureError ?? m('expenditure.placeholder', 'fields')}
									regex={NUMBER_INPUT}
									suffix="€"
									tabindex={4}
									testId="expenditure"
								/>
							</Col>
							<Col xs={12} sm={6}>
								<TextInput
									value={storeOtherExpenses || storeOtherExpenses === 0 ? storeOtherExpenses + '' : null}
									setValue={(value) => { dispatch(setOtherExpenses(value)); }}
									label={storeHasSecondApplicant ? m('otherExpenses.monthlyBoth', 'fields') : m('otherExpenses.monthly', 'fields')}
									hasError={!!otherExpensesError}
									message={otherExpensesError ?? m('otherExpenses.placeholder', 'fields')}
									regex={NUMBER_INPUT}
									suffix="€"
									tabindex={5}
									testId="other-expenses"
								/>
							</Col>
							<Col xs={12}>
								<DropdownInput
									value={storeTypeOfLiving}
									setValue={(value) => { dispatch(setTypeOfLiving(value)); }}
									items={typeOfLivingItems}
									smallItemBox
									label={m('typeOfLiving.label', 'fields')}
									hasError={!!typeOfLivingError}
									message={typeOfLivingError}
									tabindex={6}
									testId="type-of-living"
								/>
							</Col>
						</>
					)}
				</Row>

			</Form>
		</>
	);
};
export default FinancialStatusDAC;
