import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useTimeUtils } from 'utils/time/time';
import { usePhoneUtils } from 'utils/phone/phone';
import { useFlowUtils } from 'utils/flow/flow';

import { Link, Text } from 'components/Atoms/Text';
import SvgSpeechBubbleHelp from 'components/Atoms/SVG/Icons/SvgSpeechBubbleHelp';
import SvgPhone from 'components/Atoms/SVG/Icons/SvgPhone';
import SvgCalendar from 'components/Atoms/SVG/Icons/SvgCalendar';
import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';

import styles from './AppointmentButton.module.scss';

const AppointmentButton = (props) => {
	const { checkOfficeHours } = useTimeUtils();
	const { getPhoneNumber } = usePhoneUtils();
	const { isCreditAuxMoneySanc, isCreditErgoSanc, isGiroErgoUid } = useFlowUtils();

	const [phoneNumber] = useState(getPhoneNumber());

	const officeDay = ['08:00', '20:00'];
	const [isOpen, setIsOpen] = useState(false);
	let timeout = null;

	useEffect(() => {
		const appointmentButtonAutoShow = sessionStorage.getItem('appointmentButtonAutoShow');
		if (appointmentButtonAutoShow !== 'false') {
			setupTimer();
		}
	}, []);

	const setupTimer = () => {
		window.addEventListener('mousedown', resetTimer);
		window.addEventListener('keypress', resetTimer);
		window.addEventListener('touchmove', resetTimer);
		startTimer();
	};

	const startTimer = () => {
		timeout = window.setTimeout(() => {
			setIsOpen(true);
			window.removeEventListener('mousedown', resetTimer);
			window.removeEventListener('keypress', resetTimer);
			window.removeEventListener('touchmove', resetTimer);
			sessionStorage.setItem('appointmentButtonAutoShow', 'false');
		}, 15000);
	};

	const resetTimer = () => {
		window.clearTimeout(timeout);
		startTimer();
	};

	const onButtonClick = () => {
		setIsOpen(!isOpen);
	};

	const show = useMemo(() => {
		return !isCreditAuxMoneySanc && !isCreditErgoSanc && !isGiroErgoUid && (checkOfficeHours(officeDay) || !props.hideAppointmentLink);
	}, [officeDay, props.hideAppointmentLink, isCreditAuxMoneySanc, isCreditErgoSanc, isGiroErgoUid]);

	return (
		<>
			{show && (
				<div
					className={classNames([
						styles.appointmentBox,
						isOpen ? styles.appointmentBoxActive : null
					])}
				>
					<div
						className={classNames([styles.appointmentButton])}
						onClick={onButtonClick}
					>
						<div className={classNames([styles.appointmentButtonTitle])}>
							{m('appointmentButton.buttonText', 'global')}
						</div>
						<div className={classNames([styles.appointmentButtonIcon])}>
							<SvgSpeechBubbleHelp />
						</div>
					</div>
					<div
						className={classNames([
							styles.appointmentCloseBackground,
							isOpen ? styles.appointmentCloseBackgroundActive : null
						])}
						onClick={onButtonClick}
					/>
					<div
						className={classNames([
							styles.appointmentBoxContent,
							isOpen ? styles.appointmentBoxContentActive : null
						])}
					>
						<div
							className={classNames([styles.appointmentButtonClose])}
							onClick={onButtonClick}
						>
							<SvgClose />
						</div>
						<Text>{m('appointmentButton.title', 'global')}</Text>
						{checkOfficeHours(officeDay) ? (
							<>
								<div className={classNames([styles.appointmentBoxRow])}>
									<div
										className={classNames([
											styles.appointmentBoxCol,
											styles.appointmentBoxIcon
										])}
									>
										<SvgPhone />
									</div>
									<div
										className={classNames([
											styles.appointmentBoxCol,
											styles.appointmentBoxText
										])}
									>
										<Text>{m('appointmentButton.phone.title', 'global')}</Text>
										<Text>
											<Link href={'tel:' + phoneNumber?.link}>
												<span className={styles.appointmentBoxLink}>
													{phoneNumber?.text}
												</span>
											</Link>
										</Text>
									</div>
								</div>
							</>
						) : (
							<></>
						)}
						{!props.hideAppointmentLink && (
							<div className={classNames([styles.appointmentBoxRow])}>
								<div
									className={classNames([
										styles.appointmentBoxCol,
										styles.appointmentBoxIcon
									])}
								>
									<SvgCalendar/>
								</div>
								<div
									className={classNames([
										styles.appointmentBoxCol,
										styles.appointmentBoxText
									])}
								>
									<Text>{m('appointmentButton.appointment.title', 'global')}</Text>
									<Text>
										<Link
											href="https://service.santander.de/webapps/apps/terminanfrage?skip=thema&appointment-topic=Direktkredit"
											target='_blank'
										>
										<span
											className={classNames([
												styles.appointmentBoxLink,
												styles.appointmentBoxLinkUnderline
											])}
										>
											{m('appointmentButton.appointment.linkText', 'global')}
										</span>
										</Link>
									</Text>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

AppointmentButton.propTypes = {
	hideAppointmentLink: PropTypes.bool
};

export default AppointmentButton;
