/** Actions Type */
const SET_AMOUNT = 'SET_AMOUNT';
const SET_RUNTIME = 'SET_RUNTIME';
const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';

/** Actions Creators */
export const setAmount = payload => ({
	type: SET_AMOUNT,
	payload
});

export const setRuntime = payload => ({
	type: SET_RUNTIME,
	payload
});

export const setPaymentType = payload => ({
	type: SET_PAYMENT_TYPE,
	payload
});
