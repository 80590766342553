import React from 'react';

const Svg = () => {
	const width = 28;
	const height = 26;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<defs>
				<path
					d='M19.572 11.87c4.598 1.18 7.704 3.73 7.642 6.52 0 4.1-6.089 7.4-13.607 7.4C6.09 25.79 0 22.49 0 18.39c0-2.92 3.169-5.47 7.705-6.65 0 1.12.248 2.24.807 3.23l4.287 7.4c.311.56.56 1.18.684 1.8l.186-.31a5.889 5.889 0 000-5.9l-3.417-5.91c-1.056-1.86-1.056-4.1 0-5.9l.186-.31c.125.62.373 1.24.684 1.8l1.988 3.48 3.107 5.41c.31.56.559 1.18.683 1.8l.187-.31a5.889 5.889 0 000-5.9l-3.418-5.91a5.889 5.889 0 010-5.9l.187-.31c.124.62.372 1.24.683 1.8l4.225 7.4c.497.8.746 1.74.808 2.67z'
					id='logoMobile_svg__a'
				/>
			</defs>
			<g fill='none' fillRule='evenodd'>
				<mask id='logoMobile_svg__b' fill='#fff'>
					<use xlinkHref='#logoMobile_svg__a' />
				</mask>
				<path
					fill='#EC0000'
					fillRule='nonzero'
					mask='url(#logoMobile_svg__b)'
					d='M-5-5h158v35.79H-5z'
				/>
			</g>
		</svg>
	);
};
export default Svg;

