import React from 'react';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useCreditUtils } from 'utils/credit/credit';
import { useNumberUtils } from 'utils/number/number';

import { Text, Link } from 'components/Atoms/Text';
import Interest from 'components/Atoms/Partials/Values/Interest';
import PpiValue from 'components/Atoms/Partials/Values/PpiValue';
import ProtectValue from 'components/Atoms/Partials/Values/ProtectValue';
import FixedBorrowingRate from 'components/Atoms/Partials/Values/FixedBorrowingRate';
import EffectiveYearlyInterestRate from 'components/Atoms/Partials/Values/EffectiveYearlyInterestRate';
import NetAmount from 'components/Atoms/Partials/Values/NetAmount';
import TotalAmount from 'components/Atoms/Partials/Values/TotalAmount';
import MonthlyRate from 'components/Atoms/Partials/Values/MonthlyRate';
import SvgChevronDownLink from 'components/Atoms/SVG/Icons/SvgChevronDownLink';
import SvgInsurance from 'components/Atoms/SVG/Icons/SvgInsurance';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';

import { useDispatch, useSelector } from 'react-redux';
import { updateSidebarExtensionActive } from 'store/App/actions';

import styles from './CreditCalculator.module.scss';

const CreditCalculatorMonthlyRate = () => {
	const { getEffectiveYearlyInterestRate } = useCreditUtils();
	const { formatMoneyValue } = useNumberUtils();

	const dispatch = useDispatch();
	const storeSidebarExtensionActive = useSelector((state => state.app.sidebarExtensionActive));
	const storeFirstRateDate = useSelector((state => state.app.firstRateDate));
	const storeHasPpi = useSelector((state => state.insurance.hasPpi));
	const storeProtect = useSelector((state => state.insurance.protect));

	const toggleExpander = () => {
		dispatch(updateSidebarExtensionActive(!storeSidebarExtensionActive));
	};

	return (
		<>
			<div className={styles.totalValueBox}>
				<div className={classNames(styles.monthlyRateText)}>
					{m('monthlyRate.label', 'fields')}
				</div>
				<div
					className={classNames(styles.monthlyRate)}
					data-testid="monthly-rate-value"
				>
					{storeHasPpi && (
						<div className={styles.iconShield}>
							<SvgInsurance />
						</div>
					)}
					<MonthlyRate isDecimal /> €
				</div>
				<div className={classNames(
					'mt-10',
					styles.toggleDetailsButton,
					{
						[styles.toggleDetailsButtonActive]: storeSidebarExtensionActive
					}
				)}>
					<Text>
						<Link onClick={() => { toggleExpander(); }}>
							{storeSidebarExtensionActive
								? m('closeCreditDetails.label', 'fields')
								: m('openCreditDetails.label', 'fields')}
							<SvgChevronDownLink />
						</Link>
					</Text>
				</div>
			</div>

			<div
				className={classNames(styles.detailsWrapper, {
					[styles.detailsWrapperActive]: storeSidebarExtensionActive
				})}
			>
				{storeHasPpi && (
					<div className={styles.coverageBox}>
						<div className={styles.iconShield}>
							<SvgInsurance />
						</div>
						<div className={styles.coverageBoxText}>
							{m('pages.insurance.ppi.body.death.title', 'global')}
						</div>
					</div>
				)}

				<div className={styles.dataRow}>
					<div className={styles.label}>
						{m('interest.label', 'fields')}
					</div>
					<div className={styles.data}>
						<Interest isDecimal /> €
					</div>
				</div>

				{storeHasPpi && (
					<div className={styles.dataRow}>
						<div className={styles.label}>
							{m('paymentProtectionInsurance.labelLong', 'fields')}
						</div>
						<div className={styles.data}>
							<PpiValue isDecimal /> €
						</div>
					</div>
				)}

				{storeProtect && (
					<div className={styles.dataRow}>
						<div className={styles.label}>
							{m('protect.label', 'fields')}
						</div>
						<div className={styles.data}>
							<ProtectValue /> €
						</div>
					</div>
				)}

				<div className={styles.dataRow}>
					<div className={styles.label}>
						{m('fixedBorrowingRate.label', 'fields')}
					</div>
					<div className={styles.data}>
						<FixedBorrowingRate isDecimal threeDigitsAfterComma /> %
					</div>
				</div>

				<div className={styles.dataRow}>
					<div className={styles.label}>
						{m('effectiveYearlyInterest.label', 'fields')}
					</div>
					<div className={styles.data}>
						<EffectiveYearlyInterestRate isDecimal /> %
					</div>
					<div className={styles.inlineTooltipIcon}>
						<TooltipIcon
							text={
								<>{m('effectiveYearlyInterest.description', 'fields', { interest: formatMoneyValue(getEffectiveYearlyInterestRate(), true) })}</>
							}
						/>
					</div>
				</div>

				<div className={styles.dataRow}>
					<div className={styles.label}>
						{m('netLoanAmount.label', 'fields')}
					</div>
					<div className={styles.data}>
						<NetAmount isDecimal /> €
					</div>
				</div>

				<div className={styles.dataRow}>
					<div className={styles.label}>
						{m('totalAmount.label', 'fields')}
					</div>
					<div className={styles.data}>
						<TotalAmount isDecimal /> €
					</div>
				</div>

				{storeFirstRateDate && (
					<div className={styles.dataRow}>
						<div className={styles.label}>
							{m('firstRate.date', 'fields')}
						</div>
						<div className={styles.data}>
							{storeFirstRateDate}
						</div>
					</div>
				)}
			</div>
		</>
	);
};
export default CreditCalculatorMonthlyRate;
