import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';

export const Button = (props) => {
	const getButtonStyle = () => {
		switch (props.buttonStyle) {
			case 'secondary':
				return styles.secondary;
			case 'link':
				return styles.link;
			default:
				return styles.primary;
		}
	};

	const getButtonSize = () => {
		switch (props.buttonSize) {
			case 's':
				return styles.s;
			case 'l':
				return styles.l;
			default:
				return styles.m;
		}
	};

	const onClick = (event) => {
		props.onClick(event);
	};

	const getClasses = () => {
		return [
			styles.button,
			getButtonStyle(),
			getButtonSize(),
			props.disabled ? styles.disabled : null,
			props.loading ? styles.loading : null,
			props.fullWidthDesktop ? styles.fullWidthDesktop : null,
			props.fullWidthTablet ? styles.fullWidthTablet : null,
			props.fullWidthMobile ? styles.fullWidthMobile : null
		];
	};

	const getContent = () => {
		return (
			<>
				{props.loading ? (
					<span className={styles.loadingAnimation}>
						<span className={styles.loadingAnimationBounce1} />
						<span className={styles.loadingAnimationBounce2} />
						<span className={styles.loadingAnimationBounce3} />
					</span>
				) : (
					<div className={styles.text}>{props.children}</div>
				)}
			</>
		);
	};

	return (
		<>
			{props.link ? (
				<a
					className={classNames(getClasses())}
					href={props.link}
					target={props.newTab ? '_blank' : null}
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
					rel="noopener noreferrer"
				>
					{getContent()}
				</a>
			) : (
				<button
					className={classNames(getClasses())}
					onClick={onClick}
					type={props.buttonType ?? 'button'}
					disabled={props.disabled}
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
				>
					{getContent()}
				</button>
			)}
		</>
	);
};

Button.propTypes = {
	onClick: PropTypes.func,
	link: PropTypes.string,
	newTab: PropTypes.bool,
	buttonStyle: PropTypes.string,
	buttonSize: PropTypes.string,
	buttonType: PropTypes.string,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	fullWidthDesktop: PropTypes.bool,
	fullWidthTablet: PropTypes.bool,
	fullWidthMobile: PropTypes.bool,
	tabindex: PropTypes.number,
	testId: PropTypes.string,
	children: PropTypes.any
};

export default Button;
