import React from 'react';
import classNames from 'classnames';

import { useSelector } from 'react-redux';

import styles from './LoadingOverlay.module.scss';

const LoadingOverlay = () => {
	const storeIsShowOverlay = useSelector((state => state.app.isShowOverlay));

	return (
		<div
			className={classNames(
				styles.wrapper,
				{
					[styles.active]: storeIsShowOverlay
				}
			)}
		/>
	);
};
export default LoadingOverlay;
