import React from 'react';
import PropTypes from 'prop-types';

import RangeSliderNumberInput from '../RangeSliderNumberInput/RangeSliderNumberInput';
import RangeSliderInput from '../RangeSliderInput/RangeSliderInput';

const RangeSliderWithNumberInput = props => {
	return (
		<>
			<RangeSliderNumberInput
				label={props.label}
				value={props.customLabelValue ?? props.value}
				suffix={props.suffix}
				// onChangeHandler={val => props.onChangeHandler(val)}
				standalone='true'
				hasTooltipIcon={props.hasTooltipIcon}
				tooltipIconContent={props.tooltipIconContent}
				testId={`${props.label}-RangeSliderNumberInput`}
				labelClassName={props.labelClassName}
				valueClassName={props.valueClassName}
			/>
			<RangeSliderInput
				min={props.min}
				max={props.max}
				onChangeHandler={val => props.onChangeHandler(val)}
				value={props.value}
				step={props.step}
				testId={`${props.label}-RangeSliderInput`}
			/>
		</>
	);
};

RangeSliderWithNumberInput.propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	value: PropTypes.any,
	customLabelValue: PropTypes.any,
	step: PropTypes.any,
	label: PropTypes.string,
	suffix: PropTypes.string,
	hasTooltipIcon: PropTypes.bool,
	tooltipIconContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	labelClassName: PropTypes.string,
	valueClassName: PropTypes.string,
	onChangeHandler: PropTypes.func
};

export default RangeSliderWithNumberInput;
