import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M16.364 2.692a2.363 2.363 0 0 1 3.213-.12l.13.12 1.6 1.602c.882.88.922 2.284.12 3.213l-.12.13L7.091 21.853a.5.5 0 0 1-.275.14L6.737 22H2.5a.5.5 0 0 1-.492-.41L2 21.5v-4.237a.5.5 0 0 1 .095-.293l.051-.06L16.364 2.692zm-2.566 3.98L3 17.47V21h3.53l10.798-10.799-3.53-3.529zM18.999 3.4a1.363 1.363 0 0 0-1.817-.099l-.11.1-2.567 2.565 3.53 3.529L20.6 6.929c.498-.497.53-1.282.1-1.817l-.1-.11L19 3.398z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
