import React from 'react';

const Svg = () => {
	const width = 202;
	const height = 201;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<defs>
				<path id='GoToPostOffice_svg__a' d='M.54.866h59.275V75H.54z'/>
			</defs>
			<g fill='none' fillRule='evenodd'>
				<path fill='#D2F0F2' d='M87.021 191.169h98.123V33.19H87.021z'/>
				<path
					stroke='#D2F0F2'
					strokeLinecap='round'
					strokeLinejoin='round'
					d='M39.827 191.169h76.206V10.565H39.827z'
				/>
				<path
					fill='#FFF'
					d='M194.834 106.7h-83.28c-3.744 0-6.784-2.805-6.784-6.267V53.017c0-3.462 3.04-6.266 6.785-6.266h83.28c3.747 0 6.787 2.804 6.787 6.266v47.415c0 3.462-3.04 6.269-6.787 6.269'
				/>
				<g strokeLinecap='round' strokeLinejoin='round'>
					<path
						stroke='#C3DEE7'
						strokeWidth={1.5}
						d='M182.147 80.296h-13.403v18.882h19.233V88.95'
					/>
					<path
						stroke='#63BA68'
						strokeWidth={2}
						d='M174.47 88.79l5.278 5.273L188.545 80'
					/>
				</g>
				<path
					fill='#9BC3D3'
					d='M141.702 65.026l-1.418 10.148c-1.048 2.89-3.207 4.312-6.478 4.27-3.268-.043-5.135-1.52-5.6-4.426l-1.226-10.184c.402-4.058 2.882-6.056 7.442-5.998 4.556.063 6.983 2.125 7.281 6.19m-7.53 27.132c6.054 0 10.593-2.054 14.816-5.754-2.952-4.063-5.875-5.025-9.6-3.881l-4.28 1.887c-.786.346-1.628.73-2.618.433-1.064-.316-2.468-1.679-4.729-2.458-3.148-1.087-5.787-1.198-9.646 2.875 3.91 4.125 9.69 6.898 16.057 6.898'
				/>
				<path
					stroke='#9BC3D3'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth={1.712}
					d='M162 61.986h25.844'
				/>
				<path
					stroke='#DEEDF2'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth={1.141}
					d='M162 71.518h25.844'
				/>
				<path
					fill='#9BC3D3'
					d='M63.183 169.289l8.256 8.256-7.135 19.266a5.375 5.375 0 01-4.994 3.467 5.832 5.832 0 01-1.836-.306c-2.754-1.021-4.179-4.077-3.16-6.831l8.869-23.852z'
				/>
				<g transform='translate(46.75 125.481)'>
					<mask id='GoToPostOffice_svg__b' fill='#fff'>
						<use xlinkHref='#GoToPostOffice_svg__a'/>
					</mask>
					<path
						fill='#9BC3D3'
						d='M1.04 22.707l4.893-9.379a15.654 15.654 0 015.607-5.912C22.856.689 24.28.789 25.708.89l7.646.51c2.346.102 3.975.715 11.825 12.234a1.99 1.99 0 001.325.814l9.377 1.43c2.55.406 4.282 2.75 3.875 5.3-.408 2.547-2.754 4.279-5.3 3.872l-9.379-1.427c-3.058-.51-5.81-2.141-7.544-4.69-.408-.61-.712-1.12-1.12-1.63l-6.015 19.064 8.358 8.356c1.223 1.223 2.244 2.854 2.856 4.488l6.421 18.756c1.02 2.75-.51 5.81-3.262 6.725-.613.206-1.121.308-1.733.308-2.242 0-4.282-1.429-5.096-3.569l-6.423-18.756c-.102-.204-.204-.306-.306-.508L16.33 37.18c-2.141-2.14-2.956-5.095-2.343-7.95l3.26-14.473a8.957 8.957 0 01-1.019.61c-.916.511-1.631 1.326-2.14 2.243l-4.893 9.379c-.817 1.529-2.448 2.446-4.08 2.446-.712 0-1.426-.205-2.139-.51C.735 27.7-.08 24.948 1.04 22.706'
						mask='url(#GoToPostOffice_svg__b)'
					/>
				</g>
				<path
					fill='#9BC3D3'
					d='M67.616 112.672c.617-5.654 5.696-9.737 11.348-9.12 5.654.614 9.737 5.693 9.12 11.347-.614 5.652-5.693 9.736-11.347 9.121-5.652-.616-9.736-5.696-9.121-11.348'
				/>
				<path
					stroke='#9BC3D3'
					strokeLinecap='round'
					strokeLinejoin='round'
					d='M128 1c5.694 0 5.694 5.025 11.39 5.025 5.69 0 5.69-5.025 11.383-5.025 5.698 0 5.698 5.025 11.394 5.025 5.702 0 5.702-5.025 11.402-5.025 5.7 0 5.7 5.025 11.402 5.025M128 7.931c5.694 0 5.694 5.023 11.39 5.023 5.691 0 5.691-5.023 11.383-5.023 5.698 0 5.698 5.023 11.394 5.023 5.702 0 5.702-5.023 11.402-5.023 5.7 0 5.7 5.023 11.402 5.023M128 14.861c5.694 0 5.694 5.023 11.39 5.023 5.691 0 5.691-5.023 11.383-5.023 5.698 0 5.698 5.023 11.394 5.023 5.702 0 5.702-5.023 11.402-5.023 5.7 0 5.7 5.023 11.402 5.023M128 21.79c5.694 0 5.694 5.024 11.39 5.024 5.691 0 5.691-5.023 11.383-5.023 5.698 0 5.698 5.023 11.394 5.023 5.702 0 5.702-5.023 11.402-5.023 5.7 0 5.7 5.023 11.402 5.023'
				/>
				<g>
					<path
						fill='#C3DEE7'
						d='M191.308 148.654c0 13.616-24.654 46.962-24.654 46.962S142 162.27 142 148.654C142 135.038 153.038 124 166.654 124s24.654 11.038 24.654 24.654'
					/>
					<path
						fill='#F2F8FA'
						d='M179.654 148.654c0 7.18-5.82 13-13 13s-13-5.82-13-13 5.82-13 13-13 13 5.82 13 13'
					/>
				</g>
				<path fill='#FFF' d='M0 26.123h70V20H0z'/>
				<path
					fill='#D2F0F2'
					d='M40 48.492h47v-6.123H40zm0 19.817h47v-6.124H40z'
				/>
			</g>
		</svg>
	);
};
export default Svg;
