import _ from 'lodash';
import { UPDATE_TAX_INFORMATION } from './actions';

const defaultState = {
	country: '',
	selectedRadioItem: '',
	taxIdentificationNumber: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case UPDATE_TAX_INFORMATION:
			return _.merge({}, state, {
				country: action.payload.country,
				selectedRadioItem: action.payload.selectedRadioItem,
				taxIdentificationNumber: action.payload.taxIdentificationNumber
			});
		default:
			return state;
	}
};

export default reducers;
