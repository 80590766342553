import React from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useNumberUtils } from 'utils/number/number';

import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import { Row, Col } from 'components/Atoms/Grid';
import SvgIllustrationDone from 'components/Atoms/SVG/Illustrations/SvgIllustrationDone';

import { useSelector } from 'react-redux';

import styles from './CreditDone.module.scss';

const CreditDone = () => {
	const { getCurrentFlow } = useUtils();
	const { formatMoneyValue } = useNumberUtils();

	const currentFlow = getCurrentFlow();

	const storeAmount = useSelector((state => state.app.amount));

	return (
		<>
			<Row justifyContent='center'>
				<Col xs={12} sm={4} md={5}>
					<div className={styles.illustrationWrapper}>
						<SvgIllustrationDone />
					</div>
				</Col>
				<Col xs={12} sm={8} md={7}>
					<Title>{m('creditDone.title', currentFlow)}</Title>
					<div className="mt-20 mb-30">
						<Text>{m('amount.label', 'fields')}</Text>
						<Text size="xl">
						<span className={styles.amount}>
							{formatMoneyValue(storeAmount ?? 0, false)}
							<span>,00 €</span>
						</span>
						</Text>
					</div>
				</Col>
			</Row>
			<Form
				submitText={m('creditDone.button', currentFlow)}
				onSubmit={() => {
					window.open('https://santander.de', '_self');
					window.close();
				}}
				clearErrors={() => {}}
				submitTabindex={1}
			/>
		</>
	);
};
export default CreditDone;
