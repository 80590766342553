import React from 'react';
import PropTypes from 'prop-types';
import { useUtils } from 'utils/utils';

import { Col, Container, Row } from 'components/Atoms/Grid';
import Header from 'components/Organisms/Header/Header';
import Footer from 'components/Organisms/Footer/Footer';
import RightSideBar from 'components/Organisms/RightSideBar/RightSideBar';
import ProgressBar from 'components/Organisms/ProgressBar/ProgressBar';
import LoadingPage from 'components/Atoms/Loading/Page/LoadingPage';
import LoadingOverlay from 'components/Atoms/Loading/Overlay/LoadingOverlay';
import AppointmentButton from 'components/Atoms/Partials/AppointmentButton/AppointmentButton';
import DurationHint from 'components/Atoms/Partials/DurationHint/DurationHint';
import ExitWindowPopup from 'components/Atoms/Partials/ExitWindowPopup/ExitWindowPopup';

import { useSelector } from 'react-redux';

import styles from './Layout.module.scss';

const Layout = (props) => {
	const { isCreditSubType } = useUtils();

	const storeAppLoading = useSelector((state => state.app.appLoading));

	const storeCurrentStepCategory = useSelector((state => state.page.currentStepCategory));

	const content = () => {
		return (
			<main>
				{props.children}
			</main>
		);
	};

	return (
		<div className={styles.wrapper}>
			{props.hasExitWindowPopup && <ExitWindowPopup />}

			<LoadingOverlay />

			<Header
				hasRightSidebar={props.hasRightSidebar}
				hideFlowTitle={props.hideFlowTitle}
			/>

			{isCreditSubType() && props.hasRightSidebar && (
				<RightSideBar creditCalculatorBlocked={props.creditCalculatorBlocked} />
			)}

			{storeCurrentStepCategory ? (
				<ProgressBar progressBarSubline={props.showDurationHint && <DurationHint />} />
			) : (
				<div className={styles.headerSpacer} />
			)}

			<Container>
				<Row justifyContent="center">
					{props.layoutWide ? (
						<Col xs={12}>
							{content()}
						</Col>
					) : (
						<Col xs={12} md={10} lg={8} xl={7} xxl={6} xxxl={5}>
							{content()}
						</Col>
					)}
				</Row>
			</Container>

			<div className={styles.footerSpacer} />

			<Footer extension={props.footerExtension} />

			{props.showAppointmentButton && (
				<AppointmentButton hideAppointmentLink={props.hideAppointmentLink} />
			)}

			{storeAppLoading !== 'none' && (
				<LoadingPage />
			)}

			{props.background && (
				<div className={styles.background}>
					{React.createElement(props.background)}
				</div>
			)}
		</div>
	);
};

Layout.propTypes = {
	showDurationHint: PropTypes.bool,
	hasExitWindowPopup: PropTypes.bool,
	hasRightSidebar: PropTypes.bool,
	creditCalculatorBlocked: PropTypes.bool,
	footerExtension: PropTypes.any,
	layoutWide: PropTypes.bool,
	showAppointmentButton: PropTypes.bool,
	hideAppointmentLink: PropTypes.bool,
	hideFlowTitle: PropTypes.bool,
	background: PropTypes.any,
	children: PropTypes.any
};

export default Layout;
