export const defaultState = {
	salutation: null,
	firstName: null,
	lastName: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_SALUTATION':
			return { ...state, salutation: action.payload };
		case 'SET_FIRST_NAME':
			return { ...state, firstName: action.payload };
		case 'SET_LAST_NAME':
			return { ...state, lastName: action.payload };
		default:
			return state;
	}
};

export default reducers;
