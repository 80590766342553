/** Actions Type */
export const SET_NEW_ACCOUNT_IBAN = 'SET_NEW_ACCOUNT_IBAN';
export const SET_NEW_CREDIT_CARD_PAN = 'SET_NEW_CREDIT_CARD_PAN';
export const SET_LOANS_ACCOUNT_NUMBER = 'SET_LOANS_ACCOUNT_NUMBER';

/** Actions Creators */
export const setNewAccountIban = payload => ({
	type: SET_NEW_ACCOUNT_IBAN,
	payload
});

export const setNewCreditCardPan = payload => ({
	type: SET_NEW_CREDIT_CARD_PAN,
	payload
});

export const setLoansAccountNumber = payload => ({
	type: SET_LOANS_ACCOUNT_NUMBER,
	payload
});
