import bigInt from 'big-integer';

export const useIbanUtils = () => {
	const formatIbanForInput = (event, prevValue, ref, successCb) => {
		const valueBeforeChange = event.target.value;
		const cursorPosition = event.target.selectionStart;

		let formattedValue = valueBeforeChange;

		if (valueBeforeChange && valueBeforeChange.length > 0) {

			formattedValue = formatIban(formattedValue);

			let newPosition = cursorPosition + (formattedValue.length - valueBeforeChange.length);

			// Skip space on Backspace
			if (valueBeforeChange && prevValue && valueBeforeChange.length < prevValue.length) {
				if (formattedValue.charAt(cursorPosition) === ' ') {
					newPosition = newPosition - 2;
					formattedValue = formattedValue.slice(0, newPosition) + formattedValue.slice(newPosition + 1);
					formattedValue = formatIban(formattedValue);
				}
			}

			setTimeout(() => {
				const item = ref && ref.current;
				if (item && item.setSelectionRange) {
					item.setSelectionRange(newPosition, newPosition);
				} else if (item && item.element && item.element.setSelectionRange) {
					item.element.setSelectionRange(newPosition, newPosition);
				}
			});

		}

		successCb(formattedValue);
	};

	const formatIban = (iban) => {
		return iban ? iban.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').toUpperCase().trim() : iban;
	};

	const trimIban = (iban) => {
		return iban ? iban.replace(/[^\da-zA-Z]/g, '').trim() : iban;
	};

	const ibanContainsSantanderBlz = (iban) => {
		const tempIban = trimIban(iban);
		let santanderBlzs = [
			'10033300',
			'20133300',
			'25020600',
			'31010833',
			'36033300',
			'37020600',
			'50033300',
			'55033300',
			'60133300',
			'70133300',
			'86033300'
		];

		return !!santanderBlzs.find((elem) => tempIban.includes(elem));
	};

	const isIbanValid = (val) => {
		let countrycodelengths = {
			'AL': 28, 'AD': 24, 'AT': 20, 'AZ': 28,
			'BE': 16, 'BH': 22, 'BA': 20, 'BR': 29,
			'BG': 22, 'CR': 21, 'HR': 21, 'CY': 28,
			'CZ': 24, 'DK': 18, 'DO': 28, 'EE': 20,
			'FO': 18, 'FI': 18, 'FR': 27, 'GE': 22,
			'DE': 22, 'GI': 23, 'GR': 27, 'GL': 18,
			'GT': 28, 'HU': 28, 'IS': 26, 'IE': 22,
			'IL': 23, 'IT': 27, 'KZ': 20, 'KW': 30,
			'LV': 21, 'LB': 28, 'LI': 21, 'LT': 20,
			'LU': 20, 'MK': 19, 'MT': 31, 'MR': 27,
			'MU': 30, 'MC': 27, 'MD': 24, 'ME': 22,
			'NL': 18, 'NO': 15, 'PK': 24, 'PS': 29,
			'PL': 28, 'PT': 25, 'RO': 24, 'SM': 27,
			'SA': 24, 'RS': 22, 'SK': 24, 'SI': 19,
			'ES': 24, 'SE': 24, 'CH': 21, 'TN': 24,
			'TR': 26, 'AE': 23, 'GB': 22, 'VG': 24
		};

		let iban = String(val).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
			code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
			digits;

		// check syntax and length
		if (!code || iban.length !== countrycodelengths[code[1]]) {
			return false;
		}

		// rearrange country code and check digits, and convert chars to ints
		digits = bigInt((convertIbanCharsToInt(code[3]) + convertIbanCharsToInt(code[1]) + code[2]));
		const mod97 = digits.mod(97);

		return mod97.compare(1) === 0;
	};

	const convertIbanCharsToInt = (countryCode) => {
		let ints = '';
		countryCode.split('').forEach((char) => {
			ints += !isNaN(parseInt(char, 10)) ? char : getAlphabetPositionPlus9(char);
		});

		return ints;
	};

	const getAlphabetPositionPlus9 = (char) => {
		const alphabetPosition = char.charCodeAt() - 64;
		return alphabetPosition + 9;
	};

	const isGermanIban = (iban) => {
		if (iban.length >= 2) {
			const prefix = iban.slice(0, 2).toUpperCase();
			return prefix === 'DE';
		} else {
			return false;
		}
	};

	return {
		formatIbanForInput,
		formatIban,
		trimIban,
		ibanContainsSantanderBlz,
		isIbanValid,
		isGermanIban
	};
};
