/** Actions Type */
export const SET_DEPOT_NUMBER = 'SET_DEPOT_NUMBER';
export const SET_CLEARING_ACCOUNT_IBAN = 'SET_CLEARING_ACCOUNT_IBAN';
export const SET_BANK_CODE = 'SET_BANK_CODE';

/** Actions Creators */
export const setDepotNumber = (payload) => ({
	type: SET_DEPOT_NUMBER,
	payload
});

export const setClearingAccountIban = (payload) => ({
	type: SET_CLEARING_ACCOUNT_IBAN,
	payload
});

export const setBankCode = (payload) => ({
	type: SET_BANK_CODE,
	payload
});
