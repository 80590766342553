const Svg = () => {
	const width = 78;
	const height = 53;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#D2F0F2'
					d='M68.08 52.365H2.67c-1.474 0-2.67-1.18-2.67-2.635V10.996c0-1.454 1.196-2.633 2.67-2.633h65.41c1.475 0 2.671 1.179 2.671 2.633v38.735c0 1.455-1.196 2.634-2.67 2.634'
				/>
				<path
					fill='#FFF'
					stroke='#C3DEE7'
					d='M74.08 45.365H8.67c-1.474 0-2.67-1.18-2.67-2.635V3.996c0-1.454 1.196-2.633 2.67-2.633h65.41c1.475 0 2.671 1.179 2.671 2.633v38.735c0 1.455-1.196 2.634-2.67 2.634'
				/>
				<path
					fill='#9BC3D3'
					d='M52.731 14.405c-.063-.751-.253-1.503-.665-2.13l-3.421-5.922a5.67 5.67 0 01-.57-1.443l-.159.251a4.687 4.687 0 000 4.733l2.788 4.73a4.687 4.687 0 010 4.733l-.159.25c-.095-.5-.316-1.001-.57-1.44l-2.534-4.325-1.616-2.789a5.722 5.722 0 01-.57-1.442l-.158.252c-.856 1.44-.856 3.227 0 4.731l2.787 4.733a4.684 4.684 0 010 4.731l-.158.25c-.095-.5-.317-1.002-.57-1.44l-3.485-5.924a5.303 5.303 0 01-.665-2.6c-3.675.94-6.24 2.976-6.24 5.327 0 3.29 4.94 5.923 11.055 5.923 6.114 0 11.056-2.633 11.056-5.923.095-2.257-2.44-4.325-6.146-5.266'
				/>
				<path
					fill='#9BC2D2'
					d='M9.468 32.997h17.526V30.93H9.468zm0 3.397h20.906v-2.068H9.468zm0 6.3H40.4v-2.067H9.468z'
					opacity={0.352}
				/>
				<path
					fill='#2D4369'
					d='M9.468 39.805h3.863V38.6H9.468zm34.351 0h6.825V38.6h-6.825z'
					opacity={0.479}
				/>
				<path
					fill='#9BC2D2'
					d='M43.869 42.694h5.9v-2.067h-5.9z'
					opacity={0.352}
				/>
				<g stroke='#4B6066' strokeWidth={0.75} opacity={0.474}>
					<path d='M20.865 23.108v1.088h-1.104' />
					<path strokeDasharray='6.651 6.651' d='M17.314 24.196h-3.67' />
					<path d='M12.421 24.196h-1.104v-1.088' />
					<path strokeDasharray='4.977 4.977' d='M11.317 21.303v-2.707' />
					<path d='M11.317 17.693v-1.088h1.104' />
					<path strokeDasharray='6.651 6.651' d='M14.867 16.605h3.671' />
					<path d='M19.761 16.605h1.104v1.088' />
					<path strokeDasharray='4.977 4.977' d='M20.865 19.498v2.708' />
				</g>
				<path
					fill='#E51E25'
					d='M67.92 37.59c0 2.526-2.078 4.575-4.64 4.575-2.564 0-4.641-2.049-4.641-4.575 0-2.527 2.077-4.575 4.64-4.575 2.563 0 4.64 2.048 4.64 4.575'
					opacity={0.577}
				/>
				<path
					fill='#DDEBF0'
					d='M73.598 37.59c0 2.526-2.077 4.575-4.64 4.575-2.563 0-4.64-2.049-4.64-4.575 0-2.527 2.077-4.575 4.64-4.575 2.563 0 4.64 2.048 4.64 4.575'
					opacity={0.693}
				/>
			</g>
		</svg>
	);
};
export default Svg;
