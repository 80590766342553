import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M5.512 5.456l4.162-.002c.957 0 1.74-.729 1.816-1.655l.007-.17.001-1.13a.5.5 0 0 1 .992-.09l.008.09-.001 1.202a1.812 1.812 0 0 0 1.675 1.748l.147.005 4.094.001a.495.495 0 0 1 .514.34l3.047 9.064.01.033a.5.5 0 0 1 .016.127l-.026-.16a.5.5 0 0 1 .026.179c-.01 1.937-1.595 3.502-3.547 3.502-1.893 0-3.442-1.472-3.543-3.328l-.005-.205.012-.097.014-.05 2.825-8.406H14.32c-.694 0-1.33-.248-1.821-.661v14.624c1.709.036 3.356.24 4.843.597a.5.5 0 0 1-.233.972 22.033 22.033 0 0 0-4.55-.568l-.46-.004a.502.502 0 0 1-.202 0c-1.791.001-3.515.196-5.055.56a.5.5 0 1 1-.23-.973 23.074 23.074 0 0 1 4.714-.582l.173-.003V5.79a2.82 2.82 0 0 1-1.652.66l-.172.004H6.248l2.83 8.419a.5.5 0 0 1 .026.183c-.013 1.937-1.6 3.501-3.552 3.501-1.896 0-3.446-1.474-3.547-3.331L2 15.006l.012-.085.02-.066 3.046-9.06a.49.49 0 0 1 .434-.34zm2.541 10.076H3.05a2.546 2.546 0 0 0 2.502 2.025c1.237 0 2.267-.87 2.501-2.025zm12.897-.013h-4.995a2.542 2.542 0 0 0 2.498 2.02 2.54 2.54 0 0 0 2.497-2.02zM5.552 7.523l-2.356 7.01h4.712l-2.356-7.01zm12.901 0l-2.352 6.996h4.704l-2.352-6.995z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
