import React, { useEffect } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';

import { Text, Title } from 'components/Atoms/Text';
import ServiceHotline from 'components/Atoms/Partials/ServiceHotline/ServiceHotline';
import { Col, Row } from 'components/Atoms/Grid';
import BoxCollapsible from 'components/Molecules/Box/BoxCollapsible/BoxCollapsible';
import Button from 'components/Atoms/Button/Button';
import SantanderBranchHint from 'components/Atoms/Partials/SantanderBranchHint/SantanderBranchHint';

const AccountExists = () => {
	const { getCurrentFlow } = useUtils();
	const { preventHistoryBackFor } = usePageUtils();

	const currentFlow = getCurrentFlow();

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.WEB_ID_DONE);
	}, []);

	return (
		<>
			<Title mb>{m('accountExists.title', currentFlow)}</Title>
			{currentFlow === C.FRONTEND.GMK ? (
				<ServiceHotline />
			) : (
				<>
					{currentFlow === C.FRONTEND.SPARBRIEF ? (
						<>
							<Row>
								<Col xs={12} sm={6}>
									<div className="mb-10">
										<BoxCollapsible
											stretch
											title={m('accountExists.onlineBanking.title', currentFlow)}
											contentHeader={
												<>
													<Text>{m('accountExists.onlineBanking.description', currentFlow)}</Text>
													<div className="text-align-center mt-15">
														<Button
															onClick={() => {
																window.location.href = 'https://meine.santander.de/login/#/login/';
															}}
														>
															{m('accountExists.onlineBanking.button', currentFlow)}
														</Button>
													</div>
												</>
											}
										/>
									</div>
								</Col>
								<Col xs={12} sm={6}>
									<div className="mb-10">
										<BoxCollapsible
											stretch
											title={m('accountExists.support.title', currentFlow)}
											contentHeader={
												<>
													<Text>{m('accountExists.support.description', currentFlow, null, true)}</Text>
													<Text isSmall>{m('accountExists.support.descriptionSmall', currentFlow)}</Text>
													<div className="text-align-center mt-15">
														<Button
															onClick={() => {
																window.location.href = 'tel:+4921619060819';
															}}
														>
															{m('accountExists.support.button', currentFlow)}
														</Button>
													</div>
												</>
											}
										/>
									</div>
								</Col>
							</Row>
							<div className="mt-30">
								<ServiceHotline />
							</div>
						</>
					) : (
						<SantanderBranchHint />
					)}
				</>
			)}
		</>
	);
};
export default AccountExists;
