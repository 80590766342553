import _ from 'lodash';

export const defaultState = {
	income: '',
	additionalIncome: '',
	expenditure: '',
	typeOfLiving: '',
	otherExpenses: '',
	incomeError: false,
	financialStatusOption: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_FINANCIAL_STATUS':
			return _.merge({}, state, {
				income: action.payload.income,
				additionalIncome: action.payload.additionalIncome,
				expenditure: action.payload.expenditure,
				otherExpenses: action.payload.otherExpenses,
				typeOfLiving: action.payload.typeOfLiving,
				financialStatusOption: action.payload.financialStatusOption
			});
		case 'UPDATE_INCOME':
			return _.merge({}, state, {
				income: action.payload,
				incomeError: !action.payload
			});
		case 'UPDATE_ADDITIONAL_INCOME':
			return _.merge({}, state, {
				additionalIncome: action.payload
			});
		case 'UPDATE_INCOME_ERROR':
			return {
				...state,
				incomeError: action.payload
			};
		case 'UPDATE_FINANCIAL_STATUS_OPTION':
			return {
				...state,
				financialStatusOption: action.payload
			};
		case 'SET_INCOME':
			return { ...state, income: action.payload };
		case 'SET_ADDITIONAL_INCOME':
			return { ...state, additionalIncome: action.payload };
		case 'SET_EXPENDITURE':
			return { ...state, expenditure: action.payload };
		case 'SET_OTHER_EXPENSES':
			return { ...state, otherExpenses: action.payload };
		case 'SET_TYPE_OF_LIVING':
			return { ...state, typeOfLiving: action.payload };
		default:
			return state;
	}
};

export default reducers;
