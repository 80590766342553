import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Row.module.scss';

const Row = (props) => {
	const classes = classNames(
		styles.row,
		{
			[styles['justify-content-' + props.justifyContent]]: props.justifyContent
		},
		props.noWrap ? styles.noWrap : null,
		props.flexColumnMobile ? styles.flexColumnMobile : null
	);

	return <div className={classes}>{props.children}</div>;
};

Row.propTypes = {
	justifyContent: PropTypes.string,
	noWrap: PropTypes.bool,
	flexColumnMobile: PropTypes.bool,
	children: PropTypes.any
};

export default Row;
