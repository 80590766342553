/** Actions Type */
const SET_CUSTOMER_NUMBER = 'SET_CUSTOMER_NUMBER';
const SET_CURRENT_APPLICANT = 'SET_CURRENT_APPLICANT';
const SET_FIRST_APPLICANT_NAME = 'SET_FIRST_APPLICANT_NAME';
const SET_FIRST_APPLICANT_DOCUMENT_UPLOAD_STATUS = 'SET_FIRST_APPLICANT_DOCUMENT_UPLOAD_STATUS';
const SET_FIRST_APPLICANT_CONTRACT_SIGNED = 'SET_FIRST_APPLICANT_CONTRACT_SIGNED';
const SET_SECOND_APPLICANT_NAME = 'SET_SECOND_APPLICANT_NAME';
const SET_SECOND_APPLICANT_DOCUMENT_UPLOAD_STATUS = 'SET_SECOND_APPLICANT_DOCUMENT_UPLOAD_STATUS';
const SET_SECOND_APPLICANT_CONTRACT_SIGNED = 'SET_SECOND_APPLICANT_CONTRACT_SIGNED';

/** Actions Creators */
export const setCustomerNumber = payload => ({
	type: SET_CUSTOMER_NUMBER,
	payload
});

export const setCurrentApplicant = payload => ({
	type: SET_CURRENT_APPLICANT,
	payload
});

export const setFirstApplicantName = payload => ({
	type: SET_FIRST_APPLICANT_NAME,
	payload
});

export const setFirstApplicantDocumentUploadStatus = payload => ({
	type: SET_FIRST_APPLICANT_DOCUMENT_UPLOAD_STATUS,
	payload
});

export const setFirstApplicantContractSigned = payload => ({
	type: SET_FIRST_APPLICANT_CONTRACT_SIGNED,
	payload
});

export const setSecondApplicantName = payload => ({
	type: SET_SECOND_APPLICANT_NAME,
	payload
});

export const setSecondApplicantDocumentUploadStatus = payload => ({
	type: SET_SECOND_APPLICANT_DOCUMENT_UPLOAD_STATUS,
	payload
});

export const setSecondApplicantContractSigned = payload => ({
	type: SET_SECOND_APPLICANT_CONTRACT_SIGNED,
	payload
});

