export const useNumberUtils = () => {
	const formatMoneyValue = (number, isDecimal = false, threeDigitsAfterComma = false) => {
		let formattedNumber = number;

		if (isDecimal) {
			formattedNumber = parseFloat(formattedNumber).toFixed(
				threeDigitsAfterComma ? 3 : 2
			);

			const beforeComma = (formattedNumber + '').split('.')[0];
			let afterComma = (formattedNumber + '').split('.')[1];

			if (!afterComma) {
				afterComma = '00';
			} else if (afterComma && afterComma.length === 1) {
				afterComma = afterComma + '0';
			}

			formattedNumber = parseInt(beforeComma).toLocaleString('de-DE') + ',' + afterComma;
		} else {
			formattedNumber = formattedNumber ? parseInt(formattedNumber).toLocaleString('de-DE') : formattedNumber;
		}

		return formattedNumber;
	};

	const roundUp = (number, nearestInterval, maxRange) => {
		let roundedNumber = Math.ceil(number / nearestInterval) * nearestInterval;

		if (roundedNumber <= 0) {
			roundedNumber = nearestInterval;
		} else if (roundedNumber > maxRange) {
			roundedNumber = maxRange;
		}

		return roundedNumber.toString();
	};

	return {
		formatMoneyValue,
		roundUp
	};
};
