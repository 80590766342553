import React from 'react';

const Svg = () => {
	const width = 160;
	const height = 154;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<g id="-↳-💡-Concepts-&amp;-infographics" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="documentation-/-concepts-/-01" transform="translate(-913.000000, -549.000000)">
					<g id="Concepts/General/Investment" transform="translate(913.000000, 550.000000)">
						<g id="a.-backgrounds-/-shapes-/-curve-Copy" fill="#DEEDF2">
							<path d="M7.45463055,112.084596 L23.5144463,144.713195 L160,144.713195 L96.2842296,15.2615385 L55.110558,15.2615385 C12.7495241,15.2615385 -13.7260424,69.0521788 7.45463055,112.084596" id="Fill-1"></path>
						</g>
						<g id="Group" transform="translate(82.830769, 110.400000)">
							<g id="Group" transform="translate(0.000000, 1.238462)">
								<path d="M-7.95807864e-14,20.2780814 C-7.95807864e-14,31.4769963 9.07844411,40.5554404 20.2772933,40.5554404 C31.4761426,40.5554404 40.554718,31.4769963 40.554718,20.2780814 C40.554718,9.07923217 31.4761426,0.000788059385 20.2772933,0.000788059385 C9.07844411,0.000788059385 -7.95807864e-14,9.07923217 -7.95807864e-14,20.2780814" id="Shadow" fill="#D39E00"></path>
								<path d="M2.50116914,20.2788695 C2.50116914,31.4762477 11.5807953,40.5560052 22.7783311,40.5560052 C24.6247543,40.5560052 26.4144371,40.3093426 28.1136245,39.8446503 C36.7250121,37.5033258 43.0553618,29.6298508 43.0553618,20.2788695 C43.0553618,9.0796262 33.9775744,-1.53039974e-14 22.7783311,-1.53039974e-14 C17.2105602,-1.53039974e-14 12.1661921,2.24557522 8.50158464,5.88168122 C4.7963921,9.55495735 2.50116914,14.6491046 2.50116914,20.2788695" id="Primary-color" fill="#FFE99F"></path>
								<path d="M2.50116914,20.2788695 C2.50116914,31.4762477 11.5807953,40.5560052 22.7783311,40.5560052 C24.6247543,40.5560052 26.4144371,40.3093426 28.1136245,39.8446503 L8.50158464,5.88168122 C4.7963921,9.55495735 2.50116914,14.6491046 2.50116914,20.2788695" id="Secondary-color" fill="#FFCC33"></path>
							</g>
						</g>
						<g id="b.-characters-/-body-parts-/-upper-body-/-hands-&amp;-arms-/-hand-02" transform="translate(0.000000, -16.000000)" fill="#F4A48D">
							<path d="M15.0895326,15.2869744 L44.9213853,66.8699185 L95.522,68.7319744 L106.553227,64.3028757 L120.76379,61.4637789 C122.340528,61.148411 123.920673,61.9887815 124.538182,63.4707492 C124.715268,63.896038 124.798634,64.3341508 124.798634,64.763627 C124.798634,66.2509598 123.801786,67.6359617 122.254409,68.0270965 L122.254409,68.0270965 L111.88653,70.6510626 L106.812,73.3829744 L118.29061,75.2608 L142.729191,80.4718302 C144.842169,80.9225054 146.261744,82.9088001 146.000899,85.050685 C145.725635,87.3172777 143.675575,88.940703 141.401371,88.6922035 L125.024,86.9019744 L145.635191,97.7863162 C147.982281,99.0251668 148.65297,102.048393 147.100338,104.156189 L146.969566,104.325032 C146.129355,105.356456 144.886736,105.920323 143.611346,105.920323 C143.069994,105.920323 142.522088,105.818647 141.997776,105.607703 L141.997776,105.607703 L115.134382,94.8066779 L105.503,90.9339744 L108.163521,96.8529056 C109.477316,99.0369269 109.938973,101.627786 109.460407,104.130185 L105.56018,124.521275 C105.054675,127.163765 102.378579,128.79563 99.7937535,128.039682 C99.8695585,129.407385 99.2977961,130.772889 98.1779965,131.678819 C97.3714734,132.329707 96.3971704,132.651487 95.4244403,132.651487 C94.3298077,132.651487 93.2381898,132.243733 92.3945716,131.437124 L92.3945716,131.437124 L85.3339223,124.681969 C84.5675091,124.475867 81.1915944,123.117691 76.8607762,121.325067 C72.5285161,119.533881 67.2410905,117.308378 62.6489027,115.364612 C55.5718687,112.369402 49.1929562,107.94653 43.9115602,102.371583 L43.9115602,102.371583 L38.01685,96.1486274 L1.35713663e-12,61.4724156 L5.15372606,41.7760508 L8.19093514,30.1664534 L15.0895326,15.2869744 Z M83.4878193,90.8138055 L76.4449967,102.97497 L87.8141808,111.444235 C91.3846154,114.102748 94.389186,117.432955 96.6660114,121.237523 C96.6662096,121.237853 96.6664078,121.238184 96.666606,121.238515 L97.9146608,102.974839 L92.5812267,94.6469002 L83.5557177,90.8242741 L83.4878193,90.8138055 Z" id="Skin-tone"></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
export default Svg;
