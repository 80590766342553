import C from 'constants/Constants';

import CreditAmount from 'components/Pages/Flows/ShortApplication/CreditAmount/CreditAmount';
import CustomerData from 'components/Pages/Flows/ShortApplication/CustomerData/CustomerData';
import ThankYou from 'components/Pages/Flows/ShortApplication/ThankYou/ThankYou';

import FootNote from 'components/Pages/Flows/ShortApplication/FootNote';
import CreditAmountBackground from 'components/Pages/Flows/ShortApplication/CreditAmount/CreditAmountBackground';

export const routes = (flow, gtmType) => {
	let routes = [];
	routes = routes.concat([
		{
			subpage: C.ROUTES.INDEX,
			component: CreditAmount,
			gtmStep: 'personaldata_wunschbetrag',
			gtmStatus: 'application_pending',
			footerExtension: FootNote,
			layoutWide: true,
			background: CreditAmountBackground
		},
		{
			subpage: C.ROUTES.SHORT_APPLICATION,
			component: CustomerData,
			gtmStep: 'personaldata_customer_summary',
			gtmStatus: 'application_pending',
			showAppointmentButton: true
		},
		{
			subpage: C.ROUTES.THANKYOU,
			component: ThankYou,
			gtmStep: 'thank_you',
			gtmStatus: 'application_end',
			footerExtension: FootNote
		}
	]);

	for (const route of routes) {
		route.flow = flow;
		route.gtmType = gtmType;
	}

	return routes;
};
export default routes;
