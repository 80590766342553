import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M7.503 12a.5.5 0 0 1 .5.5v.498h2.777c.519 0 1.02.182 1.418.515l.143.13c.193.193.446.315.718.346l.136.007h3.293a1.5 1.5 0 0 1 1.482 1.263l1.662-.778a1.586 1.586 0 0 1 2.187.627 1.656 1.656 0 0 1-.556 2.13l-.14.1-4.266 2.202a8.527 8.527 0 0 1-4.255.329l-.35-.062-4.25-.948v.63a.5.5 0 0 1-.5.499H4.294A2.276 2.276 0 0 1 2 17.71V12.5a.5.5 0 0 1 .5-.5h5.003zm3.275 2H8v3.834l4.452.993a7.74 7.74 0 0 0 3.76-.126l.25-.08 4.155-2.143a.678.678 0 0 0 .3-.933.58.58 0 0 0-.743-.209l-.103.045-2.532 1.185a1.499 1.499 0 0 1-.9.421l-.15.008h-3.002a.5.5 0 0 1-.09-.99l.09-.007h3.002c.128 0 .25-.049.343-.136a.296.296 0 0 1 .055-.065.499.499 0 0 0-.31-.79l-.09-.008h-3.295c-.518 0-1.02-.182-1.418-.513l-.143-.132a1.207 1.207 0 0 0-.718-.344l-.136-.008V14zM7 13.001H3v4.717A1.281 1.281 0 0 0 4.295 19H7V13zM12.864 8c.23-.002.422.196.443.454.02.259-.138.492-.364.537L12.864 9h-2.862c.582 1.274 1.762 2.052 3.028 1.999.813.01 1.604-.295 2.243-.864a.402.402 0 0 1 .44-.086.49.49 0 0 1 .282.392.534.534 0 0 1-.165.47c-.795.714-1.784 1.098-2.8 1.087-1.68.063-3.224-1.036-3.901-2.775l-.059-.185-.01-.038h-.615c-.23.002-.422-.195-.443-.454-.02-.258.137-.492.363-.537L8.445 8h4.42zm.21-4.997h-.003a4.2 4.2 0 0 1 2.744 1.009.52.52 0 0 1 .182.462.49.49 0 0 1-.277.4.41.41 0 0 1-.449-.078 3.366 3.366 0 0 0-2.2-.802c-1.289-.055-2.489.733-3.068 2.015h2.9c.23-.001.424.195.445.45.021.257-.138.488-.366.533l-.08.008H8.446c-.23 0-.423-.195-.444-.45-.021-.256.137-.487.364-.533l.08-.008h.621a.542.542 0 0 1 .021-.11c.643-1.807 2.24-2.967 3.987-2.896z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
