import React from 'react';
import { m } from 'utils/messages/messages';

import { Text, Title } from 'components/Atoms/Text';
import BoxSteps from 'components/Atoms/Partials/BoxSteps/BoxSteps';
import SvgIllustrationPostidentLetter from 'components/Atoms/SVG/Illustrations/SvgIllustrationPostidentLetter';
import SvgIllustrationPostidentQRCode from 'components/Atoms/SVG/Illustrations/SvgIllustrationPostidentQRCode';
import SvgIllustrationPostidentCard from 'components/Atoms/SVG/Illustrations/SvgIllustrationPostidentCard';

const DigitalPostident = () => {
	const stepsItems = [
		{
			title: m('pages.digitalPostident.step1.text', 'global'),
			subtitle: m('pages.digitalPostident.step1.text2', 'global'),
			icon: <SvgIllustrationPostidentQRCode />
		},
		{
			title: m('pages.digitalPostident.step2.text', 'global'),
			subtitle: m('pages.digitalPostident.step2.text2', 'global'),
			icon: <SvgIllustrationPostidentLetter />
		},
		{
			title: m('pages.digitalPostident.step3.text', 'global'),
			subtitle: m('pages.digitalPostident.step3.text2', 'global'),
			icon: <SvgIllustrationPostidentCard />
		}
	];

	return (
		<>
			<Title>{m('pages.digitalPostident.title', 'global')}</Title>
			<Text mb>{m('pages.digitalPostident.subtitle', 'global')}</Text>
			<BoxSteps items={stepsItems} />
		</>
	);
};
export default DigitalPostident;
