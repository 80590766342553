import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';

import styles from './Tooltip.module.scss';

const Tooltip = (props) => {
	const id = uuid();

	return (
		<div className={styles.tooltip}>
			<div data-tip="" data-for={id}>
				{props.children}
			</div>
			<ReactTooltip
				id={id}
				effect="solid"
				type={props.theme ? props.theme : 'light'}
				place={props.position ? props.position : 'top'}
				clickable={!!props.clickable}
			>
				{props.content}
			</ReactTooltip>
		</div>
	);
};

Tooltip.propTypes = {
	theme: PropTypes.string,
	position: PropTypes.string,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	clickable: PropTypes.bool,
	children: PropTypes.any
};

export default Tooltip;
