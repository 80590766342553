import { SET_NEW_ACCOUNT_IBAN, SET_NEW_CREDIT_CARD_PAN, SET_LOANS_ACCOUNT_NUMBER } from './actions';

const defaultState = {
	iban: null,
	pan: null,
	loansAccountNumber: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case SET_NEW_ACCOUNT_IBAN:
			return { ...state, iban: action.payload };
		case SET_NEW_CREDIT_CARD_PAN:
			return { ...state, pan: action.payload };
		case SET_LOANS_ACCOUNT_NUMBER:
			return { ...state, loansAccountNumber: action.payload };
		default:
			return state;
	}
};

export default reducers;
