import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M18.974 2a3.026 3.026 0 0 1 3.02 2.848l.006.178v9.462a3.028 3.028 0 0 1-3.028 3.028h-4.841L14.905 21l.6.001a.5.5 0 0 1 .492.41l.008.09a.5.5 0 0 1-.41.492l-.09.008h-7a.5.5 0 0 1-.09-.992l.09-.008h.599l.774-3.484H5.026A3.026 3.026 0 0 1 2 14.49V5.03a3.029 3.029 0 0 1 2.85-3.025L5.028 2h13.946zm-5.868 15.516h-2.203L10.128 21h3.753l-.775-3.483zM21 14H3v.49c0 1.12.907 2.026 2.026 2.026h13.946c1.12 0 2.028-.908 2.028-2.028V14zM18.974 3H5.028c-1.07 0-1.945.828-2.022 1.878L3 5.029V13h18V5.026a2.026 2.026 0 0 0-1.875-2.02L18.974 3zm-6.087 5.542a.5.5 0 0 1 .09.992l-.09.008h-2.102c.39.631.973.953 1.809.953.59 0 .894-.13 1.483-.586a.5.5 0 1 1 .611.791c-.75.58-1.252.795-2.094.795-1.424 0-2.4-.712-2.93-1.953h-.557a.5.5 0 0 1-.09-.992l.09-.008h3.78zm-.293-4.046c.841 0 1.343.215 2.094.793a.5.5 0 0 1-.61.793c-.592-.456-.896-.587-1.484-.587-.975 0-1.606.437-1.988 1.293l2.281.001a.5.5 0 0 1 .09.992l-.09.008h-3.78a.5.5 0 0 1-.09-.992l.09-.008.427-.001c.492-1.45 1.511-2.293 3.06-2.293z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
