import React from 'react';

const Svg = () => {
	const width = 296.58;
	const height = 243.96;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<defs>
				<style>
					{
						'.DepotSuccess_svg__cls-3{fill:#d2f0f2}.DepotSuccess_svg__cls-4{fill:#9bc3d3}.DepotSuccess_svg__cls-5{fill:#f2f8fa}.DepotSuccess_svg__cls-6{fill:#c3dee7}.DepotSuccess_svg__cls-10{fill:#1bb3be}.DepotSuccess_svg__cls-11{fill:#9e3667}'
					}
				</style>
			</defs>
			<circle cx={143.6} cy={119.67} r={73.92} fill='#fff'/>
			<path
				fill='#63818e'
				d='M141.18 171.21l51.16 15.75-48.37 18.54-51.3-18.5 48.51-15.79z'
			/>
			<path
				className='DepotSuccess_svg__cls-3'
				d='M141.95 166.22l-46.65 17.8v-48.78l46.65-17.8v48.78z'
			/>
			<path
				className='DepotSuccess_svg__cls-4'
				d='M192.51 184.02l-50.56-17.8v-48.78l50.56 17.8v48.78z'
			/>
			<path
				className='DepotSuccess_svg__cls-3'
				d='M192.87 184.02l-47 17.81v-48.79l47-17.8v48.78z'
			/>
			<path
				className='DepotSuccess_svg__cls-5'
				d='M180.55 161.66l-28.51 10.32v-2.9l28.51-10.32v2.9zM169.11 173.15l-17.07 6.17v-2.9l17.07-6.17v2.9z'
			/>
			<path
				className='DepotSuccess_svg__cls-4'
				d='M145.87 201.83L95.3 184.02v-48.78l50.57 17.8v48.79z'
			/>
			<path
				className='DepotSuccess_svg__cls-6'
				d='M106.08 167.59l-6.03-2.13v-5.81l6.03 2.12v5.82z'
			/>
			<path
				fill='#deedf2'
				d='M141.95 117.44l12.11-12.81L205.69 121l-12.82 14.24-50.92-17.8z'
			/>
			<path
				className='DepotSuccess_svg__cls-6'
				d='M145.87 153.04L127 163.01l-49.14-18.87 17.44-8.9 50.57 17.8z'
			/>
			<path
				className='DepotSuccess_svg__cls-6'
				d='M141.95 117.44l-10.34-11.72-46.64 17.09 10.33 12.43 46.65-17.8zM192.87 135.24l16.73 6.05-46.29 18.52-17.44-6.77 47-17.8z'
			/>
			<path
				transform='rotate(45 69.929 164.145)'
				fill='#63ba68'
				d='M65.36 159.57h9.13v9.13h-9.13z'
			/>
			<circle cx={207.62} cy={157.63} r={3.65} fill='#fc3'/>
			<path
				className='DepotSuccess_svg__cls-10'
				d='M192.37 98.74h6.2v6.2h-6.2z'
			/>
			<path
				className='DepotSuccess_svg__cls-11'
				d='M142.21 151.72l-7.57-2.66L158 98.67l12.48 13.85 14.12-36.31 7.46 2.9-18.83 48.4-13.06-14.49-17.96 38.7z'
			/>
			<path
				className='DepotSuccess_svg__cls-10'
				d='M115.81 142.44l-7.63-2.71 21.41-38.93 10.25 11.39 13.37-29.7 7.29 3.28-18.4 40.89-10.93-12.14-15.36 27.92z'
			/>
			<path
				className='DepotSuccess_svg__cls-10'
				d='M169.85 91.31l-7.71-17.44-17.44 7.71 25.15 9.73z'
			/>
			<path
				className='DepotSuccess_svg__cls-11'
				d='M200.89 84.18l-7.71-17.44-17.44 7.7 25.15 9.74z'
			/>
		</svg>
	);
};
export default Svg;
