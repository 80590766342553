import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const List = (props) => {
	const color = () => {
		switch (props.color) {
			case 'light':
				return 'text--light';
			default:
				return 'text--primary';
		}
	};

	const size = () => {
		switch (props.size) {
			case 'xl':
				return 'text--size--xl';
			case 'l':
				return 'text--size--l';
			case 's':
				return 'text--size--s';
			case 'xs':
				return 'text--size--xs';
			default:
				return 'text--size--m';
		}
	};

	return (
		<>
			{props.listType === 'ol' ? <ol className={classNames(['list', size(), color()])}>{props.children}</ol> : <ul className={classNames(['list', size(), color()])}>{props.children}</ul>}
		</>
	);
};

List.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
	listType: PropTypes.string,
	listStyle: PropTypes.string,
	children: PropTypes.any
};

export default List;
