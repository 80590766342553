import React, { useEffect } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { Text, Title } from 'components/Atoms/Text';
import ServiceHotline from 'components/Atoms/Partials/ServiceHotline/ServiceHotline';

const ThankYouGiroSchufaFailed = () => {
	const { getCurrentFlow } = useUtils();
	const { preventHistoryBackFor } = usePageUtils();
	const { ecommercePurchase } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
		ecommercePurchase();
	}, []);

	return (
		<>
			<Title>{m('pages.thankYouSchufaFailed.title', 'global')}</Title>
			<Text mb>{m('pages.thankYouSchufaFailed.description', 'global', null, true)}</Text>
			<ServiceHotline />
		</>
	);
};
export default ThankYouGiroSchufaFailed;
