import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="m5.69,5.83c.16-.04.32.04.38.2v.07s.17,1.52.17,1.52c0,.17-.12.32-.3.32-.04,0-.08,0-.11-.02l-.06-.03-.45-.3-.03.05-.05.05c-1.78,2.81-1.64,6.42.36,9.08,2.65,3.53,7.66,4.24,11.19,1.58,1.85-1.39,3-3.51,3.17-5.82v-.11s0-.03,0-.03c.01-.21.02-.42.01-.63v-.31c-.03-.28.18-.51.46-.53.28-.02.51.19.53.47h0c.01.23.02.47.01.71v.14s0,.05,0,.05c-.13,3.7-2.53,6.95-6.03,8.16l-.26.09c-4.74,1.49-9.78-1.15-11.27-5.88-.81-2.57-.42-5.37,1.06-7.63l-.31-.21c-.14-.09-.18-.27-.09-.41.03-.04.06-.07.1-.1l.06-.03s1.46-.45,1.46-.45Zm14.25,1.98c.3.57.54,1.18.71,1.8.08.26-.07.54-.34.62-.26.08-.54-.07-.62-.34,0,0,0-.01,0-.02-.15-.55-.37-1.09-.63-1.6-.13-.24-.03-.55.21-.68.24-.13.55-.03.68.21h0Zm-2.5-2.96c.51.39.99.84,1.4,1.34.17.21.14.53-.07.7-.21.17-.51.14-.69-.06-.37-.44-.79-.84-1.25-1.19-.22-.16-.28-.47-.12-.7.16-.22.47-.28.7-.12,0,0,.02.01.03.02Zm-8.6-.77c.12.25,0,.55-.24.66h0c-.52.24-1.01.54-1.47.89-.22.17-.53.12-.7-.09-.17-.22-.12-.53.1-.7h0c.52-.39,1.07-.73,1.66-1,.25-.12.55,0,.66.24Zm4.98-.88h.09c.64.15,1.27.37,1.87.64.25.12.35.42.23.67-.12.24-.41.35-.65.24-.53-.25-1.09-.44-1.66-.56-.27-.06-.44-.32-.38-.59.04-.21.21-.36.42-.39h.09s0,0,0,0Zm-1.86-.2c.28,0,.5.22.5.5,0,.28-.22.5-.5.5-.58,0-1.15.06-1.71.19-.27.06-.54-.11-.6-.38-.06-.27.11-.54.38-.6.63-.14,1.28-.21,1.93-.21Z" />
			<path d="m12.78,11.37c.28.12.49.31.65.56s.23.56.23.92c0,.58-.18,1.03-.55,1.33-.37.31-.91.47-1.63.47-.33,0-.67-.04-1.02-.12,0-.3.04-.58.12-.84.09.03.21.05.36.07.15.02.31.03.47.03.42,0,.74-.07.94-.22.21-.15.31-.38.31-.71,0-.3-.1-.52-.29-.66-.2-.14-.49-.21-.89-.21-.3,0-.58.04-.85.11l.2-2.76h2.72c0,.14-.01.28-.03.45-.01.16-.03.3-.05.4h-1.75l-.08,1s.1,0,.18,0c.36,0,.69.06.96.19Z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
