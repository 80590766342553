/** Actions Type */
const SET_SALUTATION = 'SET_SALUTATION';
const SET_FIRST_NAME = 'SET_FIRST_NAME';
const SET_LAST_NAME = 'SET_LAST_NAME';

/** Actions Creators */
export const setSalutation = payload => ({
	type: SET_SALUTATION,
	payload
});

export const setFirstName = payload => ({
	type: SET_FIRST_NAME,
	payload
});

export const setLastName = payload => ({
	type: SET_LAST_NAME,
	payload
});
