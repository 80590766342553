/** Actions Type */
const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
const SET_MOBILE_NUMBER = 'SET_MOBILE_NUMBER';
const SET_ENCRYPTED_MOBILE_NUMBER = 'SET_ENCRYPTED_MOBILE_NUMBER';

/** Actions Creators */
export const setCountryCode = payload => ({
	type: SET_COUNTRY_CODE,
	payload
});

export const setMobileNumber = payload => ({
	type: SET_MOBILE_NUMBER,
	payload
});

export const setEncryptedMobileNumber = payload => ({
	type: SET_ENCRYPTED_MOBILE_NUMBER,
	payload
});
