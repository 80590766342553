import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-rangeslider';

const RangeSliderInput = props => {
	return (
		<Slider
			min={props.min}
			max={props.max}
			value={props.value}
			step={props.step}
			orientation='horizontal'
			onChange={val => props.onChangeHandler(val)}
		/>
	);
};

RangeSliderInput.propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	value: PropTypes.any,
	step: PropTypes.any,
	onChangeHandler: PropTypes.func
};

export default RangeSliderInput;
