import React from 'react';
import { m } from 'utils/messages/messages';

import NetAmount from 'components/Atoms/Partials/Values/NetAmount';
import Runtime from 'components/Atoms/Partials/Values/Runtime';
import MonthlyRate from 'components/Atoms/Partials/Values/MonthlyRate';

import styles from './Overview.module.scss';

const Overview = () => {

	return (
		<div className={styles.box}>
			<div className={styles.col}>
				<div className={styles.label}>{m('amount.full', 'fields')}</div>
				<div className={styles.text}><NetAmount isDecimal /> €</div>
			</div>
			<div className={styles.col}>
				<div className={styles.label}>{m('runtime.label', 'fields')}</div>
				<div className={styles.text}><Runtime showMonthLabel /></div>
			</div>
			<div className={styles.col}>
				<div className={styles.label}>{m('rate.label', 'fields')}</div>
				<div className={styles.text}><MonthlyRate isDecimal /> €</div>
			</div>
		</div>
	);
};

export default Overview;
