import _ from 'lodash';
import * as actions from './actions';

const defaultState = {
	webId: [],
	postidentDigital: [],
	postidentCoupon: [],
	kontoident: []
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case actions.UPDATE_FEATURES:
			return _.merge({}, state, {
				webId: action.payload.webId,
				postidentDigital: action.payload.postidentDigital,
				postidentCoupon: action.payload.postidentCoupon,
				kontoident: action.payload.kontoident
			});
		default:
			return state;
	}
};

export default reducers;
