import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { m } from 'utils/messages/messages';
import { useValidationUtils } from 'utils/validation/validation';

import { Row, Col } from 'components/Atoms/Grid';
import Divider from 'components/Atoms/Divider/Divider';
import Button from 'components/Atoms/Button/Button';
import Alert from 'components/Atoms/Alert/Alert';
import SvgSecureConnection from 'components/Atoms/SVG/Icons/SvgSecureConnection';

import { useSelector } from 'react-redux';

import styles from './Form.module.scss';

const Form = (props) => {
	const storeButtonLoading = useSelector((state => state.app.buttonLoading));
	const storeErrorMessages = useSelector((state => state.form.errorMessages));
	const storeSuccessMessages = useSelector((state => state.form.successMessages));

	const { clear } = useValidationUtils();

	const [clearingErrors, setClearingErrors] = useState(true);

	useEffect(() => {
		const firstInputArray = document.querySelectorAll('[tabindex="1"]');
		if (firstInputArray.length > 0) {
			firstInputArray[0].focus();
		}
	}, []);

	useEffect(() => {
		if (!clearingErrors) {
			if (props.onSubmit) {
				props.onSubmit();
			}
		}
	}, [clearingErrors]);

	const onSubmit = async (event) => {
		event.preventDefault();
		setClearingErrors(true);
		if (props.clearErrors) {
			await clear();
			props.clearErrors();
			setClearingErrors(false);
		} else {
			setClearingErrors(false);
		}
	};

	const submitButton = () => {
		return (
			<>
				{props.onSubmit && (
					<div className={classNames([styles.buttonWrapper, styles.end])}>
						<Button
							buttonType="submit"
							onClick={onSubmit}
							disabled={!!props.submitDisabled}
							loading={storeButtonLoading}
							tabindex={props.submitTabindex}
							fullWidthMobile
							fullWidthTablet={props.submitOnly}
							fullWidthDesktop={props.submitOnly}
							testId="submit"
						>
							{props.submitText ?? m('next.label', 'fields')}
						</Button>
					</div>
				)}
			</>
		);
	};

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();
			}}
		>
			{props.children}

			<div className={styles.alerts}>
				{props.formError && <Alert messages={[{ message: props.formError }]} type="error" />}
				<Alert messages={storeErrorMessages} type="error" />
				<Alert messages={storeSuccessMessages} type="success" />
			</div>

			{!props.submitOnly && (<Divider />)}

			{props.submitOnly ? (
				<>{submitButton()}</>
			) : (
				<Row>
					<Col xs={12} sm={4} md={5} xl={6} order={4} orderSm={1}>
						{props.onPrevButton && (
							<div className={styles.buttonWrapper}>
								<Button
									buttonStyle={props.prevButtonStyle ?? 'link'}
									onClick={(event) => props.onPrevButton(event)}
									fullWidthMobile
								>
									{props.prevButtonText ? props.prevButtonText : m('prev.label', 'fields')}
								</Button>
							</div>
						)}
						{props.prevButtonExtension && (
							<div className={styles.prevButtonExtension}>
								{props.prevButtonExtension}
							</div>
						)}
					</Col>
					<Col xs={12} sm={8} md={7} xl={6} order={1} orderSm={4}>
						{submitButton()}
					</Col>
				</Row>
			)}

			{!props.submitOnly && (
				<div className={styles.submitFooter}>
					<Divider onlyMobile />
					<Row noWrap flexColumnMobile>
						{props.footerContent && <Col noGrow>{props.footerContent}</Col>}
						<Col isSpacer />
						<Col noGrow>
							{props.onSubmit && (
								<>
									{props.submitExtension && (
										<div className={styles.submitExtension}>
											{props.submitExtension}
										</div>
									)}
									<div className={styles.secureConnection}>
										<SvgSecureConnection />
									</div>
								</>
							)}
						</Col>
					</Row>
				</div>
			)}
		</form>
	);
};

Form.propTypes = {
	onSubmit: PropTypes.func,
	submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	submitDisabled: PropTypes.bool,
	submitTabindex: PropTypes.number,
	submitExtension: PropTypes.any,
	submitOnly: PropTypes.bool,
	clearErrors: PropTypes.func,
	formError: PropTypes.string,
	onPrevButton: PropTypes.func,
	prevButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	prevButtonStyle: PropTypes.oneOf(['primary', 'secondary', 'link', 'link-border']),
	prevButtonExtension: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	footerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	children: PropTypes.any
};

export default Form;
