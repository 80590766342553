export const constants = {
	PRODUCT_TYPE: {
		GIRO: 'BESTGIRO',
		GMK: 'GMK',
		SPARBRIEF: 'SPARBRIEF',
		DEPOT: 'DEPOT',
		BEST_CARD_BASIC: 'BEST_CARD_BASIC',
		BEST_CARD_SMART: 'BEST_CARD_SMART',
		BEST_CARD_EXTRA: 'BEST_CARD_EXTRA',
		BEST_CARD_PREMIUM: 'BEST_CARD_PREMIUM',
		VISACARD: 'VISACARD',
		CREDIT: 'BESTCREDIT',
		SHORT_CREDIT: 'BESTCREDIT_SHORT'
	},
	FRONTEND: {
		GIRO: '/giro',
		GMK: '/gmk',
		SPARBRIEF: '/sparbrief',
		DEPOT: '/depot',
		BEST_CARD_BASIC: '/bestcard',
		BEST_CARD_SMART: '/bestcard-smart',
		BEST_CARD_EXTRA: '/bestcard-extra',
		BEST_CARD_PREMIUM: '/bestcard-premium',
		VISACARD: '/visacard',
		CREDIT: '/kredit',
		SHORT_CREDIT: '/bestcredit-kurzantrag'
	},
	ROUTES: {
		INDEX: '/',
		NAME: '/name',
		LOGIN: '/login',
		BIRTHDATE: '/geburtsdatum',
		EMAIL: '/email',
		EMAIL_SENT: '/email-gesendet',
		EMAIL_CONFIRMED: '/email-bestaetigt',
		EMAIL_NOT_CONFIRMED: '/email-nicht-bestaetigt',
		MOBILE_NUMBER: '/mobiltelefon',
		OTP: '/zugangscode',
		NATIONALITY: '/nationalitaet',
		ADDRESS: '/adresse',
		PREVIOUS_ADDRESS: '/vorherige-adresse',
		MARITAL_STATUS: '/familienstand',
		OCCUPATION: '/beschaeftigungsverhaeltnis',
		TAX_INFORMATION: '/fatca',
		TIN: '/steuernummer',
		NO_US: '/usa-hinweis',
		STOCK_PROFICIENCY: '/wertpapier-erfahrung',
		NATIONAL_ID: '/national-id',
		CLEARING_ACCOUNT: '/verrechnungs-konto',
		CLEARING_ACCOUNT_ERROR: '/verrechnungs-konto-fehler',
		TERMS_AND_CONDITIONS: '/kauf-bestaetigen',
		AFTER_PURCHASE_GIRO: '/kauf-bestaetigt',
		BCO_RESPONSE_GREEN: '/antrag-angenommen',
		BCO_RESPONSE_GREEN_LIMIT: '/antrag-angenommen-limit',
		DEPOT_PURCHASE_SUCCESS: '/depot-eroeffnet',
		BANK_ACCOUNT: '/giro-konto',
		FINANCIAL_STATUS: '/finanzieller-status',
		FINANCIAL_STATUS_DAC: '/finanzieller-status-kontoblick',
		DIGITAL_ACCOUNT_CHECK: '/kontoblick',
		DIGITAL_ACCOUNT_CHECK_SUCCESS: '/kontoblick-erfolgreich',
		DIGITAL_ACCOUNT_CHECK_FAILURE: '/kontoblick-nicht-erfolgreich',
		EXTERNAL_CREDIT: '/fremdverpflichtungen',
		PAYMENT_PROTECTION_INSURANCE: '/ratenschutzversicherung',
		UPLOAD_DOCUMENTS: '/fehlende-dokumente-hochladen',
		UPLOAD_DOCUMENTS_LATER: '/fehlende-dokumente-spaeter-hochladen',
		IDENTIFICATION: '/identifizierung',
		POSTIDENT: '/postident',
		DIGITAL_POSTIDENT: '/postident-identifizierung',
		DIGITAL_POSTIDENT_CONFIRMATION: '/postident-bestaetigen',
		WEB_ID_DONE: '/webid-abgeschlossen',
		ADDRESS_SELECTION: '/adressbestaetigung',
		ADDRESS_VERIFICATION: '/adressbestaetigung-upload',
		ADDRESS_VERIFICATION_POSTPONED: '/warten-auf-adressvalidierung',
		ACTIVATE_LOAN: '/kredit-aktivieren',
		LOAN_SUCCESS: '/kredit-erfolgreich',
		LOAN_DONE: '/kredit-beantragt',
		DISPO: '/dispo',
		ACCOUNT_SUCCESS: '/konto-eroeffnet',
		CREDIT_CARD_SUCCESS: '/kreditkarte-aktiviert',
		ACTIVATE_CREDIT_CARD_ACCOUNT: '/kreditkarte-aktivieren',
		SHORT_APPLICATION: '/angaben',
		UPSELL: '/gegenangebot',
		GIRO_EXISTS: '/giro-vorhanden',
		GMK_EXISTS: '/gmk-vorhanden',
		FINAL_STEPS_TWO_BORROWERS: '/letzte-schritte',
		GIRO_DATA: '/giro-daten',
		CARD_OPTIONS: '/karten-optionen',
		THANKYOU: '/vielen-dank',
		THANKYOU2: '/dankeschoen',
		THANKYOU3: '/vielendank',
		THANKYOU_PEP: '/vielen-dank-kein-online',
		ERROR_TECHNICAL: '/technischer-fehler',
		ERROR_TECHNICAL_APP: '/app-technischer-fehler'
	},
	CONDITIONS: {
		CCB: 'CCB',
		CCB90: '90_CCB',
		WUESTENROT: 'WUESTENROT'
	},
	DOCUMENTS_UPLOADED: {
		ALL: 'ALL_DOCUMENTS_UPLOADED',
		NONE: 'NO_DOCUMENTS_UPLOADED',
		NOT_ALL: 'NOT_ALL_DOCUMENTS_UPLOADED'
	},
	DAC_STATUS: {
		RED: 'RED',
		YELLOW: 'YELLOW',
		GREEN: 'GREEN',
		UNKNOWN: 'UNKNOWN'
	},
	DAC_XS2A_STATUS: {
		BANK: 'BANK',
		ACCOUNT: 'ACCOUNT'
	},
	DISPO_STATUS: {
		NA: 'NA',
		APPROVED: 'APPROVED',
		REJECTED: 'REJECTED'
	},
	DUMMY_IBAN: 'DE12 1231 2312 1231 23',
	DUMMY_IBAN_2: 'DE02 1203 0000 0000 2020 51'
};

export default constants;
