import { clone, reject } from 'lodash';

export const defaultState = {
	gtmType: undefined,
	gtmSubType: undefined,
	gtmStep: undefined,
	gtmStatus: undefined,
	gtmSubStatus: undefined,
	gtmIdentificationDocument: undefined,
	cart: []
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_GTM_TYPE':
			return { ...state, gtmType: action.payload };
		case 'SET_GTM_SUBTYPE':
			return { ...state, gtmSubType: action.payload };
		case 'SET_GTM_STEP':
			return { ...state, gtmStep: action.payload };
		case 'SET_GTM_STATUS':
			return { ...state, gtmStatus: action.payload };
		case 'SET_GTM_SUBSTATUS':
			return { ...state, gtmSubStatus: action.payload };
		case 'SET_GTM_IDENTIFICATION_DOCUMENT':
			return { ...state, gtmIdentificationDocument: action.payload };
		case 'SET_CART':
			return { ...state, cart: action.payload };
		case 'ADD_TO_CART':
			return {
				...state,
				cart: [
					...state.cart,
					action.payload
				]
			};
		case 'REMOVE_FROM_CART':
			let newCartRemove = clone(state.cart);
			newCartRemove = reject(newCartRemove, (item) => {
				return item.item_id === action.payload;
			});
			state.cart = newCartRemove;
			return state;
		default:
			return state;
	}
};

export default reducers;
