export const useEnvUtils = () => {
	const devUrls = process.env.REACT_APP_SERVER_DEV_URLS ? process.env.REACT_APP_SERVER_DEV_URLS.split(',') : [];
	const preUrls = process.env.REACT_APP_SERVER_PRE_URLS ? process.env.REACT_APP_SERVER_PRE_URLS.split(',') : [];
	const proUrls = process.env.REACT_APP_SERVER_PRO_URLS ? process.env.REACT_APP_SERVER_PRO_URLS.split(',') : [];

	const isLocal = () => {
		return process.env.NODE_ENV === 'development';
	};

	const isTest = () => {
		return process.env.NODE_ENV === 'test';
	};

	const isProduction = () => {
		return process.env.NODE_ENV === 'production';
	};

	const isServerDev = () => {
		const { host } = window.location;
		return isProduction() && devUrls.includes(host);
	};

	const isServerPre = () => {
		const { host } = window.location;
		return isProduction() && preUrls.includes(host);
	};

	const isServerPro = () => {
		const { host } = window.location;
		return isProduction() && proUrls.includes(host);
	};

	const isServer = () => {
		return isServerDev() || isServerPre() || isServerPro();
	};

	const showDebug = () => {
		return isLocal() || isServerDev() || isServerPre();
	};

	const showDebugNavigation = () => {
		return isLocal() || isServerDev();
	};

	const showAutofill = () => {
		return isLocal() || isServerDev() || isServerPre();
	};

	const useLoginRedirect = () => {
		return !isLocal();
	};

	return {
		isLocal,
		isTest,
		isProduction,
		isServerDev,
		isServerPre,
		isServerPro,
		isServer,
		showDebug,
		showDebugNavigation,
		showAutofill,
		useLoginRedirect
	};
};
