import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M12.476 2a.5.5 0 0 1 .294.095l.06.051 5.016 5.016a.5.5 0 0 1 .136.253l.01.097.008.984a.5.5 0 0 1-.991.098L17 8.504 16.995 8 12.499 8a.5.5 0 0 1-.492-.41l-.008-.09-.001-4.5H5v18h4.5a.5.5 0 0 1 .09.992L9.5 22h-5a.5.5 0 0 1-.492-.41L4 21.5v-19a.5.5 0 0 1 .41-.492L4.5 2h7.976zm4.405 8.404a1.385 1.385 0 0 1 1.843-.1l.113.1.76.76c.502.504.535 1.3.1 1.843l-.101.112-6.735 6.735a.5.5 0 0 1-.275.14l-.079.006H10.5a.5.5 0 0 1-.492-.41L10 19.5v-2.007a.5.5 0 0 1 .095-.293l.051-.06 6.735-6.736zm1.249.708a.385.385 0 0 0-.482-.05l-.06.05L11 17.7V19h1.3l6.588-6.588a.384.384 0 0 0 .05-.481l-.05-.06-.758-.759zM12.998 3.73V7l3.27-.001-3.27-3.269z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
