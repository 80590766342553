export const DEFAULT_INPUT = /^[0-9A-Za-zäöüÄÖÜß\-,@.+_!#$%&/=?^{|}~\s]+$/;

export const NUMBER_INPUT = /^[0-9]*$/;
export const NUMBER_WITHOUT_ZERO_INPUT = /^[1-9]*$/;
export const NUMBER_WITHOUT_LEADING_ZERO_INPUT = /^[1-9][0-9]*$/;
export const NUMBER_WITH_DOT_INPUT = /^[0-9.]*$/;
export const NUMBER_WITH_DOT_AND_COMMA_INPUT = /^[0-9.,]*$/;
export const CHAR_INPUT = /^[A-Za-zäöüÄÖÜß]+$/;
export const CHAR_SPACE_INPUT = /^[A-Za-zäöüÄÖÜß\s]+$/;
export const CHAR_SPACE_DASH_INPUT = /^[A-Za-zäöüÄÖÜß\-\s]+$/;
export const CHAR_SPACE_DASH_DOT_INPUT = /^[A-Za-zäöüÄÖÜß\-.\s]+$/;
export const CHAR_SPACE_DASH_COMMA_INPUT = /^[A-Za-zäöüÄÖÜß\-,\s]+$/;
export const CHAR_SPACE_DASH_COMMA_DOT_INPUT = /^[A-Za-zäöüÄÖÜß\-,.\s]+$/;
export const CHAR_NUMBER_INPUT = /^[0-9A-Za-zäöüÄÖÜß]+$/;
export const CHAR_NUMBER_SPACE_DASH_INPUT = /^[0-9A-Za-zäöüÄÖÜß\-\s]+$/;
export const CHAR_NUMBER_SPACE_DASH_DOT_INPUT = /^[0-9A-Za-zäöüÄÖÜß\-.\s]+$/;
export const DATE_INPUT = /^[0-9]?[0-9]?[./]?[0-9]?[0-9]?[./]?[0-9]?[0-9]?[0-9]?[0-9]?$/;
export const EMAIL_INPUT = /^[0-9A-Za-z"\-@.+_!#$%&/=?^{|}~]+$/;
export const TIN_INPUT = /^[0-9A-Za-zäöüÄÖÜß.\-/,\s]+$/;
export const IBAN_INPUT = /^(?:[A-Za-z]|$){2}(?:\d|$){2}(?:[a-zA-Z0-9\s]|$){11,35}$/;

export const IS_NUMBER = /^[0-9]*$/;
export const IS_NUMBER_WITHOUT_ZERO = /^[0-9]*$/;
export const IS_DATE = /^(0[1-9]{1}|1[0-2]{1})\.\d{4}$/;
export const IS_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
