/** Actions Type */
const SET_GTM_TYPE = 'SET_GTM_TYPE';
const SET_GTM_SUBTYPE = 'SET_GTM_SUBTYPE';
const SET_GTM_STEP = 'SET_GTM_STEP';
const SET_GTM_STATUS = 'SET_GTM_STATUS';
const SET_GTM_SUBSTATUS = 'SET_GTM_SUBSTATUS';
const SET_GTM_IDENTIFICATION_DOCUMENT = 'SET_GTM_IDENTIFICATION_DOCUMENT';
const SET_CART = 'SET_CART';
const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

/** Actions Creators */
export const setGtmType = payload => ({
	type: SET_GTM_TYPE,
	payload
});

export const setGtmSubType = payload => ({
	type: SET_GTM_SUBTYPE,
	payload
});

export const setGtmStep = payload => ({
	type: SET_GTM_STEP,
	payload
});

export const setGtmStatus = payload => ({
	type: SET_GTM_STATUS,
	payload
});

export const setGtmSubStatus = payload => ({
	type: SET_GTM_SUBSTATUS,
	payload
});

export const setGtmIdentificationDocument = payload => ({
	type: SET_GTM_IDENTIFICATION_DOCUMENT,
	payload
});

export const setCart = payload => ({
	type: SET_CART,
	payload
});

export const addToCart = payload => ({
	type: ADD_TO_CART,
	payload
});

export const removeFromCart = payload => ({
	type: REMOVE_FROM_CART,
	payload
});
