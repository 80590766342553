import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Box.module.scss';

const Box = (props) => {
	const getStyle = () => {
		switch (props.boxStyle) {
			case 'secondary':
				return styles.secondary;
			default:
				return styles.primary;
		}
	};

	const getSize = () => {
		switch (props.size) {
			case 's':
				return styles.s;
			default:
				return styles.m;
		}
	};

	return (
		<div className={classNames([
			styles.box,
			getStyle(),
			getSize(),
			props.isRow ? styles.isRow : null,
			props.border ? styles.border : null,
			props.borderRadius ? styles.borderRadius : null,
			props.shadow ? styles.shadow : null,
			props.mb ? styles.mb : null
		])}>
			{props.icon && <span className={styles.icon}>{props.icon}</span>}
			{props.children}
		</div>
	);
};

Box.propTypes = {
	boxStyle: PropTypes.string,
	size: PropTypes.string,
	border: PropTypes.bool,
	isRow: PropTypes.bool,
	borderRadius: PropTypes.bool,
	shadow: PropTypes.bool,
	mb: PropTypes.bool,
	icon: PropTypes.any,
	children: PropTypes.any
};

export default Box;
