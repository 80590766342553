import React, { useEffect, useRef, useState } from 'react';
import C from 'constants/Constants';
import { NUMBER_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useItemsUtils } from 'utils/items/items';

import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import { TextInput, DropdownInput, RadioButtonInput } from 'components/Atoms/Form';
import Divider from 'components/Atoms/Divider/Divider';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setAmount, setPaymentType, setRuntime } from 'store/Investment/actions';

const Investment = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { getInvestmentPaymentTypeItems, getInvestmentRuntimeItems } = useItemsUtils();

	const paymentTypeItems = getInvestmentPaymentTypeItems();
	const runtimeItems = getInvestmentRuntimeItems();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeAmount = useSelector((state => state.investment.amount));
	const storeRuntime = useSelector((state => state.investment.runtime));
	const storePaymentType = useSelector((state => state.investment.paymentType));

	const runtimeIsGreaterThan12 = () => {
		if ((storeRuntime || storeRuntime === 0) && !isNaN(storeRuntime)) {
			const runtimeNum = parseInt(storeRuntime + '');
			return runtimeNum > 12;
		}
	};

	const [blockPaymentType, setBlockPaymentType] = useState(!runtimeIsGreaterThan12());

	const [amountError, setAmountError] = useState(null);
	const [runtimeError, setRuntimeError] = useState(null);
	const [paymentTypeError, setPaymentTypeError] = useState(null);

	const isInitialMount = useRef(true);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			if (storeRuntime) {
				if (runtimeIsGreaterThan12()) {
					setBlockPaymentType(false);
					dispatch(setPaymentType(paymentTypeItems[0].value));
				} else {
					setBlockPaymentType(true);
					dispatch(setPaymentType(paymentTypeItems[1].value));
				}
			}
		}
	}, [storeRuntime]);

	const clearErrors = () => {
		setAmountError(null);
		setRuntimeError(null);
		setPaymentTypeError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let hasAmountError = false;

		if (isEmpty(storeAmount)) {
			setAmountError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else if (parseInt(storeAmount + '') < 2500) {
			setAmountError(m('investment.amount.error.amountTooSmall', currentFlow));
			hasAmountError = true;
		}
		if (isEmpty(storeRuntime)) {
			setRuntimeError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (isEmpty(storePaymentType)) {
			setPaymentTypeError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields && !hasAmountError;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.NAME);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		dispatch(setAmount(4000));
		dispatch(setRuntime(48));
		dispatch(setPaymentType(true));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title>{m('investment.amount.title', currentFlow)}</Title>
			<Text mb>{m('investment.amount.subtitle', currentFlow)}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={4}
			>
				<Row>
					<Col xs={12} sm={6}>
						<TextInput
							value={storeAmount || storeAmount === 0 ? storeAmount + '' : null}
							setValue={(value) => { dispatch(setAmount(value)); }}
							label={m('amount.label', 'fields')}
							hasError={!!amountError}
							message={amountError}
							suffix="€"
							regex={NUMBER_INPUT}
							tabindex={1}
							testId="amount"
						/>
					</Col>
					<Col xs={12} sm={6}>
						<DropdownInput
							value={storeRuntime || storeRuntime === 0 ? storeRuntime + '' : null}
							setValue={(value) => { dispatch(setRuntime(value)); }}
							items={runtimeItems}
							label={m('runtime.label', 'fields')}
							hasError={!!runtimeError}
							message={runtimeError}
							tabindex={2}
							testId="runtime"
						/>
					</Col>
					<Col xs={12}>
						<Divider noLine noMarginBottom />
						<Title size="s" mb>{m('investment.paymentType.title', currentFlow)}</Title>
						<RadioButtonInput
							value={storePaymentType ?? ''}
							setValue={(value) => { dispatch(setPaymentType(value)); }}
							items={paymentTypeItems}
							disabled={!storeRuntime}
							interactionDisabled={storeRuntime && blockPaymentType}
							hasError={!!paymentTypeError}
							message={paymentTypeError}
							btnStyle="switch"
							tabindex={3}
							testId="payment-type"
						/>
					</Col>

					{storeRuntime && blockPaymentType && (
						<Col xs={12}>
							<Text>{m('investment.paymentType.warning', currentFlow, null, true)}</Text>
						</Col>
					)}

				</Row>
			</Form>
		</>
	);
};
export default Investment;
