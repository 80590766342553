import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M12 2c5.514 0 10 4.486 10 10a9.948 9.948 0 0 1-2.587 6.689.487.487 0 0 1-.112.123C17.474 20.768 14.88 22 12 22c-2.881 0-5.474-1.232-7.3-3.188a.487.487 0 0 1-.113-.123A9.948 9.948 0 0 1 2 12C2 6.486 6.486 2 12 2zm3.5 14.293c-.445 0-1.068.085-1.775.24l-.268.062-.335.086c-.393.102-.798.208-1.122.208-.272 0-.524-.054-.753-.114l-.324-.087a9.756 9.756 0 0 0-.621-.143l-.314-.056-.28-.047c-.387-.066-.87-.149-1.208-.149-1.534 0-2.888.711-2.993 1.546l-.007.105v.264a8.96 8.96 0 0 0 12.792.211l.208-.211v-.264c0-.94-1.29-1.65-3-1.65zM12 3c-4.962 0-9 4.038-9 9a8.94 8.94 0 0 0 1.675 5.21c.484-1.103 2.006-1.917 3.825-1.917.34 0 .73.056 1.102.117l.545.091c.231.038.462.082.69.135l.34.084.167.045c.232.064.45.124.656.124.13 0 .333-.042.547-.094l.677-.172c.631-.15 1.537-.33 2.276-.33 1.916 0 3.381.768 3.837 1.9A8.94 8.94 0 0 0 21 12c0-4.962-4.038-9-9-9zm-.002 1.6c1.112 0 2.234.507 2.928 1.322a2.9 2.9 0 0 1 .704 2.173l-.025.196-.387 3.093c-.253 1.663-1.547 2.772-3.22 2.772-1.607 0-2.863-1.025-3.18-2.57l-.034-.189-.39-3.124c-.137-.833.104-1.675.68-2.353.693-.814 1.813-1.32 2.924-1.32zm0 1c-.826 0-1.654.37-2.162.968-.248.292-.52.762-.474 1.388l.02.174.392 3.13c.175 1.146 1.048 1.896 2.224 1.896 1.119 0 1.965-.677 2.198-1.738l.032-.17.387-3.1a1.904 1.904 0 0 0-.45-1.578c-.51-.598-1.34-.97-2.167-.97z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
