import React from 'react';
import { useLocation } from 'react-router-dom';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';

import Button from 'components/Atoms/Button/Button';
import CreditCalculatorMonthlyRate from './CreditCalculatorMonthlyRate';
import CreditCalculatorDesiredAmount from './CreditCalculatorDesiredAmount';
import CreditCalculatorRuntime from './CreditCalculatorRuntime';
// import CreditCalculatorFirstRateOption from './CreditCalculatorFirstRateOption';

import { useDispatch } from 'react-redux';
import { setSidebarActive } from 'store/App/actions';

import styles from './CreditCalculator.module.scss';

const CreditCalculator = () => {
	const { getCurrentFlow } = useUtils();

	const location = useLocation();
	const dispatch = useDispatch();

	const currentFlow = getCurrentFlow();

	const isCreditIndexPage =
		location.pathname === C.FRONTEND.CREDIT ||
		location.pathname === C.FRONTEND.CREDIT + C.ROUTES.INDEX;

	return (
		<>
			<CreditCalculatorMonthlyRate />

			<CreditCalculatorDesiredAmount />

			<CreditCalculatorRuntime />

			{/* Temporary removed
			{props.reducers.app.condition !== C.CONDITIONS.WUESTENROT ? (
				<CreditCalculatorFirstRateOption />
			) : null}
			*/}

			<div className={styles.submitbuttonWrapper}>
				<Button onClick={() => { dispatch(setSidebarActive(false)); }}>
					{
						isCreditIndexPage
							? m('name.request', currentFlow)
							: m('next.label', 'fields')
					}
				</Button>
			</div>
		</>
	);
};
export default CreditCalculator;
