import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import C from 'constants/Constants';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useDocumentsApi } from 'api/documents';

import { Row, Col } from 'components/Atoms/Grid';
import { Title, Text } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { MultipleFileUpload } from 'components/Atoms/Form';
import DownloadContracts from 'components/Atoms/Partials/DownloadContracts/DownloadContracts';

import { useSelector } from 'react-redux';

const UploadDocuments = () => {
	const params = useParams();
	const { getCurrentFlow, isCreditSubType, isCreditCardSubType, isCreditOrCreditCardSubType } = useUtils();
	const { focusFirstErrorInput, addErrorMessage } = useValidationUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid, ecommercePurchase } = useTrackingUtils();
	const { getMissingDocuments, postForwardDocuments, postUploadLaterNotification } = useDocumentsApi();

	const currentFlow = getCurrentFlow();

	const storeCurrentApplicant = useSelector((state => state.applicant.currentApplicant));
	const storeFirstApplicantContractSigned = useSelector((state => state.applicant.firstApplicantContractSigned));
	const storeSecondApplicantContractSigned = useSelector((state => state.applicant.secondApplicantContractSigned));

	const [isReminder, setIsReminder] = useState(false);

	const [missingDocumentsDAC, setMissingDocumentsDAC] = useState([]);
	const [missingDocuments, setMissingDocuments] = useState([]);

	useEffect(() => {
		// Get isReminder param
		const tempIsReminder = parseParam();

		ecommercePurchase();

		let noDocumentsNeededDestination = C.ROUTES.IDENTIFICATION;
		if (tempIsReminder) {
			if (isCreditSubType()) {
				noDocumentsNeededDestination = C.ROUTES.LOAN_DONE;
			} else if (isCreditCardSubType()) {
				noDocumentsNeededDestination = C.ROUTES.ACTIVATE_CREDIT_CARD_ACCOUNT;
			}
		}

		getMissingDocuments(
			missingDocumentsDAC,
			setMissingDocumentsDAC,
			missingDocuments,
			setMissingDocuments,
			storeCurrentApplicant ?? 1,
			false,
			noDocumentsNeededDestination,
			tempIsReminder
		);
	}, [storeCurrentApplicant]);

	const parseParam = () => {
		let tempIsReminder = false;
		if (params.isReminder) {
			let isReminderParam = params.isReminder;
			if (
				isReminderParam !== undefined &&
				isReminderParam.length > 0 &&
				isReminderParam === 'reminder'
			) {
				tempIsReminder = true;
				setIsReminder(true);
			}
		}
		return tempIsReminder;
	};

	const clearErrors = () => {};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (hasMissingDocuments()) {
			await addErrorMessage(FormErrorCode.EMPTY_FIELDS, m('pages.uploadDocuments.submitDisabledTooltip', 'global'));
			hasEmptyFields = true;
		}

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postForwardDocuments(
				() => {
					continueValid();
					if (storeCurrentApplicant !== null) {
						if (storeCurrentApplicant === 2) {
							goToPage(currentFlow + (storeSecondApplicantContractSigned ? C.ROUTES.FINAL_STEPS_TWO_BORROWERS : C.ROUTES.IDENTIFICATION));
						} else {
							goToPage(currentFlow + (storeFirstApplicantContractSigned ? C.ROUTES.FINAL_STEPS_TWO_BORROWERS : C.ROUTES.IDENTIFICATION));
						}
					} else {
						let nextPage = C.ROUTES.IDENTIFICATION;
						if (isReminder) {
							if (isCreditSubType()) {
								nextPage = C.ROUTES.LOAN_DONE;
							} else if (isCreditCardSubType()) {
								nextPage = C.ROUTES.ACTIVATE_CREDIT_CARD_ACCOUNT;
							}
						}
						goToPage(currentFlow + nextPage);
					}
				},
				() => {
					continueInvalid();
				}
			);
		} else {
			continueInvalid();
		}
	};

	const onPrevSubmit = async () => {
		await postUploadLaterNotification(
			() => {
				continueValid();
				goToPage(currentFlow + C.ROUTES.UPLOAD_DOCUMENTS_LATER);
			},
			() => {
				continueInvalid();
			}
		);
	};

	const hasMissingDocuments = () => {
		let hasMissingsDocuments = false;

		if (missingDocumentsDAC && missingDocumentsDAC.length > 0) {
			missingDocumentsDAC.forEach(missingDocument => {
				if (
					!missingDocument.documentFiles ||
					(missingDocument.documentFiles &&
						missingDocument.documentFiles.length === 0)
				) {
					hasMissingsDocuments = true;
				}
			});
		}

		if (missingDocuments && missingDocuments.length > 0) {
			missingDocuments.forEach(missingDocument => {
				if (
					!missingDocument.documentFiles ||
					(missingDocument.documentFiles &&
						missingDocument.documentFiles.length === 0)
				) {
					hasMissingsDocuments = true;
				}
			});
		}

		return hasMissingsDocuments;
	};

	return (
		<>
			<Title>{m('pages.uploadDocuments.title', 'global')}</Title>
			<Text mb>{m('pages.uploadDocuments.subtitle', 'global')}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				onPrevButton={!isReminder && isCreditSubType() ? onPrevSubmit : null}
				prevButtonText={m('goOnLater.label', 'fields')}
				prevButtonExtension={isCreditOrCreditCardSubType() ? <DownloadContracts /> : null}
				submitTabindex={1}
			>
				<Row justifyContent="center">
					{missingDocumentsDAC.map((item, index) => (
						<Col
							xs={12}
							md={4}
							key={'missing-documents-dac-' + index}
						>
							<div className="mb-20">
								<MultipleFileUpload
									index={index + 1}
									label={item.documentType}
									description={item.description}
									init={item.documentFiles ? item.documentFiles : []}
									missingDocuments={missingDocumentsDAC}
									setMissingDocuments={setMissingDocumentsDAC}
									allowJpg={true}
									allowPng={true}
									allowPdf={true}
									participant={storeCurrentApplicant ?? 1}
								/>
							</div>
						</Col>
					))}

					{missingDocuments.map((item, index) => (
						<Col
							xs={12}
							md={4}
							key={'missing-documents-' + index}
						>
							<div className="mb-20">
								<MultipleFileUpload
									index={index + 1 + missingDocumentsDAC.length}
									label={item.documentType}
									description={item.description}
									init={item.documentFiles ? item.documentFiles : []}
									missingDocuments={missingDocuments}
									setMissingDocuments={setMissingDocuments}
									allowJpg={true}
									allowPng={true}
									allowPdf={true}
									participant={storeCurrentApplicant ?? 1}
								/>
							</div>
						</Col>
					))}
				</Row>
			</Form>
		</>
	);
};
export default UploadDocuments;
