import React from 'react';

const Svg = () => {
	const width = 40;
	const height = 19;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path d="M30.5 1.29a.5.5 0 01.5.5v2.5h2.5a.5.5 0 01.5.5v11a.5.5 0 01-.5.5H22.88c.05-.33.09-.66.11-1h7.06A3.48 3.48 0 0133 12.34v-4.1a3.48 3.48 0 01-2.95-2.95H21a3.47 3.47 0 01-1.31 2.25 8.53 8.53 0 00-.83-.62A2.5 2.5 0 0020 5.29h-2v1.13A9 9 0 0017 6V4.79a.5.5 0 01.5-.5H30v-2H15v3.12c-.33 0-.66-.09-1-.11V1.79a.5.5 0 01.5-.5zM33 13.34a2.5 2.5 0 00-1.95 1.95H33zm-6.56-2.5a.5.5 0 01.5.5.51.51 0 01-.5.5h-2.1a2 2 0 001.8.95 2.15 2.15 0 001.49-.58.5.5 0 11.61.79 3.13 3.13 0 01-2.1.79 3 3 0 01-2.92-1.95h-.57a.51.51 0 01-.5-.5.5.5 0 01.5-.5zm-.3-4a3.09 3.09 0 012.1.8.5.5 0 11-.61.79 2.12 2.12 0 00-1.49-.59 2 2 0 00-2 1.3h2.26a.5.5 0 01.5.5.51.51 0 01-.5.5h-3.75a.51.51 0 01-.5-.5.5.5 0 01.5-.5h.43l.08-.2a3 3 0 012.98-2.15zM33 5.29h-1.95a2.5 2.5 0 001.95 2z" />
			<path d="M13.5 6.87a7.92 7.92 0 110 15.84.4.4 0 01-.4-.4.39.39 0 01.4-.39 7.13 7.13 0 000-14.25.4.4 0 010-.8zm-2 13.46a.4.4 0 01.4.4.39.39 0 01-.4.39H9.15a.39.39 0 01-.4-.39.4.4 0 01.4-.4zm0-2.37a.4.4 0 110 .79H6.77a.4.4 0 110-.79zm0-2.38a.4.4 0 01.4.4.39.39 0 01-.4.39H6a.39.39 0 01-.4-.39.4.4 0 01.4-.4zM13.9 10a.39.39 0 01.39.4v4.35h4.36a.39.39 0 01.39.4.38.38 0 01-.39.39H13.9a.39.39 0 01-.4-.39v-4.71a.4.4 0 01.4-.44zm-2.38 3.17a.4.4 0 110 .79H6a.4.4 0 110-.79zm0-2.38a.4.4 0 01.4.4.39.39 0 01-.4.39H6.77a.39.39 0 01-.4-.39.4.4 0 01.4-.4zm0-2.37a.4.4 0 110 .79H9.15a.4.4 0 110-.79z" />
		</svg>
	);
};
export default Svg;
