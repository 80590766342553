import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';

import Button from 'components/Atoms/Button/Button';
import SvgCheckCircle from 'components/Atoms/SVG/Icons/SvgCheckCircle';

import styles from './FinalStepsBox.module.scss';

export const FinalStepsBox = (props) => {
	const { getCurrentFlow } = useUtils();

	const currentFlow = getCurrentFlow();

	const documentsKey = {
		[C.DOCUMENTS_UPLOADED.ALL]: 'finalStepsTwoBorrowers.documentState.complete',
		[C.DOCUMENTS_UPLOADED.NOT_ALL]: 'finalStepsTwoBorrowers.documentState.missing',
		[C.DOCUMENTS_UPLOADED.NONE]: 'finalStepsTwoBorrowers.documentState.none'
	};

	const getRow = (circleClass, labelText, statusText) => (
		<div className={classNames(styles.finalStepsRow, circleClass)}>
			<div className={styles.statusDotWrapper}>
				<div className={styles.statusDot} />
			</div>
			<span className={styles.statusLabel}>{labelText}</span>
			<span className={styles.status}>{statusText}</span>
		</div>
	);

	return (
		<div className={styles.finalStepsBox}>
			{props.documents === C.DOCUMENTS_UPLOADED.ALL && props.contract ? (
				<div className={styles.finished}>
					<SvgCheckCircle />
					<div className={styles.name}>
						<span>{props.name},</span>
						<br />
						<span>{m('finalStepsTwoBorrowers.finished', currentFlow)}</span>
					</div>
				</div>
			) : (
				<div className={styles.finalSteps}>
					<div className={styles.name}>
						{props.name}
					</div>
					<hr />
					{getRow(
						classNames({
							[styles.uploaded]: props.documents === C.DOCUMENTS_UPLOADED.ALL,
							[styles.missing]: props.documents === C.DOCUMENTS_UPLOADED.NOT_ALL
						}),
						m('finalStepsTwoBorrowers.documents', currentFlow),
						m(documentsKey[props.documents ?? C.DOCUMENTS_UPLOADED.NONE], currentFlow)
					)}
					{getRow(
						classNames({
							[styles.uploaded]: props.contract
						}),
						m('finalStepsTwoBorrowers.identification', currentFlow),
						props.contract ? m('finalStepsTwoBorrowers.complete', currentFlow) : m('finalStepsTwoBorrowers.open', currentFlow)
					)}
					{getRow(
						classNames({
							[styles.uploaded]: props.contract
						}),
						m('finalStepsTwoBorrowers.signature', currentFlow),
						props.contract ? m('finalStepsTwoBorrowers.contractState.signed', currentFlow) : m('finalStepsTwoBorrowers.open', currentFlow)
					)}

					<div className="mt-15">
						<Button
							onClick={props.onContinue}
							tabindex={props.tabindex}
							fullWidthMobile
							fullWidthTablet
							fullWidthDesktop
						>
							{m('finalStepsTwoBorrowers.continue', currentFlow)}
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

FinalStepsBox.propTypes = {
	name: PropTypes.string.isRequired,
	contract: PropTypes.bool,
	documents: PropTypes.string,
	onContinue: PropTypes.func,
	tabindex: PropTypes.number
};

export default FinalStepsBox;
