/** Actions Type */
const SET_ALLOW_ADVERTISING = 'SET_ALLOW_ADVERTISING';
const SET_ACCEPT_TERMS_AND_CONDITIONS = 'SET_ACCEPT_TERMS_AND_CONDITIONS';
const SET_OWN_RESPONSIBILITY = 'SET_OWN_RESPONSIBILITY';
const SET_CONFIRM_STOCK_BASIC_INFO = 'SET_CONFIRM_STOCK_BASIC_INFO';
const SET_ACCEPT_SCHUFA = 'SET_ACCEPT_SCHUFA';
const SET_ACCEPT_PPI = 'SET_ACCEPT_PPI';
const SET_SA_ACCEPT_TERMS_AND_CONDITIONS = 'SET_SA_ACCEPT_TERMS_AND_CONDITIONS';

/** Actions Creators */
export const setAllowAdvertising = payload => ({
	type: SET_ALLOW_ADVERTISING,
	payload
});

export const setAcceptTermsAndConditions = payload => ({
	type: SET_ACCEPT_TERMS_AND_CONDITIONS,
	payload
});

export const setOwnResponsibility = payload => ({
	type: SET_OWN_RESPONSIBILITY,
	payload
});

export const setConfirmStockBasicInfo = payload => ({
	type: SET_CONFIRM_STOCK_BASIC_INFO,
	payload
});

export const setAcceptSchufa = payload => ({
	type: SET_ACCEPT_SCHUFA,
	payload
});

export const setAcceptPpi = payload => ({
	type: SET_ACCEPT_PPI,
	payload
});

export const setSaAcceptTermsAndConditions = payload => ({
	type: SET_SA_ACCEPT_TERMS_AND_CONDITIONS,
	payload
});
