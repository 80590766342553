import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M5.803 5.108C10.325.888 17.416.961 21.852 5.35a.5.5 0 0 1 .06.64l-.059.069-7.532 7.523 7.533 7.565a.5.5 0 0 1 .057.638l-.058.07a.5.5 0 0 1-.638.056l-.07-.058-7.532-7.564-7.53 7.522a.5.5 0 0 1-.638.057l-.07-.057-.066-.067C.967 17.332.906 10.314 5.096 5.816L3.773 4.493a.5.5 0 0 1 .637-.765l.07.058 1.323 1.322zm-.457 1.94l-.019.023c-3.167 3.92-3.107 9.593.203 13.439l.207.233 3.921-3.916c-2.452-2.513-4.026-6.13-4.312-9.779zm.96-.76l.011.291c.183 3.546 1.68 7.11 4.049 9.54l5.75-5.743C13.744 8.062 10.181 6.55 6.68 6.31l-.375-.02zm14.476-.573c-3.87-3.487-9.692-3.612-13.715-.379 3.633.324 7.283 1.915 9.756 4.334l3.96-3.955z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
