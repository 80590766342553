import { PPI_MAX_AGE_CREDIT, PPI_MAX_AGE_DEFAULT, PROTECT_MAX_AGE_CREDIT } from 'constants/Credit';
import { useUtils } from 'utils/utils';
import { useDateUtils } from 'utils/date/date';
import { useCreditUtils } from 'utils/credit/credit';

import { useSelector } from 'react-redux';

export const usePpiUtils = () => {
	const { isCreditSubType, isCreditCardSubType, isBestCardFamilySubType } = useUtils();
	const { mapCurrentRuntimeToYears } = useCreditUtils();
	const { getAge, getAgeWithRuntime } = useDateUtils();

	const storeBirthdate = useSelector((state => state.birthdate.birthdate));
	const storeOccupation = useSelector((state => state.occupation.occupation));
	const storePaymentType = useSelector((state => state.bankAccount.paymentType));

	const showPpiPage = () => {
		if (storeBirthdate) {
			const age = getAge(storeBirthdate);
			if (isCreditSubType()) {
				if (storeOccupation !== 'STUDENT' && storeOccupation !== 'FREELANCER' && storeOccupation !== 'UNEMPLOYED') {
					const ageWithRuntime = getAgeWithRuntime(storeBirthdate, mapCurrentRuntimeToYears());
					return ageWithRuntime < PPI_MAX_AGE_CREDIT || age < PROTECT_MAX_AGE_CREDIT;
				} else {
					return false;
				}
			} else if (isBestCardFamilySubType()) {
				return age < PPI_MAX_AGE_DEFAULT && storePaymentType !== 'VOLLZAHLUNG' && storeOccupation !== 'STUDENT';
			} else if (isCreditCardSubType()) {
				return age < PPI_MAX_AGE_DEFAULT && storeOccupation !== 'STUDENT';
			} else {
				return age < PPI_MAX_AGE_DEFAULT;
			}
		} else {
			return false;
		}
	};

	return {
		showPpiPage
	};
};
