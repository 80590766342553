import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import RadioBoxItem from 'components/Atoms/Form/RadioBoxInput/RadioBoxItem';

import inputStyles from 'components/Atoms/Form/index.module.scss';
import styles from './RadioBoxInput.module.scss';

const RadioBoxInput = (props) => {
	const [id] = useState(uuid());
	const [hasBanner, setHasBanner] = useState(false);

	useEffect(() => {
		const index = findIndex(props.items, (item) => {
			return !!item.banner;
		});
		setHasBanner(index > -1);
	}, [props.items]);

	return (
		<div
			className={classNames([
				styles.wrapper,
				hasBanner ? styles.hasBanner : null,
				props.hasError ? styles.error : null,
				props.hasError ? 'input--error' : null
			])}
			data-testid={props.testId ?? null}
		>
			<div className={styles.row}>
				{props.items && props.items.length > 0 && (
					<>
						{props.items.map((item, index) => (
							<div key={id + '-' + index} className={styles.col}>
								<RadioBoxItem
									id={id}
									index={index}
									item={item}
									hasBanner={hasBanner}
									hasEqualHeight={props.hasEqualHeight}
									hasFixedDescriptionHeight={props.hasFixedDescriptionHeight}
									setValue={props.setValue}
									value={props.value}
									itemStyle={props.itemStyle}
									disabled={props.disabled}
									hasError={props.hasError}
									tabindex={props.tabindex}
								/>
							</div>
						))}
					</>
				)}
			</div>
			<div
				className={classNames([
					styles.message,
					inputStyles.message,
					props.message ? inputStyles.show : null
				])}
			>
				{props.message}
			</div>
		</div>
	);
};

RadioBoxInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	items: PropTypes.array,
	itemStyle: PropTypes.string,
	hasEqualHeight: PropTypes.bool,
	hasFixedDescriptionHeight: PropTypes.bool,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	testId: PropTypes.string
};

export default RadioBoxInput;
