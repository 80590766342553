import React from 'react';
import PropTypes from 'prop-types';

import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

const FixedBorrowingRate = (props) => {
	const { formatMoneyValue } = useNumberUtils();
	const { getFixedBorrowingRate } = useCreditUtils();

	return (
		<>
			{props.isDecimal
				? formatMoneyValue(getFixedBorrowingRate(), true, props.threeDigitsAfterComma)
				: getFixedBorrowingRate()}
		</>
	);
};

FixedBorrowingRate.propTypes = {
	isDecimal: PropTypes.bool,
	threeDigitsAfterComma: PropTypes.bool
};

export default FixedBorrowingRate;
