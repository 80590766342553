import _ from 'lodash';

export const defaultState = {
	currentFlow: null,
	currentSubpage: null,
	currentStepCategory: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_CURRENT_FLOW':
			return _.merge({}, state, {
				currentFlow: action.payload
			});
		case 'UPDATE_CURRENT_SUBPAGE':
			return _.merge({}, state, {
				currentSubpage: action.payload
			});
		case 'UPDATE_CURRENT_STEP_CATEGORY':
			return _.merge({}, state, {
				currentStepCategory: action.payload
			});
		default:
			return state;
	}
};

export default reducers;
