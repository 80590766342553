import React, { useState } from 'react';
import { usePhoneUtils } from 'utils/phone/phone';

import { Link } from 'components/Atoms/Text';

const PhoneLink = () => {
	const { getPhoneNumber } = usePhoneUtils();

	const [phoneNumber] = useState(getPhoneNumber());

	return (
		<Link href={'tel:' + phoneNumber?.link}>
			{phoneNumber?.text}
		</Link>
	);
};

export default PhoneLink;
