import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link, Text } from 'components/Atoms/Text';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import List from 'components/Atoms/Text/List/List';
import SvgChevronDownLink from 'components/Atoms/SVG/Icons/SvgChevronDownLink';

import styles from './RadioBoxItem.module.scss';
import Check from 'components/Atoms/Partials/Check/Check';

const RadioBoxItem = (props) => {
	const [show, setShow] = useState(false);

	const isActive = useMemo(() => {
		return props.value + '' === props.item?.value + '';
	}, [props.value, props.item?.value]);

	const getStyle = useMemo(() => {
		switch (props.itemStyle) {
			case 'SMALL':
				return styles.small;
			default:
				return styles.default;
		}
	}, [props.itemStyle]);

	const toggle = (event) => {
		event.stopPropagation();
		setShow(!show);
	};

	const onChange = (event) => {
		props.setValue(event.target.value);
	};

	const onClickItem = (value) => {
		props.setValue(value);
	};

	return (
		<div
			className={classNames([
				styles.item,
				getStyle,
				show ? styles.toggleActive : null,
				isActive ? styles.isActive : null,
				props.hasBanner ? styles.hasBanner : null,
				props.hasEqualHeight ? styles.hasEqualHeight : null,
				props.hasFixedDescriptionHeight ? styles.hasFixedDescriptionHeight : null,
				props.hasError ? styles.error : null,
				props.item?.additionalContentBottom ? styles.hasAdditionalContentBottom : null
			])}
			onClick={() => {
				onClickItem(props.item?.value);
			}}
		>
			{props.item?.banner && <div className={styles.banner}>{props.item?.banner}</div>}
			<div className={styles.titleWrapper}>
				{props.item?.iconBefore && (
					<div className={styles.iconBeforeWrapper}>{props.item?.iconBefore}</div>
				)}
				<div
					className={classNames([
						styles.title,
						props.item?.iconBefore ? styles.titleHasIconBefore : null,
						props.item?.tooltip ? styles.titleHasTooltip : null
					])}
				>
					<Text mb>
						<strong>{props.item?.title}</strong>
					</Text>
				</div>
				{props.item?.tooltip && (
					<div className={styles.tooltipWrapper}>
						<TooltipIcon text={props.item?.tooltip} />
					</div>
				)}
				{props.item?.iconAfter && (
					<div className={styles.iconAfterWrapper}>
						{props.item?.iconAfter}
						{props.item?.iconAfterText && (
							<span className={styles.iconAfterText}>{props.item?.iconAfterText}</span>
						)}
					</div>
				)}
			</div>
			{props.item?.description && (
				<div className={styles.description}>
					{props.item?.descriptionIconBefore && (
						<div className={styles.descriptiomIconBeforeWrapper}>{props.item?.descriptionIconBefore}</div>
					)}
					<Text size="14">{props.item?.description}</Text>
				</div>
			)}
			{props.item?.list && (
				<div className={classNames(styles.list, props.item?.listTypeChecks ? styles.listTypeChecks : null)}>
					<List size="14" listStyle={props.item?.listStyle}>
						{props.item?.list}
					</List>
				</div>
			)}
			{props.item?.separatedDescription && (
				<div className={styles.separatedDescription}>
					<Text size="14">{props.item?.separatedDescription}</Text>
				</div>
			)}
			{props.item?.toggleButtonText && props.item?.toggleText && (
				<>
					<div
						className={classNames([
							styles.toggleButton,
							show ? styles.toggleButtonActive : null
						])}
					>
						<Text>
							<Link onClick={toggle}>
								{props.item?.toggleButtonText}
								<SvgChevronDownLink />
							</Link>
						</Text>
					</div>
					{show && <div className={styles.contentToggle}>{props.item?.toggleText}</div>}
				</>
			)}
			<div className={styles.checkWrapper}>
				<input
					id={props.id + '-' + props.index}
					type="radio"
					className={styles.radio}
					value={props.item?.value}
					checked={isActive}
					onChange={onChange}
					disabled={props.disabled}
					tabIndex={props.tabindex + 0.01 * props.index}
				/>
				<label htmlFor={props.id + '-' + props.index} className={styles.check}>
					<Check active={isActive} />
				</label>
			</div>
			{props.item?.additionalContentBottom && (
				<div className={styles.additionalContentBottom}>
					{props.item?.additionalContentBottom}
				</div>
			)}
		</div>
	);
};

RadioBoxItem.propTypes = {
	id: PropTypes.string,
	index: PropTypes.number,
	item: PropTypes.object,
	hasBanner: PropTypes.bool,
	hasEqualHeight: PropTypes.bool,
	hasFixedDescriptionHeight: PropTypes.bool,
	value: PropTypes.string,
	setValue: PropTypes.func,
	itemStyle: PropTypes.string,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	tabindex: PropTypes.number
};

export default RadioBoxItem;
