import React from 'react';

const Svg = () => {
	const width = 79;
	const height = 64;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<defs>
				<path id='postidentEnvelop_svg__a' d='M0 63.361h78.184V0H0z' />
			</defs>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#D2F0F2'
					d='M69.766 63.36h-46.92a5.025 5.025 0 01-5.025-5.024V29.953c0-1.482.653-2.889 1.787-3.843l22.78-19.198a5.027 5.027 0 016.375-.085l24.14 19.28a5.029 5.029 0 011.888 3.928v28.3a5.026 5.026 0 01-5.025 5.026'
				/>
				<path
					fill='#FFF'
					d='M26.239 56.841a4.239 4.239 0 01-4.235-4.235V24.224c0-1.251.548-2.432 1.506-3.238L46.29 1.787a4.239 4.239 0 015.372-.07l24.14 19.279a4.22 4.22 0 011.59 3.309v28.301a4.238 4.238 0 01-4.234 4.235h-46.92z'
				/>
				<path
					fill='#C3DEE7'
					d='M49.019 0a5.023 5.023 0 00-3.24 1.183L23 20.381a5.03 5.03 0 00-1.787 3.843v28.381a5.026 5.026 0 005.027 5.027h46.919a5.026 5.026 0 005.026-5.027v-28.3a5.023 5.023 0 00-1.89-3.927L52.154 1.1A4.998 4.998 0 0049.02 0m0 1.581c.777 0 1.54.268 2.148.754l24.14 19.28a3.423 3.423 0 011.295 2.69v28.3c0 1.9-1.545 3.445-3.444 3.445h-46.92a3.448 3.448 0 01-3.444-3.445v-28.38c0-1.019.447-1.979 1.225-2.634L46.8 2.392a3.446 3.446 0 012.22-.81'
				/>
				<path
					fill='#D2F0F2'
					d='M52.788 43.5l14.76-12.56V6.574h-35.94v24.16l15 12.764a5.442 5.442 0 006.18 0'
				/>
				<mask id='postidentEnvelop_svg__b' fill='#fff'>
					<use xlinkHref='#postidentEnvelop_svg__a' />
				</mask>
				<g mask='url(#postidentEnvelop_svg__b)'>
					<path fill='#FFF' d='M23.346 55.498l17.21-17.21' />
					<path
						stroke='#C3DEE7'
						strokeWidth={1.582}
						d='M23.346 55.498l17.21-17.21'
					/>
				</g>
				<g mask='url(#postidentEnvelop_svg__b)'>
					<path fill='#FFF' d='M76.152 55.498l-17.209-17.21' />
					<path
						stroke='#C3DEE7'
						strokeWidth={1.582}
						d='M76.152 55.498l-17.209-17.21'
					/>
				</g>
				<path
					fill='#FFF'
					d='M43.035 20.479h5.889v-4.58h-1.31v3.27h-5.889v3.927h1.31v-2.617zm1.309 2.617h1.962v-1.31h-1.962v1.31zm3.27 5.889h1.31v-1.963h-1.31v1.963zm5.235-7.197h1.309V19.17H50.23v2.618h-2.617v3.925h2.617v3.272h1.31v-4.58h-2.617v-1.31h2.617V20.48h1.308v1.309zm-2.617-3.926h1.309v-4.58h-3.926v1.308h2.616v3.272zm2.617 11.123h1.309v-1.963h1.962v1.963h1.31v-3.272h-4.581v3.272zm3.272-5.89h-3.273v1.31h4.581V19.17H56.12v3.926zm-1.964-6.542h1.964V14.59h-1.964v1.963zm-1.308 1.309h4.58v-4.58h-4.58v4.58zm-9.814 9.814h1.963v-1.963h-1.963v1.963zm-1.31 1.309h4.58v-4.58h-4.58v4.58zm1.31-12.432h1.963V14.59h-1.963v1.963zm-1.31 1.309h4.58v-4.58h-4.58v4.58z'
					mask='url(#postidentEnvelop_svg__b)'
				/>
				<path
					stroke='#C3DEE7'
					strokeWidth={1.582}
					d='M77.078 22.828L52.788 43.5a5.446 5.446 0 01-6.18 0L22.15 22.685'
					mask='url(#postidentEnvelop_svg__b)'
				/>
				<path
					fill='#63818E'
					d='M43.035 20.479h5.889v-4.58h-1.31v3.27h-5.889v3.927h1.31v-2.617zm1.309 2.617h1.962v-1.31h-1.962v1.31zm3.27 5.889h1.31v-1.963h-1.31v1.963zm5.235-7.197h1.309V19.17H50.23v2.618h-2.617v3.925h2.617v3.272h1.31v-4.58h-2.617v-1.31h2.617V20.48h1.308v1.309zm-2.617-3.926h1.309v-4.58h-3.926v1.308h2.616v3.272zm2.617 11.123h1.309v-1.963h1.962v1.963h1.31v-3.272h-4.581v3.272zm3.272-5.89h-3.273v1.31h4.581V19.17H56.12v3.926zm-1.964-6.542h1.964V14.59h-1.964v1.963zm-1.308 1.309h4.58v-4.58h-4.58v4.58zm-9.814 9.814h1.963v-1.963h-1.963v1.963zm-1.31 1.309h4.58v-4.58h-4.58v4.58zm1.31-12.432h1.963V14.59h-1.963v1.963zm-1.31 1.309h4.58v-4.58h-4.58v4.58z'
					mask='url(#postidentEnvelop_svg__b)'
				/>
				<path
					fill='#9BC3D3'
					d='M13.788 24.92H1.07a1.07 1.07 0 010-2.139h12.718a1.071 1.071 0 010 2.14m0 10.693H3.921a1.07 1.07 0 010-2.139h9.867a1.071 1.071 0 010 2.14m0 10.693h-5.59a1.07 1.07 0 01.001-2.14h5.59a1.07 1.07 0 01-.001 2.14'
					mask='url(#postidentEnvelop_svg__b)'
				/>
			</g>
		</svg>
	);
};
export default Svg;
