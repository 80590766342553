import React from 'react';
import PropTypes from 'prop-types';

import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

const PpiValue = (props) => {
	const { formatMoneyValue } = useNumberUtils();
	const { getPpiValue } = useCreditUtils();

	return (
		<>
			{props.isDecimal
				? formatMoneyValue(getPpiValue(), true)
				: getPpiValue()}
		</>
	);
};

PpiValue.propTypes = {
	isDecimal: PropTypes.bool
};

export default PpiValue;
