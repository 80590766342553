import axios from 'axios';
import history from 'config/history';
import C from 'constants/Constants';
import { BEST_CARD_LIMIT_DEFAULT } from 'constants/BestCard';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { useApiUtils } from 'utils/api/api';
import { useDateUtils } from 'utils/date/date';
import { usePhoneUtils } from 'utils/phone/phone';
import { useIbanUtils } from 'utils/iban/iban';
import { useCreditUtils } from 'utils/credit/credit';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useUrlUtils } from 'utils/url/url';
import { useDepotUtils } from 'utils/depot/depot';

import { useDacApi } from 'api/dac';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateAppLoading,
	updateSanc,
	updateUid,
	updateBcoResults,
	setApplicationId,
	updateRedirectVcUrl,
	setVcJwtToken,
	setCrossSellVcJwtToken,
	setRequestFromCrossSelling
} from 'store/App/actions';
import { onUpdateDispoAmount, onUpdateDispoStatus, updateLimitApplicationId } from 'store/Dispo/actions';
import { setBankCode, setClearingAccountIban, setDepotNumber } from 'store/DepotAccount/actions';
import { setNewAccountIban, setNewCreditCardPan, setLoansAccountNumber } from 'store/NewAccount/actions';
import { setGtmStatus, setGtmSubStatus } from 'store/Tracking/actions';
import { setUpsell, resetUpsell } from 'store/Upsell/actions';

export const useApplicationApi = () => {
	const { getCurrentFlow, getProductType, isCreditOrCreditCardSubType, isCreditSubType, isCreditCardSubType, isBestCardFamilySubType } = useUtils();
	const { isEmpty } = useValidationUtils();
	const { getApiBaseURL, startApiCall, stopApiCall, checkUnauthorized } = useApiUtils();
	const { bcoResults, error } = useTrackingUtils();
	const { formatFullDateForApi, getOccupationLimitedStartDay, getOccupationStartDay } = useDateUtils();
	const { removeNonDigitsAndLeadingZeroesFrom } = usePhoneUtils();
	const { getExternalCreditsWithTrimmedIbans } = useCreditUtils();
	const { trimIban } = useIbanUtils();
	const { replaceCharsForUrl } = useUrlUtils();
	const { getStockProficiencies } = useDepotUtils();
	const { getAccountScreeningProcessResult } = useDacApi();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeAccessToken = useSelector((state => state.app.accessToken));
	const storeVcJwtToken = useSelector((state => state.app.vcJwtToken));

	const storeAllowAdvertising = useSelector((state => state.consent.allowAdvertising));

	const storeSalutation = useSelector((state => state.name.salutation));
	const storeFirstName = useSelector((state => state.name.firstName));
	const storeLastName = useSelector((state => state.name.lastName));
	const storeBirthdate = useSelector((state => state.birthdate.birthdate));
	const storeEmail = useSelector((state => state.email.email));
	const storeCountryCode = useSelector((state => state.mobileNumber.countryCode));
	const storeMobileNumber = useSelector((state => state.mobileNumber.mobileNumber));
	const storeNationality = useSelector((state => state.nationality.nationality));
	const storeState = useSelector((state => state.address.state));
	const storeStreet = useSelector((state => state.address.street));
	const storeStreetNumber = useSelector((state => state.address.number));
	const storePostcode = useSelector((state => state.address.postcode));
	const storeCity = useSelector((state => state.address.city));
	const storeHasPreviousAddress = useSelector((state => state.address.hasPreviousAddress));
	const storePreviousStreet = useSelector((state => state.address.previousStreet));
	const storePreviousStreetNumber = useSelector((state => state.address.previousNumber));
	const storePreviousPostcode = useSelector((state => state.address.previousPostcode));
	const storePreviousCity = useSelector((state => state.address.previousCity));
	const storeMaritalStatus = useSelector((state => state.maritalStatus.maritalStatus));
	const storeNumberOfAdults = useSelector((state => state.maritalStatus.numberOfAdults));
	const storeNumberChildren = useSelector((state => state.maritalStatus.numberOfChildren));
	const storeNumberChildrenWithBenefit = useSelector((state => state.maritalStatus.numberOfChildrenWithBenefit));
	const storeOccupation = useSelector((state => state.occupation.occupation));
	const storeOccupationMonth = useSelector((state => state.occupation.occupationMonth));
	const storeOccupationYear = useSelector((state => state.occupation.occupationYear));
	const storeOccupationLimited = useSelector((state => state.occupation.occupationLimited));
	const storeOccupationLimitedMonth = useSelector((state => state.occupation.occupationLimitedMonth));
	const storeOccupationLimitedYear = useSelector((state => state.occupation.occupationLimitedYear));
	const storeReferenceIban = useSelector((state => state.bankAccount.referenceIban));
	const storeExistingOldIban = useSelector((state => state.bankAccount.existingOldIban));
	const storeReferenceIbanSantander = useSelector((state => state.bankAccount.referenceIbanSantander));
	const storeIsSantanderIban = useSelector((state => state.bankAccount.isSantanderIban));
	const storePaymentType = useSelector((state => state.bankAccount.paymentType));
	const storeIncome = useSelector((state => state.financialStatus.income));
	const storeAdditionalIncome = useSelector((state => state.financialStatus.additionalIncome));
	const storeExpenditure = useSelector((state => state.financialStatus.expenditure));
	const storeOtherExpenses = useSelector((state => state.financialStatus.otherExpenses));
	const storeTypeOfLiving = useSelector((state => state.financialStatus.typeOfLiving));
	const storeGermanTin = useSelector((state => state.tin.germanTin));
	const storeTinFatcaCountry = useSelector((state => state.tin.fatcaCountry));
	const storeTinTaxIdentificationNumber = useSelector((state => state.tin.taxIdentificationNumber));
	const storeTaxIdentificationNumberReason = useSelector((state => state.tin.taxIdentificationNumberReason));
	const storeNationalId = useSelector((state => state.nationalId.nationalId));
	const storeHasStockProficiency = useSelector((state => state.stockProficiency.hasStockProficiency));
	const storeHasPpi = useSelector((state => state.insurance.hasPpi));
	const storeProtect = useSelector((state => state.insurance.protect));
	const storeProtectRuntime = useSelector((state => state.insurance.protectRuntime));
	const storeProtectMonthlyRate = useSelector((state => state.insurance.protectMonthlyRate));

	const storeDispoAmount = useSelector((state => state.dispo.amount));
	const storeDispoStatus = useSelector((state => state.dispo.status));
	const storeDispoLimitApplicationId = useSelector((state => state.dispo.limitApplicationId));

	const storeCreditAmount = useSelector((state => state.app.amount));
	const storeCreditRuntime = useSelector((state => state.app.runtime));
	const storeCreditCondition = useSelector((state => state.app.condition));
	const storeBcoResults = useSelector((state => state.app.bcoResults));

	const storeRequestedBestCardLimit = useSelector((state => state.bestCard.requestedLimit));

	const storeInvestmentAmount = useSelector((state => state.investment.amount));
	const storeInvestmentRuntime = useSelector((state => state.investment.runtime));
	const storeInvestmentPaymentType = useSelector((state => state.investment.paymentType));

	const storeSanc = useSelector((state => state.app.sanc));
	const storeUid = useSelector((state => state.app.uid));

	const storeDacTransaction = useSelector((state => state.digitalAccountCheck.transaction));

	const storeAmount = useSelector((state => state.app.amount));

	const storeNewAccountIban = useSelector((state => state.newAccount.iban));

	const postApplication = async (
		onSuccess = () => {},
		onError = () => {},
		onDacResponseReceived = () => {},
		sendOid = false,
		crossSellingFlow = null
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		const flow = crossSellingFlow ?? currentFlow;

		let data = {
			registrationToken: !isEmpty(storeAccessToken) ? storeAccessToken : undefined,
			productType: getProductType(flow),
			scbAntragId: sendOid && !isEmpty(storeBcoResults) && !isEmpty(storeBcoResults.applicationId) ? storeBcoResults.applicationId + '' : undefined,
			allowAdvertising: storeAllowAdvertising,
			ownResponsibility: true,
			acceptTermsAndConditions: true,
			acceptSchufa: true,
			sanc: !isEmpty(storeSanc) ? storeSanc : undefined,
			uid: !isEmpty(storeUid) ? storeUid : undefined,
			conditionSet: !isEmpty(storeCreditCondition) ? storeCreditCondition.toUpperCase() : C.CONDITIONS.CCB,
			requestFromCrossSelling: !!crossSellingFlow ?? undefined
		};

		// First Applicant start -->
		data.applicant = {
			salutation: !isEmpty(storeSalutation) ? storeSalutation.trim() : undefined,
			givenname: !isEmpty(storeFirstName) ? storeFirstName.trim() : undefined,
			surname: !isEmpty(storeLastName) ? storeLastName.trim() : undefined,
			dateOfBirth: !isEmpty(storeBirthdate) ? formatFullDateForApi(storeBirthdate) : undefined,
			email: !isEmpty(storeEmail) ? storeEmail.trim() : undefined,
			mobilePhone: !isEmpty(storeCountryCode) && !isEmpty(storeMobileNumber) ?
				(
					'+' + storeCountryCode +
					' ' + removeNonDigitsAndLeadingZeroesFrom(storeMobileNumber)
				) : undefined,
			nationality: !isEmpty(storeNationality) ? storeNationality.trim() : undefined,
			address: {
				state: !isEmpty(storeState) ? storeState.trim() : undefined,
				street: !isEmpty(storeStreet) ? storeStreet.trim() : undefined,
				streetNr: !isEmpty(storeStreetNumber) ? storeStreetNumber.trim() : undefined,
				zip: !isEmpty(storePostcode) ? storePostcode.trim() : undefined,
				city: !isEmpty(storeCity) ? storeCity.trim() : undefined
			},
			previousAddress: !!storeHasPreviousAddress && !isEmpty(storePreviousStreet) && !isEmpty(storePreviousStreetNumber) && !isEmpty(storePreviousPostcode) && !isEmpty(storePreviousCity) ? {
				street: storePreviousStreet.trim(),
				streetNr: storePreviousStreetNumber.trim(),
				zip: storePreviousPostcode.trim(),
				city: storePreviousCity.trim()
			} : undefined,
			maritalState: !isEmpty(storeMaritalStatus) ? storeMaritalStatus.trim() : undefined,
			numberAdults: !isEmpty(storeNumberOfAdults) ? storeNumberOfAdults : 1,
			numberChildren: !isEmpty(storeNumberChildren) ? storeNumberChildren : 0,
			numberChildrenWithBenefit: !isEmpty(storeNumberChildrenWithBenefit) ? storeNumberChildrenWithBenefit : 0,
			occupation: !isEmpty(storeOccupation) ? storeOccupation.trim() : undefined,
			jobStartingDate: !isEmpty(storeOccupationMonth) && !isEmpty(storeOccupationYear) ? getOccupationStartDay(storeOccupationYear, storeOccupationMonth) : undefined,
			jobTemporaryUntil: storeOccupationLimited === 'Befristet' && !isEmpty(storeOccupationLimitedMonth) && !isEmpty(storeOccupationLimitedYear) ? getOccupationLimitedStartDay(storeOccupationLimitedYear, storeOccupationLimitedMonth) : undefined,
			referenceIban: !isEmpty(storeReferenceIban) ? trimIban(storeReferenceIban) : undefined,
			existingOldIban: !isEmpty(storeExistingOldIban) ? trimIban(storeExistingOldIban) : undefined,
			earnings: !isEmpty(storeIncome, true) ? parseInt(storeIncome) : undefined,
			additionalIncome: !isEmpty(storeAdditionalIncome, true) ? parseInt(storeAdditionalIncome) : undefined,
			livingCosts: !isEmpty(storeExpenditure, true) ? parseInt(storeExpenditure) : undefined,
			additionalCosts: !isEmpty(storeOtherExpenses, true) ? parseInt(storeOtherExpenses) : undefined,
			typeOfLiving: !isEmpty(storeTypeOfLiving) ? storeTypeOfLiving.trim() : undefined,
			germanTin: !isEmpty(storeGermanTin) ? storeGermanTin.trim() : undefined,
			fatcaCountries: !isEmpty(storeTinFatcaCountry) ? storeTinFatcaCountry.trim() : undefined,
			taxIdentificationNumber: !isEmpty(storeTinTaxIdentificationNumber) ? storeTinTaxIdentificationNumber.trim() : undefined,
			taxIdentificationNumberReason: !isEmpty(storeTaxIdentificationNumberReason) ? storeTaxIdentificationNumberReason.trim() : undefined,
			nationalId: !isEmpty(storeNationalId) ? storeNationalId.trim() : undefined
		};
		// First Applicant end -->

		// Giro start -->
		if (flow === C.FRONTEND.GIRO) {
			data.giroLimit = !isEmpty(storeDispoAmount, true) ? parseInt(storeDispoAmount) : undefined;
			data.requestedGiroLimit = storeDispoStatus === C.DISPO_STATUS.REJECTED ? 500 : (!isEmpty(storeDispoAmount, true) ? parseInt(storeDispoAmount) : undefined);
			data.limitApplicationId = !isEmpty(storeDispoLimitApplicationId) ? storeDispoLimitApplicationId.trim() : undefined;
		}
		// Giro end -->

		// Sparbrief start -->
		if (flow === C.FRONTEND.SPARBRIEF) {
			data.amount = !isEmpty(storeInvestmentAmount, true) ? storeInvestmentAmount : undefined;
			data.runtime = !isEmpty(storeInvestmentRuntime, true) ? storeInvestmentRuntime : undefined;
			data.paymentType = !isEmpty(storeInvestmentPaymentType) ? storeInvestmentPaymentType.trim() : undefined;
		}
		// Sparbrief end -->

		// Depot start -->
		if (flow === C.FRONTEND.DEPOT) {
			data.applicant.stockProficiency = storeHasStockProficiency === 'true' ? getStockProficiencies() : undefined;
			if (!isEmpty(storeReferenceIbanSantander)) {
				data.applicant.iban = trimIban(storeReferenceIbanSantander);
			}
		}
		// Depot end -->

		// Credit or CreditCard start -->
		if (isCreditOrCreditCardSubType()) {
			data.applicant.ppi = !!storeHasPpi ?? false;
			data.applicant.protect = !isEmpty(storeProtect) ? storeProtect : undefined;
			data.applicant.protectRuntime = !isEmpty(storeProtectRuntime) ? parseInt(storeProtectRuntime) : undefined;
			data.applicant.protectMonthlyRate = !isEmpty(storeProtectMonthlyRate) ? parseInt(storeProtectMonthlyRate) : undefined;
		}
		// Credit or CreditCard end -->

		// Credit start -->
		if (isCreditSubType()) {
			data.amount = !isEmpty(storeCreditAmount) ? parseInt(storeCreditAmount) : 0;
			data.runtime = !isEmpty(storeCreditRuntime) ? parseInt(storeCreditRuntime) : 0;
			data.applicant.externalCredits = getExternalCreditsWithTrimmedIbans();
			data.ratePause = !!(!isEmpty(storeCreditCondition) && storeCreditCondition === C.CONDITIONS.CCB90);
		}
		// Credit end -->

		// CreditCard start -->
		if (isCreditCardSubType()) {
			data.applicant.paymentType = isBestCardFamilySubType() && !isEmpty(storePaymentType) ? storePaymentType.trim() : 'TEILZAHLUNG';
			data.requestedBestCardLimit = !isEmpty(storeRequestedBestCardLimit) ? parseInt(storeRequestedBestCardLimit) : BEST_CARD_LIMIT_DEFAULT;

			const hasPpi = !!storeHasPpi ?? false;
			data.paymentProtectionInsurance = hasPpi ? 'PPI_COMPLETE' : 'PPI_NONE';
		}

		if (isBestCardFamilySubType()) {
			data.applicant.santanderIban = !!storeIsSantanderIban ?? false;
		}
		// CreditCard end -->

		await axios
			.post(getApiBaseURL() + '/application', data)
			.then(async (response) => {
				await onApplicationSuccess(
					response.data,
					onSuccess,
					onDacResponseReceived,
					crossSellingFlow
				);
			})
			.catch(async (err) => {
				if (err?.response?.data) {
					if (err.response.data.applicationId) {
						dispatch(setApplicationId(err.response.data.applicationId));
					}
					if (err.response.data.vcJwtToken) {
						if (crossSellingFlow) {
							dispatch(setCrossSellVcJwtToken(err.response.data.vcJwtToken));
						} else {
							dispatch(setVcJwtToken(err.response.data.vcJwtToken));
						}
					}

					if (isCreditSubType()) {
						if (err.response.data.bcoResult) {
							dispatch(updateBcoResults(err.response.data.bcoResult));
							setBCOResultsToGTM(err.response.data);
							if (err.response.data.bcoResult.optionAmount?.kreditbetragNetto) {
								dispatch(setUpsell(data.bcoResult));
							} else {
								dispatch(resetUpsell());
							}
						}

						if (err.response.data.resultCode) {
							dispatch(setGtmSubStatus(err.response.data.resultCode));
						} else if (err.response.status) {
							dispatch(setGtmSubStatus(err.response.status));
						}

						if (
							(err.response.data.resultCode === 'bco_approved' || err.response.data.resultCode === 'bco_rejected') &&
							storeDacTransaction &&
							err.response.data.bcoResult &&
							err.response.data.bcoResult.scbAntragId
						) {
							await getAccountScreeningProcessResult(
								true,
								err.response.data.bcoResult.scbAntragId,
								false
							);
						}
					}
				} else if (err?.response?.status) {
					dispatch(setGtmSubStatus(err.response.status));
				}

				const status = err?.response?.status ?? 500;
				error(status, 'POST /application call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(err?.response);
			});
	};

	const setBCOResultsToGTM = (data) => {
		if (data && data.bcoResult !== null) {
			// push bcoResults from application API call to Google Tag Manager data layer
			bcoResults(data.bcoResult);
		}
	};

	const onApplicationSuccess = async (
		data,
		onSuccess = () => {},
		onDacResponseReceived = () => {},
		crossSellingFlow = null
	) => {
		const flow = crossSellingFlow ?? currentFlow;

		setBCOResultsToGTM(data);

		if (data.applicationId) {
			dispatch(setApplicationId(data.applicationId));
		}
		if (data.redirectVcUrl) {
			dispatch(updateRedirectVcUrl(data.redirectVcUrl));
		}
		if (data.vcJwtToken) {
			if (crossSellingFlow) {
				dispatch(setCrossSellVcJwtToken(data.vcJwtToken));
			} else {
				dispatch(setVcJwtToken(data.vcJwtToken));
			}
		}
		if (data.sanc) {
			dispatch(updateSanc(data.sanc));
		}
		if (data.uid) {
			dispatch(updateUid(data.uid));
		}

		if (isCreditCardSubType()) {
			console.log('credit card call');
			if (data.bcoResult) {
				dispatch(updateBcoResults(data.bcoResult));
			}
		} else if (
			flow === C.FRONTEND.DEPOT ||
			flow === C.FRONTEND.GMK ||
			flow === C.FRONTEND.SPARBRIEF
		) {
			console.log('depot/gmk/sparbrief call');
		} else if (flow === C.FRONTEND.GIRO) {
			console.log('giro call');
			if (data.limitStatus === C.DISPO_STATUS.REJECTED) {
				dispatch(onUpdateDispoAmount(0));
				dispatch(onUpdateDispoStatus(C.DISPO_STATUS.REJECTED));
			}
			if (data.limitApplicationId) {
				dispatch(updateLimitApplicationId(data.limitApplicationId));
			}
		} else if (isCreditSubType()) {
			console.log(`credit call from ${history.location.pathname}`);
			if (data.bcoResult) {
				dispatch(updateBcoResults(data.bcoResult));
				if (data.bcoResult?.optionAmount?.kreditbetragNetto) {
					dispatch(setUpsell(data.bcoResult));
				} else {
					dispatch(resetUpsell());
				}
			}

			// Call DAC to Include OID (scbAntragId)
			if (
				storeDacTransaction &&
				data.dacStatus !== 'UNKNOWN' &&
				data.bcoResult && data.bcoResult.scbAntragId
			) {
				await getAccountScreeningProcessResult(
					true,
					data.bcoResult.scbAntragId,
					false,
					onDacResponseReceived
				);
			}
		}

		dispatch(updateAppLoading('none'));
		stopApiCall();

		onSuccess(data);
	};

	const postApplicationShort = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		let data = {
			registrationToken: 'bestcredit_short',
			productType: getProductType(),
			allowAdvertising: true,
			ownResponsibility: true,
			acceptTermsAndConditions: true,
			acceptSchufa: true,
			sanc: !isEmpty(storeSanc) ? storeSanc : undefined,
			uid: !isEmpty(storeUid) ? storeUid : undefined,
			amount: !isEmpty(storeAmount) ? parseInt(storeAmount) : 0,
			conditionSet: C.CONDITIONS.CCB,
			selectSecurityPackage: false,
			selectOtherPPI: false,
			acceptDataStorage: false,
			applicant: {
				salutation: !isEmpty(storeSalutation) ? storeSalutation.trim() : undefined,
				givenname: !isEmpty(storeFirstName) ? storeFirstName.trim() : undefined,
				surname:  !isEmpty(storeLastName) ? storeLastName.trim() : undefined,
				dateOfBirth: !isEmpty(storeBirthdate) ? formatFullDateForApi(storeBirthdate) : undefined,
				email: !isEmpty(storeEmail) ? storeEmail.trim() : undefined,
				mobilePhone: !isEmpty(storeCountryCode) && !isEmpty(storeMobileNumber) ?
					(
						'+' + storeCountryCode +
						' ' + removeNonDigitsAndLeadingZeroesFrom(storeMobileNumber)
					) : undefined,
				address: {
					street: !isEmpty(storeStreet) ? storeStreet.trim() : undefined,
					streetNr: !isEmpty(storeStreetNumber) ? storeStreetNumber.trim() : undefined,
					zip: !isEmpty(storePostcode) ? storePostcode.trim() : undefined,
					city: !isEmpty(storeCity) ? storeCity.trim() : undefined
				},
				maritalState: !isEmpty(storeMaritalStatus) ? storeMaritalStatus.trim() : undefined,
				occupation: !isEmpty(storeOccupation) ? storeOccupation.trim() : undefined,
				earnings: !isEmpty(storeIncome, true) ? parseInt(storeIncome) : undefined,
				additionalIncome: !isEmpty(storeAdditionalIncome, true) ? parseInt(storeAdditionalIncome) : undefined,
				externalCredits: []
			}
		};

		await axios
			.post(getApiBaseURL() + '/application', data)
			.then((response) => {
				onShortApplicationSuccess(
					response.data,
					onSuccess
				);
			})
			.catch(err => {
				if (err?.response?.data) {
					if (err.response.data.applicationId) {
						dispatch(setApplicationId(err.response.data.applicationId));
					}
					if (err.response.data.vcJwtToken) {
						dispatch(setVcJwtToken(err.response.data.vcJwtToken));
					}

					if (err.response.data.resultCode) {
						dispatch(setGtmSubStatus(err.response.data.resultCode));
					} else if (err.response.status) {
						dispatch(setGtmSubStatus(err.response.status));
					}
				} else if (err?.response?.status) {
					dispatch(setGtmSubStatus(err.response.status));
				}

				const status = err?.response?.status ?? 500;
				error(status, 'POST /application call failed');

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(err.response);
			});
	};

	const onShortApplicationSuccess = (
		data,
		onSuccess = () => {}
	) => {
		if (data.applicationId) {
			dispatch(setApplicationId(data.applicationId));
		}

		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess(data);
	};

	const patchApplicationDepotUpdateIban = async (
		iban,
		referenceIban,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		const data = {
			applicant: {
				iban: !isEmpty(iban) ? trimIban(iban) : null,
				referenceIban: !isEmpty(referenceIban) ? trimIban(referenceIban) : null
			}
		};

		await axios
			.patch(getApiBaseURL() + '/application', data, {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then((response) => {
				onPatchApplicationUpdateIbanSuccess(response.data, onSuccess);
			})
			.catch((err) => {
				dispatch(setGtmStatus('create_depot_failed'));
				if (err?.response?.status) {
					dispatch(setGtmSubStatus(err.response.status));
				}

				const status = err?.response?.status ?? 500;
				error(status, 'PATCH /application call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const onPatchApplicationUpdateIbanSuccess = (data, onSuccess = () => {}) => {
		dispatch(setDepotNumber(data?.depotNumber ?? null));
		dispatch(setClearingAccountIban(data?.clearingAccountIban ?? null));
		dispatch(setBankCode(data?.bankCode ?? null));

		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess(data);
	};

	const postDepot = async (
		vcJwtToken,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		await axios({
			method: 'POST',
			url: getApiBaseURL() + '/depot',
			headers: {
				vcJwtToken: vcJwtToken
			},
			timeout: 120000
		})
			.then((response) => {
				onPostDepotSuccess(response.data, onSuccess);
			})
			.catch((err) => {
				dispatch(setGtmStatus('create_depot_failed'));
				if (err?.response?.status) {
					dispatch(setGtmSubStatus(err.response.status));
				}

				const status = err?.response?.status ?? 500;
				error(status, 'POST /depot call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const onPostDepotSuccess = (data, onSuccess = () => {}) => {
		dispatch(setDepotNumber(data?.depotNumber ?? null));
		dispatch(setClearingAccountIban(data?.clearingAccountIban ?? null));
		dispatch(setBankCode(data?.bankCode ?? null));

		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess(data);
	};

	const postActivateAccount = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		await axios
			.post(getApiBaseURL() + (isCreditCardSubType() ? '/creditcard' : currentFlow) + '/activate-account', {}, {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then((response) => {
				onActivateAccountSuccess(
					response.data,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST ' + (isCreditCardSubType() ? '/creditcard' : currentFlow) + '/activate-account call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError(status);
			});
	};

	const onActivateAccountSuccess = (
		data,
		onSuccess = () => {}
	) => {
		if (data.pan) {
			dispatch(setNewCreditCardPan(data.pan));
		}
		if (data.iban) {
			dispatch(setNewAccountIban(data.iban));
		}
		if (data.requestFromCrossSelling) {
			dispatch(setRequestFromCrossSelling(data.requestFromCrossSelling));
		}

		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess(data);
	};

	const postActivateAccountLoan = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();
		dispatch(updateAppLoading('default'));

		await axios
			.post(getApiBaseURL() + '/loans/activate-account', {}, {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then(response => {
				onPostActivateAccountLoan(
					response.data,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'POST /loans/activate-account call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				dispatch(updateAppLoading('none'));
				onError();
			});
	};

	const onPostActivateAccountLoan = (
		data,
		onSuccess = () => {}
	) => {
		if (data.loansAccountNumber) {
			dispatch(setLoansAccountNumber(data.loansAccountNumber));
		}

		stopApiCall();
		dispatch(updateAppLoading('none'));
		onSuccess(data);
	};

	const getAccountSwitchUpdate = async (
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();

		await axios
			.get(getApiBaseURL() + '/account-switch-update', {
				headers: {
					vcJwtToken: storeVcJwtToken
				}
			})
			.then(() => {
				onGetAccountSwitchUpdateSuccess(onSuccess);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'GET /account-switch-update call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				onError(status);
			});
	};

	const onGetAccountSwitchUpdateSuccess = (onSuccess) => {
		const iban = !isEmpty(storeNewAccountIban) ? replaceCharsForUrl(trimIban(storeNewAccountIban)) : null;
		const firstName = !isEmpty(storeFirstName) ? replaceCharsForUrl(storeFirstName.trim()) : null;
		const lastName = !isEmpty(storeLastName) ? replaceCharsForUrl(storeLastName.trim()) : null;
		const city = !isEmpty(storeCity) ? replaceCharsForUrl(storeCity.trim()) : null;
		const postCode = !isEmpty(storePostcode) ? replaceCharsForUrl(storePostcode.trim()) : null;
		const street = !isEmpty(storeStreet) ? replaceCharsForUrl(storeStreet.trim()).split('ü').join('%C3%B6') + (storeStreetNumber ? '%20' + replaceCharsForUrl(storeStreetNumber.trim()) : '') : null;
		const dateOfBirth = !isEmpty(storeBirthdate) ? storeBirthdate.trim() : null;
		const email = !isEmpty(storeEmail) ? replaceCharsForUrl(storeEmail.trim()) : null;

		window.open(
			'https://santander.meinkontowechsel.de' +
			`/init?targetIBAN=${iban}${firstName ? '&firstName=' + firstName : ''}${
				lastName ? '&lastName=' + lastName : ''
			}${city ? '&address.city=' + city : ''}${
				postCode ? '&address.postCode=' + postCode : ''
			}${street ? '&address.street=' + street : ''}${
				dateOfBirth ? '&dateOfBirth=' + dateOfBirth : ''
			}${email ? '&email=' + email : ''}`, '_blank'
		);

		stopApiCall();
		onSuccess();
	};

	return {
		postApplication,
		postApplicationShort,
		patchApplicationDepotUpdateIban,
		postDepot,
		postActivateAccount,
		postActivateAccountLoan,
		getAccountSwitchUpdate
	};
};
