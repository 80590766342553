import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, findIndex } from 'lodash';
import { usePageUtils } from 'utils/page/page';

import { rootRoutes } from 'routes/rootRoutes';

import SvgChevronLeftThin from 'components/Atoms/SVG/Icons/SvgChevronLeftThin';
import SvgChevronRightThin from 'components/Atoms/SVG/Icons/SvgChevronRightThin';

import styles from '../Dev.module.scss';

const Pagination = (props) => {
	const { goToPage, replaceSubpageVariable } = usePageUtils();

	const [flowRoutes, setFlowRoutes] = useState([]);
	const [currentPageIndex, setCurrentPageIndex] = useState(0);

	const [hasPrevPage, setHasPrevPage] = useState(false);
	const [hasNextPage, setHasNextPage] = useState(false);

	useEffect(() => {
		prepareRoutes();
	}, [props.reducers.page.currentFlow, props.reducers.page.currentSubpage]);

	const prepareRoutes = () => {
		const currentFlow = props.reducers.page.currentFlow;
		const currentSubpage = props.reducers.page.currentSubpage;
		if (currentFlow) {
			const routes = filter(rootRoutes, (route) => {
				return route.flow === currentFlow;
			});
			setFlowRoutes(routes);
			const storePath =
				currentFlow +
				(currentSubpage ? currentSubpage.trim() : '');
			const index = findIndex(routes, (route) => {
				const routePath =
					route.flow +
					(route.subpage ? route.subpage.trim() : '');
				return storePath === routePath;
			});
			setCurrentPageIndex(index);
			setHasPrevPage(index > 0);
			setHasNextPage(index < routes.length - 1);
		}
	};

	const goBack = () => {
		if (hasPrevPage) {
			goToPage(props.reducers.page.currentFlow + replaceSubpageVariable(flowRoutes[currentPageIndex - 1].subpage, true));
		}
	};

	const goNext = () => {
		if (hasNextPage) {
			goToPage(props.reducers.page.currentFlow + replaceSubpageVariable(flowRoutes[currentPageIndex + 1].subpage, true));
		}
	};

	return (
		<div className={styles.pagination}>
			<div className={styles.paginationItem}>
				<button
					className={styles.paginationButton}
					onClick={goBack}
					disabled={!hasPrevPage}
					title={hasPrevPage ? flowRoutes[currentPageIndex - 1].subpage : null}
				>
					<SvgChevronLeftThin />
				</button>
			</div>
			<div className={styles.paginationItem}>
				<button
					className={styles.paginationButton}
					onClick={goNext}
					disabled={!hasNextPage}
					title={hasNextPage ? flowRoutes[currentPageIndex + 1].subpage : null}
				>
					<SvgChevronRightThin />
				</button>
			</div>
		</div>
	);
};

Pagination.propTypes = {
	reducers: PropTypes.object
};

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default connect(mapStateToProps, null)(Pagination);
